import { render, staticRenderFns } from "./OItemsContainer.vue?vue&type=template&id=cad41c32&scoped=true&"
import script from "./OItemsContainer.vue?vue&type=script&lang=ts&"
export * from "./OItemsContainer.vue?vue&type=script&lang=ts&"
import style0 from "./OItemsContainer.vue?vue&type=style&index=0&id=cad41c32&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cad41c32",
  null
  
)

export default component.exports