export default (): Partial<CSSStyleDeclaration> => {
  const style: any = {};
  const percentage = Number(document.body.getAttribute('data-font-percentage'));
  if (percentage > 0) {
    const multiplier = String(((100 - percentage) / 100));
    // @ts-ignore
    style.zoom = multiplier;
    style.scale = multiplier;
    style.paddingLeft = '-10px';
    style['-moz-transform'] = multiplier;
    style['-o-transform'] = multiplier;
    style['-webkit-transform'] = multiplier;
    style.transform = multiplier;
  }
  return style;
};
