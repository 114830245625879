import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

/**
 * If need to mimic size of image. Expand as needed to include all the standard image sizes used on Liffery.
 * If width or height is equal to -1 then it means the image is scaled down according to dimension given for the other
 * side. Further calculations will be needed to figure out a suitable value to use there...
 *
 * @param imageSize Type: Size
 */
export interface returnDims {
  width: number,
  height: number,
  description?: string
}

export default (imageSize: Size): returnDims => {
  let width: number = 0, height: number = 0, description: string = '';
  // A lot of sizes follow a pattern of [\d]x[\d] - try a generic catch on that, otherwise run through the switch
  const match = imageSize.match(/^(\d+)x(\d+)$/);
  if (match && match.length === 3) {
    width = parseInt(match[1]);
    height = parseInt(match[2]);
  } else {
    // The rest of the image sizes are not exact and may need a description returning to help dev
    switch (imageSize) {
      case Size.Mx800X:
        width = 800;
        height = -1;
        description = 'Max width of 800 pixels, smaller images won\'t scale up';
        break;

      case Size.X400mx:
        width = -1;
        height = 400;
        description = 'Max height of 400 pixels, smaller images won\'t scale up';
        break;
    }
  }
  const desc = description ? description : undefined;
  return {
    width,
    height,
    description: desc
  };
};