import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { getModule } from 'vuex-module-decorators';

import persist from '@/store/plugins/persist';
import EventBus, { EventBusEvents } from '@/EventBus';
import { StoreNames } from '@/store/modules/enums/StoreNames';

import AuthenticationModule from '@/store/modules/AuthenticationModule';
import { IAuthenticationModule } from '@/store/modules/interfaces/AuthenticationModule';

import ChannelModule from '@/store/modules/ChannelModule';
import { IChannelModule } from '@/store/modules/interfaces/ChannelModule';

import ChannelViewModule from '@/store/modules/ChannelViewModule';
import { IChannelViewModule } from '@/store/modules/interfaces/ChannelViewModule';

import ChannelsModule from '@/store/modules/ChannelsModule';
import { IChannelsModule } from '@/store/modules/interfaces/ChannelsModule';

import ItemDetailModule from '@/store/modules/ItemDetailModule';
import { IItemDetailModule } from '@/store/modules/interfaces/ItemDetailModule';

import ItemEntryModule from '@/store/modules/ItemEntryModule';
import { IItemEntryModule } from '@/store/modules/interfaces/ItemEntryModule';

import ItemsModule from '@/store/modules/ItemsModule';
import { IItemsModule } from '@/store/modules/interfaces/ItemsModule';

import LifferyTourModule from '@/store/modules/LifferyTourModule';
import { ILifferyTourModule } from '@/store/modules/interfaces/LifferyTourModule';

import NavigationModule from '@/store/modules/NavigationModule';
import { INavigationModule } from '@/store/modules/interfaces/NavigationModule';

import NotificationsModule from '@/store/modules/NotificationsModule';
import { INotificationsModule } from '@/store/modules/interfaces/NotificationsModule';

import PeopleModule from '@/store/modules/PeopleModule';
import { IPeopleModule } from '@/store/modules/interfaces/PeopleModule';

import PinnedItemsModule from '@/store/modules/PinnedItemsModule';
import { IPinnedItemsModule } from '@/store/modules/interfaces/PinnedItemsModule';

import RecommendationDetailModule from '@/store/modules/RecommendationDetailModule';
import { IRecommendationDetailModule } from '@/store/modules/interfaces/RecommendationDetailModule';

import SearchSettingsModule from '@/store/modules/SearchSettingsModule';
import { ISearchSettingsModule } from '@/store/modules/interfaces/SearchSettingsModule';

import ShoppingListModule from '@/store/modules/ShoppingListModule';
import { IShoppingListModule } from '@/store/modules/interfaces/ShoppingListModule';

import WatchersModule from '@/store/modules/WatchersModule';
import { IWatchersModule } from '@/store/modules/interfaces/WatchersModule';

import isBrowserExt from '@/utils/isBrowserExt';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

// Declare the initial state interface
export interface IRootState {
  version: string;
  [StoreNames.AUTHENTICATION_STORE]: IAuthenticationModule;
  [StoreNames.CHANNEL_STORE]: IChannelModule;
  [StoreNames.CHANNEL_VIEW_STORE]: IChannelViewModule;
  [StoreNames.CHANNELS_STORE]: IChannelsModule;
  [StoreNames.ITEM_DETAIL_STORE]: IItemDetailModule;
  [StoreNames.ITEM_ENTRY_STORE]: IItemEntryModule;
  [StoreNames.ITEMS_STORE]: IItemsModule;
  [StoreNames.LIFFERY_TOUR_STORE]: ILifferyTourModule;
  [StoreNames.NOTIFICATIONS_STORE]: INotificationsModule;
  [StoreNames.NAVIGATION_STORE]: INavigationModule;
  [StoreNames.PEOPLE_STORE]: IPeopleModule;
  [StoreNames.PINNED_ITEMS_STORE]: IPinnedItemsModule;
  [StoreNames.RECOMMENDATION_DETAIL_STORE]: IRecommendationDetailModule;
  [StoreNames.SEARCH_SETTINGS_STORE]: ISearchSettingsModule;
  [StoreNames.SHOPPING_LIST_STORE]: IShoppingListModule;
  [StoreNames.WATCHERS_STORE]: IWatchersModule;
}

let plugins: any[] = [];
if (!isBrowserExt()) {
  // We do not want to persist anything when the user is
  // using the browser extension as this prevents the main
  // app from loggin the browser extension out... ie the
  // main app has zero access to the browser plugin local data
  plugins = [
    vuexLocal.plugin,
    persist
  ];
}

// Create the store
const store = new Vuex.Store<IRootState>({
  modules: {
    [StoreNames.AUTHENTICATION_STORE]: AuthenticationModule,
    [StoreNames.CHANNEL_STORE]: ChannelModule,
    [StoreNames.CHANNEL_VIEW_STORE]: ChannelViewModule,
    [StoreNames.CHANNELS_STORE]: ChannelsModule,
    [StoreNames.ITEM_DETAIL_STORE]: ItemDetailModule,
    [StoreNames.ITEM_ENTRY_STORE]: ItemEntryModule,
    [StoreNames.ITEMS_STORE]: ItemsModule,
    [StoreNames.LIFFERY_TOUR_STORE]: LifferyTourModule,
    [StoreNames.NOTIFICATIONS_STORE]: NotificationsModule,
    [StoreNames.NAVIGATION_STORE]: NavigationModule,
    [StoreNames.PEOPLE_STORE]: PeopleModule,
    [StoreNames.PINNED_ITEMS_STORE]: PinnedItemsModule,
    [StoreNames.RECOMMENDATION_DETAIL_STORE]: RecommendationDetailModule,
    [StoreNames.SEARCH_SETTINGS_STORE]: SearchSettingsModule,
    [StoreNames.SHOPPING_LIST_STORE]: ShoppingListModule,
    [StoreNames.WATCHERS_STORE]: WatchersModule,
  },
  plugins
});

// Export the modules for external access
export const AuthenticationStore = getModule(AuthenticationModule, store);
export const ChannelStore = getModule(ChannelModule, store);
export const ChannelViewStore = getModule(ChannelViewModule, store);
export const ChannelsStore = getModule(ChannelsModule, store);
export const ItemDetailStore = getModule(ItemDetailModule, store);
export const ItemEntryStore = getModule(ItemEntryModule, store);
export const ItemsStore = getModule(ItemsModule, store);
export const LifferyTourStore = getModule(LifferyTourModule, store);
export const NotificationsStore = getModule(NotificationsModule, store);
export const NavigationStore = getModule(NavigationModule, store);
export const PeopleStore = getModule(PeopleModule, store);
export const PinnedItemsStore = getModule(PinnedItemsModule, store);
export const RecommendationDetailStore = getModule(RecommendationDetailModule, store);
export const SearchSettingsStore = getModule(SearchSettingsModule, store);
export const ShoppingListStore = getModule(ShoppingListModule, store);
export const WatchersStore = getModule(WatchersModule, store);

// Register the reset methods here - ie when someone logs out - nuke the stores
export const clearAll = () => {
  AuthenticationStore.RESET();
  ChannelStore.RESET();
  ChannelViewStore.RESET();
  ChannelsStore.RESET();
  ItemDetailStore.RESET();
  ItemEntryStore.RESET();
  ItemsStore.RESET();
  LifferyTourStore.RESET();
  NavigationStore.RESET();
  NotificationsStore.RESET();
  PeopleStore.RESET();
  PinnedItemsStore.RESET();
  RecommendationDetailStore.RESET();
  SearchSettingsStore.RESET();
  ShoppingListStore.RESET();
  WatchersStore.RESET();
};
EventBus.$on(EventBusEvents.AUTH_LOGOUT, 'store.index', clearAll);

// last but not least, export the store
export default store;
