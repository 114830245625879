
import { Component, Vue } from 'vue-property-decorator';
import { CheckIcon, Trash2Icon, XIcon } from 'vue-feather-icons';

@Component({
  components: {
    Trash2Icon,
    CheckIcon,
    XIcon
  }
})

export default class ADeleteIcon extends Vue {
  sureShow = false;

  sureShowToggle () {
    this.sureShow = !this.sureShow;
  }
}
