export interface UserGetQuery {
  /**
   * Web, iOS, Android etc etc
   */
  fromAppType?: FromAppType;
}

/**
 * Web, iOS, Android etc etc
 */
export enum FromAppType {
  Android = 'android',
  ChromeExt = 'chromeExt',
  Ios = 'ios',
  Web = 'web',
}
