import HttpService from '@/services/HttpService';

import { ChannelChannelIdGetPath } from './interfaces/ChannelChannelIdGetPath';
import { ChannelChannelIdGetQuery } from './interfaces/ChannelChannelIdGetQuery';
import { ChannelChannelIdPost } from './interfaces/ChannelChannelIdPost';
import { ChannelChannelIdPostPath } from './interfaces/ChannelChannelIdPostPath';
import { ChannelChannelIdPostQuery } from './interfaces/ChannelChannelIdPostQuery';
import { Chat } from './interfaces/Chat';
import { Chats } from './interfaces/Chats';

export default class ChannelService {
  public static basePath = '/__/ms-chat/';

  /**
   *  Operation ID: channelChannelIdGet
   *  Summary: Get a list chat messages for a given id
   *  Description:
   */
  public static channelChannelIdGet(
    pathParams: ChannelChannelIdGetPath,
    query: ChannelChannelIdGetQuery
  ): Promise<Chats> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelService.basePath + 'channel/:channelId',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelChannelIdPost
   *  Summary: Create a new chat message
   *  Description:
   */
  public static channelChannelIdPost(
    body: ChannelChannelIdPost,
    pathParams: ChannelChannelIdPostPath,
    query: ChannelChannelIdPostQuery
  ): Promise<Chat> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ChannelService.basePath + 'channel/:channelId',
      body,
      params: pathParams,
      qs: query,
    });
  }
}
