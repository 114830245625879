
import { Component, Prop, Vue } from 'vue-property-decorator';
import ADefaultSvgFromMaterialDesign from '@/components/atoms/icon/svg/ADefaultSvgFromMaterialDesign.vue';

@Component({
  components:{
    ADefaultSvgFromMaterialDesign
  }
})
export default class AFoldersSvgIcon extends Vue {
  @Prop({ default: 1 })
  size!: number;
  @Prop({default: true})
  keepYZero!: boolean;
}
