import { render, staticRenderFns } from "./OChannelItemsTable.vue?vue&type=template&id=26f88e10&scoped=true&"
import script from "./OChannelItemsTable.vue?vue&type=script&lang=ts&"
export * from "./OChannelItemsTable.vue?vue&type=script&lang=ts&"
import style0 from "./OChannelItemsTable.vue?vue&type=style&index=0&id=26f88e10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26f88e10",
  null
  
)

export default component.exports