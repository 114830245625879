
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { ActivityIcon } from 'vue-feather-icons';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: {
    ATooltip,
    ActivityIcon
  }
})
export default class ARouterLinkSettingWatchers extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  @Prop({ default: true })
  hasText!: boolean;

  @Prop({ default: '1x' })
  iconSize!: string;

  name = RouteNames.ROUTE_SETTINGS_WATCHERS;
}
