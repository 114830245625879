
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SettingsIcon } from 'vue-feather-icons';

import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import MSearchBarTabs from '@/components/molecules/MSearchBarTabs.vue';
import MSearchInput, { MSearchInputOutput } from '@/components/molecules/MSearchInput.vue';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';

export interface MSearchBarWithTabsIOutput {
  search: string;
  selectedTab: ASearchBarTabType;
  contentCategory?: string;
}

@Component({
  components: {
    MSearchBarTabs,
    ASearchInput: MSearchInput,
    SettingsIcon
  }
})
export default class MSearchBarWithTabs extends Vue {
  @Prop({
    default: () => {
      return [
        ASearchBarTabType.items,
        ASearchBarTabType.pinned,
        ASearchBarTabType.channels,
        ASearchBarTabType.people,
        ASearchBarTabType.explore,
      ];
    }
  })
  tabs!: string[];

  @Prop({ default: ASearchBarTabType.items })
  selectedTab!: ASearchBarTabType;

  output: MSearchBarWithTabsIOutput = {
    search: '',
    selectedTab: this.selectedTab,
    contentCategory: undefined
  };

  searchMessageShow = false;

  searchPlaceholder (): string {
    return `searchPlaceholder['${this.selectedTab}']`;
  }

  emitSearch () {
    this.searchMessageShow = this.tabs.includes(ASearchBarTabType.explore) && this.output.selectedTab === ASearchBarTabType.items && this.output.search.length > 0;
    // note - push route before emitting, as T components may also push into route which may need to overwrite this
    this.pushRoute().then(() => {
      // Emit the changes to the parent
      this.$emit(CHILD_EMIT, {
        search: this.output.search,
        selectedTab: this.output.selectedTab,
        contentCategory: this.output.contentCategory
      } as MSearchBarWithTabsIOutput);
    }).catch(console.error);
  }

  // keep the URl updated with the current search string and tab when switching between tabs
  async pushRoute () {
    // always add the tab into the query if a search is emitted
    const urlParams: any = { tab: this.output.selectedTab };
    if( this.output.search.length ){
      urlParams.text = this.output.search;
    }
    if (this.selectedTab === ASearchBarTabType.explore && this.output.contentCategory !== 'all') {
      urlParams.category = this.output.contentCategory;
    }
    const query: any = urlParams;
    // ensure if text is not present in new params, then it's deleted in the new query
    if(!urlParams.text || !urlParams.text.length){
      delete query.text;
    }
    await this.$router.push({ query });
  }

  handleUserSearch (input: MSearchInputOutput) {
    this.output.search = input.search;
    this.output.contentCategory = input.contentCategory;
    this.emitSearch();
  }

  handleHashTagClick (input: MSearchInputOutput) {
    this.output.search = input.search;
    this.output.selectedTab = ASearchBarTabType.explore;
    this.emitSearch();
  }

  handleTabSwitch (tab) {
    this.output.selectedTab = tab;
    this.emitSearch();
  }

  loadExploreTab () {
    this.handleTabSwitch(ASearchBarTabType.explore);
  }
}
