
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { format } from 'timeago.js';
import { Getgot, LatestStage } from '@/api/ms-item/services/interfaces/Item';
import { User } from '@/api/ms-authentication/services/interfaces/Login';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { AuthenticationStore } from '@/store';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import { RouteNames } from '@/router/RouteNames';

import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ADeleteIcon from '@/components/atoms/icon/ADeleteIcon.vue';
import { CheckSquareIcon, Trash2Icon } from 'vue-feather-icons';
import toggleGetGotState from '@/utils/toggleGetGotState';
import ItemService from '@/api/ms-item/services/ItemService';
import updateItemInStores from '@/utils/updateItemInStores';
import isTouchScreen from '@/utils/isTouchScreen';

@Component({
  components: {
    ADeleteIcon,
    AImage,
    AUserProfilePic,
    CheckSquareIcon,
    Trash2Icon
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    removeUrl: remove1stUrlFromString,
  },
})
export default class MShoppingListHeaderItem extends Vue {
  @Prop({ default: false })
  item!: Item;

  @Prop()
  dropdownVisible!: boolean;

  name = RouteNames.ROUTE_GETGOT;
  size = Size.The60X60;
  currentUser: User = AuthenticationStore.currentUser;
  stageNames = LatestStage;
  itemGot: string = '';
  itemRemoved: string = '';
  displayShoppingListControlTooltips: boolean = true;

  /**
   * The component isn't destroyed when the dropdown visibility changes, this is the workaround to reset the individual
   * header item states between dropdown visibility. E.g. If something is got or removed from the list.
   */
  @Watch('dropdownVisible')
  resetStatesOnClose () {
    if (!this.dropdownVisible) {
      this.itemGot = '';
      this.itemRemoved = '';
    }
  }

  get isGot () {
    return (this.itemGot === this.item.uniqueItemName && String(this.myItem?.latestStage) === this.stageNames.StageGot);
  }

  // Shopping list state can be edited in the header, myItem placed into getter to keep up to date
  get myItem (): Getgot | null {
    return this.item.getgot.filter(item => item.actor.username === this.currentUser.username)[0];
  }

  created () {
    this.displayShoppingListControlTooltips = !isTouchScreen();
  }

  hasItemImage (): boolean {
    return !!this.item?.urlCache?.image?.href;
  }

  hasUserDescription (): boolean {
    return this.item.editable && this.$options.filters?.removeUrl(this.item.editable.text).length > 0;
  }

  hasUserImage (): boolean {
    return !!this.item?.userPhotos && this.item.userPhotos.length > 0;
  }

  async toggleGotItState (e) {
    e.preventDefault();
    if (this.myItem) {
      const newState = await toggleGetGotState(this.item.uniqueItemName, this.myItem);
      // Change the state of this item just for this component depending on what state it changed to
      this.myItem.latestStage = newState as unknown as LatestStage;
      this.itemGot = this.myItem.latestStage === this.stageNames.StageGot ? this.item.uniqueItemName : '';
    }
  }

  async removeFromList (e) {
    e.preventDefault();
    if (this.myItem && this.myItem._id) {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdDelete({
        id: this.myItem._id,
        uniqueItemName: this.item.uniqueItemName
      });
      updateItemInStores(newItem);

      // Change state on a timeout otherwise race condition happens between this and mouseup and it closes the dropdown
      setTimeout(() => {
        this.itemRemoved = this.item.uniqueItemName;
      }, 50);
    }
  }

  async removeFromListUndo (e) {
    e.preventDefault();
    if (this.myItem && this.myItem._id) {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdUndoDeletePut({
        id: this.myItem._id,
        uniqueItemName: this.item.uniqueItemName
      });
      updateItemInStores(newItem);

      setTimeout(() => {
        this.itemRemoved = '';
      }, 50);
    }
  }
}
