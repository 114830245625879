export interface UrlPreview {
  backgroundRescrape?: boolean;
  /**
   * ISO 3166-1 Alpha-2 code
   */
  countryCode?: string;
  finished: boolean;
  image?: Image;
  meta?: Meta;
  price?: Price;
  /**
   * State of URL preview parsing. When preview object created sets to as toFetch, then update
   * while scraping then to either fetched or failed accordingly. Should always finish as
   * either fetched or failed.
   */
  status?: Status;
  url: string;
}

export interface Image {
  format?: string;
  height?: number;
  href?: string;
  width?: number;
}

export interface Meta {
  availability?: string;
  brand?: string;
  canonical?: string;
  description?: string;
  keywords?: string[];
  /**
   * As set by the og:locale meta tag
   */
  locale?: string;
  site?: string;
  title: string;
  /**
   * The title but with any camelCase or non-standard word_breaks turned into spaces for easy
   * searching against. i.e. Searching 'Sushi' returns 'Lisbon FunSushiBar' or 'Escape
   * Dungeons' returns 'Escape Room @york.dungeons.minster'
   */
  titleBreakWords?: string;
  /**
   * Type as set by og:type meta tag
   */
  type?: string;
}

export interface Price {
  createdAt?: Date;
  /**
   * Currency code, e.g. GBP
   */
  currency?: string;
  /**
   * How the currency is represented on screen, e.g. £. It's not always a symbol, some
   * countries use letters. E.g. R for Rand. Dhs for Dihram. (Not currently used)
   */
  currencyRepresentation?: string;
  /**
   * Undefined when the price is in progress. When definitively determined there is no price
   * will be set true.
   */
  noPrice?: boolean;
  /**
   * Price extracted from the website
   */
  price?: number;
  priceGotBy: PriceGotBy;
  updatedAt?: Date;
}

export enum PriceGotBy {
  InProgress = 'inProgress',
  OpenAi = 'openAi',
  Scraper = 'scraper',
}

/**
 * State of URL preview parsing. When preview object created sets to as toFetch, then update
 * while scraping then to either fetched or failed accordingly. Should always finish as
 * either fetched or failed.
 */
export enum Status {
  Failed = 'failed',
  Fetched = 'fetched',
  Fetching = 'fetching',
  NotFound = 'notFound',
  ToFetch = 'toFetch',
}
