import { DialogProgrammatic as Dialog } from 'buefy';
import { i18n } from '@/plugins/i18n';
import { ImageUploadError } from '@/storybook-components/src/stories/atoms/buttons/AUploadImage.vue';

export interface ImageUploadParameters {
  dimensionRequirements?: string; // one to add in the future
  fileSizeLimit?: number;
  imageTypesAllowed?: string[];
}

export default (error: ImageUploadError, imageUploadParameters: ImageUploadParameters) => {
  switch (error) {
    case ImageUploadError.fileSizeError:
      Dialog.alert({
        title: i18n.t('errors.imageUpload.fileSizeError.title') as string,
        message: i18n.t('errors.imageUpload.fileSizeError.message', { maxSize: imageUploadParameters.fileSizeLimit }) as string
      });
      break;
    case ImageUploadError.fileTypeError: {
      if(imageUploadParameters.imageTypesAllowed){
        const typesWeAllow = imageUploadParameters.imageTypesAllowed.map((type) => '.' + type.split('/')[1]).join(', ');
        Dialog.alert({
          title: i18n.t('errors.imageUpload.typeError.title') as string,
          message: i18n.t('errors.imageUpload.typeError.message', { supportedTypes: typesWeAllow }) as string
        });
      }
      break;
    }
  }
};
