
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { Item, ItemNameUniqueItemNameReportPost, } from '@/api/ms-item/services/interfaces';
import { AuthenticationStore } from '@/store';
import ItemService from '@/api/ms-item/services/ItemService';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import AMessage from '@/components/atoms/AMessage.vue';
import { XIcon } from 'vue-feather-icons';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
    XIcon,
    AMessage
  },
})
export default class OItemReportContentForm extends Vue {
  @Prop({ required: true })
  item!: Item;

  loading = false;
  emptyForm = {
    item: this.item,
    unAuthenticatedReporter: {
      email: '',
      fullName: '',
    },
    reporterComment: '',
  };
  form: ItemNameUniqueItemNameReportPost = this.emptyForm;
  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };
  error: string = '';

  get getAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  mounted () {
    this.$refs.form;
  }

  handleCloseMessage (type: string) {
    this[type] = '';
  }

  async onSubmit () {
    // https://logaretm.github.io/vee-validate/guide/forms.html#programmatic-access-with-refs
    if (!(await this.$refs.form.validate())) {
      return;
    }

    try {
      await ItemService.itemNameUniqueItemNameReportPost(this.form, {
        uniqueItemName: this.item.uniqueItemName,
      });

      this.$buefy.snackbar.open({
        message: this.$t('dict.reportItemSuccess').toString(),
        queue: false,
        type: 'is-success',
        position: 'is-top',
      });

      this.$emit('child-output');
      this.form = this.emptyForm;
      await this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (e: any) {
      if (e.response.status === 422) {
        this.error = 'reportItemFailed422';
      } else {
        this.error = 'reportItemFailedGeneral';
      }
    }
  }
}
