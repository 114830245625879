export interface UserSettingsAppSettingsSearchSettings {
  channelsSearchSettings: ChannelsSearchSettings;
  itemsSearchRecents: string[];
}

export interface ChannelsSearchSettings {
  sortBy: SortBy;
  sortDir: SortDir;
}

export enum SortBy {
  CreatedAt = 'createdAt',
  ItemLastAdded = 'itemLastAdded',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Username = 'username',
}

export enum SortDir {
  Asc = 'asc',
  Desc = 'desc',
}
