
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

// Types / interfaces / enums
import { ChannelType, EventType, Privacy, RepetitionSettings } from '@/api/ms-channel/services/interfaces/ChannelPost';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import { ControlEnum } from '@/components/molecules/MWysiwygTipTapFixedMenuHeader.vue';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { types as imageTypes } from '@/constants/imageTypes';
import {
  OChannelUpsertFormInterfacesChildOutput
} from '@/components/molecules/itemEntry/MItemEntryChannelSelector.vue';

// 1st party components
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import AImage from '@/components/atoms/AImage.vue';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import MChannelConciergeSettingsQuantity from '@/components/molecules/forms/MChannelConciergeSettingsQuantity.vue';
import MChannelConciergeSettingsUrgency from '@/components/molecules/forms/MChannelConciergeSettingsUrgency.vue';
import MChannelConciergeSettingsConciergeType
  from '@/components/molecules/forms/MChannelConciergeSettingsConciergeType.vue';
import MChannelInvitations from '@/components/molecules/MChannelInvitations.vue';
import MChannelPrivacySettingsPrivacyType from '@/components/molecules/forms/MChannelPrivacySettingsPrivacyType.vue';
import MChannelPrivacySettingsWhoCanAdd from '@/components/molecules/forms/MChannelPrivacySettingsWhoCanAdd.vue';
import MChannelBasicsChannelType from '@/components/molecules/forms/MChannelBasicsChannelType.vue';
import MEventRepetitionOptions from '@/components/molecules/MEventRepetitionOptions.vue';
import MGeolocationAutocomplete from '@/components/molecules/forms/MGeolocationAutocomplete.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import MUploadImagePreview, { MUploadImagePreviewImageItem } from '@/components/molecules/MUploadImagePreview.vue';
import MUploadOrGenerateImage, { UploadOrGenerateType } from '@/components/molecules/MUploadOrGenerateImage.vue';
import OImageCropperModal from '@/components/organisms/OImageCropperModal.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import OWysiwygTiptap from '@/components/organisms/OWysiwygTiptap.vue';

// 3rd party components
import { CameraIcon } from 'vue-feather-icons';
import DatePicker from 'vue2-datepicker';

// Methods
import ChannelService from '@/api/ms-channel/services/ChannelService';
import CurrenciesService from '@/api/ms-static-api-data/services/CurrenciesService';
import convertBase64ToBlob from '@/utils/convertBase64ToBlob';
import fileReaderToBase64Preview from '@/utils/fileReaderToBase64Preview';
import getBase64ImageFromUrl from '@/utils/getBase64ImageFromUrl';
import seoInjector from '@/services/SeoInjector';
import { DatePickerLang, getLang } from '@/utils/vue2DatepickerLocale';
import { camelCaseToSentenceCase, titleCase } from 'common-utils/string';

// Vuex
import { ChannelsStore } from '@/store';
import { translation } from '@/plugins/i18n/Translation';

// Other
import MChannelCustomFields from '@/components/molecules/forms/MChannelCustomFields.vue';
import { FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import ChannelHttpDataService, { EventSettings } from '@/services/ChannelHttpDataService';
import { ImageGenerationProvider } from '@/api/ms-channel/services/interfaces/ChannelSlugSlugImageGeneratedPutQuery';
import {
  AUploadImageOutput,
  clearImageOutput
} from '@/storybook-components/src/stories/atoms/buttons/AUploadImage.vue';

@Component({
  components: {
    MChannelCustomFields,
    AButtonSubmit,
    ACloseModalButton,
    AImage,
    ATooltip,
    MChannelBasicsChannelType,
    MChannelConciergeSettingsConciergeType,
    MChannelConciergeSettingsQuantity,
    MChannelConciergeSettingsUrgency,
    MChannelInvitations,
    MChannelPrivacySettingsPrivacyType,
    MChannelPrivacySettingsWhoCanAdd,
    MEventRepetitionOptions,
    MGeolocationAutocomplete,
    MInputWithValidation,
    MRadioCards,
    MUploadImagePreview,
    MUploadOrGenerateImage,
    OImageCropperModal,
    OModalWrapper,
    OWysiwygTiptap,
    CameraIcon,
    DatePicker,
    ValidationObserver,
  },
  filters: {
    titleCase: titleCase,
    camelCaseToSentenceCase: camelCaseToSentenceCase
  },
  methods: {
    camelCaseToSentenceCase: camelCaseToSentenceCase
  }
})
export default class OChannelUpsertFormStepped extends Vue {
  @Prop(String)
  channelSlug?: string;

  @Prop({ default: false })
  simpleForm!: boolean;

  activeStep = 0;

  datePickerLang: DatePickerLang['lang'] = 'en';
  eventDate: Date | null = null;
  eventDateOpenSync: boolean = false;
  eventTime: Date | null = null;
  eventTimeOpenSync: boolean = false;
  eventType: EventType = EventType.OneOff;
  eventRepetition: RepetitionSettings = {
    day: 0
  };
  editing: boolean = false;
  channelNameMinLength: number = 4;
  visible: boolean = false;
  privacyOptions = Privacy;
  channelTypeOptions = ChannelType;
  newChannelImage: AUploadImageOutput = clearImageOutput;
  newChannelHeroImage: AUploadImageOutput = clearImageOutput;
  previewChannelHeroImage: MUploadImagePreviewImageItem[] = [];
  previewChannelImage: MUploadImagePreviewImageItem[] = [];
  loading: boolean = false;
  imageSize = Size.The100X100;
  heroImageSize = Size.The950X;
  imageTypesAllowed = imageTypes;
  addAnimationTo: string[] = [];
  showCropperHeroModal: boolean = false;
  showCropperThumbModal: boolean = false;
  clearCropperHeroImage: number = 0;
  clearCropperThumbImage: number = 0;
  userAddedMeta: boolean = false;
  controlEnum = ControlEnum;

  uniqueCurrencies: Currency[] = [];

  channelImageUploadOrGenerate: UploadOrGenerateType | null = null;
  channelHeroUploadOrGenerate: UploadOrGenerateType | null = null;

  channelImageTypes = {
    thumbnail: 'thumbnail',
    hero: 'hero',
  };

  $refs!: {
    heroContainer: HTMLElement,
    btnUploadHero: HTMLElement,
    btnUploadThumb: HTMLElement
  };

  get channel () {
    return ChannelsStore.getChannelEditing as any;
  }

  get language () {
    return translation.currentLanguage;
  }

  get isResearchConcierge () {
    return this.channel.channelType === this.channelTypeOptions.ResearchConcierge;
  }

  getLocale () {
    this.datePickerLang = getLang(this.language);
  }

  formatDate () {
    return 'YYYY-MM-DD';
  }

  formatTime () {
    return 'HH:mm';
  }

  // Date must be in the future, tomorrow is the earliest date
  disabledDate (date) {
    // return false;
    return date < new Date();
  }

  // If no time is selected, insert 6am as the default
  insertDefaultTime () {
    if (!this.eventTime) {
      this.eventTime = new Date();
      this.eventTime.setHours(6);
      this.eventTime.setMinutes(0);
    }
  }

  // When user selects a minute, close the popup
  closeOnMinuteSelect (value, type) {
    if (type === 'minute') {
      this.eventTimeOpenSync = false;
    }
  }

  saveEventType (input) {
    this.eventType = input.eventType;
    this.eventRepetition = input.repetitions;
  }

  canSubmit () {
    let returnVal = true;
    const channelName = this.channel.name.trim();
    if (channelName === '' || channelName.length < 4) {
      this.highlightChannelName();
      returnVal = false;
    }
    if (this.channel.channelType === ChannelType.Event && (typeof this.eventDate === 'undefined' || this.eventDate === null)) {
      this.highlightEventDate();
      returnVal = false;
    }
    return returnVal;
  }

  async created () {
    this.getLocale();
    this.uniqueCurrencies = (await CurrenciesService.currenciesUniqueGet()).data;
  }

  mounted () {
    if (this.channelSlug) {
      this.editing = true;
      setTimeout(() => {
        // highlight relevant button for upload or generate
        this.channelImageUploadOrGenerate = this.channel.imagePathCredit ? UploadOrGenerateType.Generate : UploadOrGenerateType.Upload;
        this.channelHeroUploadOrGenerate = this.channel.imageHeroPathCredit ? UploadOrGenerateType.Generate : UploadOrGenerateType.Upload;
        // Initiate the date settings for event channels, but only if it's in the future, if an event has passed and is being edited a new date must be set
        if (this.channel.channelType === this.channelTypeOptions.Event && new Date(this.channel.event.eventDate) > new Date()) {
          this.eventDate = new Date(this.channel.event.eventDate);
          this.eventTime = new Date(this.channel.event.eventDate);
          this.eventType = this.channel.event.eventType;
        }
      }, 500);
    }
  }

  beforeDestroy () {
    seoInjector.clearPageTitle();
  }

  // eslint-disable-next-line max-lines-per-function
  async upsertChannel () {
    if (!this.canSubmit()) {
      return;
    }
    this.loading = true;
    const action = this.channelSlug ? 'updated' : 'created';

    const eventChannelSettings: EventSettings = {
      eventDate: this.eventDate,
      eventTime: this.eventTime,
      eventType: this.eventType,
      eventRepetition: this.eventRepetition
    };

    const savedChannel = this.channelSlug
      ? await ChannelService.channelSlugSlugPut(ChannelHttpDataService.PUT(this.channel, eventChannelSettings), { slug: this.channelSlug, })
      : await ChannelService.channelPost(ChannelHttpDataService.POST(this.channel, eventChannelSettings));

    if (this.previewChannelImage.length) {
      const block = this.previewChannelImage[0].preview.split(';');
      const contentType = block[0].split(':')[1];

      this.channelImageUploadOrGenerate === UploadOrGenerateType.Upload ?
        await ChannelService.channelSlugSlugImagePut(
          { image: convertBase64ToBlob(this.previewChannelImage[0].preview, contentType) },
          { slug: savedChannel.slug }
        ) :
        await ChannelService.channelSlugSlugImageGeneratedPut(
          { slug: savedChannel.slug },
          {
            imageGenerationUrl: this.previewChannelImage[0].generationUrl,
            imageGenerationCredits: this.previewChannelImage[0].credits,
            imageGenerationProvider: this.previewChannelImage[0].provider as unknown as ImageGenerationProvider
          }
        );
    }

    if (this.previewChannelHeroImage.length) {
      const block = this.previewChannelHeroImage[0].preview.split(';');
      const contentType = block[0].split(':')[1];

      this.channelHeroUploadOrGenerate === UploadOrGenerateType.Upload ?
        await ChannelService.channelSlugSlugImageHeroPut(
          { image: convertBase64ToBlob(this.previewChannelHeroImage[0].preview, contentType) },
          { slug: savedChannel.slug }
        ) :
        await ChannelService.channelSlugSlugImageHeroGeneratedPut(
          { slug: savedChannel.slug },
          {
            imageGenerationUrl: this.previewChannelHeroImage[0].generationUrl,
            imageGenerationCredits: this.previewChannelHeroImage[0].credits,
            imageGenerationProvider: this.previewChannelHeroImage[0].provider as unknown as ImageGenerationProvider
          }
        );
    }
    this.$emit(CHILD_EMIT, { channel: savedChannel, action } as OChannelUpsertFormInterfacesChildOutput);
    await ChannelsStore.fetchChannelsManagerOf();
    this.loading = false;
  }

  setModal () {
    if (!this.canSubmit()) {
      return;
    }
    this.visible = true;
    if (!this.previewChannelImage.length && !this.previewChannelHeroImage.length) {
      this.addAnimationTo = ['btnUploadHero', 'btnUploadThumb'];
    } else if (!this.previewChannelImage.length) {
      this.addAnimationTo = ['btnUploadThumb'];
    } else if (!this.previewChannelHeroImage.length) {
      this.addAnimationTo = ['btnUploadHero'];
    }
  }

  hideModal () {
    this.addAnimationTo.forEach(ref => this.$refs[ref].classList.remove('shake-animation'));
    this.visible = false;

    this.$refs.heroContainer.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });

    this.addAnimationTo.forEach(ref => this.$refs[ref].classList.add('shake-animation'));
  }

  clipImage (image: string, type: string): void {
    if (type === this.channelImageTypes.thumbnail) {
      this.previewChannelImage = [{
        preview: image
      }];
      this.showCropperThumbModal = false;
    } else {
      this.previewChannelHeroImage = [{
        preview: image
      }];
      this.showCropperHeroModal = false;
    }
  }

  async handleUploadThumbImage (file: Blob): Promise<void> {
    this.previewChannelImage = [{
      preview: await fileReaderToBase64Preview(file)
    }];
  }

  clearChannelThumb (): void {
    ++this.clearCropperThumbImage;
    this.showCropperThumbModal = false;
    this.newChannelImage = clearImageOutput;
    this.previewChannelImage = [];
    this.channelImageUploadOrGenerate = null;
  }

  clearChannelHero (): void {
    ++this.clearCropperHeroImage;
    this.showCropperHeroModal = false;
    this.newChannelHeroImage = clearImageOutput;
    this.previewChannelHeroImage = [];
    this.channelHeroUploadOrGenerate = null;
  }

  hasChannelImage (): boolean {
    return !this.newChannelImage.src && this.channel.imagePath;
  }

  hasChannelHeroImage (): boolean {
    return !this.newChannelHeroImage.src && this.channel.imageHeroPath;
  }

  uploadOrGenerateChannelImageHandle (input: { type: UploadOrGenerateType, image?: AUploadImageOutput }) {
    this.channelImageUploadOrGenerate = input.type;
    if (input.type === UploadOrGenerateType.Upload && input.image) {
      this.newChannelImage = input.image;
      this.showCropperThumbModal = true;
    } else {
      this.getStoreGeneratedImage(false).catch(console.error);
    }
  }

  async uploadOrGenerateCoverImageHandle (input: { type: UploadOrGenerateType, image?: AUploadImageOutput }) {
    console.log('New image', input);
    this.channelHeroUploadOrGenerate = input.type;
    if (input.type === UploadOrGenerateType.Upload && input.image) {
      this.newChannelHeroImage = input.image;
      this.showCropperHeroModal = true;
    } else {
      this.getStoreGeneratedImage(true).catch(console.error);
    }
  }

  async getStoreGeneratedImage (channelHero: boolean) {
    this.loading = true;
    const gen = await ChannelService.channelGenerateImagePexelsGet({ text: this.channel.name });
    getBase64ImageFromUrl(gen.url, true).then((image) => {
      const previewImage: MUploadImagePreviewImageItem = {
        preview: image,
        userFriendlyFilename: gen.credits ? gen.credits : 'preview',
        generationUrl: gen.url,
        credits: gen.credits,
        provider: gen.provider
      };

      if (channelHero) {
        this.newChannelHeroImage = image;
        this.previewChannelHeroImage = [previewImage];
      } else {
        this.newChannelImage = image;
        this.previewChannelImage = [previewImage];
      }

      this.loading = false;
    }).catch(() => {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error generating image, try again',
        position: 'is-bottom',
        type: 'is-danger'
      });
    });
  }

  highlightChannelName () {
    this.$buefy.toast.open({
      duration: 5000,
      message: 'Please provide a channel name of at least 4 characters',
      position: 'is-bottom',
      type: 'is-danger',
      queue: true
    });
  }

  highlightEventDate () {
    this.$buefy.toast.open({
      duration: 5000,
      message: 'Please set an event date',
      position: 'is-bottom',
      type: 'is-danger',
      queue: true
    });
  }

  highlightMetaBudget () {
    this.buefyErrorToast('Please set your approximate budget');
  }

  highlightMetaRequirements () {
    this.buefyErrorToast('Please list some requirements for the concierge to use');
  }

  highlightCustomFieldsRequirements () {
    this.buefyErrorToast('Please ensure any custom fields being used have names and units added');
  }

  buefyErrorToast (msg: string) {
    this.$buefy.toast.open({
      duration: 5000,
      message: msg,
      position: 'is-bottom',
      type: 'is-danger',
      queue: true
    });
  }

  nextStep () {
    switch (this.activeStep) {
      case 0:
        this.validateStepBasics();
        break;

      case 1:
        this.validateStepDetails();
        break;

      case 2:
        this.validateStepPrivacy();
        break;
    }
  }

  validateStepBasics () {
    let err = false;
    if (!this.channel.name || !this.channel.name.length || this.channel.name.length < 4) {
      err = true;
      this.highlightChannelName();
    }
    if (!err) {
      ++this.activeStep;
      this.$emit('create-channel-header-details', {
        channelName: this.channel.name,
        channelType: this.channel.channelType
      });
    }
  }

  validateStepDetails () {
    let err = false;
    if (this.channel.channelType === this.channelTypeOptions.Event) {
      if (!this.eventDate) {
        err = true;
        this.highlightEventDate();
      }
    } else if (this.isResearchConcierge) {
      if (!this.channel.channelMeta.budget || !this.channel.channelMeta.budget.currency || !this.channel.channelMeta.budget.value) {
        err = true;
        this.highlightMetaBudget();
      }
      if (!this.channel.channelMeta.requirements) {
        err = true;
        this.highlightMetaRequirements();
      }
      if (!err) {
        // set the default privacy options
        this.channel.privacy = Privacy.Private;
        this.channel.allCanAdd = false;
      }
    }
    // if custom fields added, ensure they are filled out
    if (this.channel.customFields && this.channel.customFields.length) {
      for (let i = 0; i < this.channel.customFields.length; i++) {
        if (this.channel.customFields[i].label === '' ||
          typeof this.channel.customFields[i].fieldType === 'undefined' ||
          this.channel.customFields[i].unit === '' ||
          (this.channel.customFields[i].fieldType === FieldType.Rating && typeof this.channel.customFields[i].modifier === 'undefined')
        ) {
          err = true;
          this.highlightCustomFieldsRequirements();
          break;
        }
      }
    }
    if (!err) {
      ++this.activeStep;
    }
  }

  validateStepPrivacy () {

  }

  @Watch('channel', { deep: true, immediate: true })
  channelHandle (newVal) {
    ChannelsStore.SET_CHANNEL_EDITING({ channel: newVal });
    let titleStr = this.channelSlug ? 'Edit Channel' : 'Create Channel';
    if (this.channel.name.length > 0) {
      titleStr += ' - ' + this.channel.name;
    }
    seoInjector.setPageTitle(titleStr);
  }

  @Watch('activeStep')
  emitStep () {
    this.$emit('active-step', this.activeStep);
  }
}
