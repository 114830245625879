
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AuthenticationStore, ItemsStore } from '@/store';
import { SearchType } from '@/store/modules/enums/SearchType';
import { ItemsStoreItemsComponent } from '@/store/modules/interfaces/ItemsModule';
import { User } from '@/api/ms-authentication/services/interfaces';
import { waitTillElementIsVisible } from '@/utils/waitTillElementIsVisible';
import OItemCard from '@/components/organisms/OItemCard.vue';
import MItemCardSkeleton from '@/components/molecules/skeletons/MItemCardSkeleton.vue';
import { RefreshCwIcon, XIcon } from 'vue-feather-icons';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import MNoItemsEmptySearch from '@/components/molecules/MNoItemsEmptySearch.vue';
import OPinnedItemsContainer from '@/components/organisms/OPinnedItemsContainer.vue';
import htmlBodyClassToggle from '@/utils/htmlBodyClassToggle';
import MNoItemsEmptyExploreSearch from '@/components/molecules/MNoItemsEmptyExploreSearch.vue';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';
import { itemsFilterSortDefaultsHashMap } from './forms/OItemsFilterForm.vue';

@Component({
  components: {
    MNoItemsEmptyExploreSearch,
    OPinnedItemsContainer,
    MNoItemsEmptySearch,
    MButtonWithIcon,
    OItemCard,
    MItemCardSkeleton,
    XIcon,
    RefreshCwIcon
  }
})
export default class OItemsContainer extends Vue {
  @Prop({
    default: () => {
      return {};
    }
  })
  cardControls!: {
    showGetGotControl?: boolean
  };
  @Prop({ required: true })
  searchType!: SearchType;
  @Prop()
  username!: string;
  @Prop({ default: false })
  loading!: boolean;
  @Prop({ default: 297 })
  headerSlotHeight!: number;
  @Prop({ required: false, default: false })
  asAnon!: boolean;
  @Prop({ default: false })
  pinned!: boolean;

  @Watch('pinned', { immediate: true })
  pinnedChangeHandle () {
    this.toggleHTMLScroll();
  }

  @Watch('searchType', { immediate: true })
  searchTypeHandle () {
    this.toggleHTMLScroll();
  }

  search: string = '';
  SearchType = SearchType;

  disableScroll: boolean = false;

  $refs!: {
    container: any
  };

  get hasSearchParams (): {
    content: boolean,
    filtersCount: number,
    qs: string
  } {
    return {
      content: this.getItemFiltersSortCount > 0 || this.search !== '',
      filtersCount: this.getItemFiltersSortCount,
      qs: this.search
    };
  }

  get getItemFiltersSortCount () {
    return countFilterSortsUsedInQuery(this.$route, itemsFilterSortDefaultsHashMap);
  }

  get userOtherMeta () {
    return AuthenticationStore.userMetaOtherData;
  }

  get getVScrollDims () {
    return ItemsStore.getVScrollDims;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get newItemsCount (): number {
    return ItemsStore.getNewItemsFetchedCount;
  }

  get items (): ItemsStoreItemsComponent {
    return ItemsStore.getItems;
  }

  get noItems (): boolean {
    if (!this.items.items.length) {
      return true;
    }
    if (this.items.items.length === ItemsStore.getVScrollDims.columnCount) {
      let pCount = 0;
      this.items.items.forEach(item => {
        if (item.data.phantom) {
          ++pCount;
        }
      });
      if (pCount === ItemsStore.getVScrollDims.columnCount) {
        return true;
      }
    }
    return false;
  }

  created () {
    this.setSearch(this.$route.query?.text as string);
  }

  setSearch (searchText: string = '') {
    this.search = searchText;
  }

  async mounted () {
    this.toggleHTMLScroll();
    this.eventsBind();
    const virtualScrollerElement = await waitTillElementIsVisible('.vue-virtual-collection');
    this.scrollDirectionMonitor(virtualScrollerElement);
    this.scrollToOffset(virtualScrollerElement);
    scrollWindowToElement('smooth', this.$refs.container);
  }

  beforeDestroy () {
    this.eventsUnbind();
    document.body.classList.remove('scrolling-down');
  }

  eventsBind () {
    window.addEventListener('resize', this.windowResizeFunction);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.windowResizeFunction);
  }

  toggleHTMLScroll () {
    htmlBodyClassToggle('hide-scroll', this.pinned);
  }

  scrollToOffset (element) {
    if (this.items.items.length > 0) {
      element.scroll(0, this.items.offset);
    }
  }

  scrollBottom () {
    ItemsStore.paginate({ searchType: this.searchType, asAnon: this.asAnon });
  }

  scrollDirectionMonitor (element) {
    let lastTop = 0;

    // @ts-ignore
    element.onscroll = (arg) => {
      // Store the offset
      ItemsStore.SET_SCROLL_OFFSET({ offset: arg.target.scrollTop });
      // handle the downward scroll
      if (arg.target.scrollTop > lastTop) {
        this.scrollDirectionDownAdd();
      }
      // handle the upward scroll
      else {
        this.scrollDirectionDownRemove();
      }
      lastTop = arg.target.scrollTop;
    };
  }

  /**
   * Must recalculate the image positions on window resize
   */
  windowResizeFunction () {
    ItemsStore.recalculateVirtualScroll();
  }

  scrollDirectionDownAdd () {
    document.body.classList.add('scrolling-down');
  }

  scrollDirectionDownRemove () {
    document.body.classList.remove('scrolling-down');
  }

  isOwnProfile (): boolean {
    //if a username isn't found in the url you can only be looking at your own profile
    if (this.$route.path === '/-/your-profile/') {
      return true;
    }
    return this.currentUser.username === this.$route.params.username;
  }

  injectLatest () {
    scrollWindowToElement('auto');
    ItemsStore.injectLatestFetched().catch(console.error);
  }
}
