import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import { INavigationModule } from '@/store/modules/interfaces/NavigationModule';
import { Route } from 'vue-router/types/router';
import BreadcrumbMaker, { Breadcrumb, PartialRoute } from '@/services/BreadcrumbMaker';
import clone from '@/utils/clone';

@Module({
  name: StoreNames.NAVIGATION_STORE,
  namespaced: true,
})
export default class NavigationModule extends VuexModule implements INavigationModule {
  breadCrumbs: Breadcrumb[] = [];
  routerHistory: PartialRoute[] = [];
  maxHistory = 5;

  get getRouterHistory (): PartialRoute[] {
    return this.routerHistory;
  }

  get getLastRouterHistory (): PartialRoute | undefined {
    return this.routerHistory.length > 0 ? this.routerHistory[this.routerHistory.length - 1] : undefined;
  }

  get getLastRouterHistoryFullpath (): string | undefined {
    return this.routerHistory.length > 0 ? this.routerHistory[this.routerHistory.length - 1].fullPath : undefined;
  }

  get getBreadCrumbs (): Breadcrumb[] {
    return this.breadCrumbs;
  }

  @Action
  recalculateBreadcrumbs (currentRoute: Route) {
    this.SET_BREADCRUMBS(
      BreadcrumbMaker.getTheCrumbs(
        currentRoute,
        clone(this.routerHistory)
      )
    );
  }

  @Mutation
  SET_BREADCRUMBS (breadCrumbRoutes: Breadcrumb[]) {
    this.breadCrumbs = breadCrumbRoutes;
  }

  @Mutation
  ADD_NEW (from: Route) {
    this.routerHistory.push({
      path: from.path,
      name: String(from.name),
      hash: from.hash,
      query: from.query,
      params: from.params,
      fullPath: from.fullPath,
      redirectedFrom: from.redirectedFrom,
    });

    if (this.routerHistory.length >= this.maxHistory) {
      this.routerHistory.splice(0, this.routerHistory.length - this.maxHistory);
    }
  }

  @Mutation
  RESET () {
    this.routerHistory = [];
  }
}