
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AEventRepeats from '@/components/atoms/ADropdownMenuSelector.vue';
import { EventType, RepetitionSettings } from '@/api/ms-channel/services/interfaces/Channel';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import ADropdownMenuSelector from '@/components/atoms/ADropdownMenuSelector.vue';

@Component({
  components: {
    ADropdownMenuSelector,
    AEventRepeats

  }
})
export default class MEventRepetitionOptions extends Vue {
  @Prop()
  eventDate!: Date | null;

  @Prop()
  channelEventType!: EventType;

  active: boolean = false;

  selectedEventType: EventType = EventType.OneOff;
  eventTypes = EventType;
  eventTypesArray: EventType[] = [
    EventType.OneOff,
    EventType.Weekly,
    EventType.Monthly,
    EventType.Yearly
  ];

  repetitions: RepetitionSettings = {
    day: 0,
    week: 1
  };

  translationParams: { dayWeekly?: string, ordering?: string, dayMonthly?: string, month?: string, date?: string } = {
    dayWeekly: '',
    ordering: '',
    dayMonthly: '',
    month: '',
    date: ''
  };

  eventTypeOptions: { oneOff: string, weekly: string, monthly: string, yearly: string } = {
    oneOff: this.$t('channel.event.repetitions.oneOff') as string,
    weekly: '',
    monthly: '',
    yearly: ''
  };
  selectedEventTypeOption: string = this.eventTypeOptions.oneOff;

  // Day 0 would be Monday per international standards, but is Sunday per US standards for JavaScript
  mapDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  // Zeroth doesn't exist, don't allow 5th because that doesn't exist in February, just set to last
  mapOrdering = ['', 'first', 'second', 'third', 'fourth', 'last', 'last'];
  mapMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

  setActive (act) {
    this.active = act;
  }

  @Watch('eventDate', { immediate: true })
  setRepetitions () {
    const date = this.eventDate !== null ? this.eventDate : false;

    if( date ) {
      this.repetitions.day = date.getDay();
      this.repetitions.week = this.getWeekOfMonth(date);

      this.setTranslationParams(date);
    }

    this.selectedEventTypeOption = date ? this.eventTypeOptions[this.selectedEventType] : this.eventTypeOptions.oneOff;
  }

  @Watch( 'channelEventType', {immediate: true})
  setChannelEventType () {
    this.selectedEventTypeOption = this.eventTypeOptions[this.channelEventType];
    this.selectedEventType = this.channelEventType;
  }

  setTranslationParams (date:Date) {
    const dateString = String(date.getDate());
    const useOrdinal = ['11', '12', '13'].includes(dateString) ? dateString : dateString.replace(/[0-9]*([0-9])$/, '$1');
    let ordinal = 'higher';
    switch (useOrdinal) {
      case '1':
        ordinal = 'first';
        break;
      case '2':
        ordinal = 'second';
        break;
      case '3':
        ordinal = 'third';
        break;
    }
    this.translationParams.dayWeekly = this.$t(`datetime.daysPlural.${this.mapDays[this.repetitions.day]}`) as string;
    this.translationParams.ordering = this.$t(`datetime.ordering.${this.mapOrdering[this.repetitions.week as number]}`) as string;
    this.translationParams.dayMonthly = this.$t(`datetime.days.${this.mapDays[this.repetitions.day]}`) as string;
    this.translationParams.month = this.$t(`datetime.months.${this.mapMonths[date.getMonth()]}`) as string;
    this.translationParams.date = dateString + this.$t(`datetime.ordering.ordinals.${ordinal}`) as string;

    this.setEventTypeOptions();
  }

  setEventTypeOptions () {
    this.eventTypeOptions.weekly = this.$t('channel.event.repetitions.weekly', this.translationParams) as string;
    this.eventTypeOptions.monthly = this.$t('channel.event.repetitions.monthly', this.translationParams) as string;
    this.eventTypeOptions.yearly = this.$t('channel.event.repetitions.yearly', this.translationParams) as string;
  }

  getWeekOfMonth (date:Date) {
    return Math.ceil(date.getDate() / 7);
  }

  // Sets and emits the selected event type
  setEventType (event:EventType) {
    this.selectedEventTypeOption = this.eventTypeOptions[event];
    this.selectedEventType = event;

    this.$emit(CHILD_EMIT, {
      eventType: event,
      repetitions: this.repetitions
    });
  }
}
