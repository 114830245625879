
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { LoginEmailPost } from '@/api/ms-authentication/services/interfaces';
import { AuthenticationStore } from '@/store';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import APasswordInputWithValidation from '@/storybook-components/src/stories/atoms/APasswordInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import MSsoButtons from '@/components/molecules/MSSOButtons.vue';
import OCaptchaForm from '@/components/organisms/forms/OCaptchaForm.vue';
import OPasswordForgotRequestForm from '@/components/organisms/forms/OPasswordForgotRequestForm.vue';
import { RouteNames } from '@/router/RouteNames';
import ARafflePrompt from '@/components/atoms/ARafflePrompt.vue';
import isIosApp from '@/utils/isIosApp';

@Component({
  components: {
    ARafflePrompt,
    AFormServerError,
    MInputWithValidation,
    AButtonSubmit,
    OCaptchaForm,
    OPasswordForgotRequestForm,
    ValidationObserver,
    APasswordInputWithValidation,
    MSsoButtons
  },
})
export default class OLoginForm extends Vue {
  @Prop()
  showForgotPassword!: boolean;

  loading: boolean = false;
  form: LoginEmailPost = {
    email: '',
    password: '',
  };

  isIos = isIosApp();

  get loginErrors () {
    return AuthenticationStore.errorLoginContent;
  }

  get loginCaptchaRequired () {
    return AuthenticationStore.captchaRequired;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_LOGIN_UNSET();
  }

  setShowForgotPassword (value: boolean) {
    this.$emit('child-output', value);
  }

  async onSubmit () {
    this.loading = true;
    try {
      await AuthenticationStore.login(this.form);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  beforeDestroy () {
    AuthenticationStore.clearAuthErrors();
  }
}
