
import {Component, Prop, Vue} from 'vue-property-decorator';
import OItemCommentForm from '@/components/organisms/forms/OItemCommentForm.vue';
import MItemCommentList from '@/components/molecules/comment/MItemCommentList.vue';

@Component({
  components: { MItemCommentList, OItemCommentForm }
})
export default class OItemComments extends Vue {
  @Prop()
  showCommentTypeSelector!: boolean;
}
