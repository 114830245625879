
import calculateFullImageHref from '../../utils/calculateFullImageHref';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ABusinessProfileImage extends Vue {
  @Prop({required: true})
  baseUrlAndPath!: string;

  @Prop({required: true})
  imagePath!: string;

  @Prop({required: true})
  altText!: string;

  @Prop({required: true})
  size!: string;

  @Prop({ default: '' })
  className!: string;

  @Prop({ required: false })
  imageBase64?: string;

  get fullHref (): string {
    if (this.imagePath) {
      return calculateFullImageHref(this.baseUrlAndPath, this.size, this.imagePath);
    } else if (this.imageBase64) {
      return this.imageBase64;
    } else {
      return '';
    }
  }
}
