export interface LatestVersionGetQuery {
  /**
   * The app types supported on Liffery
   */
  appType: AppType;
  /**
   * To record the version currently being used as well as retrieving the latest version
   * available
   */
  version?: string;
}

/**
 * The app types supported on Liffery
 */
export enum AppType {
  Android = 'android',
  Apple = 'apple',
}
