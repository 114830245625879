export default (): (input: number) => number => {
  const baseFontPx = Number(
    window.getComputedStyle(
      document.body
    )
      .fontSize
      .replace(
        'px',
        ''
      )
  );
  return (input: number): number => {
    return baseFontPx * input;
  };
};
