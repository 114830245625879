import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ItemService from '@/api/ms-item/services/ItemService';
import { Item, ItemPost, ItemPut } from '@/api/ms-item/services/interfaces';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  IItemEntryModule,
  initialItemEntryState,
  initialStateLastChannelAddedTo,
  ItemEntryStoreLastChannelAdded,
  NewImage
} from '@/store/modules/interfaces/ItemEntryModule';
import { AddType, ChannelType } from '@/api/ms-item/services/interfaces/ItemPost';

@Module({
  name: StoreNames.ITEM_ENTRY_STORE,
  namespaced: true,
})
export default class ItemEntryModule extends VuexModule implements IItemEntryModule {
  @Mutation
  public RESET (): void {
    this.itemEntry = initialItemEntryState;
    this.lastChannelAddedTo = initialStateLastChannelAddedTo;
  }

  lastChannelAddedTo: ItemEntryStoreLastChannelAdded = initialStateLastChannelAddedTo;
  itemEntry: ItemPost = initialItemEntryState;

  // note - discontinued use of item entry store Feb '23, last channel added to is still used
  get getItemEntry () {
    return this.itemEntry;
  }

  get lastChannelAddedToGet (): ItemEntryStoreLastChannelAdded {
    return this.lastChannelAddedTo;
  }

  @Action({ rawError: true })
  async saveItem (item: ItemPost) {
    // inject the from and add type
    item.fromPath = window.location.pathname;
    item.addType = AddType.ItemAdd;
    return await ItemService.itemPost(item);
  }

  @Action
  async saveItemImage (input: NewImage) {
    const { image, uniqueName, recommendUniqueName } = input;
    const formData: any = {
      image: image
    };
    if (input.exifJson) {
      formData.exifJson = input.exifJson;
    }
    return await ItemService.itemNameUniqueItemNameImagePost(formData, {
      uniqueItemName: uniqueName
    }, {
      recommendUniqueName: recommendUniqueName
    });
  }

  @Action
  async editItem (input: { uniqueItemName: string, item: ItemPut }): Promise<Item> {
    return await ItemService.itemNameUniqueItemNamePut(
      {
        editable: input.item.editable,
        reminder: input.item.reminder
      },
      { uniqueItemName: input.uniqueItemName }
    );
  }

  @Action({ rawError: true })
  async deleteItemImage (input: { uniqueItemName: string, photoFilePath: string }) {
    await ItemService.itemNameUniqueItemNameImageFilepathDelete({
      uniqueItemName: input.uniqueItemName
    }, {
      filepath: input.photoFilePath
    });
  }

  @Mutation
  UPDATE_ITEM_ENTRY (item: ItemPost) {
    this.itemEntry = item;
  }

  @Mutation
  CLEAR_ITEM_ENTRY () {
    this.itemEntry.editable = {
      text: '',
      channel: {
        isDefault: true,
        name: '',
        slug: '',
        channelType: ChannelType.General,
        customFields: []
      }
    };
  }

  @Mutation
  CLEAR_ITEM_ENTRY_LESS_CHANNEL () {
    this.itemEntry.editable.text = '';
    this.itemEntry.editable.geolocationData = undefined;
    if (this.itemEntry.editable.channel) {
      this.itemEntry.editable.channel.customFields = [];
    }
  }

  @Mutation
  UPDATE_LAST_CHANNEL_USED (channel: ItemEntryStoreLastChannelAdded) {
    this.lastChannelAddedTo = {
      name: channel.name,
      channel: channel.channel,
      channelPrivacy: channel.channelPrivacy,
      customFields: channel.customFields
    };
  }

  @Mutation
  CLEAR_LAST_CHANNEL_USED () {
    this.lastChannelAddedTo = initialStateLastChannelAddedTo;
  }
}
