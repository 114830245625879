
import { Component, Vue } from 'vue-property-decorator';
import { ActivityIcon, ShoppingBagIcon } from 'vue-feather-icons';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Item, Recommendation } from '@/api/ms-item/services/interfaces';
import OItemReportContentForm from '@/components/organisms/forms/OItemReportContentForm.vue';
import MDeleteModalBody from '@/components/molecules/MDeleteModalBody.vue';
import { AuthenticationStore } from '@/store';
import OWelcomeGuide from '@/components/organisms/OWelcomeGuide.vue';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import MPromptBanner from '@/storybook-components/src/stories/molecules/MPromptBanner.vue';
import MModalShoppingList from '@/components/molecules/shoppingList/MModalShoppingList.vue';
import MShoppingListRemoveModal from '@/components/molecules/shoppingList/MShoppingListRemoveModal.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import { Channel } from '@/api/ms-channel/services/interfaces';
import OChannelReportForm from '@/components/organisms/forms/OChannelReportForm.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import clone from '@/utils/clone';
import OItemsFilterForm from '@/components/organisms/forms/OItemsFilterForm.vue';
import OWelcomeGuideRaffle from '@/components/organisms/OWelcomeGuideRaffle.vue';
import isApp from '@/utils/isApp';
import OArchiveChannel from '@/components/organisms/OArchiveChannel.vue';
import OChannelsFilterForm from '@/components/organisms/forms/OChannelsFilterForm.vue';
import { AppType } from '@/api/ms-version-control/services/interfaces/LatestVersionGetQuery';
import MUpdateAppMajor from '@/components/molecules/MUpdateAppMajor.vue';
import config from '@/config';
import OChannelNotificationsForm from '@/components/organisms/forms/OChannelNotificationsForm.vue';
import OPricingContactForm from '@/components/organisms/forms/OPricingContactForm.vue';
import MSelectLanguageModal from '@/components/molecules/MSelectLanguageModal.vue';
import OPriceWatchSetForm from '@/components/organisms/forms/OPriceWatchSetForm.vue';
import MEmailInvitationForm from '@/components/molecules/forms/MEmailInvitationForm.vue';
import { InvitationHook } from '@/api/ms-authentication/services/interfaces/UserInvitationsAssignPost';
import ORecommendationReportContentForm from '@/components/organisms/forms/ORecommendationReportContentForm.vue';
import MChannelInformation from '@/components/molecules/MChannelInformation.vue';
import OChannelSwipeItems from '@/components/organisms/OChannelSwipeItems.vue';

export interface OModalsContainerReportItemPayload {
  item: Item,
}

export interface OModalsContainerRecommendationPayload {
  recommendation: Recommendation,
}

export interface OModalsContainerDeleteItemPayload {
  uniqueItemName: string;
  title: string;
}

export interface OModalsContainerRemoveShoppingListItemPayload {
  itemName: string;
  getGotId: string;
}

export enum OModalsContainerInfoTypeEnum {
  COMMENT_TYPE = 'commentTypeInfo',
}

export interface OModalsContainerInfoType {
  type: OModalsContainerInfoTypeEnum;
}

export interface OModalsContainerArchiveChannel {
  channel: Channel,
  toArchive?: boolean
}

export interface OModalsContainerChannelAsPayload {
  channel: Channel,
}

export interface OModalsContainerChannelSwipeItems {
  channel: Channel;
  startingUniqueItemName?: string;
}

export interface OModalsContainerAppUpdateAvailable {
  appType: AppType,
  localVersion: string,
  latestVersion: string
}

export interface OModalsContainerInviteByEmail {
  code?: string;
  email?: string;
  emailDisabled?: boolean;
  message?: string;
  messageDisabled?: boolean;
  invitationHooks?: InvitationHook[];
  firstName?: string;
  lastName?: string;
  includeCodeToCopy?: boolean;
}

@Component({
  components: {
    OChannelSwipeItems,
    MChannelInformation,
    ORecommendationReportContentForm,
    MEmailInvitationForm,
    ActivityIcon,
    OPriceWatchSetForm,
    MSelectLanguageModal,
    OPricingContactForm,
    OChannelNotificationsForm,
    MUpdateAppMajor,
    OChannelsFilterForm,
    OArchiveChannel,
    OWelcomeGuideRaffle,
    OItemsFilterForm,
    OModalWrapper,
    OChannelReportForm,
    ACloseModalButton,
    MShoppingListRemoveModal,
    MModalShoppingList,
    MPromptBanner,
    MLanguageSwitcher,
    OWelcomeGuide,
    MDeleteModalBody,
    OItemReportContentForm,
    ShoppingBagIcon
  }
})
export default class OModalsContainer extends Vue {
  channel: Channel | null = null;
  item: Item | null = null;
  recommendation: Recommendation | null = null;
  deleteItemData: OModalsContainerDeleteItemPayload | null = null;
  shoppingListData: Item | null = null;
  shoppingListRemoveData: OModalsContainerRemoveShoppingListItemPayload | null = null;
  showInfoType: OModalsContainerInfoTypeEnum = OModalsContainerInfoTypeEnum.COMMENT_TYPE;
  archiveChannel: OModalsContainerArchiveChannel | {} = {};
  appUpdateMajor: OModalsContainerAppUpdateAvailable | {} = {};
  inviteByEmailPayload: OModalsContainerInviteByEmail = {};
  channelSwipeItemsPayload: OModalsContainerChannelSwipeItems | null = null;
  itemToWatch: Item | {} = {};

  showChannelsFiltersModal: boolean = false;
  showOpeningWelcomeModal: boolean = false;
  showItemReportModal: boolean = false;
  showRecommendationReportModal: boolean = false;
  showChannelReportModal: boolean = false;
  showChannelNotificationsModal: boolean = false;
  showChannelInfoModal: boolean = false;
  showArchiveChannelToggleModal: boolean = false;
  showItemFiltersModal: boolean = false;
  showDeleteModal: boolean = false;
  showShoppingListModal: boolean = false;
  showShoppingListRemoveModal: boolean = false;
  showSelectLanguageModal: boolean = false;
  showInfoModal: boolean = false;
  showAppUpdateMajor: boolean = false;
  showBookADemo: boolean = false;
  inviteByEmail: boolean = false;
  showPriceWatchModal: boolean = false;
  channelSwipeItems: boolean = false;
  // we use a counter on the channel swipe loader so we don't remove from DOM and fire off created every time it closes and opens
  channelSwipeItemsLoadCount: number = 0;

  isApp = isApp();

  created () {
    this.eventListeners();
  }

  // Disabled in preference of new vue tour
  showWelcome () {
    if (AuthenticationStore.getAuthenticated) {
      const { accountSteps } = AuthenticationStore.currentUser;
      if (!accountSteps || !accountSteps.welcomed) {
        this.showOpeningWelcomeModal = true;
      }
    }
  }

  // Not technically a model, but this minor app update available message seems best placed here
  showAppUpdateMinorReleaseMessage (input: OModalsContainerAppUpdateAvailable) {
    this.$buefy.snackbar.open({
      message: this.$t('newReleaseAvailable.app.minor.message') as string,
      indefinite: true,
      cancelText: this.$t('dict.dismiss') as string,
      actionText: this.$t('dict.update') as string,
      onAction: () => {
        location.href = input.appType === AppType.Android ? config.appLinks.android : config.appLinks.apple;
      }
    });
  }

  // eslint-disable-next-line max-lines-per-function
  eventListeners () {
    const callerId = 'OModalsContainer';
    // Disabled in preference of new vue tour
    // EventBus.$on(EventBusEvents.AUTH_LOGIN, callerId, this.showWelcome);

    EventBus.$on(EventBusEvents.CHANNEL_REPORT, callerId, (payload: OModalsContainerChannelAsPayload) => {
      this.channel = clone(payload.channel);
      this.showChannelReportModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_NOTIFICATIONS, callerId, (payload: OModalsContainerChannelAsPayload) => {
      this.channel = clone(payload.channel);
      this.showChannelNotificationsModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_INFO_SHOW, callerId, (payload: OModalsContainerChannelAsPayload) => {
      this.channel = clone(payload.channel);
      this.showChannelInfoModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_ARCHIVE, callerId, (payload: OModalsContainerArchiveChannel) => {
      this.archiveChannel = {
        channel: clone(payload.channel),
        toArchive: (!payload.channel.archivedAt || false)
      };
      this.showArchiveChannelToggleModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_FILTERS_OPEN, callerId, () => {
      this.showItemFiltersModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_FILTERS_OPEN, callerId, () => {
      this.showChannelsFiltersModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_REPORT, callerId, (payload: OModalsContainerReportItemPayload) => {
      this.item = { ...payload.item };
      this.showItemReportModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_RECOMMENDATION_REPORT, callerId, (payload: OModalsContainerRecommendationPayload) => {
      this.recommendation = { ...payload.recommendation };
      this.showRecommendationReportModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_DELETE, callerId, (payload: OModalsContainerDeleteItemPayload) => {
      this.deleteItemData = { ...payload };
      this.showDeleteModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_PRICE_WATCH, callerId, (payload: Item) => {
      this.itemToWatch = { ...payload };
      this.showPriceWatchModal = true;
    });

    EventBus.$on(EventBusEvents.SHOPPING_LIST_MODAL, callerId, (payload: Item) => {
      this.shoppingListData = { ...payload };
      this.showShoppingListModal = true;
    });

    EventBus.$on(EventBusEvents.SHOPPING_LIST_REMOVE_MODAL, callerId, (payload: OModalsContainerRemoveShoppingListItemPayload) => {
      this.shoppingListRemoveData = { ...payload };
      this.showShoppingListRemoveModal = true;
    });

    EventBus.$on(EventBusEvents.MODAL_SHOW_INFO, callerId, (payload: OModalsContainerInfoType) => {
      this.showInfoType = payload.type;
      this.showInfoModal = true;
    });

    EventBus.$on(EventBusEvents.SELECT_LANGUAGE, callerId, () => {
      this.showSelectLanguageModal = true;
    });

    EventBus.$on(EventBusEvents.APP_EVENT_UPDATE_MAJOR, callerId, (input: OModalsContainerAppUpdateAvailable) => {
      this.appUpdateMajor = input;
      this.showAppUpdateMajor = true;
    });

    EventBus.$on(EventBusEvents.APP_EVENT_UPDATE_MINOR, callerId, (input: OModalsContainerAppUpdateAvailable) => {
      this.showAppUpdateMinorReleaseMessage(input);
    });

    EventBus.$on(EventBusEvents.BOOK_A_DEMO, callerId, () => {
      this.showBookADemo = true;
    });

    EventBus.$on(EventBusEvents.INVITE_BY_EMAIL, callerId, (input: OModalsContainerInviteByEmail) => {
      this.inviteByEmailPayload = input;
      this.inviteByEmail = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_SWIPE_ITEMS, callerId, (input: OModalsContainerChannelSwipeItems) => {
      this.channelSwipeItemsPayload = input;
      this.channelSwipeItems = true;
      ++this.channelSwipeItemsLoadCount;
    });

    EventBus.$on(EventBusEvents.CHANNEL_SWIPE_ITEMS_CLEAR, callerId, () => {
      // set load count to 0 to remove it from the DOM so the next load forces a refresh via the created() route
      this.channelSwipeItemsLoadCount = 0;
    });
  }
}
