
import { Component, Prop, Vue } from 'vue-property-decorator';
import { waitTillElementIsVisible } from '../../utils/waitTillElementIsVisible';

@Component
export default class MPromptBanner extends Vue {
  @Prop({ required: true })
  message!: string

  @Prop()
  cancelActionText!: string

  @Prop()
  confirmActionText!: string

  @Prop()
  isImportant!: boolean

  @Prop({default: 'top'  })
  position!: 'top' | 'bottom';

  $refs!: {
    bannerContainer: HTMLElement
  };

  async created () {
    const containerClass = this.position === 'top' ? '.navbar-header' : '.MFooterGeneral';
    const container = await waitTillElementIsVisible(containerClass);
    this.$refs.bannerContainer.style[this.position] = container.getBoundingClientRect().height + 15 + 'px';
  }
}
