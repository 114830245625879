import { Route } from 'vue-router';

/**
 * Using the keys in a defaults hashmap, count how many are present in the query of the $route. Return a number.
 */
export default ($route: Route, defaultHashmap: {[key: string]: any}): number => {
  let num = 0;
  if(Object.keys($route.query).length > 0){
    const keys = Object.keys(defaultHashmap);
    for (let i = 0; i < keys.length; i++) {
      if(typeof $route.query[keys[i]] !== 'undefined' && $route.query[keys[i]] !== defaultHashmap[keys[i]]){
        ++num;
      }
    }
  }
  return num;
};