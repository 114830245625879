import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { Item, ItemsGetQuery } from '@/api/ms-item/services/interfaces';

export interface ItemData {
  phantom?: boolean,
  skeleton?: boolean,
  item?: Item,
  lastIn1stRow?: boolean
}

export interface ItemDisplay {
  data: ItemData,
  // Dimensions and position required for the virtual scroller
  column: number,
  height: number;
  width: number;
  x: number;
  y: number;
}

export interface ItemsStoreItemsComponent {
  itemsNew: any[];
  items: ItemDisplay[];
  noMoreResults: boolean;
  busy: boolean;
  // relative virtual scroller flags
  offset: number;
  columnHeights: any[];
}

export interface VScrollDims {
  columnCount: number;
  cardWidth: number;
  innerCardSpace: number;
  outerCardSpace: number;
  boxHeight: number;
  boxWidth: number;
  skeletonCardHeight: number;
}

export interface IItemsModule extends IStoreModule {
  vScrollDims: VScrollDims;
  newItemsFetched: Item[];
  items: ItemsStoreItemsComponent;
  search: ItemsGetQuery;
}

export interface ICalculateItemForDisplay {
  index: number,
  item?: Item & {
    anonymous?: boolean, // injected to indicate anon item
    skeleton?: boolean, // injected to indicate loading
    phantom?: boolean // phantoms are used to ensure there is at least 1 full row in the virtual container
  }
}

export const initialVScrollDims: VScrollDims = {
  columnCount: 1,
  cardWidth: 420,
  innerCardSpace: 44,
  outerCardSpace: 22,
  boxHeight: 600,
  boxWidth: 600,
  skeletonCardHeight: 470,
};