
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore, LifferyTourStore } from '@/store';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { formatMentionUserComponentData } from '@/utils/formatMentionUsersData';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import isTouchDevice from '@/utils/isTouchScreen';

@Component({
  components: { MInputWithValidation }
})
export default class OChannelChatForm extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  chatEnabled!: boolean;

  form: { msg: string } = { msg: '' };

  get sendButtonDisabled (): boolean {
    if (!this.chatEnabled) {
      return true;
    }
    return this.loading;
  }

  get isTouchScreen (): boolean {
    return isTouchDevice();
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  handleKeyup (eventObject): void {
    if (this.isTouchScreen) {
      // then no keyboard shortcut, the user should press the button
      return;
    }

    // ctrl/shift + enter is a new line, else the enter means send
    if (eventObject.keyCode == 13 && !(eventObject.ctrlKey || eventObject.shiftKey)) {
      this.onSubmit();
    }
  }

  async fetchUsersToMention (searchText: string, cb: (a: any) => any): Promise<void> {
    const { data } = await ChannelMemberService.channelMemberSlugGet(
      { slug: this.channelSlug },
      { text: searchText }
    );

    const userObj = formatMentionUserComponentData(data, this.currentUser.username);
    cb(userObj);
  }

  onSubmit (): void {
    if (this.form.msg.trim() === '') {
      return;
    }
    this.$emit('send-message', this.form.msg);
    this.form.msg = '';

    // if the user has sent a message, we won't open the chat again
    LifferyTourStore.CHANNEL_CHAT_OPENED(3);
  }
}
