export default () => {
  function prop (prop): any {
    const str = getComputedStyle(document.body, null)
      .getPropertyValue(prop);
    return str.substring(0, str.length - 2);
  }

  function round (input: number) {
    return Math.ceil(input / 5) * 5;
  }

  const smallmobileBP = prop('--bp-smallMobile');
  const tabletBP = prop('--bp-tablet');
  const currentRootFont = prop('font-size');
  const screenWidth = window.innerWidth;
  let percentageK;
  if (screenWidth <= smallmobileBP && currentRootFont > 14) {
    // set % font class
    percentageK = 14;
  } else if (screenWidth <= tabletBP && currentRootFont > 15) {
    // set % font class
    percentageK = 15;
  }
  // at this point the larger font size should be ok
  let percentage = 0;

  if (percentageK) {
    percentage = round(
      ((currentRootFont - percentageK) / percentageK) * 100
    );
  }
  document.body.setAttribute(
    'data-font-percentage',
    String(percentage)
  );
};
