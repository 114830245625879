
import { Component, Vue } from 'vue-property-decorator';
import { PeopleStore } from '@/store';
import MPersonNotConnectedCard from '@/components/molecules/MPersonNotConnectedCard.vue';
import { Datum as ConnectionsDatum, State } from '@/api/ms-authentication/services/interfaces/Connections';
import MPersonCardSkeleton from '@/components/molecules/skeletons/MPersonCardSkeleton.vue';
import { MailIcon } from 'vue-feather-icons';

interface NotConnectedPeople {
  in: ConnectionsDatum[],
  out: ConnectionsDatum[]
}

@Component({
  components: {
    MPersonCardSkeleton,
    MPersonNotConnectedCard,
    MailIcon
  }
})
export default class OPeopleManagement extends Vue {

  loadingConnections: boolean = false;
  loadingMore: boolean = false;

  get notConnectedPeople (): NotConnectedPeople {
    const resp: NotConnectedPeople = {
      in: [],
      out: [],
    };
    PeopleStore.getConnectionRequests.forEach((p: ConnectionsDatum) => {
      if (p.state === State.RequestSent) {
        resp.out.push(p);
      } else {
        resp.in.push(p);
      }
    });
    return resp;
  }

  async created () {
    await this.fetchNotConnectedPeople();
  }

  async fetchNotConnectedPeople (): Promise<void> {
    this.loadingConnections = true;
    await PeopleStore.fetchNotConnectedPeople();
    this.loadingConnections = false;
  }
}
