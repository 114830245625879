
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { XIcon } from 'vue-feather-icons';

  @Component({
    components: {
      XIcon
    }
  })
  export default class AFormServerError extends Vue {
    @Prop({ required: true })
    errors!: string[];

    closeMessageEvent () {
      this.$emit('child-output', {});
    }
  }
