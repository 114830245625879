
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { AuthenticationStore } from '@/store';
import AAlarmClockIcon from '@/components/atoms/icon/svg/AAlarmClockIcon.vue';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import formatDateDayjs from '@/utils/formatDateDayjs';
import { translation } from '@/plugins/i18n/Translation';

@Component({
  components: { ATooltip, AAlarmClockIcon }
})
export default class AItemReminder extends Vue {
  @Prop()
  item!: Item;
  @Prop()
  tooltipPositionForce?: string;

  hover = false;
  primaryColour = '#519c7b';
  reminderTip = '';
  tooltipPosition = 'is-top';

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get displayReminder (): boolean {
    return !!this.item.reminder && !!this.item.reminder.outstanding && this.item.actor.username === this.currentUser.username;
  }

  get language () {
    return translation.currentLanguage;
  }

  async created () {
    await this.writeReminderTip();
    this.setTooltipPosition();
  }

  setTooltipPosition () {
    if( this.tooltipPositionForce ){
      this.tooltipPosition = this.tooltipPositionForce;
    } else{
      // set to left for smaller screens
      if (window.innerWidth < 800) {
        this.tooltipPosition = 'is-left';
      }
    }
  }

  async writeReminderTip (): Promise<void> {
    if (this.item.reminder && this.item.reminder.outstanding) {
      const formatDate = await formatDateDayjs(new Date(this.item.reminder.outstanding.date), this.language);
      this.reminderTip = this.$t('item.reminder.tooltip', { formatDate }) as string;
    } else {
      this.reminderTip = this.$t('item.reminder.tooltipBackup') as string;
    }
  }
}
