export default (): boolean => {
  return (
    !!(typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        // @ts-ignore
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          // @ts-ignore
          document instanceof window.DocumentTouch))) ||
    !!(typeof navigator !== 'undefined' &&
      // @ts-ignore
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
  );
};
