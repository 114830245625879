interface RegexMatch {
  match: any,
  groupIndex: any
}

export default (regex: RegExp, str: string): RegexMatch[] => {
  let m;
  const returnVal: RegexMatch[] = [];
  while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match, groupIndex) => {
      returnVal.push({
        match, groupIndex
      });
    });
  }
  return returnVal;
};
