export interface UserSubscriptions {
  _id?: string;
  channels?: Channel[];
  createdAt?: Date;
  firstName?: string;
  lastName?: string;
  updatedAt?: Date;
  username?: string;
}

export interface Channel {
  canAdd?: boolean;
  canManage?: boolean;
  /**
   * todo - incorporate this enum into the subscription doc and update as people join/leave
   * have status changed etc, make this a required field
   * Enum to represent the access status a person has to a channel for quick reference and
   * authorisation on the backend per user. Future states may also include moderator (can
   * delete comments and chat messages) and editor (can edit items).
   */
  channelAccessStatus?: ChannelAccessStatus;
  /**
   * The type of channel this is, general or event. Scope to expand more types in the future,
   * e.g. research
   */
  channelType?: ChannelType;
  createdAt?: Date;
  firstName?: string;
  lastName?: string;
  lastUnreadChat?: LastUnreadChat;
  notificationSettings?: NotificationSetting[];
  /**
   * The related channel slug
   */
  slug: string;
  subscriptionStatus: SubscriptionStatus;
  updatedAt?: Date;
  username?: string;
}

/**
 * todo - incorporate this enum into the subscription doc and update as people join/leave
 * have status changed etc, make this a required field
 * Enum to represent the access status a person has to a channel for quick reference and
 * authorisation on the backend per user. Future states may also include moderator (can
 * delete comments and chat messages) and editor (can edit items).
 */
export enum ChannelAccessStatus {
  Manager = 'manager',
  Member = 'member',
  NoAccess = 'noAccess',
  Owner = 'owner',
}

/**
 * The type of channel this is, general or event. Scope to expand more types in the future,
 * e.g. research
 */
export enum ChannelType {
  Event = 'event',
  General = 'general',
  ResearchConcierge = 'researchConcierge',
}

export interface LastUnreadChat {
  chatId: string;
  createdAt: Date;
  notifiedCount: number;
  updatedAt: Date;
}

export enum NotificationSetting {
  OneDayBefore = 'oneDayBefore',
  OneWeekBefore = 'oneWeekBefore',
  ThreeHoursBefore = 'threeHoursBefore',
  TwoWeeksBefore = 'twoWeeksBefore',
}

export enum SubscriptionStatus {
  ConciergeJobAccepted = 'ConciergeJobAccepted',
  ConciergeJobCancelled = 'ConciergeJobCancelled',
  ConciergeJobCompleted = 'ConciergeJobCompleted',
  ConciergeJobRemoved = 'ConciergeJobRemoved',
  InviteAccepted = 'InviteAccepted',
  InviteCancelled = 'InviteCancelled',
  InviteRejected = 'InviteRejected',
  Invited = 'Invited',
  JoinRequestAccepted = 'JoinRequestAccepted',
  JoinRequestCancelled = 'JoinRequestCancelled',
  JoinRequestRejected = 'JoinRequestRejected',
  JoinRequestSent = 'JoinRequestSent',
  RemovedByChannel = 'RemovedByChannel',
  SelfRemoval = 'SelfRemoval',
}
