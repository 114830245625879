
import { Component, Model, Prop, Vue } from 'vue-property-decorator';

// This function is used to detect the actual image type,
function getMimeType (file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

export interface AUploadImageOutput {
  src: any,
  type: string | null
}

export const clearImageOutput: AUploadImageOutput = { src: null, type: null };

export enum ImageUploadError {
  fileDimensionError = 'fileDimensionError',
  fileSizeError = 'fileSizeError',
  fileTypeError = 'fileTypeError',
}

/**
 * Slot component to upload an image using a custom element, e.g. a camera icon. Pass the custom
 * element in slot fashion. Binds output to v-model.
 *
 * There is no image type check. This component emits the image type. You can do the type checking
 * on the @input event.
 */
@Component
export default class AUploadImage extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: AUploadImageOutput;
  @Prop()
  imageTypesAllowed?: string[];
  @Prop()
  fileSizeLimit?: number;
  @Prop({ required: false, default: 0 })
  clearImage!: number;

  value: AUploadImageOutput = clearImageOutput;

  $refs!: {
    uploadButton: HTMLInputElement
  };

  get fileStringAccept () {
    return this.imageTypesAllowed ? this.imageTypesAllowed.join(', ') : 'image/*';
  }

  created () {
    this.value = this.activeValue;
  }

  fileSizeAllowed (file: any): boolean {
    return this.fileSizeLimit ? Number(((file.size / 1024) / 1024).toFixed(4)) < this.fileSizeLimit : true;
  }

  loadImage (event) {
    // Reference to the DOM input element
    const { files } = event.target;
    // Ensure that you have a file before attempting to read it
    if (files && files[0]) {
      if (this.fileSizeLimit && !this.fileSizeAllowed(files[0])) {
        this.$emit('image-upload-error', ImageUploadError.fileSizeError);
        return;
      }
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.value.src) {
        URL.revokeObjectURL(this.value.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(files[0]);

      // 3. The steps below are designated to determine a file mime type to use it during the
      // getting of a cropped image from the canvas. You can replace it them by the following string,
      // but the type will be derived from the extension and it can lead to an incorrect result:
      // this.image = {
      //    src: blob;
      //    type: files[0].type
      // }

      // Create a new FileReader to read this image binary data
      const reader = new FileReader();
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e: any) => {
        const imageType = getMimeType(e.target.result, files[0].type) as string;
        if (!this.imageTypesAllowed || (this.imageTypesAllowed && this.imageTypesAllowed.includes(imageType))) {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.value = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: imageType
          };
          this.$emit('updateModelValue', this.value);
          this.$emit('input');
        } else {
          this.$emit('image-upload-error', ImageUploadError.fileTypeError);
          return;
        }
      };
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsArrayBuffer(files[0]);
    }
  }

  triggerUpload () {
    this.$refs.uploadButton.click();
  }
}
