
import { Component, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: { MLanguageSwitcher }
})
export default class OWelcomeGuide extends Vue {
  isStepsClickable = false;
  activeStep = 0;

  async closeModal () {
    await AuthenticationStore.updateUserWelcomed(true);
    EventBus.$emit(EventBusEvents.ITEM_ADD_PULSE);
    this.$emit('close');
  }
}
