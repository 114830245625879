
import { Component, Vue } from 'vue-property-decorator';
import OModalsContainer from '@/components/organisms/OModalsContainer.vue';
import MHeaderSeo from '@/components/molecules/landing/MHeaderSeo.vue';
import MFooterSeo from '@/components/molecules/landing/MFooterSeo.vue';
import MComingSoonBanner from '@/components/molecules/landing/MComingSoonBanner.vue';
import isApp from '@/utils/isApp';

@Component({
  components: {
    MComingSoonBanner,
    MFooterSeo,
    MHeaderSeo,
    OModalsContainer
  },
})
export default class TMainLayoutSEO extends Vue {
  get isApp (): boolean {
    return isApp();
  }
}
