import HttpService from '@/services/HttpService';

import { UrlPreview } from './interfaces/UrlPreview';
import { UrlPreviewGetQuery } from './interfaces/UrlPreviewGetQuery';

export default class UrlPreviewService {
  public static basePath = '/__/ms-item/';

  /**
   *  Operation ID: urlPreviewGet
   *  Summary: Fetch URL preview
   *  Description: Fetches the URL preview
   */
  public static urlPreviewGet(query: UrlPreviewGetQuery): Promise<UrlPreview> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UrlPreviewService.basePath + 'url-preview',
      qs: query,
    });
  }
}
