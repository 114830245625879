
import { Component, Prop, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AConnectionIcon from '@/components/atoms/buttons/AConnectionIcon.vue';
import { Datum, } from '@/api/ms-authentication/services/interfaces/Connections';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    AConnectionIcon,
  },
})
export default class MPersonChannelJoinRequestCard extends Vue {
  @Prop(Object)
  person!: Datum;
}
