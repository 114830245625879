
import { Component, Vue } from 'vue-property-decorator';
import AAddNewItem from '@/components/atoms/buttons/AAddNewItem.vue';
import AGoToAddChannel from '@/components/atoms/buttons/AGoToAddChannel.vue';

@Component({
  components: { AGoToAddChannel, AAddNewItem }
})
export default class MNoItemsEmptySearch extends Vue {
  activeStep = 0;
}
