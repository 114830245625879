
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  CheckIcon,
  ChevronUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InfoIcon,
  CheckCircleIcon,
  MinusIcon,
  PlusIcon,
  AlertTriangleIcon,
  AlertCircleIcon,
  ChevronDownIcon,
  EyeIcon,
  EyeOffIcon,
  StarIcon
} from 'vue-feather-icons';

@Component({
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    CheckIcon,
    ChevronUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    InfoIcon,
    MinusIcon,
    PlusIcon,
    CheckCircleIcon,
    AlertTriangleIcon,
    AlertCircleIcon,
    ChevronDownIcon,
    EyeIcon,
    EyeOffIcon,
    StarIcon
  }
})
export default class AVueFeatherIconPack extends Vue {
  @Prop({ required: true })
  icon!: string;

  @Prop({ default: '1.5x' })
  size?: string;

  @Prop()
  customClass?: string;

  iconComponent() {
    return this.icon[1] + 'Icon';
  }
}
