
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component
export default class MLoginButtonTrigger extends Vue {
  @Prop({ default: '' })
  classes!: string;

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  loginButtonClick () {
    EventBus.$emit(EventBusEvents.AUTH_LOGIN_CLICKED);
  }
}
