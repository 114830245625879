import { ItemPost } from '@/api/ms-item/services/interfaces';
import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { AddType } from '@/api/ms-item/services/interfaces/ItemPost';
import { ChannelType, CustomField, Privacy } from '@/api/ms-channel/services/interfaces/Channel';


export interface ItemEntryStoreLastChannelAddedChannel {
  name: string,
  slug: string,
  channelType: ChannelType
}
export interface ItemEntryStoreLastChannelAdded {
  name: string,
  channel?: ItemEntryStoreLastChannelAddedChannel;
  channelPrivacy?: Privacy,
  customFields?: CustomField[]
}

export interface NewImage {
  uniqueName: string,
  image: any,
  exifJson?: string,
  recommendUniqueName?: string
}

export interface IItemEntryModule extends IStoreModule {
  lastChannelAddedTo: ItemEntryStoreLastChannelAdded,
  itemEntry: ItemPost
}

export const initialItemEntryState: ItemPost = {
  editable: {
    channel: {
      slug: '',
      name: '',
      channelType: ChannelType.General
    },
    text: '',
  },
  addType: AddType.ItemAdd,
  fromPath: window.location.pathname
};

export const defaultChannelName: string = 'default';

export const initialStateLastChannelAddedTo: ItemEntryStoreLastChannelAdded = {
  name: defaultChannelName,
  channel: {
    name: '',
    slug: '',
    channelType: ChannelType.General,
  },
  customFields: []
};
