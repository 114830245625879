
import { Component, Vue } from 'vue-property-decorator';
import ALink from '@/components/atoms/link/ALink.vue';
import { RouteNames } from '@/router/RouteNames';
import isIosApp from '@/utils/isIosApp';
import { AuthenticationStore } from '@/store';

@Component({
  components: { ALink }
})
export default class MFooterSeo extends Vue {

  routeNames = RouteNames;

  isIosApp = isIosApp();

  get year () {
    return new Date().getFullYear();
  }

  get routename () {
    return this.$route.name;
  }

  loginPrompt () {
    AuthenticationStore.TOGGLE_PROMPT_LOGIN(true);
  }
}
