export default (className: string, removeClass = false): void => {
  const body = document.body as HTMLElement;
  const html = document.querySelector('html') as HTMLElement;
  if (removeClass) {
    body.classList.remove(className);
    html.classList.remove(className);
  } else {
    body.classList.add(className);
    html.classList.add(className);
  }
};
