import { Dictionary, Route } from 'vue-router/types/router';
import { RouteNames } from '@/router/RouteNames';

export interface PartialRoute {
  path: string
  name?: string
  hash: string
  query: Dictionary<string | (string | null)[]>
  params: Dictionary<string>
  fullPath: string
  redirectedFrom?: string
  meta?: any
}

export interface Breadcrumb extends PartialRoute {
  // todo figure out what we need in each breadcrumb
}

class BreadcrumbMaker {
  dashboardCrumb: Breadcrumb = {
    path: '/',
    name: RouteNames.ROUTE_DASHBOARD,
    fullPath: window.location.href,
    hash: '',
    query: {},
    params: {},
  };
  yourProfileCrumb: Breadcrumb = {
    path: '/_/your-profile',
    name: RouteNames.ROUTE_YOUR_PROFILE,
    fullPath: window.location.href + '/_/your-profile',
    hash: '',
    query: {},
    params: {},
  };
  yourChannelMgmtCrumb: Breadcrumb = {
    path: '/_/your-profile/channel-management',
    name: RouteNames.ROUTE_CHANNEL_MANAGEMENT,
    fullPath: window.location.href + '/_/your-profile/channel-management',
    hash: '',
    query: {},
    params: {},
  };

  routeToBreadcrumb (routeObject: Route): Breadcrumb {
    return {
      path: routeObject.path,
      name: String(routeObject.name),
      hash: routeObject.hash,
      query: routeObject.query,
      params: routeObject.params,
      fullPath: routeObject.fullPath,
      redirectedFrom: routeObject.redirectedFrom,
    };
  }

  getTheCrumbs (currentRoute: Route, routeHistory: PartialRoute[]): Breadcrumb[] {
    // Dashboard
    if (currentRoute.name === RouteNames.ROUTE_DASHBOARD) {
      return [];
    }

    if (this.isYourProfileSettingPage(currentRoute)) {
      return [this.dashboardCrumb, this.yourProfileCrumb, this.routeToBreadcrumb(currentRoute)];
    }

    if (this.isYourChannelCreateEditPage(currentRoute)) {
      return [this.dashboardCrumb, this.yourProfileCrumb, this.yourChannelMgmtCrumb, this.routeToBreadcrumb(currentRoute)];
    }

    switch (currentRoute.name) {
      // https://conf.liffery.net/display/ORAP/In+app+navigation

      // Profile pages, yours or other
      case RouteNames.ROUTE_YOUR_PROFILE:
        return this.YOUR_PROFILE_PAGE(currentRoute);
      case RouteNames.ROUTE_PROFILE:
        return this.PROFILE_PAGE(currentRoute, routeHistory);

      // Handle the profile page
      case RouteNames.ROUTE_CHANNEL_VIEW:
        return this.CHANNEL_VIEW(currentRoute, routeHistory);

      // Handle the profile page
      case RouteNames.ROUTE_ITEM_DETAIL_VIEW:
        return this.ITEM_DETAIL_VIEW(currentRoute, routeHistory);

      // All else fails, return the dashboard
      default:
        // The dash and then whatever else
        return [
          this.dashboardCrumb,
          this.routeToBreadcrumb(currentRoute)
        ];
    }
  }

  isYourProfileSettingPage (currentRoute: Route) {
    return [
      RouteNames.ROUTE_PEOPLE_MANAGEMENT,
      RouteNames.ROUTE_CHANNEL_MANAGEMENT,
      RouteNames.ROUTE_CHANNEL_CREATE,
      RouteNames.ROUTE_CHANNEL_EDIT,
      RouteNames.ROUTE_GETGOT,
      RouteNames.ROUTE_NOTIFICATIONS,
      RouteNames.ROUTE_SETTINGS,
      RouteNames.ROUTE_SETTINGS_SECURITY,
      RouteNames.ROUTE_SETTINGS_NOTIFICATIONS
    ].includes(currentRoute.name as RouteNames);
  }

  isYourChannelCreateEditPage (currentRoute: Route) {
    return [
      RouteNames.ROUTE_CHANNEL_CREATE,
      RouteNames.ROUTE_CHANNEL_EDIT,
    ].includes(currentRoute.name as RouteNames);
  }

  CHANNEL_VIEW (currentRoute: Route, routeHistory: PartialRoute[]): Breadcrumb[] {
    const crumbs: Breadcrumb[] = [this.routeToBreadcrumb(currentRoute)];
    const lastStep = routeHistory.pop();
    const secondLastStep = routeHistory.length > 0 ? routeHistory.pop() : undefined;

    // last step was a profile
    if (lastStep && [RouteNames.ROUTE_PROFILE, RouteNames.ROUTE_YOUR_PROFILE].includes(lastStep.name as RouteNames)) {
      // if the step before that was not a channel
      if (!secondLastStep || secondLastStep && secondLastStep.name !== RouteNames.ROUTE_CHANNEL_VIEW) {
        crumbs.unshift(lastStep);
      }
    }
    // Always inject the dashboard
    crumbs.unshift(this.dashboardCrumb);
    return crumbs;
  }

  ITEM_DETAIL_VIEW (currentRoute: Route, routeHistory: PartialRoute[]): Breadcrumb[] {
    const crumbs: Breadcrumb[] = [this.routeToBreadcrumb(currentRoute)];
    const lastStep = routeHistory.pop();
    const profileOrChannel = [RouteNames.ROUTE_CHANNEL_VIEW, RouteNames.ROUTE_PROFILE, RouteNames.ROUTE_YOUR_PROFILE];

    // Straight from a channel page, inject the channel link
    if (lastStep && profileOrChannel.includes(lastStep.name as RouteNames)) {
      // where the last step could be channel or a profile page
      crumbs.unshift(lastStep);
    }

    // Always inject the dashboard
    crumbs.unshift(this.dashboardCrumb);
    return crumbs;
  }

  YOUR_PROFILE_PAGE (currentRoute: Route): Breadcrumb[] {
    return [
      this.dashboardCrumb,
      this.routeToBreadcrumb(currentRoute)
    ];
  }

  PROFILE_PAGE (currentRoute: Route, routeHistory: PartialRoute[]): Breadcrumb[] {
    const crumbs: Breadcrumb[] = [this.routeToBreadcrumb(currentRoute)];
    const lastStep = routeHistory.pop();
    const secondLastStep = routeHistory.length > 0 ? routeHistory.pop() : undefined;
    const profilePageNames = [RouteNames.ROUTE_PROFILE, RouteNames.ROUTE_YOUR_PROFILE];

    // Straight from a channel page, inject the channel link
    if (lastStep && lastStep.name === RouteNames.ROUTE_CHANNEL_VIEW) {
      // skip if the 2nd to last page was a profile page
      if (secondLastStep && !profilePageNames.includes(secondLastStep.name as RouteNames)) {
        crumbs.unshift(lastStep);
      }
    }
    // Always inject the dashboard
    crumbs.unshift(this.dashboardCrumb);
    return crumbs;
  }
}

export default new BreadcrumbMaker();
