import { extractAllAtPipeEqualsMentions } from 'mention-extractor';

export const formatMentionUserComponentData = (users, currentUser: string): { key: string, value: string }[] => {
  const mappedUsers = users.map(user => ({ key: `${user.firstName} ${user.lastName}`, value: user.username }));
  return mappedUsers.filter(user => user.value !== currentUser);
};

export const formatMentionUserContent = (input: string): string => {
  let content: string = input;
  const mentions = extractAllAtPipeEqualsMentions(input);
  if (mentions.length) {
    mentions.forEach(mention => {
      content = content.replace(
        mention,
        `<b class="as-link mentioned-user-link">${mention.substring(1, mention.length - 2)}</b>`
      );
    });
    return content;
  }

  return input;
};
