import { User } from '@/api/ms-authentication/services/interfaces';

// These are defined elsewhere but always as part of another module, defined separately to be cast as
export interface Moderation {
  waiting: boolean,
  banned: boolean,
  labels: string[]
}
export interface Actor {
  firstName: string,
  lastName: string,
  username: string
}

/**
 * If no moderation object - true
 * If not waiting and not banned - true
 * If banned - false
 * If not banned but waiting:
 *    Current user is the actor who created the content - true
 *    Else - false
 *
 * @param moderation Moderation object
 * @param actor Actor who's content created the object
 * @param currentUser The current user wanting to view the object
 */
export default (moderation: Moderation|undefined, actor: Actor, currentUser: User): boolean => {
  if( typeof moderation === 'undefined' ){
    return true;
  }
  if (!moderation.waiting && !moderation.banned) {
    return true;
  } else if (moderation.banned) {
    return false;
  } else {
    // Awaiting moderation: If the person viewing the component is the actor of the comment/reply, they may view this, otherwise not
    return currentUser.username === actor.username;
  }
};