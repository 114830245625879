import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { initialItemState, IRecommendationDetailModule } from '@/store/modules/interfaces/RecommendationDetailModule';
import { Recommendation } from '@/api/ms-item/services/interfaces';
import { NavigationStore } from '@/store';
import RecommendService from '@/api/ms-item/services/RecommendService';
import { StoreNames } from '@/store/modules/enums/StoreNames';

@Module({
  name: StoreNames.RECOMMENDATION_DETAIL_STORE,
  namespaced: true,
})
export default class RecommendationDetailModule extends VuexModule implements IRecommendationDetailModule {
  @Mutation
  public RESET () {
    this.recommendationDetail = initialItemState;
  }

  recommendationDetail: Recommendation = initialItemState;

  get getRecommendationDetail () {
    return this.recommendationDetail;
  }

  get getItemUniqueItemName () {
    return this.recommendationDetail.uniqueItemName;
  }

  @Action({ rawError: true })
  public async fetch (uniqueItemName: string) {
    this.ITEM_CLEAR_SET(
      await RecommendService.recommendNameUniqueItemNameGet({
        uniqueItemName: uniqueItemName
      }, {
        from: NavigationStore.getLastRouterHistoryFullpath
      })
    );
  }

  @Mutation
  ITEM_CLEAR_SET (recommendation: Recommendation) {
    this.recommendationDetail = recommendation;
  }
}