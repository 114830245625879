import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import beforeEach from './beforeEach';
import { Position, Route, RouterOptions } from 'vue-router/types/router';
import config, { BuildType } from '@/config';

Vue.use(Router);

const routerOpts: RouterOptions = {
  routes,
  // For the cordova apps we cannot use history mode, only hash.
  mode: config.buildType === BuildType.WEB ? 'history' : 'hash',
  scrollBehavior (to: Route, from: Route, savedPosition: Position | void) {
    const returnObj: any = savedPosition || { x: 0, y: 0 };

    if (to.hash && !to.hash.includes('=') && window.document.querySelector(to.hash)) {
      returnObj.selector = to.hash;
    }
    return returnObj;
  },
};
// Used to ensure the mode is hash in capacitorjs
console.log('Router mode: ' + routerOpts.mode);
const router = new Router(routerOpts);
router.beforeEach(beforeEach);
export default router;
