import HttpService from '@/services/HttpService';

import { Items } from './interfaces/Items';
import { SwipeScoresItemNameUniqueItemNameGetPath } from './interfaces/SwipeScoresItemNameUniqueItemNameGetPath';
import { SwipeScoresItemNameUniqueItemNameGetQuery } from './interfaces/SwipeScoresItemNameUniqueItemNameGetQuery';
import { SwipeScoresItemNameUniqueItemNamePut } from './interfaces/SwipeScoresItemNameUniqueItemNamePut';
import { SwipeScoresItemNameUniqueItemNamePutPath } from './interfaces/SwipeScoresItemNameUniqueItemNamePutPath';
import { SwipeScoresItemsGetQuery } from './interfaces/SwipeScoresItemsGetQuery';
import { SwipeScoress } from './interfaces/SwipeScoress';

export default class SwipeScoresService {
  public static basePath = '/__/ms-item/';

  /**
   *  Operation ID: swipeScoresItemNameUniqueItemNameGet
   *  Summary: Get all submitted swipe scores for this item
   *  Description: Gets all the scores that have been submitted to this item, for e.g. to be used to create a graph on item detail
   */
  public static swipeScoresItemNameUniqueItemNameGet(
    pathParams: SwipeScoresItemNameUniqueItemNameGetPath,
    query: SwipeScoresItemNameUniqueItemNameGetQuery
  ): Promise<SwipeScoress> {
    return HttpService.sendRequest({
      method: 'GET',
      path:
        SwipeScoresService.basePath + 'swipe-scores/item/name/:uniqueItemName',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: swipeScoresItemNameUniqueItemNamePut
   *  Summary: Submit a swipe score for an item
   *  Description: After a swipe action, send the action to be saved
   */
  public static swipeScoresItemNameUniqueItemNamePut(
    body: SwipeScoresItemNameUniqueItemNamePut,
    pathParams: SwipeScoresItemNameUniqueItemNamePutPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path:
        SwipeScoresService.basePath + 'swipe-scores/item/name/:uniqueItemName',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: swipeScoresItemsGet
   *  Summary: Fetch next batch of items to swipe for a channel
   *  Description:
   */
  public static swipeScoresItemsGet(
    query: SwipeScoresItemsGetQuery
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: SwipeScoresService.basePath + 'swipe-scores/items',
      qs: query,
    });
  }
}
