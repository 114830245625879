import HttpService from '@/services/HttpService';

import { Item } from './interfaces/Item';
import { ItemNameUniqueItemNameCommentCommentIdDeletePath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdDeletePath';
import { ItemNameUniqueItemNameCommentCommentIdLikePatch } from './interfaces/ItemNameUniqueItemNameCommentCommentIdLikePatch';
import { ItemNameUniqueItemNameCommentCommentIdLikePatchPath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdLikePatchPath';
import { ItemNameUniqueItemNameCommentCommentIdPatch } from './interfaces/ItemNameUniqueItemNameCommentCommentIdPatch';
import { ItemNameUniqueItemNameCommentCommentIdPatchPath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdPatchPath';
import { ItemNameUniqueItemNameCommentCommentIdReplyPost } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyPost';
import { ItemNameUniqueItemNameCommentCommentIdReplyPostPath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyPostPath';
import { ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdDeletePath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdDeletePath';
import { ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatch } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatch';
import { ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatchPath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatchPath';
import { ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatch } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatch';
import { ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatchPath } from './interfaces/ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatchPath';
import { ItemNameUniqueItemNameCommentPost } from './interfaces/ItemNameUniqueItemNameCommentPost';
import { ItemNameUniqueItemNameCommentPostPath } from './interfaces/ItemNameUniqueItemNameCommentPostPath';
import { ItemNameUniqueItemNameCustomFieldCustomFieldIdPatchPath } from './interfaces/ItemNameUniqueItemNameCustomFieldCustomFieldIdPatchPath';
import { ItemNameUniqueItemNameCustomFieldCustomFieldIdPatchQuery } from './interfaces/ItemNameUniqueItemNameCustomFieldCustomFieldIdPatchQuery';
import { ItemNameUniqueItemNameDeletePath } from './interfaces/ItemNameUniqueItemNameDeletePath';
import { ItemNameUniqueItemNameGetPath } from './interfaces/ItemNameUniqueItemNameGetPath';
import { ItemNameUniqueItemNameGetQuery } from './interfaces/ItemNameUniqueItemNameGetQuery';
import { ItemNameUniqueItemNameGetgotIdIdDeletePath } from './interfaces/ItemNameUniqueItemNameGetgotIdIdDeletePath';
import { ItemNameUniqueItemNameGetgotIdIdPatch } from './interfaces/ItemNameUniqueItemNameGetgotIdIdPatch';
import { ItemNameUniqueItemNameGetgotIdIdPatchPath } from './interfaces/ItemNameUniqueItemNameGetgotIdIdPatchPath';
import { ItemNameUniqueItemNameGetgotIdIdUndoDeletePutPath } from './interfaces/ItemNameUniqueItemNameGetgotIdIdUndoDeletePutPath';
import { ItemNameUniqueItemNameGetgotPost } from './interfaces/ItemNameUniqueItemNameGetgotPost';
import { ItemNameUniqueItemNameGetgotPostPath } from './interfaces/ItemNameUniqueItemNameGetgotPostPath';
import { ItemNameUniqueItemNameImageFilepathDeletePath } from './interfaces/ItemNameUniqueItemNameImageFilepathDeletePath';
import { ItemNameUniqueItemNameImageFilepathDeleteQuery } from './interfaces/ItemNameUniqueItemNameImageFilepathDeleteQuery';
import { ItemNameUniqueItemNameImagePostFormData } from './interfaces/ItemNameUniqueItemNameImagePostFormData';
import { ItemNameUniqueItemNameImagePostPath } from './interfaces/ItemNameUniqueItemNameImagePostPath';
import { ItemNameUniqueItemNameImagePostQuery } from './interfaces/ItemNameUniqueItemNameImagePostQuery';
import { ItemNameUniqueItemNameLikePatch } from './interfaces/ItemNameUniqueItemNameLikePatch';
import { ItemNameUniqueItemNameLikePatchPath } from './interfaces/ItemNameUniqueItemNameLikePatchPath';
import { ItemNameUniqueItemNamePinPatch } from './interfaces/ItemNameUniqueItemNamePinPatch';
import { ItemNameUniqueItemNamePinPatchPath } from './interfaces/ItemNameUniqueItemNamePinPatchPath';
import { ItemNameUniqueItemNamePut } from './interfaces/ItemNameUniqueItemNamePut';
import { ItemNameUniqueItemNamePutPath } from './interfaces/ItemNameUniqueItemNamePutPath';
import { ItemNameUniqueItemNameReportPost } from './interfaces/ItemNameUniqueItemNameReportPost';
import { ItemNameUniqueItemNameReportPostPath } from './interfaces/ItemNameUniqueItemNameReportPostPath';
import { ItemNameUniqueItemNameUrlCacheGetPath } from './interfaces/ItemNameUniqueItemNameUrlCacheGetPath';
import { ItemNameUniqueItemNameUrlCacheGetQuery } from './interfaces/ItemNameUniqueItemNameUrlCacheGetQuery';
import { ItemPost } from './interfaces/ItemPost';

export default class ItemService {
  public static basePath = '/__/ms-item/';

  /**
   *  Operation ID: itemNameUniqueItemNameCommentPost
   *  Summary: Add a new comment
   *  Description: Add a new comment to an item
   */
  public static itemNameUniqueItemNameCommentPost(
    body: ItemNameUniqueItemNameCommentPost,
    pathParams: ItemNameUniqueItemNameCommentPostPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ItemService.basePath + 'item/name/:uniqueItemName/comment',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdDelete
   *  Summary: Delete a comment item
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameCommentCommentIdDelete(
    pathParams: ItemNameUniqueItemNameCommentCommentIdDeletePath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path:
        ItemService.basePath + 'item/name/:uniqueItemName/comment/:commentId',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdPatch
   *  Summary: Edit a comment item
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameCommentCommentIdPatch(
    body: ItemNameUniqueItemNameCommentCommentIdPatch,
    pathParams: ItemNameUniqueItemNameCommentCommentIdPatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ItemService.basePath + 'item/name/:uniqueItemName/comment/:commentId',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdLikePatch
   *  Summary: Like or unlike
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameCommentCommentIdLikePatch(
    body: ItemNameUniqueItemNameCommentCommentIdLikePatch,
    pathParams: ItemNameUniqueItemNameCommentCommentIdLikePatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/comment/:commentId/like',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdReplyPost
   *  Summary: Add a new comment
   *  Description: Add a new comment to an item
   */
  public static itemNameUniqueItemNameCommentCommentIdReplyPost(
    body: ItemNameUniqueItemNameCommentCommentIdReplyPost,
    pathParams: ItemNameUniqueItemNameCommentCommentIdReplyPostPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'POST',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/comment/:commentId/reply',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdDelete
   *  Summary: Delete a comment item
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdDelete(
    pathParams: ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdDeletePath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/comment/:commentId/reply/:replyCommentId',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatch
   *  Summary: Edit a comment item
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatch(
    body: ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatch,
    pathParams: ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/comment/:commentId/reply/:replyCommentId',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatch
   *  Summary: Like or unlike
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatch(
    body: ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatch,
    pathParams: ItemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/comment/:commentId/reply/:replyCommentId/like',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameCustomFieldCustomFieldIdPatch
   *  Summary: Patch an item's custom field
   *  Description:
   */
  public static itemNameUniqueItemNameCustomFieldCustomFieldIdPatch(
    pathParams: ItemNameUniqueItemNameCustomFieldCustomFieldIdPatchPath,
    query: ItemNameUniqueItemNameCustomFieldCustomFieldIdPatchQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/custom-field/:customFieldId',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameDelete
   *  Summary: Delete an item
   *  Description: Delete an item
   */
  public static itemNameUniqueItemNameDelete(
    pathParams: ItemNameUniqueItemNameDeletePath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: ItemService.basePath + 'item/name/:uniqueItemName',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameGet
   *  Summary: Fetch an item
   *  Description: Fetches the full item object by item uuid passed in the path
   */
  public static itemNameUniqueItemNameGet(
    pathParams: ItemNameUniqueItemNameGetPath,
    query: ItemNameUniqueItemNameGetQuery
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemService.basePath + 'item/name/:uniqueItemName',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNamePut
   *  Summary: Update an item
   *  Description: Update an item
   */
  public static itemNameUniqueItemNamePut(
    body: ItemNameUniqueItemNamePut,
    pathParams: ItemNameUniqueItemNamePutPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ItemService.basePath + 'item/name/:uniqueItemName',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameGetgotIdIdDelete
   *  Summary: Removes current user from getgot
   *  Description: Removes the current user from a getgot list
   */
  public static itemNameUniqueItemNameGetgotIdIdDelete(
    pathParams: ItemNameUniqueItemNameGetgotIdIdDeletePath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: ItemService.basePath + 'item/name/:uniqueItemName/getgot/id/:id',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameGetgotIdIdPatch
   *  Summary: Update an existing get got state
   *  Description: Appends the new state to the array of existing states in the get got object
   */
  public static itemNameUniqueItemNameGetgotIdIdPatch(
    body: ItemNameUniqueItemNameGetgotIdIdPatch,
    pathParams: ItemNameUniqueItemNameGetgotIdIdPatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: ItemService.basePath + 'item/name/:uniqueItemName/getgot/id/:id',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameGetgotIdIdUndoDeletePut
   *  Summary: Undo the action of deleting an item from shopping list
   *  Description: Undo the action of deleting an item from shopping list - i.e. move from getgotCancelled back into active getgot list
   */
  public static itemNameUniqueItemNameGetgotIdIdUndoDeletePut(
    pathParams: ItemNameUniqueItemNameGetgotIdIdUndoDeletePutPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PUT',
      path:
        ItemService.basePath +
        'item/name/:uniqueItemName/getgot/id/:id/undo-delete',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameGetgotPost
   *  Summary: Add a new getgot for current user
   *  Description: For the given username a new get/got state is added to the item
   */
  public static itemNameUniqueItemNameGetgotPost(
    body: ItemNameUniqueItemNameGetgotPost,
    pathParams: ItemNameUniqueItemNameGetgotPostPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ItemService.basePath + 'item/name/:uniqueItemName/getgot',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameImageFilepathDelete
   *  Summary: Delete an image
   *  Description: Delete an image to an item based on item and image id.
   */
  public static itemNameUniqueItemNameImageFilepathDelete(
    pathParams: ItemNameUniqueItemNameImageFilepathDeletePath,
    query: ItemNameUniqueItemNameImageFilepathDeleteQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: ItemService.basePath + 'item/name/:uniqueItemName/image/filepath',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameImagePost
   *  Summary: Upload an image
   *  Description: Upload an image to an item based on item id. This is separate due to the limitations of forma data within the browser.
   */
  public static itemNameUniqueItemNameImagePost(
    formData: ItemNameUniqueItemNameImagePostFormData,
    pathParams: ItemNameUniqueItemNameImagePostPath,
    query: ItemNameUniqueItemNameImagePostQuery
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ItemService.basePath + 'item/name/:uniqueItemName/image',
      formData,
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameLikePatch
   *  Summary: Like or unlike
   *  Description: For the given username like or unlike, will also increment the like toggle counter
   */
  public static itemNameUniqueItemNameLikePatch(
    body: ItemNameUniqueItemNameLikePatch,
    pathParams: ItemNameUniqueItemNameLikePatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: ItemService.basePath + 'item/name/:uniqueItemName/like',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNamePinPatch
   *  Summary: Pin or unpin an item
   *  Description: For the given username pin or unpin an item, will also increment the pin toggle counter for that user
   */
  public static itemNameUniqueItemNamePinPatch(
    body: ItemNameUniqueItemNamePinPatch,
    pathParams: ItemNameUniqueItemNamePinPatchPath
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: ItemService.basePath + 'item/name/:uniqueItemName/pin',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameReportPost
   *  Summary: Report an item
   *  Description: Reports an item to the admin for moderation
   */
  public static itemNameUniqueItemNameReportPost(
    body: ItemNameUniqueItemNameReportPost,
    pathParams: ItemNameUniqueItemNameReportPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ItemService.basePath + 'item/name/:uniqueItemName/report',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemNameUniqueItemNameUrlCacheGet
   *  Summary: Fetch an item, but will also trigger an update to url-cache if too long has passed
   *  Description: Acts as a last resort catch all if the url-preview service messes up somehow to ensure that an item is not left indefinitely with a notice that we're still fetching the preview. Also does not spam the stat service with fake line  view stats.

   */
  public static itemNameUniqueItemNameUrlCacheGet(
    pathParams: ItemNameUniqueItemNameUrlCacheGetPath,
    query: ItemNameUniqueItemNameUrlCacheGetQuery
  ): Promise<Item> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemService.basePath + 'item/name/:uniqueItemName/url-cache',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: itemPost
   *  Summary: Create an item
   *  Description: Creates a new item
   */
  public static itemPost(body: ItemPost): Promise<Item> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ItemService.basePath + 'item',
      body,
    });
  }
}
