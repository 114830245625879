
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MButtonWithIcon extends Vue {
  @Prop({ default: 'is-outlined' })
  classes!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  iconRhs!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: 'button' })
  nativeType!: string;

  @Prop({ default: 'is-primary' })
  type!: string;
}
