
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { VEmojiPicker } from 'v-emoji-picker';
import { TributeOptions } from 'tributejs';
import { Emoji } from 'v-emoji-picker/lib/models/Emoji';
import { SmileIcon } from 'vue-feather-icons';
import AResizableTextareaWrapper from '../atoms/AResizableTextareaWrapper.vue';
import ATributeJs from '../atoms/ATributeJs.vue';
import ATooltip from '../atoms/ATooltip.vue';

@Component({
  components: {
    ATributeJs,
    ATooltip,
    AResizableTextareaWrapper,
    ValidationObserver,
    ValidationProvider,
    VEmojiPicker,
    SmileIcon
  },
})
export default class MInputWithValidation extends Vue {
  @Prop({ required: true })
  value!: any;

  @Prop()
  label?: string;

  @Prop()
  labelTooltip?: string;

  @Prop({ default: '' })
  subMessage!: string;

  @Prop({ required: true })
  name!: string;

  @Prop({ default: 'off' })
  autoComplete!: string;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop()
  autoFocus?: boolean;

  @Prop({ default: 'text' })
  type!: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop()
  nameAlt?: string;

  @Prop()
  placeholder?: string;

  @Prop()
  vid?: string;

  @Prop()
  rules?: string;

  @Prop({ default: false })
  horizontal!: boolean;

  @Prop()
  isResizable?: boolean;

  @Prop()
  isDecimalNumber?: boolean;

  @Prop()
  resetTextareaHeight?: boolean;

  @Prop({ default: 1 })
  textareaRows?: number;

  @Prop()
  groupFieldClass?: string;

  @Prop()
  allowEmoji?: boolean;

  @Prop()
  hashtagFetch?: (text: string, cb: (tags) => void) => void;

  @Prop()
  atMentionFetch?: (text: string, cb: (users) => void) => void;

  @Prop()
  atMentionNothingFound?: string;

  @Prop({default: 'bottom' })
  emojiPickerPosition?: 'top' | 'bottom';

  @Prop({ required: false })
  validationWrapperClass?: string;

  // array of keycodes to prevent, e.g. 13 = enter, prevent when enter should be form submission instead
  @Prop({required: false})
  preventKeypress?: number[];

  innerValue = '';
  showEmoji: boolean = false;
  body: any = null;
  $refs!: {
    bfield: any,
    input: HTMLElement,
    textarea: HTMLElement,
    emojiButton: HTMLElement
  };

  get hasLabelSlot () {
    return this.$slots.label !== undefined;
  }

  get tributeOptions (): TributeOptions<any>[] {
    const tributeOptions: TributeOptions<any>[] = [];

    if (this.atMentionFetch) {
      tributeOptions.push({
        trigger: '@',
        selectClass: 'highlight-item',
        values: (text: string, cb): void => {
          if (text.length > 0) {
            setTimeout(() => {
              // @ts-ignore
              this.atMentionFetch(text, users => cb(users));
            }, 200);
          }
        },
        noMatchTemplate: (): string => `<span class="mentioned-not-found">${this.atMentionNothingFound}</span>`
      });
    }
    if (this.hashtagFetch) {
      tributeOptions.push({
        trigger: '#',
        selectClass: 'highlight-item',
        values: (text: string, cb): void => {
          if (text.length > 0) {
            setTimeout(() => {
              // @ts-ignore
              this.hashtagFetch(text, tags => cb(tags));
            }, 200);
          }
        },
        noMatchTemplate: (): string => '<span class="mentioned-not-found hashtag"/>'
      });
    }
    return tributeOptions;
  }

  created () {
    if (typeof this.value !== 'undefined') {
      this.innerValue = this.value;
    }
  }

  mounted () {
    if (this.$refs.input && this.autoFocus) {
      this.$refs.input.focus();
    }

    if (this.$refs.textarea && this.autoFocus) {
      this.$refs.textarea.focus();
    }
  }

  beforeDestroy () {
    if (this.body) {
      this.body.removeEventListener('click', this.clickOutside);
      window.removeEventListener('scroll', this.getButtonPosition);
      window.removeEventListener('resize', this.getButtonPosition);
    }
  }

  focusOnInput () {
    const input = this.$refs.bfield.$el.querySelector('input, textarea');
    input && input.focus();
  }

  handleEnterPressed (e): void {
    if (!e.shiftKey) {
      this.$emit('enter');
    }
  }

  preventKey (e): void {
    if( !this.preventKeypress ){
      return;
    }
    else if( this.preventKeypress.includes(e.keyCode)){
      e.preventDefault();
    }
  }

  handleKeyup (e): void {
    this.$emit('keyup', e);
  }

  selectEmoji (emoji: Emoji): void {
    this.innerValue += emoji.data;
    setTimeout(() => {
      this.getButtonPosition();
    }, 100);
    this.focusOnInput();
    this.showEmoji = false;
  }

  showEmojiPicker (e): void {
    e.stopPropagation();

    // emoji picker will follow button position
    window.addEventListener('scroll', this.getButtonPosition);
    window.addEventListener('resize', this.getButtonPosition);

    // it will close emoji picker if clicked outside
    this.body = document.querySelector('body');
    this.body.addEventListener('click', this.clickOutside);

    // toggle visibility
    this.showEmoji = !this.showEmoji;

    // set emoji picker position
    this.$nextTick(() => {
      this.getButtonPosition();
    });
  }

  getButtonPosition (): void {
    const emojiPicker: any = document.querySelector('#EmojiPicker');

    if (emojiPicker) {
      const emojiRect = this.$refs.emojiButton.getBoundingClientRect();

      // sets picker position based on prop and button position
      emojiPicker.style.top = this.emojiPickerPosition === 'top' ?
        emojiRect.top - (emojiPicker.clientHeight + 20) + 'px' :
        emojiRect.top + 30 + 'px';
      emojiPicker.style.left = emojiRect.left - emojiPicker.clientWidth / 2 + 'px';
    }
  }

  clickOutside (e): void {
    if (this.showEmoji) {
      const emojiPicker: any = document.querySelector('#EmojiPicker');

      if (!(emojiPicker == e.target || emojiPicker.contains(e.target))) {
        this.showEmoji = false;
      }
    }
  }

  @Watch('innerValue')
  innerValueHandle (newVal) {
    this.$emit('child-output', newVal);
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueValueHandle (newVal) {
    this.innerValue = newVal;
  }
}
