
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus, { EventBusEvents } from '@/EventBus';
import { pause } from 'common-utils/time';

@Component
export default class AAddItemHeaderIcon extends Vue {
  @Prop({ default: 'navbar' })
  locationType!: string;

  grow: boolean = false;
  pulse: boolean = false;

  classObj = {
    'navbar-header-item-icon': this.locationType === 'navbar',
    'button-icon': this.locationType === 'button'
  };

  created () {
    EventBus.$on(EventBusEvents.ITEM_ADD_PULSE, 'AAddItemHeaderIcon.vue', () => {
      this.pulse = true;
      this.triggerPulse();
    });
  }

  beforeDestroy () {
    EventBus.$remove(EventBusEvents.ITEM_ADD_PULSE, 'AAddItemHeaderIcon.vue');
  }

  click () {
    this.$emit('click');
    this.pulse = false;
  }

  async triggerPulse () {
    this.grow = true;
    await pause(300);
    this.grow = false;
    await pause(2000);
    if (this.pulse) {
      await this.triggerPulse();
    }
  }
}
