
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Channel } from '@/api/ms-channel/services/interfaces';
import nl2br from '@/utils/nl2br';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AImage from '@/components/atoms/AImage.vue';
import { translation } from '@/plugins/i18n/Translation';
import formatDateDayjs from '@/utils/formatDateDayjs';
import { CalendarIcon, RepeatIcon } from 'vue-feather-icons';
import { ChannelType, EventType } from '@/api/ms-channel/services/interfaces/Channel';
import {
  camelCaseToSentenceCase,
  capitaliseFirstLetterOfString,
  capitaliseFirstLetterOfWords
} from 'common-utils/string';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import ASpanFormatDateDayJs from '@/components/atoms/ASpanFormatDateDayJs.vue';
import AUserContentDisplay from '@/components/atoms/AUserContentDisplay.vue';
import { Provider } from '@/api/ms-channel/services/interfaces/ChannelGenerateImage';

@Component({
  computed: {
    Provider () {
      return Provider;
    }
  },
  components: {
    AUserContentDisplay,
    ASpanFormatDateDayJs,
    ALightbulbOnOutlineIcon,
    AImage,
    CalendarIcon,
    RepeatIcon
  },
  filters: {
    lowerCase: (str) => str.toLowerCase(),
    ucfirst: capitaliseFirstLetterOfString
  },
  methods: {
    capitaliseFirstLetterOfWords: capitaliseFirstLetterOfWords,
    camelCaseToSentenceCase: camelCaseToSentenceCase
  }
})
export default class MChannelInformation extends Vue {
  @Prop()
  channel!: Channel;

  @Prop({ required: false, default: Size.The950X })
  sizeHero!: Size;

  @Prop({ required: false, default: Size.The100X100 })
  sizeThumbnail!: Size;

  @Prop({ required: false, default: false })
  hideHero!: boolean;

  @Prop({ required: false, default: false })
  hideThumbnail!: boolean;

  @Prop({ required: false, default: false })
  includeItemMeta!: boolean;

  @Prop({ required: false, default: false })
  hideImageCredits!: boolean;

  @Prop({ required: false, default: false })
  hideFooterControls!: boolean;

  isChannelEvent: boolean = false;
  isResearchConcierge: boolean = false;

  formattedEventDate: string = '';
  eventTypes = EventType;

  nl2br (input: string): string {
    return nl2br(input);
  }

  //todo - incorporate more meta data, no. items and stuff like that
  get itemMeta () {
    if (this.includeItemMeta) {
      return 'abc';
    } else {
      return false;
    }
  }

  get language () {
    return translation.currentLanguage;
  }

  get budgetString () {
    return this.channel.channelMeta && this.channel.channelMeta.budget ? this.channel.channelMeta.budget.currency + ' - ' + this.numberWithCommas(this.channel.channelMeta.budget.value) : '';
  }

  created () {
    this.setIsChannelType();
    this.setFormattedEventDate();
  }

  numberWithCommas (x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  setIsChannelType () {
    this.isChannelEvent = this.channel.channelType === ChannelType.Event;
    this.isResearchConcierge = this.channel.channelType === ChannelType.ResearchConcierge;
  }

  async setFormattedEventDate() {
    if( this.isChannelEvent && this.channel.event && this.channel.event.eventDate ){
      this.formattedEventDate = capitaliseFirstLetterOfString(await formatDateDayjs(this.channel.event.eventDate, this.language));
    }
  }

  closeInfo () {
    this.$emit('close-info');
  }
}
