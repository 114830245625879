
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ThumbsDownIcon } from 'vue-feather-icons';

@Component({
  components: { ThumbsDownIcon }
})
export default class AChannelSwipeItemLeft extends Vue {
  @Prop({ default: '1.5x' })
  size!: string;
  @Prop({ required: false, default: false })
  fill!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  mouseover = false;

  colours: {
    disabled: string,
    swipeLeft: string,
    white: string
  } = {
    disabled: 'var(--grey-mid-color)',
    swipeLeft: 'rgb(var(--channel-swipe-left))',
    white: 'var(--white-color)'
  };

  get style () {
    const size = parseFloat(this.size);
    return `width: ${size}rem; height: ${size}rem;`;
  }

  get fillColour () {
    if (this.disabled) {
      return this.colours.white;
    }

    return (this.mouseover || this.fill) ? this.colours.swipeLeft : this.colours.white;
  }

  get strokeColour () {
    if (this.disabled) {
      return this.colours.disabled;
    }

    return (!this.mouseover && !this.fill) ? this.colours.swipeLeft : this.colours.white;
  }

  get circleStrokeColour () {
    return this.disabled ? this.colours.disabled : this.colours.swipeLeft;
  }
}
