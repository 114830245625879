import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  ChannelWithRelationalMetaVue,
  IChannelModule,
  initialChannelData
} from '@/store/modules/interfaces/ChannelModule';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import NotificationService from '@/api/ms-channel/services/NotificationService';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { ChannelType } from '@/api/ms-channel/services/interfaces/ChannelWithRelationalMeta';
import { AuthenticationStore } from '@/store';

@Module({
  name: StoreNames.CHANNEL_STORE,
  namespaced: true,
})
export default class ChannelModule extends VuexModule implements IChannelModule {
  currentChannelViewed: ChannelWithRelationalMetaVue = initialChannelData;
  currentChannelViewedMembers: any[] = [];
  currentChannelNotificationsEnabled: boolean = false;

  initialLoadStatus: boolean = false;

  get getCurrentChannelViewed () {
    return this.currentChannelViewed;
  }

  get getCurrentChannelNotificationsEnabled () {
    return this.currentChannelNotificationsEnabled;
  }

  //todo - bring the channel editing stuff into here
  get getCurrentChannelViewedMembers () {
    return this.currentChannelViewedMembers;
  }

  @Mutation
  RESET () {
    this.currentChannelViewed = initialChannelData;
    this.currentChannelViewedMembers = [];

    this.initialLoadStatus = false;
  }

  @Action
  async fetchChannelData (slug) {
    const channel = {
      ...await ChannelService.channelSlugSlugGet({ slug: slug }),
      ...{
        archived: false
      }
    };
    channel.archived = !!channel.archivedAt;
    this.SET_CURRENT_CHANNEL_VIEWED({ channel: channel });
    this.SET_INITIAL_LOAD_STATUS({ status: true });
  }

  @Action
  async areNotificationsEnabledForChannel () {
    if (!AuthenticationStore.getAuthenticated) {
      return;
    }
    const channel = this.currentChannelViewed;
    const { notificationSettings } = await NotificationService.notificationSlugGet({
      slug: channel.slug
    });
    // if one of the settings are enabled set to true
    if (notificationSettings.filter((setting) => setting.enabled).length > 0) {
      this.SET_NOTIFICATIONS_ENABLED_TRUE();
      return;
    }
    // if an event channel, check if any are enabled
    if (channel.channelType === ChannelType.Event) {
      const eventNotificationSettings = (await ChannelMemberService.channelMemberSlugEventNotificationsGet({
        slug: channel.slug
      })).notificationSettings;
      if (eventNotificationSettings.length > 0) {
        this.SET_NOTIFICATIONS_ENABLED_TRUE();
        return;
      }
    }
    // set false
    this.SET_NOTIFICATIONSS_ENABLED_FALSE();
  }

  // Sets new archivedAt date or deletes the field, date will be accurate next time db is queried
  @Action
  updateArchivedAt (input: { toArchive: boolean }) {
    if (input.toArchive) {
      console.log('to archive');
      this.SET_ARCHIVED_AT({ archivedAt: new Date() });
    } else {
      console.log('restore');
      this.REMOVE_ARCHIVED_AT();
    }
  }

  @Mutation
  SET_INITIAL_LOAD_STATUS (input: { status: boolean }) {
    this.initialLoadStatus = input.status;
  }

  @Mutation
  SET_CURRENT_CHANNEL_VIEWED (input: { channel: ChannelWithRelationalMetaVue }) {
    this.currentChannelViewed = input.channel;
  }

  @Mutation
  SET_ARCHIVED_AT (input: { archivedAt: Date }) {
    this.currentChannelViewed.archivedAt = input.archivedAt;
    this.currentChannelViewed.archived = true;
  }

  @Mutation
  REMOVE_ARCHIVED_AT () {
    delete this.currentChannelViewed.archivedAt;
    this.currentChannelViewed.archived = false;
  }

  // Deleted at exists as a field, but not in the interface. Update at some point. Can piggy back UI off archivedAt
  // for now
  //
  // @Mutation
  // SET_DELETED_AT (input: { deletedAt: Date }) {
  //   if(this.currentChannelViewed.data) {
  //     this.currentChannelViewed.data.del = input.deletedAt;
  //   }
  // }

  @Mutation
  CLEAR_CHANNEL () {
    this.currentChannelViewed = initialChannelData;
    this.currentChannelViewedMembers = [];

    this.initialLoadStatus = false;
  }

  @Mutation
  SET_NOTIFICATIONS_ENABLED_TRUE () {
    this.currentChannelNotificationsEnabled = true;
  }

  @Mutation
  SET_NOTIFICATIONSS_ENABLED_FALSE () {
    this.currentChannelNotificationsEnabled = false;
  }

}
