
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserCheckIcon, UserMinusIcon, UserPlusIcon, UserXIcon } from 'vue-feather-icons';

@Component({
  components: {
    UserPlusIcon,
    UserXIcon,
    UserMinusIcon,
    UserCheckIcon
  }
})
export default class AConnectionIcon extends Vue {
  @Prop({ required: true })
  size!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ required: true })
  connectionType!: string;

  @Prop({ required: true })
  tooltipText!: string;

  @Prop({ default: 'is-top' })
  tooltipPosition!: string;
}
