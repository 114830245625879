import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { CustomField } from '@/api/ms-channel/services/interfaces/Channel';

export interface IChannelViewModule extends IStoreModule {
  channelsViewed: ChannelViewData[];
}

export interface CustomFieldDisplay extends CustomField {
  show: boolean;
}


export type NonCustomFields = NonCustomField[]

export interface NonCustomField {
  key: string,
  label: string,
  show: boolean
}

export enum AChannelViewEnums {
  grid = 'grid',
  table = 'table',
  map = 'map'
}

export interface ChannelViewData {
  slug: string,
  loadTab: AChannelViewEnums,
  timestamp: Date,
  displayCustomFields: CustomFieldDisplay[],
  nonCustomFields: NonCustomFields
}

export const initialChannelViewData: ChannelViewData = {
  slug: '',
  loadTab: AChannelViewEnums.grid,
  timestamp: new Date,
  displayCustomFields: [],
  nonCustomFields: [
    {
      key: 'createdAt',
      label: 'Date added',
      show: true
    },
    {
      key: 'editable.text',
      label: 'Item Description',
      show: true
    },
    {
      key: 'commentCount',
      label: 'Item comment count',
      show: false
    },
    {
      key: 'likeCount',
      label: 'Item like count',
      show: false
    },
    {
      key: 'swipeScore',
      label: 'Decision score',
      show: false
    },
    {
      key: 'userPhotos',
      label: 'Uploaded images',
      show: false
    },
    {
      key: 'urlCache.url',
      label: 'Web address (URL)',
      show: true
    },
    {
      key: 'urlCache.image',
      label: 'Website data: image',
      show: true
    },
    {
      key: 'urlCache.meta.title',
      label: 'Website data: title',
      show: true
    },
    {
      key: 'urlCache.meta.description',
      label: 'Website data: description',
      show: false
    },
    {
      key: 'urlCache.meta.brand',
      label: 'Website data: brand',
      show: false
    },
    {
      key: 'urlCache.price.price',
      label: 'Website data: price',
      show: false
    },
    {
      key: 'urlCache.price.currency',
      label: 'Website data: currency',
      show: false
    }
  ]
};
