import HttpService from '@/services/HttpService';

import { UserSettingsAppSettings } from './interfaces/UserSettingsAppSettings';
import { UserSettingsPut } from './interfaces/UserSettingsPut';

export default class UserSettingsService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: userSettingsGet
   *  Summary: Get user-settings
   *  Description:
   */
  public static userSettingsGet(): Promise<UserSettingsAppSettings> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserSettingsService.basePath + 'user-settings',
    });
  }

  /**
   *  Operation ID: userSettingsPut
   *  Summary: Update a user-settings
   *  Description: Overrides any existing or creates new should one not already be found
   */
  public static userSettingsPut(body: UserSettingsPut): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: UserSettingsService.basePath + 'user-settings',
      body,
    });
  }
}
