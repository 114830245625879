
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { MailIcon } from 'vue-feather-icons';

@Component({
  components: {
    MailIcon
  }
})
export default class ARouterLinkEmailNotifications extends Vue {
  @Prop({default: true})
  hasIcon!: boolean;

  name = RouteNames.ROUTE_SETTINGS_NOTIFICATIONS;
}
