
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HelpCircleIcon, MessageSquareIcon } from 'vue-feather-icons';
import { Comment, CommentLike } from '@/api/ms-item/services/interfaces/Item';
import { User } from '@/api/ms-authentication/services/interfaces';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

import { format } from 'timeago.js';
import ALikeIcon, { ALikeChildOutput } from '@/components/atoms/icon/ALikeIcon.vue';
import AEditIcon from '@/components/atoms/icon/AEditIcon.vue';
import OItemCommentForm from '@/components/organisms/forms/OItemCommentForm.vue';
import ADeleteIcon from '@/components/atoms/icon/ADeleteIcon.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ADotDivide from '@/components/atoms/ADotDivide.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import OItemCommentReplyForm from '@/components/organisms/forms/OItemCommentReplyForm.vue';
import MItemCommentReply from '@/components/molecules/comment/MItemCommentReply.vue';
import sortCompare from '@/utils/sortCompare';
import removeEmojis from '@/utils/removeEmojis';
import linkedText from '@/utils/linkedText';
import { RouteNames } from '@/router/RouteNames';
import { formatMentionUserContent } from '@/utils/formatMentionUsersData';
import { OModalsContainerInfoTypeEnum } from '@/components/organisms/OModalsContainer.vue';
import AUserDeletedProfilePic from '@/components/atoms/AUserDeletedProfilePic.vue';
import doesThisPassModeration from '@/utils/doesThisPassModeration';

@Component({
  components: {
    AUserDeletedProfilePic,
    MItemCommentReply,
    OItemCommentReplyForm,
    ADotDivide,
    ARouterLinkProfile,
    ADeleteIcon,
    OItemCommentForm,
    AEditIcon,
    ALikeIcon,
    AUserProfilePic,
    HelpCircleIcon,
    MessageSquareIcon
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    strToLower: (str) => {
      return str.toLowerCase();
    }
  },
})
export default class MItemComment extends Vue {
  @Prop({ required: true })
  uniqueItemName!: string;

  @Prop({ required: true })
  comment!: Comment;

  currentUser: User = AuthenticationStore.currentUser;
  likeLoading: boolean = false;
  editLoading: boolean = false;
  edit: boolean = false;
  userImageSize = Size.The60X60;

  get currentUserLikesThisComment (): boolean {
    const like = this.comment.likes.filter((like: CommentLike) => {
      return like.actor.username === this.currentUser.username && like.like;
    });
    return like.length > 0;
  }

  get commentLikeCount (): number {
    const likes = this.comment.likes.filter((like: CommentLike) => {
      return like.like;
    });
    return likes.length;
  }

  get getCommentReplies (): any[] {
    if (!this.comment.replies) {
      return [];
    }

    return this.comment.replies.reverse().sort(sortCompare('createdAt', 'asc')).filter((reply) => {
      return doesThisPassModeration(reply.moderation, reply.actor, this.currentUser);
    });
  }

  get isCommentBanned () {
    if( !this.comment.moderation ){
      return false;
    } else {
      return this.comment.moderation.banned;
    }
  }

  get commentPassesModeration () {
    return doesThisPassModeration(this.comment.moderation, this.comment.actor, this.currentUser);
  }

  // If comment awaits moderation or is banned, but there are replies we still want to display the replies
  get commentDisplay () {
    if( this.commentPassesModeration ){
      return true;
    }
    else {
      // If there are no replies we can just hide the whole component and any children
      return this.getCommentReplies.length > 0;
    }
  }

  mounted () {
    // redirect to users profile
    const mentionedProfiles: any = document.querySelectorAll('.mentioned-user-link');
    Array.from(mentionedProfiles, (item: any) => {
      item.addEventListener('click', (e) => {
        this.$router.push({
          name: RouteNames.ROUTE_PROFILE, params: {
            username: e.target.innerText,
          }
        });
      });
    });
  }

  formatCommentContent (input: string): string {
    let comment = linkedText(input);

    return formatMentionUserContent(comment);
  }

  showCommentTypeHelp (): void {
    EventBus.$emit(EventBusEvents.MODAL_SHOW_INFO, {
      type: OModalsContainerInfoTypeEnum.COMMENT_TYPE
    });
  }

  async likeComment (input: ALikeChildOutput): Promise<void> {
    if( !this.comment.deletedAt ) {
      this.likeLoading = true;
      const item = await ItemDetailStore.itemCommentLikePatch({
        commentId: String(this.comment._id),
        like: input.like
      });
      // update the item in the main items store as well if it exists
      ItemsStore.SET_ITEM_DETAIL(item);
      this.likeLoading = false;
    }
  }

  displayEditToggle (): void {
    this.edit = !this.edit;
  }

  async deleteHandle (): Promise<void> {
    const item = await ItemDetailStore.itemCommentDelete({
      commentId: String(this.comment._id)
    });

    // update the item in the main items store as well if it exists
    ItemsStore.SET_ITEM_DETAIL(item);
  }

  isEmojiOnly (): boolean {
    return !removeEmojis(this.comment.comment);
  }
}
