import { DialogProgrammatic as Dialog } from 'buefy';
import { i18n } from '@/plugins/i18n';

export default (e) => {
  console.error(e.message);
  Dialog.alert({
    title: i18n.t('form.error.title') as string,
    message: i18n.t('form.error.message') as string,
  });
};
