
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ExternalLinkIcon } from 'vue-feather-icons';

@Component({
  components: {
    ExternalLinkIcon
  }
})
export default class ALink extends Vue {
  @Prop({ required: true })
  link!: string;
  @Prop({default: false})
  external!: boolean;

  get urlOrigin () {
    return (new URL(this.link)).origin;
  }
}
