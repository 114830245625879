
import { Component, Prop, Vue } from 'vue-property-decorator';
import ABadge from '@/components/atoms/ABadge.vue';
import { ShoppingBagIcon } from 'vue-feather-icons';

@Component({
  components: {
    ABadge,
    ShoppingBagIcon
  }
})
export default class AAddItemIcon extends Vue {
  @Prop({ default: 0 })
  count!: number;
}
