export interface ChannelsWithStatusGetQuery {
  memberStatus?: MemberStatus;
}

export enum MemberStatus {
  ConciergeJobAccepted = 'ConciergeJobAccepted',
  ConciergeJobCancelled = 'ConciergeJobCancelled',
  ConciergeJobCompleted = 'ConciergeJobCompleted',
  ConciergeJobRemoved = 'ConciergeJobRemoved',
  InviteAccepted = 'InviteAccepted',
  InviteCancelled = 'InviteCancelled',
  InviteRejected = 'InviteRejected',
  Invited = 'Invited',
  JoinRequestAccepted = 'JoinRequestAccepted',
  JoinRequestCancelled = 'JoinRequestCancelled',
  JoinRequestRejected = 'JoinRequestRejected',
  JoinRequestSent = 'JoinRequestSent',
  RemovedByChannel = 'RemovedByChannel',
  SelfRemoval = 'SelfRemoval',
}
