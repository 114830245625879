import HttpService from '@/services/HttpService';

import { Connection } from './interfaces/Connection';
import { Connections } from './interfaces/Connections';
import { ConnectionsGetQuery } from './interfaces/ConnectionsGetQuery';
import { ConnectionsPost } from './interfaces/ConnectionsPost';
import { ConnectionsUsernameDeletePath } from './interfaces/ConnectionsUsernameDeletePath';
import { ConnectionsUsernameMatchingGetPath } from './interfaces/ConnectionsUsernameMatchingGetPath';
import { ConnectionsUsernameMatchingGetQuery } from './interfaces/ConnectionsUsernameMatchingGetQuery';
import { ConnectionsUsernameNotMatchingGetPath } from './interfaces/ConnectionsUsernameNotMatchingGetPath';
import { ConnectionsUsernameNotMatchingGetQuery } from './interfaces/ConnectionsUsernameNotMatchingGetQuery';
import { ConnectionsUsernamePutPath } from './interfaces/ConnectionsUsernamePutPath';

export default class ConnectionsService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: connectionsGet
   *  Summary: Get connections
   *  Description: By default the API will return only accepted connections
   */
  public static connectionsGet(
    query: ConnectionsGetQuery
  ): Promise<Connections> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ConnectionsService.basePath + 'connections',
      qs: query,
    });
  }

  /**
   *  Operation ID: connectionsPost
   *  Summary: Create a connections
   *  Description:
   */
  public static connectionsPost(body: ConnectionsPost): Promise<Connection> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ConnectionsService.basePath + 'connections',
      body,
    });
  }

  /**
   *  Operation ID: connectionsUsernameDelete
   *  Summary: Delete a connections based on {username}
   *  Description:
   */
  public static connectionsUsernameDelete(
    pathParams: ConnectionsUsernameDeletePath
  ): Promise<Connection> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: ConnectionsService.basePath + 'connections/:username',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: connectionsUsernamePut
   *  Summary: Update a connections based on {username}
   *  Description:
   */
  public static connectionsUsernamePut(
    pathParams: ConnectionsUsernamePutPath
  ): Promise<Connection> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ConnectionsService.basePath + 'connections/:username',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: connectionsUsernameMatchingGet
   *  Summary: Get matching, from connections {username}
   *  Description: Fetches other usernames connections in common with the current user
   */
  public static connectionsUsernameMatchingGet(
    pathParams: ConnectionsUsernameMatchingGetPath,
    query: ConnectionsUsernameMatchingGetQuery
  ): Promise<Connections> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ConnectionsService.basePath + 'connections/:username/matching',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: connectionsUsernameNotMatchingGet
   *  Summary: Get not-matching, from connections {username}
   *  Description: Fetches users connected to a given username that are not connected to the current user
   */
  public static connectionsUsernameNotMatchingGet(
    pathParams: ConnectionsUsernameNotMatchingGetPath,
    query: ConnectionsUsernameNotMatchingGetQuery
  ): Promise<Connections> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ConnectionsService.basePath + 'connections/:username/not-matching',
      params: pathParams,
      qs: query,
    });
  }
}
