import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AuthenticationStore, SearchSettingsStore } from '@/store';
import ChannelsService from '@/api/ms-channel/services/ChannelsService';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { Datum as ChannelMembersDatum } from '@/api/ms-channel/services/interfaces/ChannelMembers';
import { SearchType } from '@/store/modules/enums/SearchType';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  ChannelDatumExtended,
  defaultChannelUpsertObject,
  IChannelsModule
} from '@/store/modules/interfaces/ChannelsModule';
import { Channel } from '@/api/ms-channel/services/interfaces/Channel';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import { ChannelPost, ChannelsGetQuery, ChannelWithCurrentUserStatus } from '@/api/ms-channel/services/interfaces';
import { MemberStatus } from '@/api/ms-channel/services/interfaces/ChannelsWithStatusGetQuery';
import { ChannelOrPeopleContainerSelectedTab } from '@/enums/ChannelOrPeopleContainerSelectedTab';
import clone from '@/utils/clone';
import { Datum as ChannelManagesDatum } from '@/api/ms-channel/services/interfaces/ChannelManages';

interface ChannelsStoreSearch {
  text: string,
  searchType: SearchType,
  searchQuery: ChannelsGetQuery
}

interface ChannelsOfOtherProfileStoreSearch {
  username: string;
  search: ChannelsStoreSearch;
}

interface ChannelStatuses {
  channelsNotMemberOf: boolean,
  channelsMemberOf: boolean,
  channelsOfProfile: boolean,
  channelsArchived: boolean
}

@Module({
  name: StoreNames.CHANNELS_STORE,
  namespaced: true,
})
export default class ChannelsModule extends VuexModule implements IChannelsModule {
  //todo pull out channel singular stuff to the ChannelModule
  channelEditing!: ChannelPost | Channel;
  currentChannelViewedMembers: any[] = [];
  channelsOfProfile: ChannelDatumExtended[] = [];
  channelsMemberOf: ChannelDatumExtended[] = [];
  channelsManagerOf: ChannelManagesDatum[] = [];
  channelsNotMemberOf: ChannelDatumExtended[] = [];
  channelsArchived: ChannelDatumExtended[] = [];
  channelsLastAddedTo: ChannelDatumExtended[] = [];
  channelsLastAddedToFetched: Date = new Date('2020-01-01');
  channelsSelectedTab: ChannelOrPeopleContainerSelectedTab = ChannelOrPeopleContainerSelectedTab.yours;
  channelInvites: ChannelWithCurrentUserStatus = {
    channels: [],
    statuses: []
  };
  currentChannelNotMemberOf: boolean = false;
  //Boolean statements to say whether the first load has been completed or not, change to false to refresh
  initialLoadStatuses: ChannelStatuses = {
    channelsNotMemberOf: false,
    channelsMemberOf: false,
    channelsOfProfile: false,
    channelsArchived: false
  };
  //Boolean statements are checked against to determine whether to display load more button or not. False = load more.
  noMoreChannelsResults: ChannelStatuses = {
    channelsNotMemberOf: true,
    channelsMemberOf: true,
    channelsOfProfile: true,
    channelsArchived: true
  };
  membersNoMoreResults: boolean = false;

  get getChannelsSelectedTab (): ChannelOrPeopleContainerSelectedTab {
    return this.channelsSelectedTab;
  }

  get getChannelInvites (): ChannelWithCurrentUserStatus {
    return this.channelInvites;
  }

  get getChannelsOfProfile () {
    return this.channelsOfProfile;
  }

  get getChannelsMemberOf () {
    return this.channelsMemberOf;
  }

  get getChannelsManagerOf () {
    return this.channelsManagerOf;
  }

  get getChannelsNotMemberOf () {
    return this.channelsNotMemberOf;
  }

  get getChannelsArchived () {
    return this.channelsArchived;
  }

  get getChannelsLastAddedTo () {
    return this.channelsLastAddedTo;
  }

  get getChannelsLastAddedToFetched () {
    return this.channelsLastAddedToFetched;
  }

  get getCurrentChannelViewedMembers () {
    return this.currentChannelViewedMembers;
  }

  get getChannelEditing () {
    return this.channelEditing ? this.channelEditing : clone(defaultChannelUpsertObject);
  }

  get getCurrentChannelNotMemberOf () {
    // returns true if the current user is a member of the current channel being viewed
    return this.currentChannelNotMemberOf;
  }

  get getNoMoreChannelsResults () {
    return this.noMoreChannelsResults;
  }

  get getInitialLoadStatuses () {
    return this.initialLoadStatuses;
  }

  get getMembersNoMoreResults () {
    return this.membersNoMoreResults;
  }

  @Mutation
  RESET () {
    this.currentChannelViewedMembers = [];
    this.channelsNotMemberOf = [];
    this.channelsMemberOf = [];
    this.channelsManagerOf = [];
    this.channelsOfProfile = [];
    this.channelsArchived = [];
    this.channelsLastAddedTo = [];
    this.channelsLastAddedToFetched = new Date('2020-01-01');
    this.channelInvites = {
      channels: [],
      statuses: [],
    };
    this.channelEditing = clone(defaultChannelUpsertObject);
    this.currentChannelNotMemberOf = false;
    this.initialLoadStatuses = {
      channelsNotMemberOf: false,
      channelsMemberOf: false,
      channelsOfProfile: false,
      channelsArchived: false
    };
    this.noMoreChannelsResults = {
      channelsNotMemberOf: true,
      channelsMemberOf: true,
      channelsOfProfile: true,
      channelsArchived: true
    };
    this.membersNoMoreResults = true;
  }

  @Action
  async fetchChannelInvites () {
    this.SET_CHANNEL_INVITES(
      await ChannelsService.channelsWithStatusGet({
        memberStatus: MemberStatus.Invited,
      })
    );
  }

  @Action
  async fetchChannelMembers (input: { slug: string, searchString: string }) {
    this.CLEAR_MEMBERS_CHANNEL();
    const { searchString, slug } = input;
    try {
      const { data } = await ChannelMemberService.channelMemberSlugGet(
        {
          slug
        },
        {
          text: searchString
        });
      this.SET_MEMBERS_CHANNEL({ results: data });
      this.SET_MEMBERS_NO_MORE_RESULTS({ value: (data.length < 20) });
      this.CURRENT_CHANNEL_NOT_MEMBER_OF_SET({ value: false });
    } catch (e) {
      this.CURRENT_CHANNEL_NOT_MEMBER_OF_SET({ value: true });
    }
  }

  @Action
  async fetchLatestChannelMembers (slug) {
    try {
      const { data } = await ChannelMemberService.channelMemberSlugGet(
        { slug },
        { offset: this.currentChannelViewedMembers.length }
      );
      this.APPEND_MEMBERS_CHANNEL({ results: data });
      this.SET_MEMBERS_NO_MORE_RESULTS({ value: (data.length < 20) });
      this.CURRENT_CHANNEL_NOT_MEMBER_OF_SET({ value: false });
    } catch (e) {
      this.CURRENT_CHANNEL_NOT_MEMBER_OF_SET({ value: true });
    }
  }

  @Action
  async searchChannels (search: ChannelsStoreSearch) {
    const { text } = search;
    if (AuthenticationStore.getAuthenticated) {
      const memberData = await ChannelsService.channelsGet(Object.assign({
        memberOf: true,
        text,
        offset: 0,
      }, search.searchQuery ? search.searchQuery : {}));
      // pre-populate for reactivity
      memberData.data = memberData.data.map((data) => {
        return {
          ...data,
          ...{
            notificationsEnabled: {
              stateGot: false,
              true: false
            }
          }
        };
      });
      this.SET_CHANNELS_MEMBER_OF({ results: memberData.data });
      this.SET_NO_MORE_RESULTS({ key: 'channelsMemberOf', value: (memberData.data.length < 20) });
    }
    const nonMemberData = await ChannelsService.channelsGet(Object.assign({
      memberOf: false,
      text,
      offset: 0
    }, SearchSettingsStore.getChannelsSearchSettingsSortOnly));
    this.SET_CHANNELS_NOT_MEMBER_OF({ results: nonMemberData.data });
    this.SET_NO_MORE_RESULTS({ key: 'channelsNotMemberOf', value: (nonMemberData.data.length < 20) });
  }

  @Action
  async fetchMineLatestChannels (search: ChannelsStoreSearch) {
    const { text } = search;
    if (AuthenticationStore.getAuthenticated) {
      const memberData = await ChannelsService.channelsGet(Object.assign({
        memberOf: true,
        text,
        offset: this.channelsMemberOf.length
      }, search.searchQuery ? search.searchQuery : {}));
      this.APPEND_CHANNELS_MEMBER_OF({ results: memberData.data });
      this.SET_NO_MORE_RESULTS({ key: 'channelsMemberOf', value: (memberData.data.length < 20) });
    }
  }

  @Action
  async fetchOthersLatestChannels (search: ChannelsStoreSearch) {
    const { text } = search;
    const nonMemberData = await ChannelsService.channelsGet(Object.assign({
      memberOf: false,
      text,
      offset: this.channelsNotMemberOf.length
    }, search.searchQuery ? search.searchQuery : {}));
    this.APPEND_CHANNELS_NOT_MEMBER_OF({ results: nonMemberData.data });
    this.SET_NO_MORE_RESULTS({ key: 'channelsNotMemberOf', value: (nonMemberData.data.length < 20) });
  }

  @Action
  async fetchLatestChannelsOfProfile (input: ChannelsOfOtherProfileStoreSearch) {
    const { text } = input.search;
    const { data } = await ChannelsService.channelsOfConnectionUsernameGet(
      { username: input.username },
      {
        text: text,
        offset: this.channelsOfProfile.length
      }
    );
    this.APPEND_CHANNELS_OF_PROFILE({ results: data });
    this.SET_NO_MORE_RESULTS({ key: 'channelsOfProfile', value: (data.length < 20) });
  }

  /**
   * Returns the entire list of channels the current user, via jwt, is a manager of
   */
  @Action
  async fetchChannelsManagerOf () {
    const channels = await ChannelsService.channelsManageGet();
    this.SET_CHANNELS_MANAGER_OF({ channels: channels.data });
  }

  @Action
  async fetchChannelsArchived (search: ChannelsStoreSearch) {
    const { text } = search;
    if (AuthenticationStore.getAuthenticated) {
      const channelsData = await ChannelsService.channelsGet(Object.assign({
        memberOf: true,
        text,
        offset: this.channelsArchived.length,
        archived: true
      }, search.searchQuery ? search.searchQuery : {}));

      this.channelsArchived.length ?
        this.APPEND_CHANNELS_ARCHIVED({ results: channelsData.data }) :
        this.SET_CHANNELS_ARCHIVED({ results: channelsData.data });
      this.SET_NO_MORE_RESULTS({ key: 'channelsArchived', value: (channelsData.data.length < 20) });
    }
  }

  /**
   * Viewing the list of channels from a different users profile page
   * @param input
   */
  @Action
  async fetchChannelsByUsername (input: ChannelsOfOtherProfileStoreSearch) {
    const { text } = input.search;
    const { data } = await ChannelsService.channelsOfConnectionUsernameGet(
      { username: input.username },
      {
        text: text,
        offset: this.channelsOfProfile.length
      }
    );
    this.SET_NO_MORE_RESULTS({ key: 'channelsOfProfile', value: (data.length < 20) });
    this.SET_CHANNELS_OF_PROFILE({
      results: data
    });
  }

  @Action
  async fetchChannelsLastAddedTo () {
    const { data } = await ChannelsService.channelsLastAddedToGet();

    this.SET_CHANNELS_LAST_ADDED_TO({ channels: data });
    this.SET_CHANNELS_LAST_ADDED_TO_FETCHED();
  }

  //todo - move channel editing stuff to ChannelModule
  @Action
  async fetchChannel (input: { slug: string }) {
    const channel = await ChannelService.channelSlugSlugGet({
      slug: input.slug,
    });

    this.SET_CHANNEL_EDITING({ channel });
  }

  /**
   * Leave a channel then update the channels store to remove it.
   */
  @Action
  async leaveChannelMemberOfBySlug (slug: string) {
    await ChannelMemberService.channelMemberSlugLeavePatch({ slug });
    const index = this.channelsMemberOf.findIndex((channel) => channel.slug === slug);
    if (index > -1) {
      this.REMOVE_CHANNEL_MEMBER_OF_BY_INDEX({ index: index });
    }
  }

  /**
   * Removing a channel from the store - e.g. it's been archived
   *
   * @param slug Slug of the channel
   */
  @Action
  removeChannelMemberOfBySlug (slug: string) {
    const index = this.channelsMemberOf.findIndex((channel) => channel.slug === slug);
    if (index > -1) {
      this.REMOVE_CHANNEL_MEMBER_OF_BY_INDEX({ index: index });
    }
  }

  @Action
  removeChannelArchivedBySlug (slug: string) {
    const index = this.channelsArchived.findIndex((channel) => channel.slug === slug);
    if (index > -1) {
      this.REMOVE_CHANNEL_ARCHIVED_BY_INDEX({ index: index });
    }
  }

  @Mutation
  SET_MEMBERS_CHANNEL (input: { results: ChannelMembersDatum[] }) {
    this.currentChannelViewedMembers = input.results;
  }

  @Mutation
  APPEND_MEMBERS_CHANNEL (input: { results: ChannelMembersDatum[] }) {
    this.currentChannelViewedMembers = this.currentChannelViewedMembers.concat(input.results);
  }

  @Mutation
  CLEAR_MEMBERS_CHANNEL () {
    this.currentChannelViewedMembers = [];
  }

  @Mutation
  SET_CHANNELS_OF_PROFILE (input: { results: ChannelDatumExtended[] }) {
    this.channelsOfProfile = input.results;
  }

  @Mutation
  APPEND_CHANNELS_OF_PROFILE (input: { results: ChannelDatumExtended[] }) {
    this.channelsOfProfile = this.channelsOfProfile.concat(input.results);
  }

  @Mutation
  SET_CHANNEL_INVITES (input: ChannelWithCurrentUserStatus) {
    this.channelInvites = input;
  }

  @Mutation
  SET_CHANNELS_MEMBER_OF (input: { results: ChannelDatumExtended[] }) {
    this.channelsMemberOf = input.results;
  }

  @Mutation
  APPEND_CHANNELS_MEMBER_OF (input: { results: ChannelDatumExtended[] }) {
    this.channelsMemberOf = this.channelsMemberOf.concat(input.results);
  }

  @Mutation
  SET_CHANNELS_NOT_MEMBER_OF (input: { results: ChannelDatumExtended[] }) {
    this.channelsNotMemberOf = input.results;
  }

  @Mutation
  SET_CHANNEL_MEMBER_OF_NOTIFICATIONS_ENABLED (input: { channel: ChannelDatumExtended }) {
    const channelIndex = this.channelsMemberOf.findIndex((channel) => channel.slug === input.channel.slug);
    if (channelIndex !== -1) {
      this.channelsMemberOf[channelIndex].notificationsEnabled = {
        stateGot: true,
        true: true
      };
    }
  }

  @Mutation
  SET_CHANNEL_MEMBER_OF_NOTIFICATIONS_DISABLED (input: { channel: ChannelDatumExtended }) {
    const channelIndex = this.channelsMemberOf.findIndex((channel) => channel.slug === input.channel.slug);
    if (channelIndex !== -1) {
      this.channelsMemberOf[channelIndex].notificationsEnabled = {
        stateGot: true,
        true: false
      };
    }
  }

  @Mutation
  APPEND_CHANNELS_NOT_MEMBER_OF (input: { results: ChannelDatumExtended[] }) {
    this.channelsNotMemberOf = this.channelsNotMemberOf.concat(input.results);
  }

  @Mutation
  SET_CHANNELS_ARCHIVED (input: { results: ChannelDatumExtended[] }) {
    this.channelsArchived = input.results;
  }

  @Mutation
  APPEND_CHANNELS_ARCHIVED (input: { results: ChannelDatumExtended[] }) {
    this.channelsArchived = this.channelsArchived.concat(input.results);
  }

  @Mutation
  SET_CHANNELS_MANAGER_OF (input: { channels: ChannelManagesDatum[] }) {
    this.channelsManagerOf = input.channels;
  }

  @Mutation
  SET_CHANNEL_EDITING (input: { channel: Channel }) {
    this.channelEditing = input.channel;
  }

  @Mutation
  CURRENT_CHANNEL_NOT_MEMBER_OF_SET (input: { value }) {
    this.currentChannelNotMemberOf = input.value;
  }

  /**
   * Sets whether there are more results to load, and also sets the initial load as true for this
   * key as well as wherever this mutation is called must have loaded at least some results
   */
  @Mutation
  SET_NO_MORE_RESULTS (input: { key: string, value: boolean }) {
    this.initialLoadStatuses[input.key] = true;
    this.noMoreChannelsResults[input.key] = input.value;
  }

  @Mutation
  SET_MEMBERS_NO_MORE_RESULTS (input: { value: boolean }) {
    this.membersNoMoreResults = input.value;
  }

  @Mutation
  REMOVE_CHANNEL_MEMBER_OF_BY_INDEX (input: { index: number }) {
    this.channelsMemberOf.splice(input.index, 1);
  }

  @Mutation
  REMOVE_CHANNEL_ARCHIVED_BY_INDEX (input: { index: number }) {
    this.channelsArchived.splice(input.index, 1);
  }

  @Mutation
  SET_CHANNELS_SELECTED_TAB (tab: ChannelOrPeopleContainerSelectedTab): void {
    this.channelsSelectedTab = tab;
  }

  @Mutation
  SET_CHANNELS_LAST_ADDED_TO (input: { channels: ChannelDatumExtended[] }) {
    this.channelsLastAddedTo = input.channels;
  }

  @Mutation
  SET_CHANNELS_LAST_ADDED_TO_FETCHED () {
    this.channelsLastAddedToFetched = new Date();
  }

  @Mutation
  RESET_CHANNEL_EDITING () {
    this.channelEditing = clone(defaultChannelUpsertObject);
  }

  @Mutation
  CLEAR_CHANNELS_MEMBER_OF () {
    this.channelsMemberOf = [];
    this.initialLoadStatuses.channelsMemberOf = false;
    this.noMoreChannelsResults.channelsMemberOf = true;
  }

  @Mutation
  CLEAR_CHANNELS_MANAGER_OF () {
    this.channelsManagerOf = [];
  }

  @Mutation
  CLEAR_CHANNELS_ARCHIVED () {
    this.channelsArchived = [];
    this.initialLoadStatuses.channelsArchived = false;
    this.noMoreChannelsResults.channelsArchived = true;
  }

  @Mutation
  CLEAR_CHANNELS () {
    this.channelsNotMemberOf = [];
    this.channelsMemberOf = [];
    this.channelsOfProfile = [];
    this.channelsArchived = [];
    this.initialLoadStatuses = {
      channelsNotMemberOf: false,
      channelsMemberOf: false,
      channelsOfProfile: false,
      channelsArchived: false
    };
    this.noMoreChannelsResults = {
      channelsNotMemberOf: true,
      channelsMemberOf: true,
      channelsOfProfile: true,
      channelsArchived: true
    };
  }
}
