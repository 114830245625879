import HttpService from '@/services/HttpService';

import { ChannelMembers } from './interfaces/ChannelMembers';
import { SwipeScoresSlugMembersGetPath } from './interfaces/SwipeScoresSlugMembersGetPath';
import { SwipeScoresSlugMembersGetQuery } from './interfaces/SwipeScoresSlugMembersGetQuery';
import { SwipeScoresSlugNudgePost } from './interfaces/SwipeScoresSlugNudgePost';
import { SwipeScoresSlugNudgePostPath } from './interfaces/SwipeScoresSlugNudgePostPath';

export default class SwipeScoresService {
  public static basePath = '/__/ms-channel/';

  /**
   *  Operation ID: swipeScoresSlugMembersGet
   *  Summary: Get members for a channel to send decision maker nudges to
   *  Description: Validate that this user can send nudges for this channel, then return the channel members list
   */
  public static swipeScoresSlugMembersGet(
    pathParams: SwipeScoresSlugMembersGetPath,
    query: SwipeScoresSlugMembersGetQuery
  ): Promise<ChannelMembers> {
    return HttpService.sendRequest({
      method: 'GET',
      path: SwipeScoresService.basePath + 'swipe-scores/:slug/members',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: swipeScoresSlugNudgePost
   *  Summary: Nudge members of a channel to remind them about the swipe scores
   *  Description:
   */
  public static swipeScoresSlugNudgePost(
    body: SwipeScoresSlugNudgePost,
    pathParams: SwipeScoresSlugNudgePostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: SwipeScoresService.basePath + 'swipe-scores/:slug/nudge',
      body,
      params: pathParams,
    });
  }
}
