export interface UserPut {
  accountSteps?: AccountSteps;
  birthday?: Date;
  firstName?: string;
  gender?: Gender;
  language?: Language;
  lastName?: string;
  measurements?: Measurements;
}

export interface AccountSteps {
  welcomed?: boolean;
}

export enum Gender {
  Female = 'female',
  Male = 'male',
  NoAnswer = 'no-answer',
}

export enum Language {
  DeDE = 'deDE',
  EnGB = 'enGB',
  EsES = 'esES',
  FrFR = 'frFR',
  HrHR = 'hrHR',
  ItIT = 'itIT',
  NlNL = 'nlNL',
  PtBR = 'ptBR',
  RoRO = 'roRO',
}

export interface Measurements {
  heightCM?: number;
  shoeSizeEU?: number;
  wasteCM?: number;
}
