import { Route } from 'vue-router';

/**
 * Merge an object to the query of Route. Returns the new object to push into the Router, or false if nothing to update.
 *
 * @param $route Vue Route
 * @param queryToMerge Object representing a query (e.g. { param: value, foo: bar });
 * @param defaultHashMap (optional) Object representing default values to strip from the query as they are moot
 *
 * @return Returns the new object, or if nothing changed then returns false
 */
export default ($route: Route, queryToMerge: {[key: string]: any}, defaultHashMap?: {[key: string]: any}): false|{[key: string]: any} => {
  if (!queryToMerge || Object.keys(queryToMerge).length === 0) {
    return false;
  }
  Object.keys(queryToMerge).forEach(key => (queryToMerge[key] === undefined || (defaultHashMap ? queryToMerge[key] === defaultHashMap[key] : queryToMerge[key] === undefined)) && delete queryToMerge[key]);
  if (Object.keys(queryToMerge).length === 0) {
    return false;
  }
  return { ...$route.query, ...queryToMerge };
};