import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  ChannelViewData,
  CustomFieldDisplay,
  IChannelViewModule,
  initialChannelViewData,
  NonCustomField
} from '@/store/modules/interfaces/ChannelViewModule';
import { clone } from 'common-utils/object';
import { getDifferenceBetweenTwoDates } from 'common-utils/time';
import { CustomFieldsOrder } from '@/api/ms-channel/services/interfaces/ChannelWithRelationalMeta';

interface Slug {
  slug: string;
}

interface SlugCustomField extends Slug {
  field: CustomFieldDisplay;
}

interface SlugCustomFields extends Slug {
  fields: CustomFieldDisplay[];
}

interface SlugStandardField extends Slug {
  field: NonCustomField;
}

@Module({
  name: StoreNames.CHANNEL_VIEW_STORE,
  namespaced: true,
})
export default class ChannelViewModule extends VuexModule implements IChannelViewModule {
  channelsViewed: ChannelViewData[] = [];

  get getChannelsViewed (): ChannelViewData[] {
    return this.channelsViewed;
  }

  /**
   * Actions
   */

  @Action
  addNewChannel (input: { slugCustomFields: SlugCustomFields, savedCustomFieldsOrder?: CustomFieldsOrder }) {
    const newChannel: ChannelViewData = clone(initialChannelViewData);
    newChannel.slug = input.slugCustomFields.slug;
    newChannel.displayCustomFields = input.slugCustomFields.fields;
    // If we have default settings to input, do it here
    if (input.savedCustomFieldsOrder) {
      input.savedCustomFieldsOrder.nonCustomFields.forEach((savedField) => {
        const index = newChannel.nonCustomFields.findIndex((field) => field.key === savedField.key);
        if (index !== -1) {
          newChannel.nonCustomFields[index].show = savedField.show;
        }
      });
      input.savedCustomFieldsOrder.customFieldDisplay.forEach((savedField) => {
        const index = newChannel.displayCustomFields.findIndex((field) => field._id === savedField._id);
        if (index !== -1) {
          newChannel.displayCustomFields[index].show = savedField.show;
        }
      });
    }
    this.ADD_NEW_CHANNEL(newChannel);
  }

  @Action
  pruneOldChannels () {
    // channels older than 1 week get pruned, for loop in reverse order to avoid splice splicing wrong splicee if more than 1
    const date = new Date();
    for (let i = this.channelsViewed.length - 1; i >= 0; i--) {
      const diff = getDifferenceBetweenTwoDates(date, new Date(this.channelsViewed[i].timestamp));
      if (diff.days > 7) {
        this.REMOVE_ONE_CHANNEL(i);
      }
    }
  }

  @Action
  setAllStandardOff (input: Slug) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ALL_STANDARD_SHOW_OFF(index);
    }
  }

  @Action
  setAllStandardOn (input: Slug) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ALL_STANDARD_SHOW_ON(index);
    }
  }

  @Action
  setOneStandardField (input: SlugStandardField) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ONE_STANDARD_FIELD({ index, field: input.field });
    }
  }

  @Action
  setStandardToDefault (input: Slug) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_STANDARD_TO_DEFAULT(index);
    }
  }

  @Action
  setAllCustomOff (input: Slug) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ALL_CUSTOM_SHOW_OFF(index);
    }
  }

  @Action
  setAllCustomOn (input: Slug) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ALL_CUSTOM_SHOW_ON(index);
    }
  }

  @Action
  setAllCustomFields (input: SlugCustomFields) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ALL_CUSTOM_FIELDS({ index, fields: input.fields });
    }
  }

  @Action
  setOneCustomField (input: SlugCustomField) {
    const index = this.channelsViewed.findIndex((channel) => channel.slug === input.slug);
    if (index !== -1) {
      this.SET_ONE_CUSTOM_FIELD({ index, field: input.field });
    }
  }

  /**
   * Mutations
   */

  @Mutation
  RESET () {
    this.channelsViewed = [];
  }

  @Mutation
  SET_ALL_STANDARD_SHOW_OFF (index: number) {
    for (let i = 0; i < this.channelsViewed[index].nonCustomFields.length; i++) {
      this.channelsViewed[index].nonCustomFields[i].show = false;
    }
  }

  @Mutation
  SET_ALL_STANDARD_SHOW_ON (index: number) {
    for (let i = 0; i < this.channelsViewed[index].nonCustomFields.length; i++) {
      this.channelsViewed[index].nonCustomFields[i].show = true;
    }
  }

  @Mutation
  SET_ONE_STANDARD_FIELD (input: { index: number, field: NonCustomField }) {
    const fieldIndex = this.channelsViewed[input.index].nonCustomFields.findIndex(f => f.key === input.field.key);
    this.channelsViewed[input.index].nonCustomFields.splice(fieldIndex, 1, input.field);
  }

  @Mutation
  SET_STANDARD_TO_DEFAULT (index: number) {
    this.channelsViewed[index].nonCustomFields = clone(initialChannelViewData.nonCustomFields);
  }

  @Mutation
  SET_ALL_CUSTOM_SHOW_OFF (index: number) {
    for (let i = 0; i < this.channelsViewed[index].displayCustomFields.length; i++) {
      this.channelsViewed[index].displayCustomFields[i].show = false;
    }
  }

  @Mutation
  SET_ALL_CUSTOM_SHOW_ON (index: number) {
    for (let i = 0; i < this.channelsViewed[index].displayCustomFields.length; i++) {
      this.channelsViewed[index].displayCustomFields[i].show = true;
    }
  }

  @Mutation
  SET_ALL_CUSTOM_FIELDS (input: { index: number, fields: CustomFieldDisplay[] }) {
    this.channelsViewed[input.index].displayCustomFields = input.fields;
  }

  @Mutation
  SET_ONE_CUSTOM_FIELD (input: { index: number, field: CustomFieldDisplay }) {
    const fieldIndex = this.channelsViewed[input.index].displayCustomFields.findIndex(f => f._id === input.field._id);
    this.channelsViewed[input.index].displayCustomFields.splice(fieldIndex, 1, input.field);
  }

  @Mutation
  ADD_NEW_CHANNEL (channel: ChannelViewData) {
    this.channelsViewed.push(channel);
  }

  @Mutation
  REMOVE_ONE_CHANNEL (index: number) {
    this.channelsViewed.splice(index, 1);
  }
}
