
import { Component, Prop, Vue } from 'vue-property-decorator';
import MChannelInformation from '@/components/molecules/MChannelInformation.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import { ChannelsStore, ChannelStore, ItemEntryStore } from '@/store';
import { ChannelWithRelationalMetaVue } from '@/store/modules/interfaces/ChannelModule';
import { RouteNames } from '@/router/RouteNames';
import ItemsService from '@/api/ms-item/services/ItemsService';

@Component({
  components: { MChannelInformation }
})
export default class OArchiveChannel extends Vue {
  @Prop()
  channel!: ChannelWithRelationalMetaVue;

  @Prop()
  toArchive!: boolean;

  size = Size;

  isLoading: boolean = false;
  willDelete: boolean = false;
  confirmed: boolean = false;
  toastMsg: string = '';
  wasDeleted: boolean = false;

  async created () {
    if (this.toArchive) {
      await this.toDeleteOrArchive();
    }
  }

  async toDeleteOrArchive () {
    this.isLoading = true;
    const { count } = await ItemsService.itemsCountByChannelSlugGet({
      slug: this.channel.slug
    });
    this.isLoading = false;
    if (count === 0) {
      this.willDelete = true;
      this.$buefy.dialog.alert({
        title: this.$t('page.channel.archived.alertDeletingTitle') as string,
        message: this.$t('page.channel.archived.alertDeletingMsg') as string
      });
    }
  }

  async toggleArchive () {
    if (typeof this.channel.slug === 'undefined' || this.channel.slug === '') {
      this.emit();
      return;
    }
    const archive = await ChannelService.channelSlugSlugArchivePatch({
      archive: this.toArchive
    }, {
      slug: this.channel.slug
    });

    this.wasDeleted = archive.deleted;

    // remove the channel from the store or archived store to update the view of the user
    // then clear the opposite, the store or the archived store, to force a refresh next time it loads
    if (this.toArchive) {
      ChannelsStore.removeChannelMemberOfBySlug(this.channel.slug);
      // no need to clear the store if the channel was deleted
      if (!archive.deleted) {
        ChannelsStore.CLEAR_CHANNELS_ARCHIVED();
      }
      // If sending to archive, check to see if this channel was last added to. If was, clear the ItemEntry store
      if( ItemEntryStore.getItemEntry.editable.channel?.slug === this.channel.slug ){
        ItemEntryStore.RESET();
      }
    } else {
      ChannelsStore.removeChannelArchivedBySlug(this.channel.slug);
      ChannelsStore.CLEAR_CHANNELS_MEMBER_OF();
    }
    // update the channel we are looking at
    ChannelStore.updateArchivedAt({ toArchive: this.toArchive });

    this.toastMsg = archive.deleted ?
      this.$t('dict.channelDeleted') as string :
      this.toArchive ?
        this.$t('dict.channelArchived') as string :
        this.$t('dict.channelUnArchived') as string;

    this.confirmed = true;
  }

  emit () {
    //if the channel was deleted and we are on channel view (i.e. looking at that channel), redirect to profile view with channels tab selected
    if (this.wasDeleted && this.$route.name === RouteNames.ROUTE_CHANNEL_VIEW) {
      this.$router.push({
        name: RouteNames.ROUTE_YOUR_PROFILE,
        query: {
          tab: 'channels'
        }
      });
    }
    this.$emit('child-output', {});
  }
}
