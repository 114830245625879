var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"OPinnedItemsContainer"},[_vm._t("default"),(!_vm.items.busy && _vm.noItems)?_c('div',{staticClass:"not-found-wrapper"},[_c('div',[[_c('p',{staticClass:"title-7 not-found-message"},[(!_vm.hasSearchParams.content)?[(_vm.searchType === 'dashboard')?[_c('m-no-items-empty-search')]:(_vm.searchType === 'channel')?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.channelPins'))}})]:[(_vm.isOwnProfile())?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.profilePins'))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.otherPersonsProfilePins', {firstName: _vm.userOtherMeta.firstName}))}})]]:(_vm.hasSearchParams.qs !== '' && _vm.hasSearchParams.filtersCount === 0)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.fromSearch', {
              qs: _vm.hasSearchParams.qs,
              sortCount: _vm.hasSearchParams.filtersCount
            }))}})]:(_vm.hasSearchParams.qs !== '' && _vm.hasSearchParams.filtersCount !== 0)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.fromSearchAndSort', {
              qs: _vm.hasSearchParams.qs,
              sortCount: _vm.hasSearchParams.filtersCount
            }))}})]:(_vm.hasSearchParams.qs === '' && _vm.hasSearchParams.filtersCount !== 0)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.fromSort', {
            sortCount: _vm.hasSearchParams.filtersCount
          }))}})]:_vm._e()],2)]],2)]):_c('div',{staticClass:"OPinnedItemsGrid"},[_vm._l((_vm.items.items),function(item,index){return _c('div',{key:index,staticClass:"item-card",style:({ minHeight: _vm.calculateItemCardHeight(item.data.item)}),attrs:{"data-card-height":_vm.calculateItemCardHeight(item.data.item)}},[(item.data.skeleton)?[_c('m-item-card-skeleton')]:(item.data.phantom)?[_c('div',{staticClass:"phantom-card"})]:[_c('o-item-card',{key:item.data.item.uniqueItemName,attrs:{"currentUser":_vm.currentUser,"item":item.data.item,"search-type":_vm.searchType,"show-get-got-control":_vm.cardControls.showGetGotControl},on:{"item-request-update":(item) => _vm.$emit('item-request-update', item)}})]],2)}),(!_vm.items.busy && _vm.firstResizeComplete && !_vm.noMoreResults)?_c('infinite-loading',{on:{"infinite":_vm.scrollBottom}}):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }