
import { Component, Vue } from 'vue-property-decorator';
import MItemEntryTrigger from '@/components/molecules/itemEntry/MItemEntryTrigger.vue';
import MShoppingListHeader from '@/components/molecules/shoppingList/MShoppingListHeader.vue';
import MNotificationHeader from '@/components/molecules/notification/MNotificationHeader.vue';
import MUserProfileHeader from '@/components/molecules/MUserProfileHeader.vue';
import MHeaderLoginTrigger from '@/components/molecules/MHeaderLoginTrigger.vue';

import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';
import { RouteNames } from '@/router/RouteNames';
import MHeader from '@/storybook-components/src/stories/molecules/MHeader.vue';
import MHeaderItemRight from '@/storybook-components/src/stories/molecules/MHeaderItemRight.vue';
import fontPercentageToScale from '@/utils/fontPercentageToScale';
import MBookDemo from '@/components/molecules/for-business/MBookDemo.vue';
import config from '@/config';

@Component({
  components: {
    MBookDemo,
    MHeaderItemRight,
    MHeader,
    MNotificationHeader,
    MShoppingListHeader,
    MHeaderLoginTrigger,
    MItemEntryTrigger,
    MUserProfileHeader
  },
})
export default class MAppHeader extends Vue {
  scaledStyle: Partial<CSSStyleDeclaration> = {};
  font = false;
  accountHref = config.accountsHref;

  hideBanner = false;
  baseUrl = config.api.baseUrl;
  mode = config.mode;

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get headerLink () {
    return {
      name: this.authenticated ? RouteNames.ROUTE_DASHBOARD : RouteNames.ROUTE_INFO_WHAT_IS
    };
  }

  get isNotBusinessLandingPage () {
    return this.$route.name !== RouteNames.ROUTE_INFO_BUSINESS;
  }

  created () {
    this.scaledStyle = fontPercentageToScale();
  }

  logoClick () {
    EventBus.$emit(EventBusEvents.LOGO_CLICK, {
      from: this.$route.name
    });
  }

  accountsClick () {
    window.location.href = this.accountHref;
  }
}
