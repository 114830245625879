import HttpService from '@/services/HttpService';

import { ChannelMember } from './interfaces/ChannelMember';
import { ChannelMemberLastUnreadChat } from './interfaces/ChannelMemberLastUnreadChat';
import { ChannelMemberSlugChatReadPatchPath } from './interfaces/ChannelMemberSlugChatReadPatchPath';
import { ChannelMemberSlugChatUnreadGetPath } from './interfaces/ChannelMemberSlugChatUnreadGetPath';
import { ChannelMemberSlugEventNotificationsGetPath } from './interfaces/ChannelMemberSlugEventNotificationsGetPath';
import { ChannelMemberSlugEventNotificationsPutPath } from './interfaces/ChannelMemberSlugEventNotificationsPutPath';
import { ChannelMemberSlugGetPath } from './interfaces/ChannelMemberSlugGetPath';
import { ChannelMemberSlugGetQuery } from './interfaces/ChannelMemberSlugGetQuery';
import { ChannelMemberSlugInviteAcceptPatchPath } from './interfaces/ChannelMemberSlugInviteAcceptPatchPath';
import { ChannelMemberSlugInvitePost } from './interfaces/ChannelMemberSlugInvitePost';
import { ChannelMemberSlugInvitePostPath } from './interfaces/ChannelMemberSlugInvitePostPath';
import { ChannelMemberSlugInviteRejectPatchPath } from './interfaces/ChannelMemberSlugInviteRejectPatchPath';
import { ChannelMemberSlugInviteRemoveUsernameDeletePath } from './interfaces/ChannelMemberSlugInviteRemoveUsernameDeletePath';
import { ChannelMemberSlugInviteUpdatePatch } from './interfaces/ChannelMemberSlugInviteUpdatePatch';
import { ChannelMemberSlugInviteUpdatePatchPath } from './interfaces/ChannelMemberSlugInviteUpdatePatchPath';
import { ChannelMemberSlugIsMemberGetPath } from './interfaces/ChannelMemberSlugIsMemberGetPath';
import { ChannelMemberSlugJoinRequestAcceptPatch } from './interfaces/ChannelMemberSlugJoinRequestAcceptPatch';
import { ChannelMemberSlugJoinRequestAcceptPatchPath } from './interfaces/ChannelMemberSlugJoinRequestAcceptPatchPath';
import { ChannelMemberSlugJoinRequestCancelPatchPath } from './interfaces/ChannelMemberSlugJoinRequestCancelPatchPath';
import { ChannelMemberSlugJoinRequestPostPath } from './interfaces/ChannelMemberSlugJoinRequestPostPath';
import { ChannelMemberSlugJoinRequestRejectPatch } from './interfaces/ChannelMemberSlugJoinRequestRejectPatch';
import { ChannelMemberSlugJoinRequestRejectPatchPath } from './interfaces/ChannelMemberSlugJoinRequestRejectPatchPath';
import { ChannelMemberSlugLeavePatchPath } from './interfaces/ChannelMemberSlugLeavePatchPath';
import { ChannelMembers } from './interfaces/ChannelMembers';
import { EventNotificationsPut } from './interfaces/EventNotificationsPut';
import { UserSubscriptionsNotificationSettings } from './interfaces/UserSubscriptionsNotificationSettings';

export default class ChannelMemberService {
  public static basePath = '/__/ms-channel/';

  /**
   *  Operation ID: channelMemberSlugChatReadPatch
   *  Summary: Marks all this channels chat for this user as read
   *  Description:
   */
  public static channelMemberSlugChatReadPatch(
    pathParams: ChannelMemberSlugChatReadPatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: ChannelMemberService.basePath + 'channel-member/:slug/chat/read',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugChatUnreadGet
   *  Summary: Gets the last unread object for the given channel slug relative to the current user
   *  Description:
   */
  public static channelMemberSlugChatUnreadGet(
    pathParams: ChannelMemberSlugChatUnreadGetPath
  ): Promise<ChannelMemberLastUnreadChat> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelMemberService.basePath + 'channel-member/:slug/chat/unread',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugEventNotificationsGet
   *  Summary: Fetch the user's event notifications for a channel
   *  Description: Fetch the user's event notifications for a channel
   */
  public static channelMemberSlugEventNotificationsGet(
    pathParams: ChannelMemberSlugEventNotificationsGetPath
  ): Promise<UserSubscriptionsNotificationSettings> {
    return HttpService.sendRequest({
      method: 'GET',
      path:
        ChannelMemberService.basePath +
        'channel-member/:slug/event-notifications',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugEventNotificationsPut
   *  Summary: Update notification settings
   *  Description: Update a user's notification settings for a specific channel
   */
  public static channelMemberSlugEventNotificationsPut(
    body: EventNotificationsPut,
    pathParams: ChannelMemberSlugEventNotificationsPutPath
  ): Promise<UserSubscriptionsNotificationSettings> {
    return HttpService.sendRequest({
      method: 'PUT',
      path:
        ChannelMemberService.basePath +
        'channel-member/:slug/event-notifications',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugGet
   *  Summary: Fetch a channels members
   *  Description: The queryText is a name search Only accepted states returned to non-channel admins (ie the owner currently - Jul 2020). All members and states returned to channel owners

   */
  public static channelMemberSlugGet(
    pathParams: ChannelMemberSlugGetPath,
    query: ChannelMemberSlugGetQuery
  ): Promise<ChannelMembers> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelMemberService.basePath + 'channel-member/:slug',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelMemberSlugInviteAcceptPatch
   *  Summary: A user accepts an invitation
   *  Description: Will update the said users inviation status to invite accepted for the given channel slug
   */
  public static channelMemberSlugInviteAcceptPatch(
    pathParams: ChannelMemberSlugInviteAcceptPatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ChannelMemberService.basePath + 'channel-member/:slug/invite/accept',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugInvitePost
   *  Summary: Invite user
   *  Description: Invite a user to a channel
   */
  public static channelMemberSlugInvitePost(
    body: ChannelMemberSlugInvitePost,
    pathParams: ChannelMemberSlugInvitePostPath
  ): Promise<ChannelMember> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ChannelMemberService.basePath + 'channel-member/:slug/invite',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugInviteRejectPatch
   *  Summary: A user reject an invitation
   *  Description: Will update the said users invitation status to invite rejected for the given channel slug
   */
  public static channelMemberSlugInviteRejectPatch(
    pathParams: ChannelMemberSlugInviteRejectPatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ChannelMemberService.basePath + 'channel-member/:slug/invite/reject',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugInviteRemoveUsernameDelete
   *  Summary: Delete a channel member
   *  Description: Sets the invitation to removed
   */
  public static channelMemberSlugInviteRemoveUsernameDelete(
    pathParams: ChannelMemberSlugInviteRemoveUsernameDeletePath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path:
        ChannelMemberService.basePath +
        'channel-member/:slug/invite/remove/:username',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugInviteUpdatePatch
   *  Summary: Invite update
   *  Description: Typical for the owner updating the invite to allow or disallow invites to add items to a channel
   */
  public static channelMemberSlugInviteUpdatePatch(
    body: ChannelMemberSlugInviteUpdatePatch,
    pathParams: ChannelMemberSlugInviteUpdatePatchPath
  ): Promise<ChannelMember> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ChannelMemberService.basePath + 'channel-member/:slug/invite/update',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugIsMemberGet
   *  Summary: Is the user of the current JwtData a member of the given channel
   *  Description:
   */
  public static channelMemberSlugIsMemberGet(
    pathParams: ChannelMemberSlugIsMemberGetPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelMemberService.basePath + 'channel-member/:slug/is-member',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugJoinRequestAcceptPatch
   *  Summary: The channel owner accepts the request to join the channel
   *  Description: only the channel owner may accept the join request
   */
  public static channelMemberSlugJoinRequestAcceptPatch(
    body: ChannelMemberSlugJoinRequestAcceptPatch,
    pathParams: ChannelMemberSlugJoinRequestAcceptPatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ChannelMemberService.basePath +
        'channel-member/:slug/join-request/accept',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugJoinRequestCancelPatch
   *  Summary: The requester cancels the join request
   *  Description: Only the requester may cancel the request
   */
  public static channelMemberSlugJoinRequestCancelPatch(
    pathParams: ChannelMemberSlugJoinRequestCancelPatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ChannelMemberService.basePath +
        'channel-member/:slug/join-request/cancel',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugJoinRequestPost
   *  Summary: User request to join channel
   *  Description: If the channel is public the request goes straight through and the user will join immediately. If the channel is not public, then a request is sent to join the channel and the user must wait for the approval to be accepted.

   */
  public static channelMemberSlugJoinRequestPost(
    pathParams: ChannelMemberSlugJoinRequestPostPath
  ): Promise<ChannelMember> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ChannelMemberService.basePath + 'channel-member/:slug/join-request',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugJoinRequestRejectPatch
   *  Summary: A user reject an invitation
   *  Description: Will update the said users inviation status to invite rejected for the given channel slug
   */
  public static channelMemberSlugJoinRequestRejectPatch(
    body: ChannelMemberSlugJoinRequestRejectPatch,
    pathParams: ChannelMemberSlugJoinRequestRejectPatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path:
        ChannelMemberService.basePath +
        'channel-member/:slug/join-request/reject',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelMemberSlugLeavePatch
   *  Summary: When a user leaves a channel they are a member of
   *  Description:
   */
  public static channelMemberSlugLeavePatch(
    pathParams: ChannelMemberSlugLeavePatchPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: ChannelMemberService.basePath + 'channel-member/:slug/leave',
      params: pathParams,
    });
  }
}
