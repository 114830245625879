
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';

  @Component({
    components: {
      AButtonSubmit
    }
  })
  export default class ASaveLocationButton extends Vue {
    @Prop({ default: true })
    display!: boolean;

    @Prop({ default: false })
    loading!: boolean;

    @Prop({ default: 'Reset Map' })
    buttonText!: string;

    @Prop()
    buttonClasses!: string;
  }
