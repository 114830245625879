export interface SwipeScores {
  _id: string;
  action: Action;
  channelSlug: string;
  createdAt?: Date;
  /**
   * All will be '1' to start with, future may include multiple decision makers  per channel
   * which can utilise this number to differentiate between them.
   */
  reportIteration: number;
  /**
   * Number is -1, 0, 1, or 2
   */
  scoreApplied: number;
  timeLock?: Date;
  uniqueItemName: string;
  updatedAt?: Date;
  username: string;
}

export enum Action {
  SwipeDown = 'SWIPE_DOWN',
  SwipeLeft = 'SWIPE_LEFT',
  SwipeRight = 'SWIPE_RIGHT',
  SwipeUp = 'SWIPE_UP',
}
