export interface Connections {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  firstName: string;
  lastName: string;
  state: State;
  updated: Date;
  username: string;
}

export enum State {
  RequestReceived = 'requestReceived',
  RequestReceivedAccepted = 'requestReceivedAccepted',
  RequestReceivedAcceptedDisconnectedByReceiver = 'requestReceivedAcceptedDisconnectedByReceiver',
  RequestReceivedAcceptedDisconnectedByRequester = 'requestReceivedAcceptedDisconnectedByRequester',
  RequestReceivedCancelled = 'requestReceivedCancelled',
  RequestReceivedRejected = 'requestReceivedRejected',
  RequestSent = 'requestSent',
  RequestSentAccepted = 'requestSentAccepted',
  RequestSentAcceptedDisconnectByRequester = 'requestSentAcceptedDisconnectByRequester',
  RequestSentAcceptedDisconnectedByReceiver = 'requestSentAcceptedDisconnectedByReceiver',
  RequestSentCancelled = 'requestSentCancelled',
  RequestSentRejected = 'requestSentRejected',
}

export interface Meta {
  limit: number;
  offset: number;
  totalCount?: number;
}
