export interface ChannelMembers {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  canAdd: boolean;
  canManage: boolean;
  createdAt: Date;
  firstName: string;
  lastName: string;
  lastUnreadChat?: LastUnreadChat;
  notificationSettings?: NotificationSetting[];
  /**
   * The related channel slug
   */
  slug: string;
  subscriptionStatus: SubscriptionStatus;
  updatedAt: Date;
  username: string;
}

export interface LastUnreadChat {
  chatId: string;
  createdAt: Date;
  notifiedCount: number;
  updatedAt: Date;
}

export enum NotificationSetting {
  OneDayBefore = 'oneDayBefore',
  OneWeekBefore = 'oneWeekBefore',
  ThreeHoursBefore = 'threeHoursBefore',
  TwoWeeksBefore = 'twoWeeksBefore',
}

export enum SubscriptionStatus {
  ConciergeJobAccepted = 'ConciergeJobAccepted',
  ConciergeJobCancelled = 'ConciergeJobCancelled',
  ConciergeJobCompleted = 'ConciergeJobCompleted',
  ConciergeJobRemoved = 'ConciergeJobRemoved',
  InviteAccepted = 'InviteAccepted',
  InviteCancelled = 'InviteCancelled',
  InviteRejected = 'InviteRejected',
  Invited = 'Invited',
  JoinRequestAccepted = 'JoinRequestAccepted',
  JoinRequestCancelled = 'JoinRequestCancelled',
  JoinRequestRejected = 'JoinRequestRejected',
  JoinRequestSent = 'JoinRequestSent',
  RemovedByChannel = 'RemovedByChannel',
  SelfRemoval = 'SelfRemoval',
}

export interface Meta {
  limit: number;
  offset: number;
  total?: number;
}
