
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { AuthenticationStore } from '@/store';
import { EditIcon, PlusIcon } from 'vue-feather-icons';

@Component({
  components: {
    EditIcon,
    PlusIcon
  }
})
export default class ARouterLinkChannelUpsert extends Vue {
  @Prop(String)
  channelSlug!: string;

  @Prop(String)
  channelOwner!: string;

  @Prop(Boolean)
  hasIcon!: boolean;

  @Prop(String)
  customClasses!: string;

  @Prop(String)
  customText!: string;

  @Prop({required: false, default: '1.4x'})
  editIconSize!: string;

  @Prop({required: false, default: '1.5x'})
  plusIconSize!: string;

  params = this.channelSlug ? { channelSlug: this.channelSlug } : undefined;
  name = !this.channelSlug ? RouteNames.ROUTE_CHANNEL_CREATE : RouteNames.ROUTE_CHANNEL_EDIT;
  username?: string;

  created () {
    this.username = AuthenticationStore.authenticated ? AuthenticationStore.user.username : undefined;
  }
}
