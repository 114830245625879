
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { GeolocationData } from '@/api/ms-channel/services/interfaces/ChannelPost';
import injectGoogleMapScript, { defaultMapPosition } from '@/utils/injectGoogleMapScript';
import { XIcon } from 'vue-feather-icons';
import googleMapsStoreKeywordsAddress from '@/utils/googleMapsStoreKeywordsAddress';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: {
    ATooltip,
    XIcon
  }
})
export default class MGeolocationAutocomplete extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: GeolocationData;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({required: false, default: false})
  isResearchConcierge!: boolean;

  value: GeolocationData = defaultMapPosition;
  formattedAddress: string = '';

  autocomplete!: any;
  $refs!: {
    autocomplete: any
  };

  created () {
    if( this.activeValue && this.activeValue.formattedAddress ){
      this.value = this.activeValue;
      this.formattedAddress = this.activeValue.formattedAddress;
    }
  }

  async mounted () {
    try {
      await this.setupGoogle();
    } catch (e) {
      console.error(e);
    }
  }

  async setupGoogle () {
    await injectGoogleMapScript();
    this.loadAutocomplete();
  }

  loadAutocomplete () {
    //if in dev, remove 'fields' key to return every field from google - certain field are counted as extra cost, see conf.
    this.autocomplete = new window.google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        { fields: ['address_components', 'business_status', 'formatted_address', 'geometry', 'name'] }
    );
    this.autocomplete.addListener('place_changed', this.handleAutocompleteChange);
  }

  handleAutocompleteChange () {
    const place = this.autocomplete.getPlace();
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const businessDetails = place.business_status ? { businessStatus: place.business_status, name: place.name } : {};

    this.value.position.coordinates[0] = lng;
    this.value.position.coordinates[1] = lat;
    this.value.formattedAddress = place.formatted_address;
    this.value.keywordsAddress = googleMapsStoreKeywordsAddress(place.address_components, businessDetails);
    this.formattedAddress = place.formatted_address;

    this.updateModel(this.value);
  }

  clearAddressMarker () {
    this.formattedAddress = '';
    this.updateModel(undefined);
  }

  updateModel (val: GeolocationData|undefined) {
    this.$emit('updateModelValue', val);
  }

  // Watch formatted address, if user backspaces over entire address, run the clear
  @Watch('formattedAddress')
  updateValue () {
    if( this.formattedAddress === '' ){
      this.clearAddressMarker();
    }
  }
}
