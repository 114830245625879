import { CustomField as ChannelCustomField, FieldType } from '@/api/ms-channel/services/interfaces/Channel';

class CustomFields {
  channelUnitDisplay (channelCustomFieldData: ChannelCustomField): string | undefined {
    if ([FieldType.Compass, FieldType.Rating, FieldType.Price].includes(channelCustomFieldData.fieldType)) {
      return undefined;
    } else {
      return channelCustomFieldData.unit;
    }
  }
}

export default new CustomFields();
