
import { Component, Vue } from 'vue-property-decorator';
import { Edit2Icon } from 'vue-feather-icons';

@Component({
  components: {
    Edit2Icon
  }
})
export default class AEditIcon extends Vue {
}
