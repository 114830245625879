
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FilterIcon, SearchIcon, SettingsIcon, XIcon } from 'vue-feather-icons';
import { SearchSettingsStore } from '@/store';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';
import EventBus, { EventBusEvents } from '@/EventBus';
import ABadge from '@/components/atoms/ABadge.vue';
import AContentCategoryDropdown from '@/components/atoms/inputs/AContentCategoryDropdown.vue';
import { SortBy, SortDir } from '@/api/ms-channel/services/interfaces/ChannelsGetQuery';
import { channelsFilterSortDefaultsHashMap } from '../organisms/forms/OChannelsFilterForm.vue';
import { itemsFilterSortDefaultsHashMap } from '../organisms/forms/OItemsFilterForm.vue';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';

export interface MSearchInputOutput {
  search: string;
  contentCategory?: string;
}

@Component({
  components: {
    AContentCategoryDropdown,
    ABadge,
    FilterIcon,
    SearchIcon,
    SettingsIcon,
    XIcon
  }
})
export default class MSearchInput extends Vue {
  @Prop({ default: 'Search...' })
  placeholder!: string;

  @Prop({ default: ASearchBarTabType.items })
  selectedTab!: ASearchBarTabType;

  sortBy = SortBy;
  searchBarTabs = ASearchBarTabType;
  showSearchIcon = true;
  search: string = '';
  timeoutDebounce: any;
  timeoutDebounce2: any;

  eventBusId = 'MSearchInput';

  contentCategory = 'all';

  $refs!: {
    search: HTMLElement
  };

  get getItemFilterSortCount () {
    return countFilterSortsUsedInQuery(this.$route, itemsFilterSortDefaultsHashMap);
  }

  get getChannelFilterSortCount () {
    let num = countFilterSortsUsedInQuery(this.$route, channelsFilterSortDefaultsHashMap);
    // if sort by item last added, it will also be descending, remove 1 from the count as for the user they only added 1
    if(this.$route.query && this.$route.query.sortBy && this.$route.query.sortBy === SortBy.ItemLastAdded && this.$route.query.sortDir && this.$route.query.sortDir === SortDir.Desc){
      --num;
    }
    return num;
  }

  get getFilterSortCount () {
    return this.selectedTab === ASearchBarTabType.channels ? this.getChannelFilterSortCount : this.getItemFilterSortCount;
  }

  get getChannelsSearchSortBy () {
    return SearchSettingsStore.getChannelsSearchSettingsSortOnly.sortBy;
  }

  get getRecentItemsSearch () {
    return SearchSettingsStore.getItemsSearchRecents;
  }

  created () {
    this.setup(this.$route.query?.text as string);
    this.eventsBind();
    if (this.search.length > 0) {
      this.emitEvent('USER_ACTION');
    }
    if (this.$route.query.category && typeof this.$route.query.category === 'string') {
      this.contentCategory = this.$route.query.category;
    }
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.HASH_TAG_CLICK, this.eventBusId, (payload: { tag: string }) => {
      this.setup(payload.tag);
      this.emitEvent('HASH_TAG_CLICK');
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.HASH_TAG_CLICK, this.eventBusId);
  }

  setup (searchText: string = '') {
    this.search = searchText;
    this.toggleIcon();
  }

  handleSearch () {
    this.search = this.search.trim();
    if (this.timeoutDebounce) {
      clearTimeout(this.timeoutDebounce);
    }
    this.timeoutDebounce = setTimeout(
        () => this.emitEvent('USER_ACTION'),
        300
    );

    if (this.timeoutDebounce2) {
      clearTimeout(this.timeoutDebounce2);
    }
    this.timeoutDebounce2 = setTimeout(
        () => SearchSettingsStore.ITEMS_SEARCH_RECENT_ADD(this.search),
        3000
    );
  }

  headerSearchCLicked () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: this.$t('searchRecent.clearConfirm') as string,
      onConfirm: () => {
        SearchSettingsStore.ITEMS_SEARCH_RECENT_CLEAR_ALL();
      }
    });
  }

  clearThenHandleSearch () {
    this.search = '';
    this.contentCategory = 'all';
    this.handleSearch();
  }

  emitEvent (eventName: 'USER_ACTION' | 'HASH_TAG_CLICK') {
    this.storeText(this.search);
    this.toggleIcon();
    const output: MSearchInputOutput = { search: this.search };
    if (this.selectedTab === ASearchBarTabType.explore) {
      output.contentCategory = this.contentCategory;
    }
    this.$emit(eventName, output);
  }



  storeText (text: string) {
    // SearchSettingsStore.CROSS_TAB_TEXT(text);
  }

  toggleIcon () {
    this.showSearchIcon = this.search.length === 0;
  }

  openFilters () {
    EventBus.$emit(
        this.selectedTab === ASearchBarTabType.channels ?
            EventBusEvents.CHANNEL_FILTERS_OPEN :
            EventBusEvents.ITEM_FILTERS_OPEN
    );
  }

  @Watch('contentCategory')
  searchByCategory () {
    this.emitEvent('USER_ACTION');
  }
}
