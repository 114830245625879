
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ADefaultSvgFromMaterialDesign extends Vue {
  @Prop()
  path!: string;
  @Prop()
  size!: number;
  @Prop({ default: 24 })
  viewWidth!:number;
  @Prop({ default: 24 })
  viewHeight!:number;
  @Prop({default: 'currentColor'})
  fillColour!: string;
  // at a certain point the minYK stuff just goes wrong (e.g. for light bulb icon), I don't know why, pass true to force Y to stay at 0
  @Prop({default: false})
  keepYZero!: boolean;

  width:string = '0';

  height:string = '0';
  viewBox = '';
  viewMinX = 0;
  viewMinY = 0;

  /**
   * note - this was made before understanding really what the view-box and size meant. Look at AChannelSwipeItemUp
   * to see how style should be used to dictate size instead and update this component accordingly, then update all
   * the places which reference an icon through this component.
   */
  created () {
    this.width = this.size + 'em';
    this.height = this.size + 'em';
    const minYK = Math.ceil((1 - this.size) * 10);
    if (this.size > 1) {
      this.viewMinY -= minYK;
    } else if (this.size < 1) {
      this.viewMinY += minYK;
    }
    this.viewBox = `${this.viewMinX} ${this.keepYZero ? 0 : this.viewMinY} ${this.viewWidth} ${this.viewHeight}`;
  }
}
