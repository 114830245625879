
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';

@Component
export default class AAddItemIcon extends Vue {
  @Prop({required: false, default: 1})
  size!: number|string;
  @Prop({required: false, default: 'rem'})
  sizeUnits!: string;

  get style () {
    const size = typeof this.size === 'number' ? this.size : parseFloat(this.size);
    return `width: ${size}${this.sizeUnits}; height: ${size}${this.sizeUnits};`;
  }

  click () {
    this.$emit(CHILD_EMIT);
  }
}
