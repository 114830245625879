import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { SearchType } from '@/store/modules/enums/SearchType';
import ConnectionsService from '@/api/ms-authentication/services/ConnectionsService';
import { Connection, Connections, ConnectionsGetQuery, UserSearchs } from '@/api/ms-authentication/services/interfaces';
import UserService from '@/api/ms-authentication/services/UserService';
import { AuthenticationStore, ItemsStore, SearchSettingsStore } from '@/store';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  initialNoMoreResults,
  initialPeopleState,
  INoMoreResults,
  IPeopleModule,
  People
} from '@/store/modules/interfaces/PeopleModule';
import { State } from '@/api/ms-authentication/services/interfaces/ConnectionsGetQuery';
import { Datum as ConnectionsDatum } from '@/api/ms-authentication/services/interfaces/Connections';
import { PeopleFetchEnum as peopleTypes } from '@/store/modules/enums/PeopleFetchTypes';
import {
  SortBy as PeopleSortBy,
  SortDir
} from '@/api/ms-authentication/services/interfaces/ConnectionsUsernameMatchingGetQuery';
import EventBus, { EventBusEvents } from '@/EventBus';

@Module({
  name: StoreNames.PEOPLE_STORE,
  namespaced: true,
})
export default class PeopleModule extends VuexModule implements IPeopleModule {
  people: People = initialPeopleState;
  connectionRequests: ConnectionsDatum[] = [];
  connectedPeople: ConnectionsDatum[] = [];
  noMoreResults: INoMoreResults = initialNoMoreResults;
  blankSearch: UserSearchs = {
    data: [],
    meta: {
      limit: 20,
      offset: 0
    }
  };

  get getPeople (): People {
    return this.people;
  }

  get getConnectionRequests (): ConnectionsDatum[] {
    return this.connectionRequests;
  }

  get getConnectedPeople (): ConnectionsDatum[] {
    return this.connectedPeople;
  }

  get getNoMoreResults () {
    return this.noMoreResults;
  }

  @Mutation
  RESET (): void {
    this.people = initialPeopleState;
    this.connectionRequests = [];
    this.connectedPeople = [];
    this.noMoreResults = initialNoMoreResults;
  }

  @Action({ rawError: true })
  async peopleSearch (input: { search: string, searchType: SearchType, searchTypeId?: string }) {
    const { search, searchType } = input;
    const searchTypeId = input.searchTypeId || '';
    if (!AuthenticationStore.authenticated) {
      throw new Error('Non-auth`d user cannot search users');
    }
    let connections;
    let nonConnections;
    let keyConnected: string = peopleTypes.connected;
    let keyNotConnected: string = peopleTypes.notConnected;

    // merge in the search filter settings into the query object
    const query = Object.assign(
      {
        offset: 0,
        qs: search
      },
      {
        sortBy: PeopleSortBy.FirstName,
        sortDir: SortDir.Asc,
      }
    );
    // we only run people search with an authenticated user and search string length of 2+
    if (searchType === SearchType.profile && AuthenticationStore.user.username !== searchTypeId) {
      // not on own profile page
      connections = await ConnectionsService.connectionsUsernameMatchingGet({ username: searchTypeId }, query);
      nonConnections = await ConnectionsService.connectionsUsernameNotMatchingGet({ username: searchTypeId }, query);
      keyConnected = peopleTypes.matching;
      keyNotConnected = peopleTypes.notMatching;
    } else {
      connections = await ConnectionsService.connectionsGet(query);
      nonConnections = search.length > 1 ? await UserService.userSearchGet(query) : this.blankSearch;
    }
    this.context.commit('CONNECTIONS_FETCHED_NEW', { people: connections, search, searchType });
    this.context.commit('PEOPLE_SEARCHED_NEW', { people: nonConnections, search, searchType });

    this.SET_NO_MORE_RESULTS({ key: keyConnected, value: (connections.data.length < 20) });
    this.SET_NO_MORE_RESULTS({ key: keyNotConnected, value: (nonConnections.data.length < 20) });
  }

  @Action
  async fetchConnectedPeople () {
    const connected = await ConnectionsService.connectionsGet({
      state: [State.RequestSentAccepted, State.RequestReceivedAccepted]
    });
    this.SET_CONNECTED_PEOPLE({ connections: connected.data });
    this.SET_NO_MORE_RESULTS({ key: 'myConnections', value: (connected.data.length < 20) });
    return connected.data;
  }

  @Action
  async fetchNotConnectedPeople () {
    const notConnected = await ConnectionsService.connectionsGet({
      state: [State.RequestSent, State.RequestReceived]
    });
    this.SET_CONNECTION_REQUESTS({
      connections: notConnected.data
    });
    return notConnected.data;
  }

  @Action
  async fetchLatestPeople (input: { type: string, search: string, searchType: SearchType, username?: string }) {
    const { search, searchType, type } = input;
    const username = input.username || '';
    const objName = type === peopleTypes.connected ? type : 'searchResults';
    let connections;

    const query = Object.assign({
        offset: this.people[input.searchType][objName].data.length,
        qs: search
      },
      SearchSettingsStore.getPeopleSearchSettings
    );

    if (type === peopleTypes.connected) {
      connections = await ConnectionsService.connectionsGet(query);
      this.APPEND_CONNECTIONS_FETCHED({ connections: connections.data, searchType });
    } else if (type === peopleTypes.matching) {
      connections = await ConnectionsService.connectionsUsernameMatchingGet({ username }, query);
      this.APPEND_CONNECTIONS_FETCHED({ connections: connections.data, searchType });
    } else if (type === peopleTypes.notConnected) {
      //only search on string length 2+
      connections = search.length > 1 ? await UserService.userSearchGet(query) : this.blankSearch;
      this.APPEND_PEOPLE_SEARCHED({ people: connections.data, searchType });
    } else if (type === peopleTypes.notMatching) {
      connections = await ConnectionsService.connectionsUsernameNotMatchingGet({ username }, query);
      this.APPEND_PEOPLE_SEARCHED({ people: connections.data, searchType });
    }

    this.SET_NO_MORE_RESULTS({ key: type, value: (connections.data.length < 20) });
  }

  @Action
  async fetchLatestConnectedPeople () {
    const connected = await ConnectionsService.connectionsGet({
      state: [State.RequestSentAccepted, State.RequestReceivedAccepted],
      offset: this.connectedPeople.length
    });
    this.APPEND_CONNECTED_PEOPLE({ connections: connected.data });
    this.SET_NO_MORE_RESULTS({ key: 'myConnections', value: (connected.data.length < 20) });
    return connected.data;
  }

  @Action
  async makeConnectionRequest (toUsername: string) {
    return await ConnectionsService.connectionsPost({
      username: toUsername
    });
  }

  @Action
  async connectionRequestUpdate (toUsername: string): Promise<Connection> {
    ItemsStore.CLEAR_ITEMS();
    return await ConnectionsService.connectionsUsernamePut({
      username: toUsername
    });
  }

  @Action
  async connectionRequestRemove (username: string): Promise<Connection> {
    ItemsStore.CLEAR_ITEMS();
    const newState = await ConnectionsService.connectionsUsernameDelete({ username });
    this.REMOVE_CONNECTION_REQUEST({ username });
    return newState;
  }

  @Mutation
  CONNECTIONS_FETCHED_NEW (input: { people: Connections, search: ConnectionsGetQuery, searchType: SearchType }): void {
    const { people, searchType } = input;
    this.people[searchType].connected = people;
  }

  @Mutation
  PEOPLE_SEARCHED_NEW (input: { people: UserSearchs, search: ConnectionsGetQuery, searchType: SearchType }): void {
    const { people, searchType } = input;
    this.people[searchType].searchResults = people;
  }

  @Mutation
  SET_CONNECTION_REQUESTS (input: { connections: ConnectionsDatum[] }) {
    this.connectionRequests = input.connections;
  }

  @Mutation
  REMOVE_CONNECTION_REQUEST (input: { username: string }) {
    this.connectionRequests.splice(
      this.connectionRequests.findIndex(connection => connection.username === input.username),
      1
    );
  }

  @Mutation
  SET_CONNECTED_PEOPLE (input: { connections: ConnectionsDatum[] }) {
    this.connectedPeople = input.connections;
  }

  @Mutation
  APPEND_CONNECTED_PEOPLE (input: { connections }) {
    this.connectedPeople = this.connectedPeople.concat(input.connections);
    EventBus.$emit(EventBusEvents.PEOPLE_CHANGE);
  }

  @Mutation
  APPEND_CONNECTIONS_FETCHED (input: { connections: ConnectionsDatum[], searchType }) {
    const { connections, searchType } = input;

    this.people[searchType].connected.data = this.people[searchType].connected.data.concat(connections);
  }

  @Mutation
  APPEND_PEOPLE_SEARCHED (input: { people: UserSearchs, searchType }) {
    const { people, searchType } = input;
    this.people[searchType].searchResults.data = this.people[searchType].searchResults.data.concat(people);
  }

  @Mutation
  SET_NO_MORE_RESULTS (input: { key: string, value: boolean }) {
    const { key, value } = input;
    this.noMoreResults[key] = value;
  }
}
