
import { Component, Prop, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AConnectionIcon from '@/components/atoms/buttons/AConnectionIcon.vue';
import { PeopleStore } from '@/store';
import { Datum, State, } from '@/api/ms-authentication/services/interfaces/Connections';
import { ArrowDownLeftIcon, ArrowUpRightIcon } from 'vue-feather-icons';
import ellipsisString from '@/utils/ellipsisString';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    AConnectionIcon,
    ArrowDownLeftIcon,
    ArrowUpRightIcon
  },
  filters: {
    ellipsisString
  }
})
export default class MPersonNotConnectedCard extends Vue {
  @Prop(Object)
  person!: Datum;

  @Prop({ default: false })
  showConnectIcon!: boolean;

  @Prop({ default: false })
  showDisconnectIcon!: boolean;
  showDisconnectIconInnerValue = false;
  requestLoading: boolean = false;
  connectionSent = false;
  connectTooltipText = '';
  disconnectTooltipText = '';
  state!: State;
  states = State;

  created () {
    this.showDisconnectIconInnerValue = this.showDisconnectIcon;
    this.state = this.person.state;
    this.setConnectToolTip();
    this.disconnectTooltipText = String(
      this.$t(
        this.state === State.RequestReceived ? 'dict.reject' : 'dict.cancel'
      )
    );
  }

  async sendConnectionRequest () {
    this.requestLoading = true;
    if (!this.state) {
      await PeopleStore.makeConnectionRequest(this.person.username);
    } else {
      try {
        await PeopleStore.connectionRequestUpdate(this.person.username);
        const newPerson = {
          ...this.person,
          state: 'requestReceivedAccepted'
        };
        PeopleStore.REMOVE_CONNECTION_REQUEST({ username: this.person.username });
        PeopleStore.APPEND_CONNECTED_PEOPLE({ connections: newPerson });
        this.showDisconnectIconInnerValue = false;
      } catch (e) {
        // TODO: handle error
        console.error(e);
      }
    }

    this.connectTooltipText = String(this.$t('dict.cancel'));
    this.connectionSent = true;
    this.requestLoading = false;
  }

  async cancelConnectionRequest () {
    this.requestLoading = true;
    await PeopleStore.connectionRequestRemove(this.person.username);
    this.connectTooltipText = String(this.$t('dict.connect'));
    this.connectionSent = false;
    this.requestLoading = false;
  }

  setConnectToolTip () {
    switch (this.checkConnectionType()) {
      case 'cancelConnectionRequest':
        this.connectTooltipText = String(this.$t('dict.cancel'));
        break;
      case 'sendConnectionRequest':
      case 'acceptConnectionRequest':
        this.connectTooltipText = String(this.$t('dict.connect'));
        this.disconnectTooltipText = String(this.$t('dict.reject'));
    }
  }

  checkConnectionType () {
    if (this.state === State.RequestSent || this.connectionSent) {
      return 'cancelConnectionRequest';
    } else if (this.state === State.RequestReceived) {
      return 'acceptConnectionRequest';
    } else {
      return 'sendConnectionRequest';
    }
  }
}
