
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomFieldDisplay, NonCustomFields } from '@/store/modules/interfaces/ChannelViewModule';
import { ChevronDownIcon, ChevronUpIcon, MessageSquareIcon, SettingsIcon, ThumbsUpIcon } from 'vue-feather-icons';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';

@Component({
    components: {
      OModalWrapper,
      ChevronDownIcon,
      ChevronUpIcon,
      ThumbsUpIcon,
      MessageSquareIcon,
      SettingsIcon
    },
  }
)
export default class MChannelCustomFieldTableSettings extends Vue {
  @Prop({ required: true })
  customFieldDisplay!: CustomFieldDisplay[];

  @Prop({ required: true })
  nonCustomFields!: NonCustomFields;

  @Prop({ required: true })
  currentUserOwnsChannel!: boolean;

  showModal = false;
  saving = false;

  emitSingleKeyAndClose (eventName: 'allStandardOff' | 'allStandardOn' | 'standardReset' | 'allCustomOff' | 'allCustomOn') {
    this.$emit(eventName);
  }

  saveAsDefaultSettings () {
    this.saving = true;
    setTimeout(() => {
      this.saving = false;
      this.showModal = false;
      this.$emit('saveSettingsAsDefault', {
        customFieldDisplay: this.customFieldDisplay,
        nonCustomFields: this.nonCustomFields
      });
    }, 300);
  }
}
