
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Datum as ChannelMembersDatum} from '@/api/ms-channel/services/interfaces/ChannelMembers';
import {AuthenticationStore, ChannelsStore, PeopleStore} from '@/store';
import {User} from '@/api/ms-authentication/services/interfaces/Login';
import {SearchType} from '@/store/modules/enums/SearchType';
import {Connections} from '@/api/ms-authentication/services/interfaces';

import MPersonNotConnectedCard from '@/components/molecules/MPersonNotConnectedCard.vue';
import MPersonCardSkeleton from '@/components/molecules/skeletons/MPersonCardSkeleton.vue';
import {RouteNames} from '@/router/RouteNames';
import { PlusIcon } from 'vue-feather-icons';
import htmlBodyClassToggle from '@/utils/htmlBodyClassToggle';

@Component({
  components: {
    MPersonNotConnectedCard,
    MPersonCardSkeleton,
    PlusIcon
  }
})
export default class OChannelMembersContainer extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ required: true})
  channelOwner!: string;

  @Prop({ required: true})
  channelArchived!: boolean;

  @Prop()
  loading!: boolean;

  @Prop()
  loadingMore!: boolean;

  people: string[] = [];

  get members (): ChannelMembersDatum[] {
    return ChannelsStore.getCurrentChannelViewedMembers;
  }

  get notMemberOf (): boolean {
    return ChannelsStore.getCurrentChannelNotMemberOf;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get peopleConnected (): Connections {
    return PeopleStore.getPeople[SearchType.channel].connected;
  }

  get noMoreResults () {
    return ChannelsStore.getMembersNoMoreResults;
  }

  created () {
    this.people = this.peopleConnected.data.map(person => person.username);
  }

  mounted () {
    htmlBodyClassToggle('hide-scroll', true);
  }

  goToEditMembers (): void {
    const redirect = {
      name: RouteNames.ROUTE_CHANNEL_EDIT,
      params: {
        channelSlug: this.channelSlug,
        username: this.currentUser.username,
      }
    };

    this.$router.push({ ...redirect, hash: '#invitations' });
  }

  // hasConnection (username): boolean {
  //   return this.people.includes(username);
  // }
}
