
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChannelWithRelationalMetaVue } from '@/store/modules/interfaces/ChannelModule';
import {
  AlertCircleIcon,
  ArchiveIcon,
  BellIcon,
  BellOffIcon,
  ChevronDownIcon,
  EditIcon,
  InfoIcon,
  KeyIcon,
  Share2Icon,
  UserXIcon
} from 'vue-feather-icons';
import { ChannelStore } from '@/store';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';

export enum EventsToEmit {
  ArchiveChannel = 'archiveChannel',
  ChannelInfo = 'channelInfo',
  ChannelNotifications = 'channelNotifications',
  LeaveChannel = 'leaveChannel',
  NavigateToEdit = 'navigateToEdit',
  ReportChannel = 'reportChannel',
  ShareChannel = 'shareChannel',
  ShuffleItems = 'shuffleItems',
  ShowSecretShareLink = 'showSecretShareLink',
  SwipeItems = 'swipeItems'
}

@Component({
  components: {
    ADecisionMakerIconSvg,
    AlertCircleIcon,
    ArchiveIcon,
    BellIcon,
    BellOffIcon,
    ChevronDownIcon,
    EditIcon,
    InfoIcon,
    KeyIcon,
    Share2Icon,
    UserXIcon
  }
})
export default class MChannelHeaderDropdownMenu extends Vue {
  @Prop({required: true})
  channel!: ChannelWithRelationalMetaVue;
  @Prop({required: true})
  currentUserCanEditChannel!: boolean;
  @Prop({required: true})
  isChannelEvent!: boolean;
  @Prop({required: true})
  currentUserOwnsChannel!: boolean;
  @Prop({required: true})
  isResearchConcierge!: boolean;
  @Prop({required: true})
  currentUserJoined!: boolean;

  EventsToEmit = EventsToEmit;

  get channelNotificationsEnabled () {
    return ChannelStore.getCurrentChannelNotificationsEnabled;
  }

  eventEmitter (event: EventsToEmit) {
    this.$emit('dropdown-action', event);
  }
}
