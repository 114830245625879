
import { Component, Vue } from 'vue-property-decorator';
import { NavigationStore } from '@/store';

import { Breadcrumb } from '@/services/BreadcrumbMaker';
import { ChevronLeftIcon, ChevronRightIcon, HomeIcon } from 'vue-feather-icons';
import isIosApp from '@/utils/isIosApp';

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    HomeIcon
  }
})
export default class MBreadcrumbs extends Vue {
  homeKey: string = 'dashboard';
  routeName!: string;

  isIos = isIosApp();

  get breadcrumbs (): Breadcrumb[] {
    return NavigationStore.getBreadCrumbs;
  }

  created () {
    NavigationStore.recalculateBreadcrumbs(this.$route);
    this.routeName = this.$route.name || '';
  }

  getName (name): string {
    return this.$t(`breadcrumbs['${name}']`).toString();
  }

  browserBack () {
    this.$router.go(-1);
  }
}
