
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore, ShoppingListStore } from '@/store';
import { Item } from '@/api/ms-item/services/interfaces';

import MShoppingListHeaderItem from '@/components/molecules/shoppingList/MShoppingListHeaderItem.vue';
import ARouterLinkGetGot from '@/components/atoms/link/ARouterLinkGetGot.vue';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import AListIcon from '@/components/atoms/icon/AListIcon.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';

@Component({
  components: {
    ACloseModalButton,
    AListIcon,
    ARouterLinkGetGot,
    MShoppingListHeaderItem,
    MAuthenticationTabs,
  }
})
export default class MShoppingListHeader extends Vue {
  visible = false;
  loading = false;
  dropdownVisible = false;

  /**
   * When an item is removed from shopping list it's unique item name is emitted up and stored in this array.
   * When dropdown is closed we iterate through this array and remove all those items from the store.
   */
  listRemovals: string[] = [];

  get itemsToGet (): Item[] {
    return ShoppingListStore.getItemsToGet;
  }

  get itemsToGetAvailableCount (): number {
    return ShoppingListStore.getItemsToGetAvailableCount;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  async created () {
    this.loading = true;

    if (AuthenticationStore.getAuthenticated) {
      await ShoppingListStore.fetchShoppingListToGet();
    }

    this.loading = false;
  }

  /**
   * When the dropdown is closed, trigger to update the get got states so any marked as bought/removed are removed from
   * list. It also updates the timestamps as a nice side effect.
   */
  triggerGetGotUpdate (active) {
    this.dropdownVisible = active;
    if (!active) {
      ShoppingListStore.fetchShoppingListToGet();
    }
  }
}
