
import { Component, Prop, Vue } from 'vue-property-decorator';
import ABadge from '../../atoms/ABadge.vue';

@Component({
  components: {
    ABadge,
  },
})

export default class MNotificationBell extends Vue {
  @Prop({ default: 0 })
  count!: number;
}
