
import { Component, Prop, Vue } from 'vue-property-decorator';
import { parser } from 'qs-in-filename';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import ellipsisString from '@/utils/ellipsisString';
import AImage from '@/components/atoms/AImage.vue';
import { Item } from '@/api/ms-item/services/interfaces';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import ItemsService from '@/api/ms-item/services/ItemsService';
import clone from '@/utils/clone';

@Component({
  components: { MInputWithValidation, AImage, ARouterLinkIssueDetail },
  filters: {
    ellipsisString: (input: string) => {
      return ellipsisString(input, 10);
    }
  }
})
export default class MItemsShuffler extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  original: any[] = [];
  loading: boolean = false;
  innerCards: any[] = [];
  shuffleSpeed = 'shuffleMedium';
  shuffleTypes = ['Slow', 'Medium', 'Fast'];
  isDeckBeingShuffled = false;
  shuffleCount = 0;
  timesToShuffle = 4;

  async created () {
    await this.fetchCards();
    this.displayInitialDeck();
  }

  displayInitialDeck () {
    this.isDeckBeingShuffled = false;
    this.shuffleCount = 0;
    this.innerCards.forEach((item) => {
      if (item.urlCache?.image?.href) {
        const parsed = parser(item.urlCache?.image?.href);
        item.c = parsed.c[0];
      }
    });
  }

  set () {
    const data = clone(this.original);
    let multi = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].inChannelWeight && data[i].inChannelWeight as number > 1) {
        multi = true;
      }
      data[i].uniqueItemNameKey = data[i].uniqueItemName + '_' + i;
    }
    if (multi) {
      this.innerCards = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].inChannelWeight) {
          const k = data[i].inChannelWeight as number * 10;
          for (let j = 0; j < k; j++) {
            const cloned = clone(data[i]);
            cloned.uniqueItemNameKey = cloned.uniqueItemName + '_' + i + j;
            this.innerCards.push(cloned);
          }
        } else {
          this.innerCards.push(data[i]);
        }
      }
    } else {
      this.innerCards = data;
    }
  }

  reset () {
    this.innerCards = [];
    this.set();
  }

  async fetchCards () {
    this.loading = true;
    this.original = (await ItemsService.itemsWithWeightsInChannelGet({
      channel: this.channelSlug,
      mostUserInvitations: this.channelSlug.startsWith('liffery.official@')
    })).data;
    this.set();
    this.loading = false;
  }

  getText (item: Item) {
    if (item.urlCache?.meta?.title) {
      return item.urlCache?.meta?.title;
    } else {
      return item.editable.text;
    }
  }

  shuffleDeck () {
    this.isDeckBeingShuffled = true;
    for (let i = this.innerCards.length - 1; i > 0; i--) {
      let randomIndex = Math.floor(Math.random() * i);
      let temp = this.innerCards[i];
      Vue.set(this.innerCards, i, this.innerCards[randomIndex]);
      Vue.set(this.innerCards, randomIndex, temp);
    }
    if (this.shuffleCount <= this.timesToShuffle) {
      ++this.shuffleCount;
      setTimeout(() => {
        this.shuffleDeck();
      }, 250);
    } else {
      setTimeout(() => {
        this.shuffleCount = 0;
        this.isDeckBeingShuffled = false;
      }, 650);
    }
  }

  showWinner () {
    this.$buefy.dialog.alert({
      title: 'Shuffle result',
      message: `
        Top of the stack is an item belonging to, ${this.innerCards[0].actor.firstName} ${this.innerCards[0].actor.lastName}.
      `,
    });
  }

  hex2rgb (hex, alpha: 1) {
    // return {r, g, b} // return an object
    return `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 7), 16)}, ${alpha})`;
  }
}
