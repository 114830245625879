export interface DropdownOptions {
  label: string;
  value: string;
  // to add more icons make sure you include them as components to be used in <component is="icon">
  icon?: string;
}

export enum NativeButtonTypes {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}
