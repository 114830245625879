
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus, { EventBusEvents } from '@/EventBus';
import { PlusIcon } from 'vue-feather-icons';

@Component({
  components: {
    PlusIcon
  },
})
export default class AAddNewItem extends Vue {
  @Prop()
  isOutlined?: boolean;

  showAddItemModal () {
    EventBus.$emit(EventBusEvents.ITEM_ADD);
  }
}
