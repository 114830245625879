
import { Component, Vue } from 'vue-property-decorator';
import { RegisterEmailPost } from '@/api/ms-authentication/services/interfaces';
import { ValidationObserver } from 'vee-validate';
import { AuthenticationStore } from '@/store';

import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import APasswordInputWithValidation from '@/storybook-components/src/stories/atoms/APasswordInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import MSsoButtons from '@/components/molecules/MSSOButtons.vue';
import MAgreeToTcsCs from '@/components/molecules/MAgreeToTcsCs.vue';
import { RouteNames } from '@/router/RouteNames';
import config, { BuildType } from '@/config';
import ARafflePrompt from '@/components/atoms/ARafflePrompt.vue';
import UserService from '@/api/ms-authentication/services/UserService';
import isIosApp from '@/utils/isIosApp';

@Component({
  components: {
    ARafflePrompt,
    MAgreeToTcsCs,
    AFormServerError,
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
    APasswordInputWithValidation,
    MSsoButtons
  },
})
export default class ORegisterForm extends Vue {
  web = (config.buildType === BuildType.WEB);
  termsAgreed: boolean = false;

  isIos = isIosApp();

  loading: boolean = false;
  showAllHelpText: boolean = false;
  emailConfirm: string = '';
  form: RegisterEmailPost = {
    allTermsAgreed: new Date(),
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    registeredFromHref: window.location.href
  };
  inviteCodeIsDisabled = true;
  invitationMessage: string = '';
  invitationFrom: string = '';

  get errors () {
    return AuthenticationStore.errorRegisterContent;
  }

  created () {
    if (this.$route.query.inviteCode) {
      this.form.inviteCode = this.$route.query.inviteCode as string;
      this.loadInviteeDetails();
    }
  }

  async loadInviteeDetails () {
    if (!this.form.inviteCode) {
      return;
    }
    const details = await UserService.userInvitationsInviteeCodeGet({
      code: this.form.inviteCode
    });
    if (details) {
      this.inviteCodeIsDisabled = true;
      this.form.email = details.email;
      this.form.firstName = details.firstName;
      this.form.lastName = details.lastName;
      this.invitationMessage = details.message ? details.message : '';
      this.invitationFrom = this.$t('dict.inviteFrom', { name: details.firstName }) as string;
    }
  }

  agreedToTerms () {
    this.termsAgreed = true;
  }

  disagreedToTerms () {
    this.termsAgreed = false;
  }

  async onSubmit () {
    this.loading = true;

    // Trim the content before sending
    this.form.firstName = this.form.firstName.trim();
    this.form.lastName = this.form.lastName.trim();
    this.form.email = this.form.email.trim();

    // we now request the email to be confirmed and not the password,
    // if the email is wrong, there is no getting back in unlike a
    // password which can be reset
    this.form.passwordConfirm = this.form.password;

    // register
    await AuthenticationStore.register(this.form);

    // success.. then route to the dashboard
    await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    this.loading = false;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_REGISTER_UNSET();
  }
}
