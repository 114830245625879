/**
 * Decision made to set Monday as the first day of the week for English as a language. Reasoning below...
 *
 * What's the first day of the week on a calendar?
 *
 * ISO Week: https://en.wikipedia.org/wiki/ISO_week_date
 *  "Weeks start with Monday and end on Sunday"
 *
 * Week: https://en.wikipedia.org/wiki/Week
 *  "The United States, Canada, Brazil, Israel, Japan and other countries consider Sunday as the first day of the week"
 *  "The week begins with Saturday in much of the Middle East"
 *  "The international ISO 8601 standard and most of Europe has Monday as the first day of the week"
 *
 * Map of first day of the week in different countries: http://chartsbin.com/view/41671
 *  The world is very split is the takeaway here. English speaking countries are very split.
 *
 * The locale settings for each country will set the first day of the week accordingly along with the language. So as we
 * introduce more languages to Liffery the locale settings will adjust the first day of the week accordingly. However,
 * the default language in Datepicker is English, and it sets Monday as the first day of the week. For English as a
 * language we don't need to import it, but since English is the international language we will set Monday as the first
 * day. The world is very split between Sunday/Monday but the ISO international standard clearly defines Monday as the
 * first day which handily breaks the tie.
 *
 * TODO - Further locale work will need to be put in so that every country has the calendar according to their preference.
 */

const map = {
  enGB: 'en',
  deDE: 'de'
};

export interface DatePickerLang {
  lang: string | { formatLocale: any };
}

export function getLang (code) {
  const EnglishSpeakingCountriesFirstDayMonday = ['enGB'];
  let lang: DatePickerLang['lang'];
  if (EnglishSpeakingCountriesFirstDayMonday.includes(code)) {
    lang = {
      formatLocale: {
        firstDayOfWeek: 1
      }
    };
  } else {
    lang = map[code];
    if (code === 'de') {
      import ('vue2-datepicker/locale/de');
    } else {
      import ('vue2-datepicker/locale/en');
    }
  }

  return lang;
}
