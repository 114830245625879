
import { Action } from '@/api/ms-item/services/interfaces/SwipeScores';
import { Component, Vue } from 'vue-property-decorator';
import MChannelSwipeActionIcons from '@/components/molecules/channel-swipe/MChannelSwipeActionIcons.vue';

@Component({
  components: { MChannelSwipeActionIcons }
})
export default class MChannelSwipeInfo extends Vue {
  Actions: Action[] = [
    Action.SwipeRight,
    Action.SwipeUp,
    Action.SwipeLeft,
    Action.SwipeDown
  ];
  actionIconHighlight: {[key:string]: boolean} = {
    [Action.SwipeRight]: false,
    [Action.SwipeUp]: false,
    [Action.SwipeLeft]: false,
    [Action.SwipeDown]: false
  };

  showUseCaseIdeas: boolean = false;

  closeInfo () {
    this.$emit('close');
  }
}
