
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import ARouterLinkItemUrl from '@/components/atoms/link/ARouterLinkItemUrl.vue';
import { SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';
import { MessageSquareIcon, ThumbsUpIcon } from 'vue-feather-icons';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import { AuthenticationStore, ItemsStore } from '@/store';
import AItemPrice from '@/components/atoms/AItemPrice.vue';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';

@Component({
  components: {
    ADecisionMakerIconSvg,
    AItemPrice,
    ARouterLinkIssueDetail,
    ARouterLinkItemUrl,
    AImage,
    ThumbsUpIcon,
    MessageSquareIcon
  },
  filters: {
    removeUrl: remove1stUrlFromString,
  },
})
export default class OChannelItemsTable extends Vue {
  @Prop()
  loading!: boolean;

  @Prop({ required: true })
  items!: Item[];

  @Prop({ required: true })
  total!: number;

  @Prop({ default: 20 })
  perPage!: number;

  @Prop({ required: true })
  sortingDirection!: SortDir;

  @Prop({ required: true })
  sortBy!: SortBy;

  imageSize: Size = Size.The200X;
  sortByOptions = SortBy;
  refetchItemsTimeout: any = {};

  get isLoggedIn () {
    return AuthenticationStore.getAuthenticated;
  }

  get noItemsInChannel () {
    return ItemsStore.getItems.items.length;
  }

  mounted () {
    scrollWindowToElement('smooth', '.OChannelItemsTable', true);
    //if using browser back no items will have been loaded yet, emit to fetch the first page of results.
    if (!this.items.length) {
      this.$emit('page-change', 1);
    }
  }

  onPageChange (page: number): void {
    this.$emit('page-change', page);
  }

  onSort (sortBy: SortBy, sortDir: SortDir): void {
    this.$emit('sort-change', { sortBy, sortDir });
  }

  hasUserDescription (text): boolean {
    return this.$options.filters?.removeUrl(text).length > 0;
  }

  @Watch('noItemsInChannel')
  itemsStoreUpdated () {
    clearTimeout(this.refetchItemsTimeout);
    this.refetchItemsTimeout = setTimeout(() => this.$emit('refetch-items'), 350);
  }
}
