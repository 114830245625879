
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';
import { AuthenticationStore, ChannelsStore, PeopleStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';

const defaultTabs = [
  ASearchBarTabType.items,
  ASearchBarTabType.pinned,
  ASearchBarTabType.channels,
  ASearchBarTabType.people,
  ASearchBarTabType.explore
];
@Component
export default class MSearchBarTabs extends Vue {
  @Prop({
    default: () => defaultTabs,
    validator: (value) => {
      if (!Array.isArray(value)) {
        return false;
      }
      let pass = true;
      value.forEach((item) => {
        if (!defaultTabs.includes(item)) {
          pass = false;
        }
      });
      return pass;
    }
  })
  tabs!: string[];

  @Prop({ default: ASearchBarTabType.items })
  selectedTab!: ASearchBarTabType;

  innerSelectedTab: ASearchBarTabType = ASearchBarTabType.items;

  get getPendingPeople (): number {
    return PeopleStore.getConnectionRequests.length;
  }

  get getPendingChannel (): number {
    return ChannelsStore.getChannelInvites.channels.length;
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  get isViewingSomeoneElsesProfile () {
    const username = AuthenticationStore.user.username;
    return this.$route.name === RouteNames.ROUTE_PROFILE && this.$route.path !== `/profile/${username}/`;
  }

  get isViewingChannel () {
    return this.$route.name === RouteNames.ROUTE_CHANNEL_VIEW;
  }

  created () {
    this.innerSelectedTab = this.selectedTab;
  }

  handleTabChange (tabName: ASearchBarTabType) {
    if (!this.isAuthenticated && tabName !== 'items') {
      AuthenticationStore.TOGGLE_PROMPT_LOGIN(true);
      this.selectedTab = ASearchBarTabType.items;
    } else {
      this.$emit('child-output', tabName);
    }
  }

  @Watch('selectedTab')
  selectedTabKeyHandle (newTabKey: ASearchBarTabType) {
    if (newTabKey) {
      this.innerSelectedTab = newTabKey;
    }
  }
}
