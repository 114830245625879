export const DEFAULT_LANGUAGE = 'enGB';
export const FALLBACK_LANGUAGE = 'enGB';
export const SUPPORTED_LANGUAGES = [
//  'hrHR', // Croatian
//  'nlNL', // Dutch
  'enGB', // English
//  'frFR', // French
  'deDE', // German
//  'itIT', // Italian
//  'ptBR', // Portuguese
//  'roRO', // Romanian
 'esES', // Spanish
];
