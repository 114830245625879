
// 3rd party code
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import getFirstUrlFromString from 'get-first-url-from-string';
import { ValidationObserver } from 'vee-validate';
import {
  ArrowLeftIcon,
  CalendarIcon,
  ChevronDownIcon,
  Link2Icon,
  MapPinIcon,
  UploadIcon,
  XIcon
} from 'vue-feather-icons';

// 1st party code
import { AuthenticationStore, ChannelsStore, ItemEntryStore } from '@/store';
import {
  defaultChannelName,
  ItemEntryStoreLastChannelAdded,
  NewImage
} from '@/store/modules/interfaces/ItemEntryModule';
import UrlPreviewService from '@/api/ms-item/services/UrlPreviewService';
import { ItemPost } from '@/api/ms-item/services/interfaces';
import { AddType, Channel, ChannelType, ItemPostReminder } from '@/api/ms-item/services/interfaces/ItemPost';
import { User } from '@/api/ms-authentication/services/interfaces';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import MUploadImagePreview, { MUploadImagePreviewChildOutput } from '@/components/molecules/MUploadImagePreview.vue';
import MUrlPreview from '@/components/molecules/MUrlPreview.vue';
import MItemEntryChannelSelector from '@/components/molecules/itemEntry/MItemEntryChannelSelector.vue';

import fileReaderToBase64Preview from '@/utils/fileReaderToBase64Preview';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import formatUrl from '@/utils/formatUrl';
import getBase64ImageFromUrl from '@/utils/getBase64ImageFromUrl';
import convertBase64ToBlob from '@/utils/convertBase64ToBlob';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import { Privacy } from '@/api/ms-channel/services/interfaces/Channel';
import { ItemAndImage, OItemEntryFormOutput } from '@/components/molecules/itemEntry/MItemEntryTrigger.vue';
import fontPercentageToScale from '@/utils/fontPercentageToScale';
import MItemEntryMap from '@/components/molecules/itemEntry/MItemEntryMap.vue';
import { CustomField, GeolocationData, Outstanding, ReminderType } from '@/api/ms-item/services/interfaces/Item';
import { RouteNames } from '@/router/RouteNames';
import channelSlugFromRoute from '@/utils/channelSlugFromRoute';
import updateItemEntryLastChannelUsed from '@/utils/updateItemEntryLastChannelUsed';
import truncateText from '@/utils/truncateText';
import MImageUpload from '@/components/molecules/MImageUpload.vue';
import { Photo } from '@capacitor/camera/dist/esm/definitions';
import EventBus, { EventBusEvents } from '@/EventBus';
import seoInjector from '@/services/SeoInjector';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import hashtagsFetchToTributejs from '@/utils/hashtagsFetchToTributejs';
import { Source } from '@/api/ms-hashtags/services/interfaces/HashtagSearchGetQuery';
import MItemEntryCustomFields from '@/components/molecules/itemEntry/MItemEntryCustomFields.vue';
import { pause } from 'common-utils/time';
import { clone } from 'common-utils/object';
import { generateFibonacciArrayToX } from 'common-utils/number';
import { ItemPreview } from '@/components/organisms/OItemCard.vue';
import { Status } from '@/api/ms-item/services/interfaces/UrlPreview';
import calculateFullImageHref from '@/storybook-components/src/utils/calculateFullImageHref';
import config from '@/config';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import isBrowserExt from '@/utils/isBrowserExt';
import AAlarmClockIcon from '@/components/atoms/icon/svg/AAlarmClockIcon.vue';
import MItemEntrySetReminder from '@/components/molecules/itemEntry/MItemEntrySetReminder.vue';
import googleMapsReverseGeoFromGPS from '@/utils/googleMapsReverseGeoFromGPS';
import MDropdownButton from '@/storybook-components/src/stories/molecules/MDropdownButton.vue';
import { DropdownOptions } from '@/storybook-components/src/stories/molecules/MDropdownButtonInterfaces';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import MItemEntryRecommendTo from '@/components/molecules/itemEntry/MItemEntryRecommendTo.vue';
import UrlResolverService from '@/api/ms-item/services/UrlResolverService';

export interface ImageUpload {
  preview: string,
  userFriendlyFilename?: string,
  exifJson?: string
}

export interface ImageUploadPreviews {
  previews: ImageUpload[];
}

// future - schedule for later
enum DropdownOptionValues {
  RecommendTo = 'recommendTo'
}

@Component({
  components: {
    MItemEntryRecommendTo,
    OModalWrapper,
    MDropdownButton,
    MItemEntrySetReminder,
    AAlarmClockIcon,
    MItemEntryCustomFields,
    ALightbulbOnOutlineIcon,
    MImageUpload,
    MItemEntryMap,
    ACloseModalButton,
    MItemEntryChannelSelector,
    MUploadImagePreview,
    MInputWithValidation,
    MUrlPreview,
    ValidationObserver,
    ArrowLeftIcon,
    CalendarIcon,
    ChevronDownIcon,
    Link2Icon,
    MapPinIcon,
    UploadIcon,
    XIcon,
  },
  filters: {
    truncateText: truncateText,
    formatUrl: formatUrl,
  }
})
export default class OItemEntryForm extends Vue {
  @Prop({ required: true })
  contentToPreload!: ItemAndImage;
  @Prop({ required: true, default: false })
  isEditingItem!: boolean;
  @Prop({ required: false, default: false })
  isMovingItem!: boolean;
  @Prop({ required: false, default: false })
  isItemRecommendation!: boolean;
  @Prop({ required: true })
  isMobileView!: boolean;

  scaledStyle: Partial<CSSStyleDeclaration> = {};

  isBrowserExt = isBrowserExt();

  isMounted = false;
  firstLoad = true;
  saving = false;
  loading = false;
  fetchingChannel = false;
  urlPreviewCalls: string[] = [];
  imagesToUpload: ImageUpload[] = [];
  imagesToUploadPreviews: ImageUploadPreviews = { previews: [] };
  failedUrl: string = '';
  urlData: { url: string, hasPreview: boolean } = { url: '', hasPreview: false };
  showSelectChannel: boolean = false;
  showUrlPreview: boolean = false;
  showSetReminder: number = 0;
  customFields: CustomField[] = [];
  defaultReminder: ItemPostReminder = {
    isSet: false,
    reminder: {
      reminderType: ReminderType.OneWeek,
      date: new Date()
    }
  };
  form: ItemPost = {
    editable: {
      text: '',
      channel: {
        isDefault: this.isDefault,
        name: this.selectedChannel.name,
        slug: this.selectedChannel.channel?.slug as string,
        channelType: this.selectedChannel.channel?.channelType as ChannelType,
        customFields: []
      }
    },
    addType: AddType.ItemAdd,
    fromPath: window.location.pathname,
    reminder: this.defaultReminder,
    recommendationItemName: undefined
  };
  formClone: ItemPost = this.form;
  hashtagsFetchToTributejs = (inputText, cb) => {
    hashtagsFetchToTributejs(inputText, Source.Item, cb);
  };
  hasNewUploadedImage: boolean = false;
  maxImageUploadReached: boolean = false;
  defaultUserFriendlyFilename: string = 'user-photo.png';
  urlPreviewFailed = false;
  itemPreview: any = {};
  previewSettings: ItemPreview = {
    isPreview: true,
    loading: false,
    message: ''
  };
  urlPreviewFetching: string = '';
  itemPreviewDefaultData = {
    actor: {
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      username: this.currentUser.username
    },
    likes: [],
    comments: [],
    uniqueItemName: '',
    getgot: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  geolocationData: GeolocationData = {
    //default position is roughly the centre of the uk
    position: {
      type: 'Point',
      coordinates: [-4.087832161591848, 54.66146490422102]
    }
  };
  geoFormattedAddress = '';
  showMapSelect = false;

  fibonacciValues: number[] = [];
  urlPreviewTimeout: any;

  channelTypes = ChannelType;

  //called during the tour
  addByEventBus: boolean = false;
  demoUrl: string = 'https://shop.liffery.com/products/organic-canvas-tote-bag';

  storePageTitle = {
    str: '',
    get title () {
      return this.str.replace(' | Liffery', '');
    }
  };

  saveDropdownOptions: DropdownOptions[] = [{
    label: this.$t('dict.recommendTo') as string,
    value: DropdownOptionValues.RecommendTo,
    icon: 'SendIcon'
  }];
  isRecommendToModalActive: boolean = false;

  $refs!: {
    EntryFormTitle: HTMLElement
  };

  get isDefault () {
    return this.selectedChannel.channelPrivacy === Privacy.FriendsOnly ||
        [defaultChannelName, ''].includes(this.selectedChannel.name);
  }

  get isGeneral (): boolean {
    return this.selectedChannel.channel?.name === '' && this.selectedChannel.channel.slug == '';
  }

  get selectedChannel (): ItemEntryStoreLastChannelAdded {
    return ItemEntryStore.lastChannelAddedToGet;
  }

  get selectedChannelHasCustomFields (): boolean {
    return !!(!this.isGeneral && this.selectedChannel && this.selectedChannel.customFields && this.selectedChannel.customFields.length > 0);
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get selectedOptionText (): string {
    let text = this.isDefault ? this.$t('dict.general') as string : '';
    if (this.selectedChannel.channel && this.selectedChannel.channel.slug.length > 0 && this.selectedChannel.name.length > 0) {
      if (this.isDefault) {
        text += ': ';
      }
      text += this.selectedChannel.name;
    }
    return this.$options.filters?.truncateText(text);
  }

  beforeCreate () {
    this.urlData = {
      hasPreview: false,
      url: ''
    };
  }

  beforeDestroy () {
    clearTimeout(this.urlPreviewTimeout);
    this.eventsUnbind();
    seoInjector.setPageTitle(this.storePageTitle.title);
  }

  created () {
    this.storePageTitle.str = seoInjector.getPageTitle();
    seoInjector.setPageTitle(this.isEditingItem ? 'Edit Item' : 'Add Item');

    this.eventsBind();
    // Set the defaults
    this.itemPreview = {
      editable: {
        text: '',
        channel: {
          isDefault: this.isDefault,
          slug: this.selectedChannel.channel?.slug,
          name: this.selectedChannel.name
        }
      },
      urlCache: null,
      userPhotos: null,
      ...this.itemPreviewDefaultData
    };
    // if editing make sure the actor of the item is put into the item preview object
    if (this.isEditingItem) {
      this.itemPreview = {
        ...this.itemPreview,
        ...{
          actor: this.contentToPreload.actor
        }
      };
      // and if it's also a move, open the showSelectChannel immediately
      this.showSelectChannel = this.isMovingItem;
    }

    this.scaledStyle = fontPercentageToScale();
  }

  async mounted () {
    // Preloading content, either editing or copying items
    if (this.contentToPreload) {
      await this.setFormDataFromContentToPreload();
    }
    // No content to preload
    else {
      // if adding an item from a channel, ensure that channel is automatically selected in the form
      // otherwise don't do anything here, default behaviour uses channel in last channel added to store
      if (this.$route.name === RouteNames.ROUTE_CHANNEL_VIEW) {
        await this.setFormChannelFromRoute();
      }
    }

    // as we inject the content stored in the local cache, call the text change now
    if (this.form.editable.text) {
      await this.textChange(this.form.editable.text);
    }
    this.firstLoad = false;

    this.isMounted = true;
  }

  // for predefined reminders, if we're still on the same date use the 1 week/1 month, otherwise call it custom and return that
  preloadFormReminder (reminder: Outstanding): void {
    const date = new Date();
    const reminderDate = new Date(reminder.date);
    if (reminderDate > date && this.form.reminder && this.form.reminder.reminder) {
      // set values - assume this is a custom date, even if not custom default position is to assume it is for purposes of the form
      this.form.reminder.isSet = true;
      this.form.reminder.reminder.date = reminderDate;
      this.form.reminder.reminder.reminderType = ReminderType.CustomDate;
      // if the type is not custom, reverse engineer the date and compare to today, if it's the same day we can use friendly text
      if (reminder.reminderType !== ReminderType.CustomDate) {
        const comparisonDate = new Date(reminder.date);
        switch (reminder.reminderType) {
          case ReminderType.OneWeek:
            comparisonDate.setDate(reminderDate.getDate() - 7);
            break;
          case ReminderType.OneMonth:
            comparisonDate.setMonth(reminderDate.getMonth() - 1);
            break;
          case ReminderType.ThreeMonths:
            comparisonDate.setMonth(reminderDate.getMonth() - 3);
            break;
        }
        // only if we get a direct match set reminder type to a default option for friendly text
        if (comparisonDate.getFullYear() === date.getFullYear()
            && comparisonDate.getMonth() === date.getMonth()
            && comparisonDate.getDate() === date.getDate()) {
          this.form.reminder.reminder.reminderType = reminder.reminderType;
        }
      }
    }
  }

  eventsBind () {
    const callerId = 'OItemEntryForm';
    EventBus.$on(EventBusEvents.ITEM_ENTRY_CLOSE, callerId, this.closeModal);
    EventBus.$on(EventBusEvents.TOUR_INSERT_DEMO_URL, callerId, this.welcomeTourInsertDemoUrlText);
    EventBus.$on(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM_SUBMIT, callerId, this.welcomeTourSubmitForm);
    EventBus.$on(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_PREVIEW, callerId, this.closeItemPreview);
  }

  eventsUnbind () {
    const callerId = 'OItemEntryForm';
    EventBus.$remove(EventBusEvents.ITEM_ENTRY_CLOSE, callerId);
    EventBus.$remove(EventBusEvents.TOUR_INSERT_DEMO_URL, callerId);
    EventBus.$remove(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM_SUBMIT, callerId);
    EventBus.$remove(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_PREVIEW, callerId);
  }

  /**
   * If the channel to preload does not permit the current user to add to
   * Then do nothing.
   **/
  async preloadFormChannel (channelSlug?: string) {
    // check the current user can add to this channel
    if (channelSlug) {
      this.saving = true;
      this.fetchingChannel = true;
      try {
        const fetchedChannel = await ChannelService.channelSlugSlugGet({ slug: channelSlug });
        if (fetchedChannel.currentUserCanAdd) {
          this.updateLastUsedChannel(fetchedChannel);
        }
        this.saving = false;
        this.fetchingChannel = false;
      } catch (e) {
        this.saving = false;
        this.fetchingChannel = false;
      }
    }
  }

  updateLastUsedChannel (channel: any): void {
    updateItemEntryLastChannelUsed({
      allCanAdd: channel.allCanAdd,
      channelType: channel.channelType || ChannelType.General,
      currentUserCanAdd: channel.currentUserCanAdd,
      imagePath: channel.imagePath,
      isDefaultChannel: this.isDefault,
      name: channel.name,
      description: channel.description as string,
      privacy: channel.privacy,
      slug: channel.slug,
      memberCount: channel.memberCount as number,
      customFields: channel.customFields
    });
  }

  async setFormChannelFromRoute (): Promise<void> {
    // get the channel slug from the route, then ensure it is a channel that can be posted to by this user, if it is, auto-populate
    const channelSlug = channelSlugFromRoute(this.$route) as string;
    const channelCanPost = await ChannelService.channelSlugSlugUserCanPostGet({ slug: channelSlug });
    if (channelCanPost.isTrue && this.form.editable.channel?.slug !== channelSlug) {
      this.form.editable.channel = this.form.editable.channel || {
        name: '',
        slug: '',
        channelType: ChannelType.General
      };
      const channel = await ChannelService.channelSlugSlugGet({ slug: channelSlug });
      this.updateLastUsedChannel({
        allCanAdd: channel.allCanAdd,
        channelType: channel.channelType || ChannelType.General,
        currentUserCanAdd: channel.currentUserCanAdd,
        imagePath: channel.imagePath,
        isDefaultChannel: this.isDefault,
        name: channel.name,
        description: channel.description as string,
        privacy: channel.privacy,
        slug: channel.slug,
        memberCount: channel.memberCount as number,
        customFields: channel.customFields
      });
    }
  }

  // eslint-disable-next-line max-lines-per-function
  async setFormDataFromContentToPreload (): Promise<void> {
    this.form.editable.text = this.contentToPreload.editable.text || this.form.editable.text;
    // share from browser to app doesn't send a channel, so leave as whatever is in the store
    if (this.contentToPreload.editable.channel) {
      this.form.editable.channel = clone(this.contentToPreload.editable.channel as Channel);
    }

    this.urlPreviewCalls = [];
    // image handling, editing will have the userphotos as urls
    if (this.contentToPreload.userPhotos?.length) {
      this.imagesToUploadPreviews.previews.push({
        userFriendlyFilename: this.defaultUserFriendlyFilename,
        preview: await getBase64ImageFromUrl(this.contentToPreload.userPhotos[0].filePath)
      });
      this.imagesToUpload = this.imagesToUploadPreviews.previews;
      this.maxImageUploadReachedCalculate();
    }
    // else we could have the images passed in via the app share to liffery, they will be in
    else if (this.contentToPreload.base64image) {
      this.imagesToUploadPreviews.previews.push({
        userFriendlyFilename: this.defaultUserFriendlyFilename,
        preview: this.contentToPreload.base64image
      });
      this.imagesToUpload = this.imagesToUploadPreviews.previews;
      this.maxImageUploadReachedCalculate();
    }

    // Geodata handling
    if (this.contentToPreload.editable.geolocationData) {
      this.geolocationData = this.contentToPreload.editable.geolocationData as GeolocationData;
      this.geoFormattedAddress = this.$options.filters?.truncateText(this.contentToPreload.editable.geolocationData.formattedAddress as string, 25);
    }
    // Else, we could still have lng/lat handed in from an image shared in via the app parsed from exif data
    else if (this.contentToPreload.imageGeo) {
      this.loading = true;
      await this.setGeoData({
        lng: this.contentToPreload.imageGeo.lng,
        lat: this.contentToPreload.imageGeo.lat
      });
      this.loading = false;
    }

    // Reminder input from copy/edit/move an item
    if (this.contentToPreload.reminder && this.contentToPreload.reminder.outstanding) {
      this.preloadFormReminder(this.contentToPreload.reminder.outstanding);
    }

    await this.preloadFormChannel(this.contentToPreload.editable.channel?.slug);
  }

  async setGeoData (input: { lat: number, lng: number }) {
    const geoData = await googleMapsReverseGeoFromGPS(input);
    this.geolocationData = geoData;
    this.geoFormattedAddress = this.$options.filters?.truncateText(geoData.formattedAddress as string, 25);
  }

  /**
   * If there is no image or url, there should be text
   */
  canSubmit (): boolean {
    const content: {
      text: boolean,
      url: boolean,
      photo: boolean
    } = {
      text: this.form.editable.text.trim() !== '',
      url: this.urlData.hasPreview,
      photo: this.hasNewUploadedImage || this.itemPreview.userPhotos.length > 0
    };

    // check if a reminder set, if set, ensure content is filled out
    let reminderValid = true;
    if (this.form.reminder) {
      if (this.form.reminder.isSet) {
        if (!this.form.reminder.reminder || !this.form.reminder.reminder.reminderType || !this.form.reminder.reminder.date) {
          reminderValid = false;
        }
      }
    }

    return ((content.text || content.url || content.photo) && reminderValid);
  }

  cannotSubmitForm () {
    this.$buefy.dialog.alert({
      title: this.$t('item.entry.noTextTitle') as string,
      message: this.$t('item.entry.noText') as string,
    });
  }

  /**
   * Clones the form and adds in computed data prior to posting, such as geolocation data.
   */
  prepFormClone () {
    const formClone = clone(this.form);

    if (!formClone.editable.text.includes(this.urlData.url)) {
      // if there is some user content in there, add a buffer in
      if (formClone.editable.text.trim().length > 0) {
        formClone.editable.text += ' ';
      }
      formClone.editable.text += this.urlData.url;
    }
    //only store the geolocation data if there is a formatted address present, which indicates the default position changed
    if (this.geolocationData.formattedAddress) {
      formClone.editable.geolocationData = this.geolocationData;
    }
    // if this is an item recommendation then the unique item name in the preloaded content needs to be posted as well
    formClone.recommendationItemName = undefined;
    if (this.isItemRecommendation && this.contentToPreload.uniqueItemName) {
      formClone.recommendationItemName = this.contentToPreload.uniqueItemName;
    }

    this.formClone = formClone;
  }

  // eslint-disable-next-line max-lines-per-function
  async onSubmit (): Promise<void> {
    if (!this.canSubmit()) {
      this.cannotSubmitForm();
      return;
    }

    if (this.imagesToUploadPreviews.previews.length > 0) {
      console.log(this.imagesToUploadPreviews.previews);
      console.log(this.hasNewUploadedImage);
    }

    this.prepFormClone();

    this.saving = true;

    this.$refs.EntryFormTitle.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    this.handleSave(this.formClone)
        .then((newItem) => {
          // 1st up clear the item entry before everything else
          ItemEntryStore.CLEAR_ITEM_ENTRY_LESS_CHANNEL();

          clearTimeout(this.urlPreviewTimeout);

          // scroll window to top
          scrollWindowToElement('auto');
          this.saving = false;
          this.$emit('child-output', {
            closeModal: true,
            newItem,
            editingItem: this.isEditingItem
          } as OItemEntryFormOutput);

          // If in the tour emit the new item back to OVueTour to use later
          if (this.addByEventBus) {
            this.addByEventBus = false;
            EventBus.$emit(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM_NEW_ITEM, newItem);
          }
          // if channel is not general, update the last added to store
          if (newItem.editable.channel !== '') {
            ChannelsStore.fetchChannelsLastAddedTo();
          }

          // if this was added by way of recommendation, navigate to the dashboard
          if (this.isItemRecommendation) {
            this.$router.push({
              name: RouteNames.ROUTE_DASHBOARD
            }).then(() => EventBus.$emit(EventBusEvents.ITEMS_FETCH_INJECT_LATEST)).catch((e) => console.error(e.message));
          }
        })
        .catch((e) => {
          console.error(e);
          if (e.response && e.response.data && e.response.data.status === 429) {
            const b = e.response.data.body;
            this.$buefy.dialog.alert({
              title: this.$t('raffle.launch21.tooManyEntries.title') as string,
              message: this.$t('raffle.launch21.tooManyEntries.message', {
                tickets: b.invitationUsedCount + 1,
                invitationUsedCount: b.invitationUsedCount,
                usedCount: b.raffleEntryCount
              }) as string,
              onConfirm: () => {
                this.$emit('close-modal');
                this.$router.push({
                  name: RouteNames.ROUTE_SETTINGS_INVITATIONS
                });
              }
            });
          }
          ChannelsStore.fetchChannelsManagerOf().catch(console.error);
          this.$buefy.dialog.alert({
            title: 'Sorry',
            message: this.$t('errors.editItemError') as string,
            onConfirm: () => {
              this.$emit('close-modal');
              location.reload();
            }
          });
          this.saving = false;
        });
  }

  async handleSave (formClone) {
    let newItem;
    if (this.isEditingItem) {
      newItem = await ItemEntryStore.editItem({
        uniqueItemName: this.contentToPreload?.uniqueItemName as string,
        item: formClone
      });

      // New user image
      if (this.hasNewUploadedImage) {
        if (this.contentToPreload?.userPhotos?.length) {
          await ItemEntryStore.deleteItemImage({
            uniqueItemName: this.contentToPreload.uniqueItemName,
            photoFilePath: this.contentToPreload.userPhotos[0].filePath
          });
        }
        newItem = await this.handleImageSubmit(newItem.uniqueItemName);
      }
      // see if the item object has images but the images to preview is empty
      else if (newItem.userPhotos.length > 0 && this.imagesToUpload.length === 0) {
        await ItemEntryStore.deleteItemImage({
          uniqueItemName: newItem.uniqueItemName,
          photoFilePath: newItem.userPhotos[0].filePath,
        });
      }
    } else {
      newItem = await ItemEntryStore.saveItem(formClone);
      const itemWithSavedImage = await this.handleImageSubmit(newItem.uniqueItemName);
      if (itemWithSavedImage) {
        newItem = itemWithSavedImage;
      }
    }
    return newItem;
  }

  async handleImageSubmit (uniqueItemName: string) {
    if (this.imagesToUploadPreviews.previews.length > 0) {
      const image = this.imagesToUploadPreviews.previews[0];
      const block = image.preview.split(';');
      const contentType = block[0].split(':')[1];
      let imageObj: NewImage = {
        uniqueName: uniqueItemName,
        image: convertBase64ToBlob(image.preview, contentType),
      };
      if (image.exifJson) {
        imageObj.exifJson = image.exifJson;
      }
      // if this is a recommendation and user did not change image, pass the unique name along so we can copy the original file
      if (this.formClone.recommendationItemName && !this.hasNewUploadedImage) {
        imageObj.recommendUniqueName = this.formClone.recommendationItemName;
      }
      const newItem = await ItemEntryStore.saveItemImage(imageObj);
      // try and get the image
      try {
        await fetch(calculateFullImageHref(
            config.api.baseUrl + config.api.basePaths.imageRead,
            Size.The400X,
            // @ts-ignore
            newItem.userPhotos[0].filePath
        ) + '?' + Date.now());
      } catch (e) {
        console.error(e);
      }

      return newItem;
    }
  }

  handleOptionSelected (value: DropdownOptionValues) {
    switch (value) {
      case DropdownOptionValues.RecommendTo:
        this.handleRecommendTo();
        break;
    }
  }

  handleRecommendTo () {
    if (!this.canSubmit()) {
      this.cannotSubmitForm();
      return;
    }
    this.prepFormClone();
    this.isRecommendToModalActive = true;
  }

  clear (): void {
    this.form.editable.text = '';
    this.itemPreview = {};
    this.urlData.url = '';
    this.urlPreviewFetching = '';
    this.urlData.hasPreview = false;
    this.hasNewUploadedImage = false;
    this.imagesToUpload = [];
    this.imagesToUploadPreviews.previews = [];
    this.updateItemEntry(true);
  }

  async textChange (newText: string): Promise<void> {
    if (this.form.editable?.text) {
      this.form.editable.text = this.form.editable.text.trim();
    }
    this.updateItemEntry();
    const url = getFirstUrlFromString(newText);
    if (!url && !this.urlData.hasPreview) {
      this.urlPreviewCalls = [];
    }
    if (url && !this.urlData.hasPreview) {
      this.urlData.url = url;
      this.urlPreviewCalls = [url];
      await this.loadUrlPreview();
    } else {
      await this.formatItemPreview();
    }
  }

  /**
   * Sets up then runs the recursive function to get the url preview.
   */
  async loadUrlPreview (): Promise<void> {
    // if a url preview is already being fetched, just form item preview instead so text is updated
    if (this.urlPreviewFetching === this.urlData.url) {
      await this.formatItemPreview();
      return;
    }

    this.loading = true;
    this.urlPreviewFetching = this.urlData.url;

    // First run the URL resolve checking service before continuing.
    // This will resolve shortened URLs such as Google Maps and optionally return the GPS data
    const output = await UrlResolverService.urlResolverGet({ url: this.urlData.url });
    if (output.url !== this.urlPreviewFetching) {
      // remove the shortened url from the editable input
      this.form.editable.text = this.form.editable.text.replace(this.urlPreviewFetching, output.url);
      this.urlData.url = output.url;
      this.urlPreviewFetching = this.urlData.url;
      if (output.additionalInfo.longitude && output.additionalInfo.latitude) {
        await this.setGeoData({
          lng: output.additionalInfo.longitude,
          lat: output.additionalInfo.latitude
        });
      }
    }

    setTimeout(() => this.loading = false, 250);
    this.previewSettings.loading = true;
    this.previewSettings.message = this.$t('item.entry.fetchingPreview') as string;
    // initiate fibanacci values array with padded 1's if not already done
    if (this.fibonacciValues.length === 0) {
      // padded with 3 1's at the start so the first 5 iterations are 1 second each
      this.fibonacciValues = [
        ...[1, 1, 1],
        ...generateFibonacciArrayToX(8)
      ].filter((num) => num !== 0);
    }
    await this.getUrlCache();
  }

  /**
   * Recursive function will query for url cache response, if one is not found will setTimeout to run again using the
   * fibonacci sequence to increase the gaps between calls. First 5 calls are 1 second apart.
   */
  async getUrlCache (i = 0) {
    const url = this.urlData.url;
    if (!url) {
      return;
    }
    // send ajax request to get cache for this url
    const preview = await UrlPreviewService.urlPreviewGet({ url });
    // if response, all good, show that
    if (preview.finished) {
      clearTimeout(this.urlPreviewTimeout);
      if (preview.status === Status.Failed) {
        this.urlPreviewFailed = true;
      }
      this.urlData.hasPreview = true;
      this.previewSettings.loading = false;
      this.previewSettings.backgroundRescrapeInProgress = false;
      this.handleUploadRemove({
        imageIndexRemoved: 0
      });
      await this.formatItemPreview(preview);
    } else {
      // if we have an old cache we can display while a background rescrape is happening put it on screen and let the user know background rescrape is happening but also don't stop trying to get the latest scrape
      if (typeof preview.backgroundRescrape !== 'undefined' && preview.backgroundRescrape) {
        this.previewSettings.loading = false;
        this.previewSettings.backgroundRescrapeInProgress = true;
        await this.formatItemPreview(preview);
      }
      // if on the 5th+ poll update the message to indicate it's going slow
      if (i === 5) {
        this.previewSettings.message = this.$t('item.entry.fetchingPreviewSlow') as string;
      }
      // fibonacci needs extending
      if (i === this.fibonacciValues.length) {
        this.fibonacciValues.push(this.fibonacciValues[i - 2] + this.fibonacciValues[i - 1]);
      }
      // if no response then set a timeout to run again according to the next value in our fibonacci sequence
      this.urlPreviewTimeout = setTimeout(async () => await this.getUrlCache(i + 1), this.fibonacciValues[i] * 1000);
    }
  }

  /**
   * Creates the preview content
   */
  async formatItemPreview (item?): Promise<void> {
    await pause(0);
    let urlCache;
    if (item) {
      item = clone(item);
      urlCache = {
        url: item.url,
        image: item.image,
        meta: item.meta
      };
    } else if (this.urlData.url) {
      urlCache = { ...this.itemPreview.urlCache };
    }
    const editable = clone(this.form.editable);
    this.itemPreview = {
      editable: {
        text: editable.text,
        channel: {
          isDefault: this.isDefault,
          slug: this.selectedChannel.channel?.slug,
          name: this.selectedChannel.name,
          channelType: this.selectedChannel.channel?.channelType
        }
      },
      urlCache,
      userPhotos: this.imagesToUploadPreviews.previews,
      ...this.itemPreviewDefaultData
    };
    // if editing make sure the actor of the item is put into the item preview object
    if (this.isEditingItem) {
      this.itemPreview = {
        ...this.itemPreview,
        ...{
          actor: this.contentToPreload.actor
        }
      };
    }
  }

  hasItemImage (): boolean {
    return !!this.itemPreview?.urlCache?.image?.href;
  }

  removeUrl (): void {
    this.form.editable.text = this.form.editable.text.replace(this.urlData.url, '');
    this.urlData.url = '';
    this.urlData.hasPreview = false;
    this.urlPreviewFailed = false;
    this.urlPreviewFetching = '';
    this.previewSettings.loading = false;
    this.itemPreview = {};
    this.formatItemPreview();
  }

  async handleUploadInput (fileList: any[] | Photo[]): Promise<void> {
    const index = fileList.length - 1;
    let newItem: any = {};
    if (fileList[index].base64String) {
      newItem.preview = fileList[index].base64String;
      newItem.userFriendlyFilename = 'Photo';
      newItem.exifJson = JSON.stringify(fileList[index].exif);
    } else {
      newItem.preview = await fileReaderToBase64Preview(fileList[index]);
      newItem.userFriendlyFilename = this.$options.filters?.truncateText(fileList[index].name, 25);
    }
    this.imagesToUploadPreviews.previews.splice(
        fileList[index],
        1,
        newItem
    );
    this.hasNewUploadedImage = true;
    this.maxImageUploadReachedCalculate();
    this.showUrlPreview = true;
  }

  maxImageUploadReachedCalculate (): void {
    this.maxImageUploadReached = this.imagesToUpload.length >= 1;
  }

  handleUploadRemove (output: MUploadImagePreviewChildOutput): void {
    this.imagesToUpload.splice(output.imageIndexRemoved, 1);

    this.imagesToUploadPreviews.previews.splice(
        output.imageIndexRemoved,
        1,
    );

    this.hasNewUploadedImage = false;
    this.maxImageUploadReachedCalculate();
  }

  shakeTextareaTimeout: any = false;

  shakeTextarea () {
    if (this.urlData.hasPreview) {
      return;
    }
    clearTimeout(this.shakeTextareaTimeout);
    const textarea = document.querySelectorAll('textarea[name="text"]')[0] as HTMLElement;
    const textareaWrapper = textarea.parentElement as HTMLElement;
    textarea.focus();
    textareaWrapper.classList.add('shake-textarea');
    this.shakeTextareaTimeout = setTimeout(() => {
      textareaWrapper.classList.remove('shake-textarea');
    }, 2000);
  }

  storeGeolocationData (data: any) {
    this.geolocationData = data;
    this.geoFormattedAddress = this.$options.filters?.truncateText(data.formattedAddress, 25);

    this.closeMapEntryMolecule();
  }

  removeGeolocationData () {
    this.geolocationData = {
      position: {
        type: 'Point',
        coordinates: [-4.087832161591848, 54.66146490422102]
      }
    };
    this.geoFormattedAddress = '';
  }

  /**
   * Types into the textarea a message explaining what to do, plus a demo URL
   */
  async welcomeTourInsertDemoUrlText () {
    this.form.editable.text = '';
    const demotext = this.$t('vueTour.page.dashboard.itemEntry_b') as string;
    const demo = demotext.split('');
    for (let i = 0; i < demo.length; i++) {
      this.form.editable.text += demo[i];
      await pause(30);
    }
    // add dots a bit slower
    for (let i = 0; i < 3; i++) {
      this.form.editable.text += '.';
      await pause(300);
    }
    // then paste url
    await pause(300);
    this.form.editable.text += ' ' + this.demoUrl;
    // emit event back to tour to let it know to continue
    EventBus.$emit(EventBusEvents.TOUR_DEMO_URL_INPUT_FINISHED);
  }

  welcomeTourSubmitForm () {
    //just in case they messed with the content, ensure the link is still there, we don't want the tour to stop, then submit
    if (!this.canSubmit()) {
      this.form.editable.text = this.demoUrl;
    }
    this.addByEventBus = true;
    this.onSubmit();
  }

  closeMapEntryMolecule () {
    this.showMapSelect = false;
  }

  closeItemPreview () {
    if (this.isMobileView) {
      this.showUrlPreview = false;
    }
  }

  // don't update the store if we are editing an item
  updateItemEntry (force = false) {
    if (!this.isEditingItem || force) {
      ItemEntryStore.UPDATE_ITEM_ENTRY(this.form);
    }
  }

  closeModal () {
    ItemEntryStore.CLEAR_ITEM_ENTRY_LESS_CHANNEL();
    clearTimeout(this.urlPreviewTimeout);
    this.$emit('close-modal');
  }

  @Watch('selectedChannel', { deep: true, immediate: true })
  handleSelectedChannelChange (changed: ItemEntryStoreLastChannelAdded) {
    if (changed.channel?.slug !== this.form.editable.channel?.slug) {
      this.form.editable.channel = {
        isDefault: this.isDefault,
        name: changed.channel?.name as string,
        slug: changed.channel?.slug as string,
        channelType: changed.channel?.channelType as ChannelType,
        customFields: changed.customFields ? changed.customFields.map((field) => {
          return {
            ...field,
            ...{
              value: ''
            }
          };
        }) : []
      };
      this.updateItemEntry();
    }
    this.formatItemPreview();
  }

  recommendationSent () {
    this.closeModal();
  }
}
