export interface HashtagSearchGetQuery {
  /**
   * The hashtag without the preceding hash
   */
  q: string;
  /**
   * The source of the tag
   */
  source: Source;
}

/**
 * The source of the tag
 */
export enum Source {
  Item = 'item',
}
