import HttpService from '@/services/HttpService';

import { GenericCount } from './interfaces/GenericCount';
import { Items } from './interfaces/Items';
import { ItemsByGeolocationByMapViewGetQuery } from './interfaces/ItemsByGeolocationByMapViewGetQuery';
import { ItemsByGeolocationGetQuery } from './interfaces/ItemsByGeolocationGetQuery';
import { ItemsCountByChannelSlugGetPath } from './interfaces/ItemsCountByChannelSlugGetPath';
import { ItemsGetQuery } from './interfaces/ItemsGetQuery';
import { ItemsOnShoppingListUsernameGetPath } from './interfaces/ItemsOnShoppingListUsernameGetPath';
import { ItemsOnShoppingListUsernameGetQuery } from './interfaces/ItemsOnShoppingListUsernameGetQuery';
import { ItemsPinnedGetQuery } from './interfaces/ItemsPinnedGetQuery';
import { ItemsWithWeightsInChannelGetQuery } from './interfaces/ItemsWithWeightsInChannelGetQuery';

export default class ItemsService {
  public static basePath = '/__/ms-item/';

  /**
   *  Operation ID: itemsByGeolocationByMapViewGet
   *  Summary: Fetch items
   *  Description: Fetches a list of items based on geolocation of the map as the user is currently looking at it
   */
  public static itemsByGeolocationByMapViewGet(
    query: ItemsByGeolocationByMapViewGetQuery
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items/by-geolocation/by-map-view',
      qs: query,
    });
  }

  /**
   *  Operation ID: itemsByGeolocationGet
   *  Summary: Fetch items
   *  Description: Fetches a list of items based on geolocation of the most recent item that exists with geolocation
   */
  public static itemsByGeolocationGet(
    query: ItemsByGeolocationGetQuery
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items/by-geolocation',
      qs: query,
    });
  }

  /**
   *  Operation ID: itemsCountByChannelSlugGet
   *  Summary: Count items in channel
   *  Description: If you are authenticated and have permission to view the channel,  this route will return the count of the items related to the channel

   */
  public static itemsCountByChannelSlugGet(
    pathParams: ItemsCountByChannelSlugGetPath
  ): Promise<GenericCount> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items/count/by/channel/:slug',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: itemsGet
   *  Summary: Fetch items
   *  Description: Fetches a list of items based on search criteria
   */
  public static itemsGet(query: ItemsGetQuery): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items',
      qs: query,
    });
  }

  /**
   *  Operation ID: itemsOnShoppingListUsernameGet
   *  Summary: Fetch items on a shopping list of a given username
   *  Description: The API will return a 403 is the user is not permitted to view the shopping list requested
   */
  public static itemsOnShoppingListUsernameGet(
    pathParams: ItemsOnShoppingListUsernameGetPath,
    query: ItemsOnShoppingListUsernameGetQuery
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items/on-shopping-list/:username',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: itemsPinnedGet
   *  Summary: Fetch pinned items
   *  Description: Fetches a list of items that have been pinned based on search criteria
   */
  public static itemsPinnedGet(query: ItemsPinnedGetQuery): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items/pinned',
      qs: query,
    });
  }

  /**
   *  Operation ID: itemsWithWeightsInChannelGet
   *  Summary: Fetch 1 random item
   *  Description: The item will only be an item the user can see and based on the query params
   */
  public static itemsWithWeightsInChannelGet(
    query: ItemsWithWeightsInChannelGetQuery
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ItemsService.basePath + 'items/with-weights-in-channel',
      qs: query,
    });
  }
}
