
import { Component, Prop, Vue } from 'vue-property-decorator';
import AMenuUpSvg from '@/components/atoms/icon/svg/AMenuUpSvg.vue';
import AMenuLeftSvg from '@/components/atoms/icon/svg/AMenuLeftSvg.vue';
import AMenuDownSvg from '@/components/atoms/icon/svg/AMenuDownSvg.vue';
import AMenuRightSvg from '@/components/atoms/icon/svg/AMenuRightSvg.vue';
import { KeyboardNavigationKeys } from '@/enums/KeyboardNavigationKeys';

@Component({
  components: { AMenuRightSvg, AMenuDownSvg, AMenuLeftSvg, AMenuUpSvg }
})
export default class MKeyboardDirectionPad extends Vue {
  @Prop({ required: false, default: false })
  initKeyboardEventListener!: boolean;

  iconSize: number = 1.25;

  pressedKeys: {
    ArrowUp: boolean,
    ArrowLeft: boolean,
    ArrowDown: boolean,
    ArrowRight: boolean,
  } = {
    ArrowUp: false,
    ArrowLeft: false,
    ArrowDown: false,
    ArrowRight: false
  };
  ArrowKeys: KeyboardNavigationKeys[] = [KeyboardNavigationKeys.ArrowUp, KeyboardNavigationKeys.ArrowLeft, KeyboardNavigationKeys.ArrowDown, KeyboardNavigationKeys.ArrowRight];

  created () {
    if (this.initKeyboardEventListener) {
      this.eventsBind();
    }
  }

  beforeDestroy () {
    if (this.initKeyboardEventListener) {
      this.eventsUnbind();
    }
  }

  eventsBind () {
    window.addEventListener('keydown', this.keyboardNavigationDownListener);
    window.addEventListener('keyup', this.keyboardNavigationUpListener);
  }

  eventsUnbind () {
    window.removeEventListener('keydown', this.keyboardNavigationDownListener);
    window.removeEventListener('keyup', this.keyboardNavigationUpListener);
  }

  keyboardNavigationDownListener (e) {
    if (this.ArrowKeys.includes(e.key)) {
      this.pressedKeys[e.key] = true;
      this.$emit('keydown', e.key);
    }
  }

  keyboardNavigationUpListener (e) {
    if (this.ArrowKeys.includes(e.key)) {
      this.pressedKeys[e.key] = false;
      // emit keyup, and if no other key is currently being pressed emit solo keyup
      this.$emit('keyup', e.key);
      if (!Object.values(this.pressedKeys).includes(true)) {
        this.$emit('solo-keyup', e.key);
      }
    }
  }
}
