import { i18n } from '@/plugins/i18n';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(calendar);
dayjs.extend(localizedFormat);

// Supported locales: https://github.com/iamkun/dayjs/tree/dev/src/locale
const map = {
  enGB: 'en-gb',
  deDE: 'de'
};

export default async (date: Date, locale?: string): Promise<string> => {
  const userLocale = map[typeof locale === 'undefined' ? 'enGB' : locale];
  if (userLocale === 'de') {
    import ('dayjs/locale/de');
  } else {
    import ('dayjs/locale/en-gb');
  }
  const time: any = i18n.t('datetime.dict');
  return dayjs(date).locale(userLocale).calendar(null, {
    lastDay: `[${time.yesterday} ${time.at}] LT`,
    sameDay: `[${time.today} ${time.at}] LT`,
    nextDay: `[${time.tomorrow} ${time.at}] LT`,
    lastWeek: `[${time.last}] dddd [${time.at}] LT`,
    nextWeek: `dddd [${time.at}] LT`,
    sameElse: 'LLLL'
  });
};
