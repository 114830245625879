export default (param: string, opts?: { removeAndReplaceUrl?: boolean, setValue?: string }) => {
  try {
    const parsedHash = new URLSearchParams(
      window.location.hash.substring(1) // skip the first char (#)
    );
    const hashValue = parsedHash.get(param);

    if (opts) {
      if (opts.removeAndReplaceUrl && hashValue) {
        parsedHash.delete(param);
        window.location
          .replace(
            window.location.href.replace(window.location.hash, '#' + parsedHash.toString())
              .replace(window.location.origin, '')
          );
      } else if (opts.setValue) {
        if (hashValue) {
          parsedHash.set(param, opts.setValue);
        } else {
          parsedHash.append(param, opts.setValue);
        }
        window.location.hash = '#' + parsedHash.toString();
      }
    }

    return hashValue;
  } catch (e) {
    return null;
  }
};
