
import { Component, Prop, Vue } from 'vue-property-decorator';
import AFireIconSvg from '@/components/atoms/icon/svg/AFireIconSvg.vue';

@Component({
  components: { AFireIconSvg }
})
export default class ADecisionMakerIconSvg extends Vue {
  @Prop({ default: 1 })
  size!: number;
}
