import { render, staticRenderFns } from "./AStrikethroughIcon.vue?vue&type=template&id=1e6d62ee&"
import script from "./AStrikethroughIcon.vue?vue&type=script&lang=ts&"
export * from "./AStrikethroughIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports