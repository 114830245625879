/**
 * Return the total number of comments on an item.
 * Include replies, but exclude all comments/replies awaiting moderation or banned.
 */
import { Item } from '@/api/ms-item/services/interfaces/Item';
import { User } from '@/api/ms-authentication/services/interfaces';
import doesThisPassModeration from '@/utils/doesThisPassModeration';

// eslint-disable-next-line max-lines-per-function
export default (item: Item, currentUser: User): number => {
  let count = 0;

  // Increases the count if no moderation object present, or if passModeration returns true
  const increaseCount = (comment) => {
    // No moderation object, add
    if (!comment.moderation) {
      ++count;
    } else {
      // Check moderation status
      if (doesThisPassModeration(comment.moderation, comment.actor, currentUser)) {
        ++count;
      }
    }
  };

  // Comments
  for (let i = 0; i < item.comments.length; i++) {
    increaseCount(item.comments[i]);
    // put replies into const to remove "might be undefined" warning
    const replies = item.comments[i].replies;
    if (replies) {
      // Comment replies
      for (let j = 0; j < replies.length; j++) {
        increaseCount(replies[j]);
      }
    }
  }

  return count;
};