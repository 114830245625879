import HttpService from '@/services/HttpService';

import { Currenciess } from './interfaces/Currenciess';
import { GenericResponseAnyObject } from './interfaces/GenericResponseAnyObject';

export default class CurrenciesService {
  public static basePath = '/__/ms-static-api-data/';

  /**
   *  Operation ID: currenciesCountriesGet
   *  Summary: Get countries, from currencies
   *  Description: Returns an object of each country's currency in the world, 2 letter country code
   */
  public static currenciesCountriesGet(): Promise<GenericResponseAnyObject> {
    return HttpService.sendRequest({
      method: 'GET',
      path: CurrenciesService.basePath + 'currencies/countries',
    });
  }

  /**
   *  Operation ID: currenciesUniqueGet
   *  Summary: Get unique, from currencies
   *  Description: Returns an array of unique currencies in the world
   */
  public static currenciesUniqueGet(): Promise<Currenciess> {
    return HttpService.sendRequest({
      method: 'GET',
      path: CurrenciesService.basePath + 'currencies/unique',
    });
  }
}
