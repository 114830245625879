import { parser } from 'qs-in-filename';

interface ImageRatioFromFileName {
  yRatio: number;
  originalHeight: number;
}

export default (input: string): ImageRatioFromFileName => {
  const response: ImageRatioFromFileName = {
    yRatio: 1,
    originalHeight: 0
  };
  try {
    const data = parser(input);
    if (data.r) {
      response.yRatio = Number(data.r);
    }
    if (data.oh) {
      response.originalHeight = Number(data.oh);
    }
  } catch (e) {
    console.error(e);
  }
  return response;
};
