
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import A3Dots from '@/components/atoms/A3Dots.vue';
import { getDifferenceBetweenTwoDates } from 'common-utils/time';
import { PriceGotBy } from '@/api/ms-item/services/interfaces/Item';
import { translation } from '@/plugins/i18n/Translation';
import StaticApiDataService from '@/services/StaticApiDataService';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import displayPrice from '@/utils/displayPrice';

@Component({
  components: { A3Dots }
})
export default class AItemPrice extends Vue {
  @Prop({ required: true })
  item!: Item;
  @Prop({ required: false, default: false })
  forceDecimal!: boolean;

  forceStop = false;
  uniqueCurrencies: Currency[] = [];

  async created () {
    setTimeout(() => this.forceStop = true, 60000);
    this.uniqueCurrencies = await StaticApiDataService.currenciesUniqueGet();
  }

  get language () {
    return translation.currentLanguage;
  }

  // eslint-disable-next-line max-lines-per-function
  get price (): { inProgress: boolean, price: string | null } {
    if (this.item.urlCache) {
      if (this.item.urlCache.price && this.item.urlCache.price.updatedAt && this.item.urlCache.price.priceGotBy == PriceGotBy.InProgress) {
        const inProgress = 1000 * 60 > getDifferenceBetweenTwoDates(new Date(this.item.urlCache.price.updatedAt), new Date()).milliseconds;
        return {
          inProgress,
          price: ''
        };
      } else {
        if (this.item.urlCache.price && this.item.urlCache.price.price && this.item.urlCache.price.currency) {
          const { currency, price } = this.item.urlCache.price;
          return {
            inProgress: false,
            price: displayPrice(price, currency, this.language, this.forceDecimal, this.uniqueCurrencies)
          };
        }
      }
    }
    return {
      inProgress: false,
      price: ''
    };
  }
}
