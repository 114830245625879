import { Item } from '@/api/ms-item/services/interfaces';
import shareOrCopy from '@/utils/shareOrCopy';
import config from '@/config';

export enum SharedFrom {
  itemDetail,
  channelPage
}

export interface ShareOrCopy {
  text: string,
  url: string,
  sharedFrom: SharedFrom
}

export default (item: Item, currentFullPath: string): void => {
  const url = config.api.baseUrl + currentFullPath;
  const payload: ShareOrCopy = {
    text: item.editable.text,
    url: url.replace('#', ''),
    sharedFrom: SharedFrom.itemDetail
  };
  if (item.urlCache && item.urlCache.meta) {
    payload.text = item.urlCache.meta.title;
  }
  shareOrCopy(payload);
};
