
import { Component, Prop, Vue } from 'vue-property-decorator';
import ALink from '@/components/atoms/link/ALink.vue';
import { OModalsContainerAppUpdateAvailable } from '@/components/organisms/OModalsContainer.vue';
import { AppType } from '@/api/ms-version-control/services/interfaces/LatestVersionGetQuery';
import config from '@/config';

@Component({
  components: {
    ALink
  }
})
export default class MUpdateAppMajor extends Vue {
  @Prop()
  appVersions!: OModalsContainerAppUpdateAvailable;

  appType = AppType;
  appLinks = config.appLinks;

  get updateLink () {
    return this.appVersions.appType === this.appType.Android ? this.appLinks.android : this.appLinks.apple;
  }
}
