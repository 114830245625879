import HttpService from '@/services/HttpService';

import { ChannelConciergeUniqueCategoriess } from './interfaces/ChannelConciergeUniqueCategoriess';
import { ChannelManages } from './interfaces/ChannelManages';
import { ChannelWithCurrentUserStatus } from './interfaces/ChannelWithCurrentUserStatus';
import { ChannelWithRelationalMetas } from './interfaces/ChannelWithRelationalMetas';
import { Channels } from './interfaces/Channels';
import { ChannelsGetQuery } from './interfaces/ChannelsGetQuery';
import { ChannelsOfConnectionUsernameGetPath } from './interfaces/ChannelsOfConnectionUsernameGetPath';
import { ChannelsOfConnectionUsernameGetQuery } from './interfaces/ChannelsOfConnectionUsernameGetQuery';
import { ChannelsResearchConciergeGetQuery } from './interfaces/ChannelsResearchConciergeGetQuery';
import { ChannelsWithStatusGetQuery } from './interfaces/ChannelsWithStatusGetQuery';

export default class ChannelsService {
  public static basePath = '/__/ms-channel/';

  /**
   *  Operation ID: channelsCurrentUserCanAddToGet
   *  Summary: Get a list of channels the current user can add to
   *  Description:
   */
  public static channelsCurrentUserCanAddToGet(): Promise<Channels> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/current-user-can-add-to',
    });
  }

  /**
   *  Operation ID: channelsGet
   *  Summary: Get a list of channels
   *  Description: - SubscriptionStatus.Invited - SubscriptionStatus.InviteAccepted - SubscriptionStatus.JoinRequestSent - SubscriptionStatus.JoinRequestAccepted If the memberOf is false, this will only return channels where invite status is not present or not in the above list Else only channels with member status of the above list

   */
  public static channelsGet(
    query: ChannelsGetQuery
  ): Promise<ChannelWithRelationalMetas> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels',
      qs: query,
    });
  }

  /**
   *  Operation ID: channelsLastAddedToGet
   *  Summary: Fetch the list of last channels added to
   *  Description: Fetch the list of last channels added to for a specific user
   */
  public static channelsLastAddedToGet(): Promise<ChannelWithRelationalMetas> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/last-added-to',
    });
  }

  /**
   *  Operation ID: channelsManageGet
   *  Summary: List of channels this user is a manager of
   *  Description: Gets a list of all the current users channels they have a management level to
   */
  public static channelsManageGet(): Promise<ChannelManages> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/manage',
    });
  }

  /**
   *  Operation ID: channelsOfConnectionUsernameGet
   *  Summary: List of own channels
   *  Description: Gets a list of all the current users channels they own/manage
   */
  public static channelsOfConnectionUsernameGet(
    pathParams: ChannelsOfConnectionUsernameGetPath,
    query: ChannelsOfConnectionUsernameGetQuery
  ): Promise<Channels> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/of-connection/:username',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelsOwnGet
   *  Summary: List of own channels
   *  Description: Gets a list of all the current users channels they own/manage
   */
  public static channelsOwnGet(): Promise<Channels> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/own',
    });
  }

  /**
   *  Operation ID: channelsResearchConciergeGet
   *  Summary: Fetch all the research concierge channels
   *  Description: Excludes channels where current user was removed as the concierge Ordered by estimated completion date based on job creation and urgency

   */
  public static channelsResearchConciergeGet(
    query: ChannelsResearchConciergeGetQuery
  ): Promise<Channels> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/research-concierge',
      qs: query,
    });
  }

  /**
   *  Operation ID: channelsResearchConciergeUniqueCategoriesGet
   *  Summary: Fetch an array of all unique categories in the database
   *  Description:
   */
  public static channelsResearchConciergeUniqueCategoriesGet(): Promise<ChannelConciergeUniqueCategoriess> {
    return HttpService.sendRequest({
      method: 'GET',
      path:
        ChannelsService.basePath +
        'channels/research-concierge/unique-categories',
    });
  }

  /**
   *  Operation ID: channelsWithStatusGet
   *  Summary: List all channels with current user status
   *  Description: Gets a list of all channels the current user is related to by a given status(s) The order of the channels and subscriptions array will alway be aligned together by channel slug

   */
  public static channelsWithStatusGet(
    query: ChannelsWithStatusGetQuery
  ): Promise<ChannelWithCurrentUserStatus> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/with-status',
      qs: query,
    });
  }
}
