
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChannelType } from '@/api/ms-channel/services/interfaces/Channel';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import SettingService from '@/api/ms-app-settings/services/SettingService';
import config from '@/config';

@Component({
  components: { MRadioCards }
})
export default class MChannelBasicsChannelType extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ChannelType;
  @Prop({ required: false, default: false })
  recalculateAccordion!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({required: false, default: false})
  editing!: boolean;

  value: ChannelType = ChannelType.General;
  channelTypes = ChannelType;
  loading = false;
  researchConciergeDisabled = false;

  created () {
    this.value = this.activeValue;
    this.getResearchConciergeStatus();
  }

  // get state of research concierge
  async getResearchConciergeStatus () {
    // no need to run the check if it's already disabled or editing which cannot set to RC anyway
    if( !this.disabled && !this.editing ){
      this.loading = true;
      const setting = await SettingService.settingGet({
        name: config.settings.researchConcierge
      });
      this.researchConciergeDisabled = !setting.state.active;
      this.loading = false;
    }
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
