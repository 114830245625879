
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { UserIcon } from 'vue-feather-icons';

@Component({
  components: {
    UserIcon
  }
})
export default class ARouterLinkProfile extends Vue {
  @Prop({ required: true })
  username!: string;

  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_PROFILE;
}
