
import { Component, Vue } from 'vue-property-decorator';
import { ChevronDownIcon } from 'vue-feather-icons';
import { RouteNames } from '@/router/RouteNames';
import ALink from '@/components/atoms/link/ALink.vue';
import MHeaderLoginTrigger from '@/components/molecules/MHeaderLoginTrigger.vue';
import config from '@/config';
import { AuthenticationStore } from '@/store';
import MLoginButtonTrigger from '@/components/molecules/buttons/MLoginButtonTrigger.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { translation } from '@/plugins/i18n/Translation';
import { intersperse } from 'common-utils/array';

@Component({
  components: {
    MLoginButtonTrigger,
    MHeaderLoginTrigger,
    ALink,
    ChevronDownIcon
  }
})
export default class MHeaderSeoSidebarContent extends Vue {
  burgerHover = false;

  accountHref = config.accountsHref;

  routeNames = RouteNames;

  footerLinks: {
    name?: RouteNames,
    link?: string,
    textKey: string,
    activeAlsoWith: RouteNames[]
  }[] = [{
    name: this.isAuthenticated ? RouteNames.ROUTE_INFO_WHAT_IS_AUTHED : RouteNames.ROUTE_INFO_WHAT_IS,
    activeAlsoWith: [RouteNames.ROUTE_DASHBOARD],
    textKey: 'footer.forPeople'
  }, {
    name: RouteNames.ROUTE_INFO_BUSINESS,
    activeAlsoWith: [],
    textKey: 'footer.forBusiness'
  }, {
    name: RouteNames.ROUTE_INFO_CONTACT,
    activeAlsoWith: [],
    textKey: 'footer.contact'
  }, {
    link: 'https://blog.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.blog'
  }, {
    name: RouteNames.ROUTE_INFO_CAREERS,
    activeAlsoWith: [],
    textKey: 'footer.careers'
  }, {
    link: 'https://help.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.help'
  }];

  localeToISOA2 = translation.localeToISOA2;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get currentLanguageISOAlpha2 () {
    return translation.currentLanguageISOAlpha2;
  }

  get languages () {
    return intersperse(translation.supportedLanguages, 'DIVIDE');
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  get isBusinessLandingPage () {
    return this.$route.name === RouteNames.ROUTE_INFO_BUSINESS;
  }

  created () {
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.AUTH_LOGIN_CLICKED, 'MHeaderSeoSidebarContent', () => {
      this.close();
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.AUTH_LOGIN_CLICKED, 'MHeaderSeoSidebarContent');
  }

  close () {
    this.$emit('close');
    this.burgerHover = true;
  }

  accountsClick () {
    window.location.href = this.accountHref;
  }
}
