/**
 * Default element to scroll to is the vue collection, i.e. the top of the page.
 * scrollTo doesn't work great with deeply nested elements. Pass 3rd parameter as true to use scrollIntoView instead.
 *
 * @param scrollBehavior
 * @param querySelectorOrElement Either a string to use with document.querySelector or the HTMLElement itself
 * @param useScrollIntoView
 */
export default (scrollBehavior: ScrollBehavior = 'smooth', querySelectorOrElement: string | HTMLElement = '.vue-virtual-collection', useScrollIntoView: boolean = false): boolean => {
  const scrollObject = {
    behavior: scrollBehavior,
    top: 0
  };
  const $el = typeof querySelectorOrElement === 'string' ? document.querySelector(querySelectorOrElement) : querySelectorOrElement;
  if (!$el) {
    return false;
  } else {
    setTimeout(() => {
      if ($el) {
        if( useScrollIntoView ){
          $el.scrollIntoView(scrollObject);
        } else {
          $el.scrollTo(scrollObject);
        }
      }
    }, 100);
    window.scrollTo(scrollObject);
    return true;
  }
};
