
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Datum as Notification, Type } from '@/api/ms-notification/services/interfaces/Notifications';
import { RouteNames } from '@/router/RouteNames';
import payloadArrayToObject from '@/utils/payloadArrayToObject';
import { AuthenticationStore, NotificationsStore } from '@/store';
import { format } from 'timeago.js';

import ANotificationIcon from '@/components/atoms/icon/ANotificationIcon.vue';
import { Location } from 'vue-router/types/router';
import VueI18n from 'vue-i18n';
import { Key, State } from '@/api/ms-notification/services/interfaces/Notification';
import ARouterLinkChannelView from '@/components/atoms/link/ARouterLinkChannelView.vue';
import { ChannelOrPeopleContainerSelectedTab } from '@/enums/ChannelOrPeopleContainerSelectedTab';
import notificationPayloadToObj from '@/utils/notificationPayloadToObj';
import ellipsisString from '@/utils/ellipsisString';
import { NotificationSetting } from '@/api/ms-channel/services/interfaces/UserSubscriptions';
import { itemDetailTabs } from '@/components/organisms/OItemDetailTabs.vue';
import ALink from '@/components/atoms/link/ALink.vue';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import { translation } from '@/plugins/i18n/Translation';
import StaticApiDataService from '@/services/StaticApiDataService';
import displayPrice from '@/utils/displayPrice';
import { AutoOpen as ChannelAutoOpen } from '@/components/templates/TChannel.vue';
import TranslateResult = VueI18n.TranslateResult;
import { AChannelViewEnums } from '@/store/modules/interfaces/ChannelViewModule';
import { SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';

// todo - This seems to only be used on MNotificationPageList - move there...
export const notificationTypes = {
  [Type.AuthConnectionRequestAccepted]: 'Connection Request Accepted',
  [Type.AuthConnectionRequestIncoming]: 'Connection Request Incoming',
  [Type.B2CMessage]: 'Business Message',
  [Type.ChannelEventDate]: 'Event Happening Now',
  [Type.ChannelInvited]: 'Channel Invite',
  [Type.ChannelInviteAccepted]: 'Channel Invite Accepted',
  [Type.ChannelJoinRequested]: 'Channel Join Requested',
  [Type.ChannelJoinAccepted]: 'Channel Join Accepted',
  [Type.ItemComment]: 'Comment',
  [Type.ItemCommentLike]: 'Like Comment',
  [Type.ItemCommentOnComment]: 'Comment on Comment',
  [Type.ItemLike]: 'Like',
  [Type.ItemReminder]: 'Item Reminder',
  [Type.ItemCommentMention]: 'Mentioned on a Comment',
  [Type.ChatChannelMention]: 'Mentioned on a Chat Message'
};

@Component({
  components: {
    ALink,
    ARouterLinkChannelView,
    ANotificationIcon
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
  },
})
export default class MNotificationItem extends Vue {
  @Prop()
  notification!: Notification;

  @Prop({ default: false })
  asLink!: boolean;

  @Prop({ default: false })
  isBig!: boolean;

  channelViewLinks: { name: string, slug: string }[] = [];
  notificationStates = { ...State };
  notificationTypes = Type;
  notificationStructuredPayloadData: {
    [Key: string]: string
  } = {};
  loading: boolean = false;
  uniqueCurrencies: Currency[] = [];

  get isUnreadMessage (): boolean {
    return this.notification.interactionStates[this.notification.interactionStates.length - 1].state !== this.notificationStates.Interacted;
  }

  get singularLinkNotification (): boolean {
    // later there will be many multiple link notification types, for now; KISS
    return ![Type.ChannelUnreadChat, Type.ItemDeletedByOther].includes(this.notification.type);
  }

  // to create the singular link look and feel, but for bespoke formatting of the html
  get singularLinkBespokeFormat (): boolean {
    return [Type.ItemReminder, Type.ItemRecommendationRejected, Type.WatcherEnded, Type.WatcherItemNoLongerAvailable, Type.WatcherPriceChanged, Type.WatcherPriceNoLongerAvailable].includes(this.notification.type);
  }

  get title (): TranslateResult {
    const translationParams = this.setTranslationParams(this.notification);
    return this.$t(`notificationHeaderTitle.${this.notification.type}`, translationParams);
  }

  get language () {
    return translation.currentLanguage;
  }

  get description (): TranslateResult {
    const notification = this.notification;
    const translationParams = this.setTranslationParams(notification);
    this.structurePayloadData(notification);
    if (notification.type === Type.ItemReminder && translationParams.additionalText.length === 0) {
      return this.$t(`notificationHeaderMsg.${notification.type}Backup`);
    } else if (notification.type === Type.WatcherPriceChanged && translationParams.oldPrice.length === 0) {
      return this.$t(`notificationHeaderMsg.${notification.type}WithoutFrom`, translationParams);
    } else if (notification.type === Type.ItemRecommendationAccepted && translationParams.itemNameText.length === 0) {
      return this.$t(`notificationHeaderMsg.${notification.type}NoText`, translationParams);
    } else if (notification.type === Type.ItemRecommendationRejected) {
      if(translationParams.additionalText.length > 0) {
        if(translationParams.itemNameText.length > 0) {
          return this.$t(`notificationHeaderMsg.${notification.type}WithMessage`, translationParams);
        } else {
          return this.$t(`notificationHeaderMsg.${notification.type}WithMessageNoItemText`, translationParams);
        }
      } else if (translationParams.additionalText.length === 0 && translationParams.itemNameText.length === 0) {
        return this.$t(`notificationHeaderMsg.${notification.type}NoTextAnywhere`, translationParams);
      }
    }
    return notification.type ? this.$t(`notificationHeaderMsg.${notification.type}`, translationParams) : this.$t('notificationHeaderMsg.unknown');
  }

  setTranslationParams (notification) {
    const translationParams: any = {
      ...notificationPayloadToObj(notification.payload),
      name: ellipsisString(notification.initiator.firstName as string, 20)
    };
    switch (notification.type) {
      case Type.ChannelEventNotification:
        translationParams.timeTill = this.setEventTimeTillString(notification.payload?.variables);
        break;

      case Type.B2CMessage: {
        const interactionType = notification.payload?.variables.find((type) => type.key === 'itemInteractionType');
        translationParams.interactionString = this.$t(`notificationParamStrings.${interactionType.value}`) as string;
        break;
      }

      case Type.ItemEditedByOther:
      case Type.ItemDeletedByOther: {
        const managerType = notification.payload?.variables.find((type) => type.key === 'itemManagerType');
        translationParams.role = this.$t(`notificationParamStrings.${managerType.value}`) as string;
        break;
      }

      case Type.WatcherPriceChanged: {
        const priceChangeData: any = JSON.parse(notification.payload?.variables.find((type) => type.key === 'priceChangeData').value);
        let oldPrice = '';
        if (typeof priceChangeData.oldPrice === 'number' && priceChangeData.oldCurrency && priceChangeData.oldCurrency.length) {
          // oldPrice = `${priceChangeData.oldPrice} ${priceChangeData.oldCurrency}`;
          oldPrice = displayPrice(priceChangeData.oldPrice, priceChangeData.oldCurrency, this.language, false, this.uniqueCurrencies);
        }
        // const newPrice = `${priceChangeData.newPrice} ${priceChangeData.newCurrency}`;
        const newPrice = displayPrice(priceChangeData.newPrice, priceChangeData.newCurrency, this.language, false, this.uniqueCurrencies);
        translationParams.oldPrice = oldPrice;
        translationParams.newPrice = newPrice;
      }
    }
    return translationParams;
  }

  // Structure payload data for non-iterable access in the vue
  structurePayloadData (notification) {
    switch (notification.type) {
      case Type.ItemDeletedByOther: {
        const text = notification.payload?.variables.find((type) => type.key === 'additionalText');
        const url = notification.payload?.variables.find((type) => type.key === 'itemUrl');
        this.notificationStructuredPayloadData = {
          itemText: text ? text.value : '',
          itemUrl: url ? url.value : ''
        };
      }
    }
  }

  // eslint-disable-next-line max-lines-per-function
  setEventTimeTillString (payloadVariables): string {
    const reminderType = payloadVariables.filter((load) => {
      return load.key === Key.ChannelEventNotification;
    });
    // Catch all will say "... soon"
    let timeTill = this.$t('datetime.dict.soon') as string;
    if (reminderType) {
      const date = payloadVariables.filter((load) => {
        return load.key === Key.ChannelEventDate;
      });
      let at = '';
      // For events just about to happen we also add the time to the notification
      if (date) {
        const parsedDate = JSON.parse(date[0].value);
        const dateObj = new Date(parsedDate.date);
        at += this.$t('dict.at') as string;
        at += ' ' + String(dateObj.getHours()).padStart(2, '0');
        at += ':' + String(dateObj.getMinutes()).padStart(2, '0');
      }
      switch (reminderType[0].value) {
        case NotificationSetting.ThreeHoursBefore:
          timeTill = this.$t('dict.in') as string;
          timeTill += ' ' + this.$t('datetime.numbers.three') as string;
          timeTill += ' ' + this.$t('datetime.dict.hours') as string;
          timeTill += ' ' + at;
          break;
        case NotificationSetting.OneDayBefore:
          timeTill = this.$t('datetime.dict.tomorrow') as string;
          timeTill += ' ' + at;
          break;
        case NotificationSetting.OneWeekBefore:
          timeTill = this.$t('dict.in') as string;
          timeTill += ' ' + this.$t('datetime.numbers.one') as string;
          timeTill += ' ' + this.$t('datetime.dict.week') as string;
          break;
        case NotificationSetting.TwoWeeksBefore:
          timeTill = this.$t('dict.in') as string;
          timeTill += ' ' + this.$t('datetime.numbers.two') as string;
          timeTill += ' ' + this.$t('datetime.dict.weeks') as string;
          break;
      }
    }
    return timeTill;
  }

  getNotificationType (type) {
    return notificationTypes[type];
  }

  async created () {
    if (this.notification.type === Type.ChannelUnreadChat) {
      this.channelViewLinks = JSON.parse(this.notification.payload?.variables[0].value as string);
    }
    this.uniqueCurrencies = await StaticApiDataService.currenciesUniqueGet();
  }

  // eslint-disable-next-line max-lines-per-function
  routerLinkParams (): Location {
    const payloadObject = payloadArrayToObject(this.notification.payload);
    const username = AuthenticationStore.currentUser.username;

    if ([Type.AuthConnectionRequestAccepted, Type.AuthConnectionRequestIncoming].includes(this.notification.type)) {
      // DASHBOARD - PEOPLE TAB
      const stab = this.notification.type === Type.AuthConnectionRequestIncoming ?
          ChannelOrPeopleContainerSelectedTab.invites :
          ChannelOrPeopleContainerSelectedTab.yours;
      return {
        name: RouteNames.ROUTE_DASHBOARD,
        params: { username },
        query: {
          tab: 'people',
          stab
        }
      };
    } else if ([Type.ChannelInviteAccepted, Type.ChannelJoinRequested].includes(this.notification.type)) {
      // EDIT CHANNEL
      return {
        name: RouteNames.ROUTE_CHANNEL_EDIT,
        params: {
          channelSlug: payloadObject.channelSlug,
          username,
          hash: '#invitations'
        }
      };
    } else if ([Type.ChannelJoinAccepted].includes(this.notification.type)) {
      // DASHBOARD - CHANNELS TAB, YOURS
      return {
        name: RouteNames.ROUTE_DASHBOARD,
        query: {
          tab: 'channels',
          stab: ChannelOrPeopleContainerSelectedTab.yours
        }
      };
    } else if ([Type.ChannelInvited].includes(this.notification.type)) {
      // DASHBOARD - CHANNELS TAB, INVITATIONS
      return {
        name: RouteNames.ROUTE_DASHBOARD,
        query: {
          tab: 'channels',
          stab: ChannelOrPeopleContainerSelectedTab.invites
        }
      };
    } else if ([Type.ChatChannelMention, Type.ChannelCreatedNewUserAuto, Type.ChannelJoinPublic, Type.ChannelNotificationItemAdded, Type.ChannelEventDate, Type.ChannelEmailInviteAccepted, Type.ChannelEventNotification, Type.ConciergeJobAssignedConcierge, Type.ConciergeJobAssignedOwner, Type.ConciergeJobFinishedConcierge, Type.ConciergeJobFinishedOwner, Type.ConciergeJobItemPosted].includes(this.notification.type)) {
      // CHANNEL PAGE
      const parts = payloadObject.channelSlug.split('@');
      return {
        name: RouteNames.ROUTE_CHANNEL_VIEW,
        params: {
          channelSlug0: parts[0],
          channelSlug1: parts[1]
        }
      };
    } else if([Type.ChatChannelMention, Type.ConciergeJobAssignedConcierge, Type.ConciergeJobAssignedOwner, Type.ConciergeJobFinishedConcierge, Type.ConciergeJobFinishedOwner].includes(this.notification.type)) {
      // CHANNEL PAGE + CHAT OPEN
      const parts = payloadObject.channelSlug.split('@');
      return {
        name: RouteNames.ROUTE_CHANNEL_VIEW,
        params: {
          channelSlug0: parts[0],
          channelSlug1: parts[1]
        },
        query: {
          chat: 'open' // todo - flip so it is open=chat
        }
      };
    } else if([Type.ChannelSwipeNudge].includes(this.notification.type)) {
      // CHANNEL PAGE + DECISION MAKER OPEN
      const parts = payloadObject.channelSlug.split('@');
      return {
        name: RouteNames.ROUTE_CHANNEL_VIEW,
        params: {
          channelSlug0: parts[0],
          channelSlug1: parts[1]
        },
        query: {
          open: ChannelAutoOpen.SwipeScore
        }
      };
    } else if ([Type.ChannelSwipeCompletedOwnerManager].includes(this.notification.type)) {
      // CHANNEL PAGE + TABLE VIEW
      const parts = payloadObject.channelSlug.split('@');
      let query:any = undefined;
      // channel swipe complete, set sort
      if( this.notification.type === Type.ChannelSwipeCompletedOwnerManager ){
        query = {
          sortBy: SortBy.SwipeScore,
          sortDir: SortDir.Desc,
        };
      }
      return {
        name: RouteNames.ROUTE_CHANNEL_VIEW,
        params: {
          channelSlug0: parts[0],
          channelSlug1: parts[1],
          tab: AChannelViewEnums.table
        },
        query: query
      };
    } else if ([Type.ChannelUnreadChat].includes(this.notification.type)) {
      // NOTIFICATION DETAIL PAGE
      return {
        name: RouteNames.ROUTE_NOTIFICATIONS_ID,
        params: {
          notificationId: this.notification._id as string,
        }
      };
    } else if ([Type.B2CMessage].includes(this.notification.type)) {
      // ITEM DETAIL - B2C MESSAGES TAB
      return {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW_LIKES,
        params: {
          uniqueItemName: payloadObject.itemUniqueName
        },
        query: {
          tab: itemDetailTabs.b2cMessages
        }
      };
    } else if ([Type.ItemLike].includes(this.notification.type)) {
      // ITEM DETAIL - LIKES TAB
      return {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW_LIKES,
        params: {
          uniqueItemName: payloadObject.itemUniqueName
        }
      };
    } else if ([Type.ItemComment, Type.ItemCommentLike, Type.ItemCommentReplyLiked, Type.ItemCommentOnComment, Type.ItemCommentReplied, Type.ItemCommentMention].includes(this.notification.type)) {
      // ITEM DETAIL - COMMENTS TAB
      return {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW_COMMENTS,
        params: {
          uniqueItemName: payloadObject.itemUniqueName
        }
      };
    } else if ([Type.ItemEditedByOther, Type.ItemReminder, Type.WatcherEnded, Type.WatcherItemNoLongerAvailable, Type.WatcherPriceChanged, Type.WatcherPriceNoLongerAvailable, Type.ItemRecommendationAccepted].includes(this.notification.type)) {
      // ITEM DETAIL
      return {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW,
        params: {
          uniqueItemName: payloadObject.itemUniqueName
        }
      };
    } else if ([Type.AuthInvitationAcceptedInvitee, Type.AuthInvitationAcceptedInvitor].includes(this.notification.type)) {
      // PROFILE PAGE - OTHER PERSON
      return {
        name: RouteNames.ROUTE_PROFILE,
        params: {
          username: this.notification.initiator.username
        }
      };
    } else if ([Type.ItemRecommendationNew].includes(this.notification.type)) {
      // ITEM RECOMMENDATION DETAIL PAGE
      return {
        name: RouteNames.ROUTE_ITEM_RECOMMENDATION_DETAIL_VIEW,
        params: {
          uniqueItemName: payloadObject.itemUniqueName
        }
      };
    }
    // catch-all goes to notifications page
    return {
      name: RouteNames.ROUTE_NOTIFICATIONS
    };
  }

  patch () {
    return NotificationsStore.patchNotifications({ notifications: this.notification, newState: State.Interacted });
  }

  async navigate () {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const redirect = this.routerLinkParams();

    this.patch().catch(console.error);

    if (this.$route.name === redirect.name) {
      // this is a filthy hack - the vue router will not reload the current page and then have vue update the view.
      // This hack routes to a generic page, then after this has happened the real redirect can happen
      // It happens on most devices too fast to be noticed by the human eye, and in addition does not do a window
      // redirect which breaks the mobile apps.
      await this.$router.push({
        name: this.$route.name === RouteNames.ROUTE_NOTIFICATIONS ? RouteNames.ROUTE_DASHBOARD : RouteNames.ROUTE_NOTIFICATIONS
      });
    }

    const obj = redirect?.params?.hash ? { ...redirect, hash: redirect.params.hash } : redirect;
    await this.$router.push(obj);
    this.loading = false;
  }
}
