export enum StoreNames {
  AUTHENTICATION_STORE = 'authentication',
  CHANNEL_STORE = 'channel',
  CHANNEL_VIEW_STORE = 'channelView',
  CHANNELS_STORE = 'channels',
  ITEM_ENTRY_STORE = 'itemEntry',
  ITEM_DETAIL_STORE = 'itemDetailStore',
  NOTIFICATIONS_STORE = 'notifications',
  ITEMS_STORE = 'items',
  LIFFERY_TOUR_STORE = 'lifferyTourStore',
  NAVIGATION_STORE = 'navi',
  PEOPLE_STORE = 'people',
  PINNED_ITEMS_STORE = 'pinnedItems',
  RECOMMENDATION_DETAIL_STORE = 'recommendationDetailStore',
  SEARCH_SETTINGS_STORE = 'search_settings_store',
  SHOPPING_LIST_STORE = 'shopping_list_store',
  WATCHERS_STORE = 'watchers_store',
}
