/**
 * A very simple naming convention.
 * EV stands for event following by the domain it relates.
 * General rule of thumb that the event bus is not expected
 * to send data in the payload. It is merely is notification
 * of an event occurring.
 */
export enum EventBusEvents {
  APP_EVENT_UPDATE_MINOR = 'APP_EVENT_UPDATE_MINOR',
  APP_EVENT_UPDATE_MAJOR = 'APP_EVENT_UPDATE_MAJOR',
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_LOGIN_CLICKED = 'AUTH_LOGIN_CLICKED',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_CHANGE = 'AUTH_CHANGE',
  APP_LOADED = 'APP_LOADED',
  BOOK_A_DEMO = 'BOOK_A_DEMO',
  CAPJS_SSO_GOOG = 'CAPJS_SSO_GOOG',
  CAPJS_SSO_GOOG_SUCCESS = 'CAPJS_SSO_GOOG_SUCCESS',
  CAPJS_SSO_GOOG_ERROR = 'CAPJS_SSO_GOOG_ERROR',
  CAPJS_SSO_FB = 'CAPJS_SSO_FB',
  CAPJS_SSO_FB_SUCCESS = 'CAPJS_SSO_FB_SUCCESS',
  CAPJS_SSO_FB_ERROR = 'CAPJS_SSO_FB_ERROR',
  CAPJS_GET_PIC = 'CAPJS_GET_PIC',
  CAPJS_GOT_PIC = 'CAPJS_GOT_PIC',
  CAPJS_SPEAK_PLAY = 'CAPJS_SPEAK_PLAY',
  CAPJS_SPEAK_ENDED = 'CAPJS_SPEAK_ENDED',
  CAPJS_SPEAK_STOP = 'CAPJS_SPEAK_STOP',
  CAPJS_SPEAK_ERR = 'CAPJS_SPEAK_ERR',
  CORDOVA_RESUME = 'CORDOVA_RESUME',
  CHAT_NEW_MSG = 'CHAT_NEW_MSG',
  CHANNEL_ARCHIVE = 'CHANNEL_ARCHIVE',
  CHANNEL_FILTERS_OPEN = 'CHANNEL_FILTERS_OPEN',
  CHANNEL_INFO_SHOW = 'CHANNEL_INFO_SHOW',
  CHANNEL_NOTIFICATIONS = 'CHANNEL_NOTIFICATIONS',
  CHANNEL_REPORT = 'CHANNEL_REPORT',
  CHANNEL_SWIPE_ITEMS = 'CHANNEL_SWIPE_ITEMS',
  CHANNEL_SWIPE_ITEMS_CLEAR = 'CHANNEL_SWIPE_ITEMS_CLEAR',
  HASH_TAG_CLICK = 'HASH_TAG_CLICK',
  INVITE_BY_EMAIL = 'INVITE_BY_EMAIL',
  INVITE_BY_EMAIL_SENT = 'INVITE_BY_EMAIL_SENT',
  ITEM_ADD = 'ITEM_ADD',
  ITEM_ENTRY_CLOSE = 'ITEM_ENTRY_CLOSE',
  ITEM_FILTERS_OPEN = 'ITEM_FILTERS_OPEN',
  ITEM_FILTERS_APPLY = 'ITEM_FILTERS_APPLY',
  ITEM_ADD_PULSE = 'ITEM_ADD_PULSE',
  ITEM_SHARE = 'ITEM_SHARE',
  ITEM_COPY = 'ITEM_COPY',
  ITEM_DELETE = 'ITEM_DELETE',
  ITEM_PRICE_WATCH = 'ITEM_PRICE_WATCH',
  ITEM_RECOMMENDATION_ADD = 'ITEM_RECOMMENDATION_ADD',
  ITEM_RECOMMENDATION_REPORT = 'ITEM_RECOMMENDATION_REPORT',
  ITEM_EDIT = 'ITEM_EDIT',
  ITEM_EDITED = 'ITEM_EDITED', // Unused
  ITEMS_FETCH_LATEST = 'ITEMS_FETCH_LATEST',
  ITEMS_FETCH_INJECT_LATEST = 'ITEMS_FETCH_INJECT_LATEST',
  ITEM_REPORT = 'ITEM_REPORT',
  ITEM_LISTEN = 'ITEM_LISTEN',
  LOGO_CLICK = 'LOGO_CLICK',
  FILTER_CHANNELS = 'FILTER_CHANNELS',
  MODAL_SHOW_INFO = 'MODAL_SHOW_INFO',
  NEW_APP_DOWNLOADED = 'NEW_APP_DOWNLOADED',
  NEW_APP_DOWNLOADED_RELOAD_HIDDEN = 'NEW_APP_DOWNLOADED_RELOAD_HIDDEN',
  NETWORK_STATUS_CHANGE = 'NETWORK_STATUS_CHANGE',
  NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
  PEOPLE_CHANGE = 'PEOPLE_CHANGE',
  PUSHN_REGISTERED = 'PUSHN_REGISTERED',
  SELECT_LANGUAGE = 'SELECT_LANGUAGE',
  SHOPPING_LIST_MODAL = 'SHOPPING_LIST_MODAL',
  SHOPPING_LIST_MODAL_SUBMIT = 'SHOPPING_LIST_MODAL_SUBMIT',
  SHOPPING_LIST_REMOVE_MODAL = 'SHOPPING_LIST_REMOVE_MODAL',
  SOCKET_CONNECTION_DROPPED = 'SOCKET_CONNECTION_DROPPED',
  TOUR_CLOSE_ITEM_ENTRY_FORM = 'TOUR_CLOSE_ITEM_ENTRY_FORM',
  TOUR_CLOSE_ITEM_ENTRY_FORM_SUBMIT = 'TOUR_CLOSE_ITEM_ENTRY_FORM_SUBMIT',
  TOUR_CLOSE_ITEM_ENTRY_FORM_NEW_ITEM = 'TOUR_CLOSE_ITEM_ENTRY_FORM_NEW_ITEM',
  TOUR_CLOSE_ITEM_ENTRY_PREVIEW = 'TOUR_CLOSE_ITEM_ENTRY_PREVIEW',
  TOUR_HIDE_PROFILE_HEADER = 'TOUR_HIDE_PROFILE_HEADER',
  TOUR_SHOW_PROFILE_HEADER = 'TOUR_SHOW_PROFILE_HEADER',
  TOUR_START = 'TOUR_START',
  TOUR_INSERT_DEMO_URL = 'TOUR_INSERT_DEMO_URL',
  TOUR_DEMO_URL_INPUT_FINISHED = 'TOUR_DEMO_URL_INPUT_FINISHED',
  TOUR_OPEN_FIND_CONNECTIONS_TAB = 'TOUR_OPEN_FIND_CONNECTIONS_TAB',
  PATH_REDIRECT = 'PATH_REDIRECT',
  VUEX_UPDATE = 'VUEX_UPDATE'
}

export interface BusEvent {
  name: EventBusEvents,
  callerId: string,
  callback: (event: any) => void
}

class EventBus {
  static verbose = false;
  static events: BusEvent[] = [];

  /**
   * Runs the function assigned to each event name
   * @param name
   * @param payload
   */
  static $emit (name: EventBusEvents, payload?: any) {
    EventBus.events.forEach((event: BusEvent) => {
      if (event.name === name) {
        event.callback(payload);
        if (EventBus.verbose) {
          console.info(`Eventbus.$emit event name: ${name}, callerId: ${event.callerId}`);
          console.info(payload);
        }
      }
    });
  }

  /**
   * Emit multiple events using the same payload (or no payload).
   * Emits in order of the array. 0, 1 etc.
   * @param names
   * @param payload
   */
  static $emitMany (names: EventBusEvents[], payload?: any) {
    if (EventBus.verbose) {
      console.info('Eventbus.$emitMany: ', JSON.stringify(names, null, 2));
      console.info(payload);
    }
    for (let i = 0; i < names.length; i++) {
      EventBus.$emit(names[i], payload);
    }
  }

  /**
   * Listen to an event, cb run when event called
   * @param name - Name of the event
   * @param callerId - Which component added the event, prevents the same component adding the same event multiple times
   * @param callback - What to do when the event is called with $emit
   */
  static $on (name: EventBusEvents, callerId: string, callback: (event: any) => void): void {
    const foundIndex = EventBus.events.findIndex((event: BusEvent) => {
      return event.name === name && event.callerId === callerId;
    });
    if (foundIndex !== -1) {
      // remove the event to prevent events stacking up
      EventBus.events.splice(foundIndex, 1);
    }
    this.events.push({ name, callerId, callback });
    if (EventBus.verbose) {
      console.info(`Eventbus.$on event name: ${name}, callerId: ${callerId}. New stack:`, EventBus.events);
    }
  }

  /**
   * Remove an event from the stack
   * @param name - The name of the event to remove
   * @param [callerId] - The component callerId, if not passed removes all events by the name provided
   */
  static $remove (name: EventBusEvents, callerId: string | boolean = false) {
    EventBus.events = EventBus.events.filter((event: BusEvent) => {
      if (typeof callerId === 'string') {
        return !(event.name === name && event.callerId === callerId);
      } else {
        return event.name !== name;
      }
    });
    if (EventBus.verbose) {
      console.info(`Eventbus.$remove event name: ${name}, callerId: ${callerId}. New stack:`, EventBus.events);
    }
  }
}

export default EventBus;
