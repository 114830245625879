import HttpService from '@/services/HttpService';

import { ChannelNotificationsPut } from './interfaces/ChannelNotificationsPut';
import { GenericIsTrue } from './interfaces/GenericIsTrue';
import { NotificationBaseAttributesArray } from './interfaces/NotificationBaseAttributesArray';
import { NotificationSlugAnyEnabledGetPath } from './interfaces/NotificationSlugAnyEnabledGetPath';
import { NotificationSlugGetPath } from './interfaces/NotificationSlugGetPath';
import { NotificationSlugPutPath } from './interfaces/NotificationSlugPutPath';

export default class NotificationService {
  public static basePath = '/__/ms-channel/';

  /**
   *  Operation ID: notificationSlugAnyEnabledGet
   *  Summary: Returns a boolean to indicate whether anyone has turned notifications on for this channel
   *  Description: Returns a boolean to indicate whether anyone has turned notifications on for this channel
   */
  public static notificationSlugAnyEnabledGet(
    pathParams: NotificationSlugAnyEnabledGetPath
  ): Promise<GenericIsTrue> {
    return HttpService.sendRequest({
      method: 'GET',
      path: NotificationService.basePath + 'notification/:slug/any-enabled',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: notificationSlugGet
   *  Summary: Fetch notification settings by slug
   *  Description: Fetches all notification settings for a user from a certain channel
   */
  public static notificationSlugGet(
    pathParams: NotificationSlugGetPath
  ): Promise<NotificationBaseAttributesArray> {
    return HttpService.sendRequest({
      method: 'GET',
      path: NotificationService.basePath + 'notification/:slug',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: notificationSlugPut
   *  Summary: Update notification settings by slug
   *  Description: Updates multiple notification settings for a user from a certain channel by array
   */
  public static notificationSlugPut(
    body: ChannelNotificationsPut,
    pathParams: NotificationSlugPutPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: NotificationService.basePath + 'notification/:slug',
      body,
      params: pathParams,
    });
  }
}
