import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { ChannelWithRelationalMeta } from '@/api/ms-channel/services/interfaces';
import { ChannelType, Privacy } from '@/api/ms-channel/services/interfaces/ChannelWithRelationalMeta';

export interface currentChannelViewed {
  data?: ChannelWithRelationalMeta;
}

export interface IChannelModule extends IStoreModule {
  currentChannelViewed: ChannelWithRelationalMeta;
  currentChannelViewedMembers: any[]
}

export interface ChannelWithRelationalMetaVue extends ChannelWithRelationalMeta{
  archived: boolean
}

export const initialChannelData: ChannelWithRelationalMetaVue = {
  name: '',
  slug: '',
  _id: '',
  allCanAdd: false,
  chatEnabled: true,
  createdAt: new Date(),
  currentUserCanAdd: false,
  currentUserCanEdit: false,
  customFields: [],
  description: '',
  imageHeroPath: '',
  imageHeroPathCredit: '',
  imagePath: '',
  owner: {
    username: '',
    firstName: '',
    lastName: ''
  },
  performanceValues: { memberCount: 1 },
  privacy: Privacy.Private,
  channelType: ChannelType.General,
  updatedAt: new Date(),
  archived: false
};
