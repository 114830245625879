
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ABusinessProfileImage from '../atoms/ABusinessProfileImage.vue';
import AButtonLink from '../atoms/links/AButtonLink.vue';
import { MoreVerticalIcon } from 'vue-feather-icons';

interface Parameter {
  key: string;
  value: string;
}

interface OB2CMessageTextElements {
  notInterested: string;
  clickoutButton: string;
  expires: string;
  expired: string;
  doesntExpire: string;
}

interface Locale {
  language: string;
}

@Component({
  components: {
    AButtonLink,
    ABusinessProfileImage,
    MoreVerticalIcon
  },
  filters: {
    lowerCase: (str: string) => {
      return str.toLowerCase();
    }
  }
})
export default class OB2CMessage extends Vue {
  @Prop({required: true})
  baseUrlAndPath!: string;

  @Prop({ required: true })
  messageId!: string;

  @Prop({ required: true })
  domainId!: string;

  @Prop({ required: true })
  businessName!: string;

  @Prop({ required: true })
  message!: string;

  @Prop({ required: false })
  expiredAt?: Date;

  @Prop({ required: false })
  expiryDate?: Date;

  @Prop({ required: true })
  url!: string;

  @Prop()
  imageSize!: string;

  @Prop({ required: false })
  parameters?: Parameter[];

  @Prop({ required: false })
  componentViewed?: boolean;

  @Prop({
    type: Object,
    default: () => ({
      notInterested: 'Not interested',
      clickoutButton: 'Go to shop',
      expires: 'Expires',
      expired: 'Expired',
      doesntExpire: 'Doesn\'t expire'
    })
  })
  textElements!: OB2CMessageTextElements;

  @Prop({
    type: Object,
    default: () => ({
      language: 'enGB'
    })
  })
  locale!: Locale;

  viewedYet: boolean = false;
  clickoutUrl: string = '';

  created () {
    this.generateClickoutUrl();
  }

  generateClickoutUrl () {
    let clickoutUrl = this.url;
    if (this.parameters) {
      let params = '?';
      for (let i = 0; i < this.parameters?.length; i++) {
        if (this.parameters[i].key && this.parameters[i].value) {
          params += this.parameters[i].key;
          params += '=';
          params += this.parameters[i].value;
          params += '&';
        }
      }
      params = params.replace(/&$/, '');
      clickoutUrl += params;
    }
    this.clickoutUrl = encodeURIComponent(clickoutUrl);
  }

  domainProfileImageUrl (): string {
    return 'business/domain-profile-image/' + this.domainId + '.png';
  }

  clickoutButtonClass (): string {
    return this.expiredAt ? 'is-secondary' : 'is-primary';
  }

  goToClickout () {
    this.$emit('clickout', { clickoutUrl: this.clickoutUrl, messageId: this.messageId });
  }

  markAsNotInterested () {
    this.$emit('not-interested', this.messageId);
  }

  // Only fire off the viewed stat if the user looks at the message, and only do it the one time per page load
  @Watch('componentViewed', { immediate: true })
  viewedB2CStats () {
    if (this.componentViewed && !this.viewedYet) {
      this.viewedYet = true;
      this.$emit('viewed', this.messageId);
    }
  }
}
