/**
 * Pass a function, converts it to a string, then removes dead whitespace on the left hand side that may be there due to
 * indentation. Useful for printing functions into the console when debugging.
 *
 * @param func Any function
 */
export default (func:any):string => {
  //trim out dead spaces so this isn't so useless to read in the logs
  let str = String(func);
  const matchRegex = /(?:[\n\r])(\s+)(?:[^\s][^}]+})$/;
  const lastLineSpaces = str.match(matchRegex);
  if (lastLineSpaces && lastLineSpaces[1]) {
    str = str.replaceAll(lastLineSpaces[1], '');
  }

  return str;
};