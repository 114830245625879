
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import { ConciergeType } from '@/api/ms-channel/services/interfaces/Channel';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: { ATooltip, MRadioCards }
})
export default class MChannelConciergeSettingsConciergeType extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ConciergeType;
  @Prop({required: false, default: false})
  recalculateAccordion!: boolean;
  @Prop({required: false, default: false})
  disabled!: boolean;

  value: ConciergeType = ConciergeType.SingleItem;
  conciergeTypes = ConciergeType;

  created () {
    this.value = this.activeValue;
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
