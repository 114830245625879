
import { Component, Prop, Vue } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import InternetStatus from '@/services/InternetStatus';

@Component({
  components: {
    AImage
  }
})
export default class AUserProfilePic extends Vue {
  @Prop(String)
  username?: string;

  @Prop(String)
  imageSize?: Size;

  get isOnline () {
    return InternetStatus.isOnline;
  }

  profilePicUrl () {
    return 'user/profile-pic/' + this.username + '.png';
  }
}
