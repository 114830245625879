import { Datum as Currency, SymbolPlacement } from '@/api/ms-static-api-data/services/interfaces/Currenciess';

/**
 * Format the price using the correct currency symbol, symbol placement and spacing, and number representation
 * for the locale of the user.
 */
// eslint-disable-next-line max-lines-per-function
export default (price: number, currency: string, localeString: string, forceDecimal: boolean, uniqueCurrencyArray: Currency[]): string => {
  // forceDecimal will show .00 if no decimal present
  // localeString is used with toLocaleString to show commas or periods according to use locale
  const priceToString = (priceDisplay: number) => {
    // pass through Number constructor which will remove any trailing 0's
    const price = Number(priceDisplay);

    // if this is an integer, and we are not forcing the decimal, set decimal places to 0
    let decimalPlaces = 2;
    if (Number.isInteger(price) && !forceDecimal) {
      decimalPlaces = 0;
    }

    // set locale string that we use (enGB) into the format that JavaScript uses (en-GB)
    localeString = localeString.replace(/^([a-z]{2})([A-Z]{2})$/, '$1-$2');

    // return our locale string with the relevant number of decimals
    return price.toLocaleString(localeString, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  };

  const priceNumAsStr = priceToString(price);
  // default would look like "20.50 GBP"
  let priceString = priceNumAsStr + ' ' + currency;
  // if we find symbol data change the price to use relevant symbol and placement, "£20.50"
  if (uniqueCurrencyArray) {
    const symbolData = uniqueCurrencyArray.find((el) => el.currency === currency);
    if (symbolData) {
      if (symbolData.symbolPlacement === SymbolPlacement.Right) {
        priceString = priceNumAsStr;
        priceString += symbolData.symbolGap ? ' ' : '';
        priceString += symbolData.symbol;
      } else {
        priceString = symbolData.symbol;
        priceString += symbolData.symbolGap ? ' ' : '';
        priceString += priceNumAsStr;
      }
    }
  }

  return priceString;
};