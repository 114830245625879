
import { Component, Vue } from 'vue-property-decorator';
import AuthenticationService from '@/services/AuthenticationService';
import { AuthenticationStore, ChannelsStore, LifferyTourStore, PeopleStore, WatchersStore } from '@/store';
import WebSocket from '@/socket/WebSocket';
import config from '@/config';
import MChatBotHelpCrunch from '@/components/molecules/MChatBotHelpCrunch.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import OModalsContainer from '@/components/organisms/OModalsContainer.vue';
import setRootFontSize from '@/storybook-components/src/utils/setRootFontSize';
import UserAppSettingsHydrate from '@/services/UserAppSettingsHydrate';
import setLanguage from '@/utils/setLanguage';
import { AvailableTours } from '@/components/organisms/OVueTour.types';
import { translation } from '@/plugins/i18n/Translation';
import { RouteNames } from '@/router/RouteNames';

import OVueTour from '@/components/organisms/OVueTour.vue';
@Component({
  components: {
    OVueTour,
    OModalsContainer,
    MChatBotHelpCrunch
  }
})
export default class App extends Vue {
  loading: boolean = true;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  // eslint-disable-next-line max-lines-per-function
  async created () {
    this.loading = true;
    setRootFontSize();
    this.eventsBind();

    const token = await AuthenticationService.getAccessJWT();

    if (token) {
      
      let user = AuthenticationStore.currentUser;
      // This can happen when the user logs out of the web app on liffery.com, logs into liffery in a 3pie, then load liffery.com again.
      // 3pie does not set the vuex store - arguably a reason to rebuild the sidebar in vue
      if(user.username === ''){
        // then we must refetch the current user 1st
        await AuthenticationStore.reFetchCurrentUser();
        user = AuthenticationStore.currentUser;
      } else {
        AuthenticationStore.reFetchCurrentUser().catch(console.error);
      }
      

      await setLanguage(user.language, true);
      await AuthenticationStore.getCurrentUserMetaData();
      // NB it is important that we 1st make a call to get a JWT protected API here 1st as
      // this will if required renew the JWT
      //
      // Calling the websocket connect before an API call has the risk of the socket
      // attempting to connect with an exprired JWT token. However, the socket connection
      // should be established before loading the app/
      WebSocket.connect(`${config.api.baseWss}`, token);
      if (AuthenticationStore.getAuthenticated) {
        await UserAppSettingsHydrate.fetchThenHydrate();
        Promise.all([
          ChannelsStore.fetchChannelInvites(),
          ChannelsStore.fetchChannelsLastAddedTo(),
          PeopleStore.fetchNotConnectedPeople(),
          ChannelsStore.fetchChannelsManagerOf(),
          WatchersStore.getAllActive({ username: user.username }),
        ]).catch((e) => {
          console.log('Error on load', e);
        });
        // if the user has not ever seen the welcome tour, emit to start this immediately
        const tours = LifferyTourStore.getToursSeen;
        if (!tours.welcome?.takeTheTour) {
          EventBus.$emit(EventBusEvents.TOUR_START, AvailableTours.WelcomeTour);
        }
      }
    }
    this.loading = false;
  }

  mounted () {
    setTimeout(() => {
      EventBus.$emit(EventBusEvents.APP_LOADED);
    }, 400);
  }

  eventsBind () {
    const callerId = 'App.vue';
    EventBus.$on(EventBusEvents.PATH_REDIRECT, callerId, (input: { path: string }) => {
      this.$router.push({ path: input.path });
    });

    EventBus.$on(EventBusEvents.AUTH_LOGOUT, callerId, () => {
      this.$router.push({
        name: RouteNames.ROUTE_INFO_WHAT_IS
      });
    });
  }
}
