
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
import { ShoppingBagIcon } from 'vue-feather-icons';

  @Component({
    components: {
      ShoppingBagIcon
    }
  })
  export default class ARouterLinkShoppingList extends Vue {
    @Prop(Boolean)
    hasIcon!: boolean;

    name = RouteNames.ROUTE_GETGOT;
  }
