
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon } from 'vue-feather-icons';
import MHeaderSeoSidebarContent from '@/components/molecules/landing/MHeaderSeoSidebarContent.vue';
import { RouteNames } from '@/router/RouteNames';
import { AuthenticationStore } from '@/store';
import MHeaderLoginTrigger from '@/components/molecules/MHeaderLoginTrigger.vue';
import config from '@/config';
import { translation } from '@/plugins/i18n/Translation';
import setLanguage from '@/utils/setLanguage';
import { intersperse } from 'common-utils/array';
import ALink from '@/components/atoms/link/ALink.vue';
import MUserProfileHeader from '@/components/molecules/MUserProfileHeader.vue';
import MLoginButtonTrigger from '@/components/molecules/buttons/MLoginButtonTrigger.vue';
import Cookie from '@/services/Cookies';
import { Language } from '@/api/ms-authentication/services/interfaces/User';
import { getDateInXMonths } from 'common-utils/time';
import urlHashParamGet from '@/utils/urlHashParamGet';

@Component({
  components: {
    MLoginButtonTrigger,
    MUserProfileHeader,
    ALink,
    MHeaderLoginTrigger,
    ChevronDownIcon,
    MHeaderSeoSidebarContent
  }
})
export default class MHeaderSeo extends Vue {
  @Prop({ default: true })
  noVhAdjustment!: boolean;

  openSidebar = false;

  accountHref = config.accountsHref;

  routeNames = RouteNames;

  localeToISOA2 = translation.localeToISOA2;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get currentLanguageISOAlpha2 () {
    return translation.currentLanguageISOAlpha2;
  }

  get languages () {
    return intersperse(translation.supportedLanguages, 'DIVIDE');
  }

  get isBusinessLandingPage () {
    return this.$route.name === RouteNames.ROUTE_INFO_BUSINESS;
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  async created () {
    await this.languageSetFromPersist();
  }

  loginPrompt () {
    AuthenticationStore.TOGGLE_PROMPT_LOGIN(true);
  }

  accountsClick () {
    window.location.href = this.accountHref;
  }

  async changeLanguage (language: Language, bypassCookie = false) {
    await setLanguage(language);
    if (!bypassCookie) {
      await this.languagePersist(language);
    }
    // add the langauge to the url for people to share
    urlHashParamGet('lang', { setValue: language });
  }

  async languagePersist (language: Language) {
    await Cookie.set('language-switcher', language, { expires: getDateInXMonths(18), });
  }

  async languageSetFromPersist () {
    // set the cookie based lang
    const language = await Cookie.get('language-switcher') as Language;
    if (language && this.currentLanguage !== language) {
      await this.changeLanguage(language, true);
    } else {
      const hashLang = urlHashParamGet('lang') as Language;
      if (hashLang && Object.values(Language).includes(hashLang)) {
        await this.changeLanguage(hashLang);
      }
    }
  }
}
