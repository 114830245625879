import { RouteNames } from '@/router/RouteNames';

interface GetChannelRouterObject {
  name: string;
  params: Record<string, string>;
}

export default (channelSlug: string): GetChannelRouterObject => {
  const parts = channelSlug.split('@');
  return {
    name: channelSlug.includes('@') ? RouteNames.ROUTE_CHANNEL_VIEW : RouteNames.ROUTE_CHANNEL_BUSINESS_VIEW,
    params: {
      channelSlug0: parts[0],
      channelSlug1: parts[1]
    }
  };
};
