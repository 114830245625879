
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField, FieldType } from '@/api/ms-item/services/interfaces/Item';
import StaticApiDataService from '@/services/StaticApiDataService';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';

@Component
export default class ACustomFieldDisplayPrice extends Vue {
  @Prop({ required: true })
  field!: CustomField;
  @Prop({required:true, default: true})
  displayUnit!:boolean;

  currencies:Currency[] = [];
  formattedPrice = '';

  async created () {
    if( this.field.fieldType !== FieldType.Price ){
      console.error('Wrong field type. Expects price.');
    } else {
      // locally cached, only an API call on first load
      this.currencies = await StaticApiDataService.currenciesUniqueGet();
      this.formatPrice();
    }
  }

  // if the currency has a symbol place that at the front, otherwise put currency code at the end
  // note - this is not accurate as symbol placement varies country to country, but good enough for mvp
  formatPrice (): void {
    if( !this.field.value || this.field.value === '' ){
      this.formattedPrice = '';
      return;
    }
    const currency = this.currencies.filter((el) => el.currency === this.field.unit)[0];
    this.formattedPrice = currency.symbol.match(/[^A-Za-z]/g) ? currency.symbol + this.field.value : this.field.value + ' ' + currency.symbol;
  }
}
