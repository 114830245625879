
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import AFoldersSvgIcon from '@/components/atoms/icon/svg/AFoldersSvgIcon.vue';
@Component({
  components: { AFoldersSvgIcon }
})
export default class ARouterLinkYourChannels extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_YOUR_PROFILE;
}
