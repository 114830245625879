export interface SwipeScoresItemNameUniqueItemNamePut {
  action: Action;
  channelSlug: string;
}

export enum Action {
  SwipeDown = 'SWIPE_DOWN',
  SwipeLeft = 'SWIPE_LEFT',
  SwipeRight = 'SWIPE_RIGHT',
  SwipeUp = 'SWIPE_UP',
}
