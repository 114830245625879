import HttpService from '@/services/HttpService';

import { WatchItem } from './interfaces/WatchItem';
import { WatchItemCanWatch } from './interfaces/WatchItemCanWatch';
import { WatchItemPriceUsernameCanAddNewWatcherGetPath } from './interfaces/WatchItemPriceUsernameCanAddNewWatcherGetPath';
import { WatchItemPriceUsernameGetPath } from './interfaces/WatchItemPriceUsernameGetPath';
import { WatchItemPriceUsernameIdDeletePath } from './interfaces/WatchItemPriceUsernameIdDeletePath';
import { WatchItemPriceUsernamePost } from './interfaces/WatchItemPriceUsernamePost';
import { WatchItemPriceUsernamePostPath } from './interfaces/WatchItemPriceUsernamePostPath';
import { WatchItems } from './interfaces/WatchItems';

export default class WatchItemPriceService {
  public static basePath = '/__/ms-watch-urls/';

  /**
   *  Operation ID: watchItemPriceUsernameCanAddNewWatcherGet
   *  Summary: Get can-add-new-watcher, from watch-item-price {username}
   *  Description:
   */
  public static watchItemPriceUsernameCanAddNewWatcherGet(
    pathParams: WatchItemPriceUsernameCanAddNewWatcherGetPath
  ): Promise<WatchItemCanWatch> {
    return HttpService.sendRequest({
      method: 'GET',
      path:
        WatchItemPriceService.basePath +
        'watch-item-price/:username/can-add-new-watcher',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: watchItemPriceUsernameGet
   *  Summary: Get watch-item-price based on {username}
   *  Description:
   */
  public static watchItemPriceUsernameGet(
    pathParams: WatchItemPriceUsernameGetPath
  ): Promise<WatchItems> {
    return HttpService.sendRequest({
      method: 'GET',
      path: WatchItemPriceService.basePath + 'watch-item-price/:username',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: watchItemPriceUsernamePost
   *  Summary: Create a watch-item-price based on {username}
   *  Description:
   */
  public static watchItemPriceUsernamePost(
    body: WatchItemPriceUsernamePost,
    pathParams: WatchItemPriceUsernamePostPath
  ): Promise<WatchItem> {
    return HttpService.sendRequest({
      method: 'POST',
      path: WatchItemPriceService.basePath + 'watch-item-price/:username',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: watchItemPriceUsernameIdDelete
   *  Summary: Delete a {username} based on {id}, from watch-item-price
   *  Description:
   */
  public static watchItemPriceUsernameIdDelete(
    pathParams: WatchItemPriceUsernameIdDeletePath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: WatchItemPriceService.basePath + 'watch-item-price/:username/:id',
      params: pathParams,
    });
  }
}
