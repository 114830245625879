import { Store } from 'vuex';
import UserSettingsService from '@/api/ms-authentication/services/UserSettingsService';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';
import { UserSettingsPut } from '@/api/ms-authentication/services/interfaces';

/**
 * Save certain keys from the store to the API
 */
export default <State> (store: Store<State>) => {
  // Listen to store changes and persist after 500ms buffer
  let debouncer;
  let lastState: any = false;
  store.subscribe((mutation, state: any) => {
    if (!AuthenticationStore.getAuthenticated) {
      return;
    }
    const body: UserSettingsPut = JSON.parse(JSON.stringify({
      searchSettings: state.search_settings_store.searchSettings,
      itemEntry: state.itemEntry.itemEntry,
      lastChannelAddedTo: state.itemEntry.lastChannelAddedTo,
      toursSeen: state.lifferyTourStore.toursSeen
    }));
    // Only update the settings when
    if (
      lastState === false ||
      JSON.stringify(lastState) !== JSON.stringify(body)
    ) {
      lastState = body;
      clearTimeout(debouncer);
      debouncer = setTimeout(() => {
        EventBus.$emit(EventBusEvents.VUEX_UPDATE, state);
        UserSettingsService.userSettingsPut(body).catch(console.error);
      }, 1000);
    }
  });
};
