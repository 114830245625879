
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { HelpCircleIcon, MessageSquareIcon } from 'vue-feather-icons';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';
import { ItemCommentPost, Type } from '@/api/ms-item/services/interfaces/ItemCommentPost';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Comment, Item } from '@/api/ms-item/services/interfaces/Item';
import ConnectionsService from '@/api/ms-authentication/services/ConnectionsService';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { formatMentionUserComponentData } from '@/utils/formatMentionUsersData';
import { OModalsContainerInfoTypeEnum } from '@/components/organisms/OModalsContainer.vue';

@Component({
  components: {
    MInputWithValidation,
    ValidationObserver,
    HelpCircleIcon,
    MessageSquareIcon
  }
})
export default class OItemCommentForm extends Vue {
  @Prop()
  comment?: Comment;

  @Prop()
  commentId?: string;

  @Prop()
  commentReplyId?: string;

  @Prop({ default: true })
  add!: boolean;

  @Prop({ default: false })
  isEdit!: boolean;

  @Prop({ default: false })
  isEditReply!: boolean;

  @Prop()
  showCommentTypeSelector!: boolean;

  savingComment: boolean = false;
  failedSubmit: boolean = false;
  placeholder!: string;
  isGeneralChannel: boolean = true;
  notFoundMessage: string = '';

  commentTypes: any[] = [{
    value: Type.General,
    label: this.$t('item.comment.type.general')
  }, {
    value: Type.C2B,
    label: this.$t('item.comment.type.c2b')
  }];

  form: ItemCommentPost = {
    comment: '',
    type: Type.General
  };

  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };

  get item (): Item {
    return ItemDetailStore.getItemDetail;
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  created () {
    this.placeholder = String(this.add ? this.$t('dict.leaveAComment') : this.$t('dict.editComment'));
    if (this.comment) {
      this.form.comment = this.comment.comment;
      this.form.type = this.comment.type;
    }

    this.isGeneralChannel = !!this.item.editable.channel!.isDefault;

    if (this.isGeneralChannel) {
      this.notFoundMessage = this.item.actor.username === this.currentUser.username ?
        this.$t('dict.notFoundMessageMentionedConnections').toString() :
        this.$t('dict.notFoundMessageMentionedCommonConnections', { name: this.item.actor.firstName }).toString();
    } else {
      this.notFoundMessage = this.$t('dict.notFoundMessageMentionedChannelMembers').toString();
    }
  }

  showCommentTypeHelp () {
    EventBus.$emit(EventBusEvents.MODAL_SHOW_INFO, {
      type: OModalsContainerInfoTypeEnum.COMMENT_TYPE
    });
  }

  async fetchUsersToMention (searchText: string, cb): Promise<void> {
    let resp;

    // if it's the general channel and it's own item: fetch connections
    // if it's general channel, and another user's items: fetch connections in common
    // if it's another channel: fetch channel members
    if (this.isGeneralChannel) {
      if (this.item.actor.username === this.currentUser.username) {
        resp = await ConnectionsService.connectionsGet({
          offset: 0,
          qs: searchText
        });
      } else {
        resp = await ConnectionsService.connectionsUsernameMatchingGet(
          { username: this.item.actor.username },
          { offset: 0, qs: searchText, includeRequestedUsername: true }
        );
      }
    } else {
      resp = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.item.editable.channel!.slug },
        { text: searchText }
      );
    }

    const userObj = formatMentionUserComponentData(resp.data, this.currentUser.username);
    cb(userObj);
  }

  async onSubmit () {
    // https://logaretm.github.io/vee-validate/guide/forms.html#programmatic-access-with-refs
    if (this.savingComment || !await this.$refs.form.validate()) {
      this.failedSubmit = true;
      return;
    }
    this.savingComment = true;
    let item!: Item;
    if (this.isEdit) {
      item = await ItemDetailStore.itemCommentSubmitPatch({
        comment: this.form.comment,
        commentId: String(this.commentId)
      });
    } else if (this.isEditReply) {
      item = await ItemDetailStore.itemCommentReplySubmitPatch({
        comment: this.form.comment,
        commentId: String(this.commentId),
        replyCommentId: String(this.commentReplyId),
      });
    } else {
      item = await ItemDetailStore.itemCommentSubmitNew(this.form);
    }

    // update the item in the main items store as well if it exists
    ItemsStore.SET_ITEM_DETAIL(item);

    this.form.comment = '';
    await this.$nextTick(() => {
      this.$refs.form.reset();
    });
    this.failedSubmit = false;
    this.$emit('close');
    this.savingComment = false;
  }
}
