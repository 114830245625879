
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { AUploadImageOutput } from '@/storybook-components/src/stories/atoms/buttons/AUploadImage.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';

@Component({
  components: {
    Cropper,
    CircleStencil,
    OModalWrapper
  },
})
export default class OImageCropperModal extends Vue {
  @Prop({required: true})
  visible!: boolean;

  @Prop({ required: true })
  file!: AUploadImageOutput;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({required: false, default: true})
  isRound!: boolean;

  @Prop({ required: false, default: 1 })
  aspectRatio!: number;

  @Prop()
  message?: string;

  // OUTPUT from this component: base64 encoded cropped image
  croppedImage: any = false;
  coordinates: any = {};
  cropping: boolean = false;

  $refs!: {
    cropper: any,
  };

  get modalLoading () {
    return this.loading || this.cropping;
  }

  get getStencil () {
    return this.isRound && this.$options.components ? this.$options.components.CircleStencil : undefined;
  }

  clip (): void {
    this.cropping = true;
    const { coordinates, canvas } = this.$refs.cropper.getResult();
    this.coordinates = coordinates;

    if (canvas) {
      this.croppedImage = canvas?.toDataURL('image/png', 1);
      this.$emit('clip-image', this.croppedImage);
    }
    this.cropping = false;
  }

  close (): void {
    if(this.file.src){
      console.log('Revoking', this.file.src);
      URL.revokeObjectURL(this.file.src);
    }
    this.$emit('close');
  }
}
