
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AlignJustifyIcon, GridIcon, MapIcon } from 'vue-feather-icons';
import { AChannelViewEnums } from '@/store/modules/interfaces/ChannelViewModule';

@Component({
  components: {
    GridIcon,
    AlignJustifyIcon,
    MapIcon
  },
})
export default class AToggleChannelView extends Vue {
  @Prop({ required: true })
  activeView!: string;

  channelView = AChannelViewEnums;
}
