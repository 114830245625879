
import { Component, Prop, Vue } from 'vue-property-decorator';
import { calculateImageHeight } from '@/utils/calculateItemCardHeight';

@Component
export default class MYouTube extends Vue {
  @Prop({ required: false })
  url!: string;

  @Prop()
  imagePreviewUrl?: string;

  @Prop()
  cardWidth?: number;

  get height (): number {
    if (this.cardWidth && this.imagePreviewUrl) {
      return calculateImageHeight(this.cardWidth, this.imagePreviewUrl);
    } else {
      return 450;
    }
  }
}
