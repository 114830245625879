// set the selected channel
import { ItemEntryStore } from '@/store';
import { CustomField as ChannelCustomField, Event, Privacy } from '@/api/ms-channel/services/interfaces/Channel';
import { ChannelType } from '@/api/ms-item/services/interfaces/Item';

export interface FormattedChannel {
  allCanAdd?: boolean,
  channelType: ChannelType,
  currentUserCanAdd?: boolean,
  event?: Event,
  imagePath?: string,
  isDefaultChannel: boolean,
  name: string,
  description: string,
  privacy?: Privacy,
  slug: string,
  memberCount: number,
  customFields?: ChannelCustomField[]
}

export default (channel: FormattedChannel): void => {
  ItemEntryStore.UPDATE_LAST_CHANNEL_USED({
    name: channel.name === 'General' ? '' : channel.name,
    channel: {
      name: channel.name,
      slug: channel.slug as string,
      channelType: channel.channelType
    },
    channelPrivacy: channel.privacy,
    customFields: channel.customFields ? channel.customFields : []
  });
};
