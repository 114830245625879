
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AImage from '@/components/atoms/AImage.vue';
import { Item } from '@/api/ms-item/services/interfaces/Item';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { translation } from '@/plugins/i18n/Translation';
import { format } from 'timeago.js';
import linkedText from '@/utils/linkedText';

@Component({
  components: { AUserProfilePic, AImage },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    }
  }
})
export default class MImageWithLightbox extends Vue {
  @Prop({ required: true })
  imagePath!: string;

  @Prop({ required: true })
  size!: Size;

  @Prop({ default: '' })
  className!: string;

  @Prop({ required: true })
  item!: Item;

  sizes = Size;
  loaded = false;
  loading = false;

  showImageModal = false;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  mounted () {
    setTimeout(() => {
      if (!this.loaded) {
        this.loading = true;
      }
    }, 1000);
  }

  linkText (input: string): string {
    return linkedText(input, true);
  }

  imageReady () {
    this.loaded = true;
    this.loading = false;
  }
}
