import { ChannelPost, ChannelPut } from '@/api/ms-channel/services/interfaces';
import {
  ChannelMeta,
  ChannelType,
  CustomField as CustomFieldPost,
  Event,
  EventType,
  RepetitionSettings
} from '@/api/ms-channel/services/interfaces/ChannelPost';
import { CustomField as CustomFieldPut } from '@/api/ms-channel/services/interfaces/ChannelPut';
import { Editor } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';

export interface EventSettings {
  eventDate: Date | null;
  eventTime: Date | null;
  eventType: EventType;
  eventRepetition: RepetitionSettings;
}

/**
 * Form object shapes relevant to post or put to server.
 * POST and PUT are mostly the same but have some minor differences. Common function wasn't working to handle it.
 */
class ChannelHttpDataService {
  editor: any = null;

  // common base between put and post
  base (channel: ChannelPost | ChannelPut) {
    return {
      allCanAdd: channel.allCanAdd,
      chatEnabled: channel.chatEnabled,
      description: this.getEditorContent(channel.description),
      name: channel.name,
      privacy: channel.privacy,
      channelType: channel.channelType,
      shareLinkEnabled: channel.shareLinkEnabled
    };
  }

  /**
   * Tiptap leaves an empty <p></p> when text is deleted which tricks us into thinking there is content when there
   * isn't. Run a check using tiptaps textContent and return either the content that was provided or undefined if we
   * don't actually have any text.
   */
  getEditorContent (val?: string): string | undefined {
    if (typeof val === 'undefined') {
      return undefined;
    }
    if (this.editor === null) {
      this.editor = new Editor({
        content: val,
        extensions: [StarterKit]
      });
    }
    this.editor.commands.setContent(val, false);
    return this.editor.state.doc.textContent === '' ? undefined : val;
  }

  // defaults the time to 6am if none was given
  insertDefaultTime (eventTime: Date | null): Date {
    if (!eventTime) {
      eventTime = new Date();
      eventTime.setHours(6);
      eventTime.setMinutes(0);
    }

    return eventTime;
  }

  // handle the event field shape
  event (channel: ChannelPost | ChannelPut, eventChannelSettings: EventSettings | undefined): Event | undefined {
    let event: Event | undefined;
    if (channel.channelType === ChannelType.Event && eventChannelSettings && eventChannelSettings.eventDate) {
      // If user has deleted the time, insert the default of 6am
      eventChannelSettings.eventTime = this.insertDefaultTime(eventChannelSettings.eventTime);
      if (eventChannelSettings.eventTime) {
        eventChannelSettings.eventDate?.setHours(eventChannelSettings.eventTime?.getHours());
        eventChannelSettings.eventDate?.setMinutes(eventChannelSettings.eventTime?.getMinutes());
      }
      event = {
        eventType: eventChannelSettings.eventType,
        eventDate: eventChannelSettings.eventDate
      };
      // Based on event type save the repetitions or eventFinished boolean (nothing additional needed for weekly or yearly)
      switch (eventChannelSettings.eventType) {
        case EventType.OneOff:
          event.eventFinished = false;
          break;

        case EventType.Monthly:
          // week set to -1 will get "last" day of the month
          event.repetitionSettings = {
            day: eventChannelSettings.eventRepetition.day,
            week: eventChannelSettings.eventRepetition.week as number > 4 ? -1 : eventChannelSettings.eventRepetition.week
          };
          break;
      }
    }

    return event;
  }

  // handle the channel meta shape
  meta (channel: ChannelPost | ChannelPut): ChannelMeta | undefined {
    let meta: ChannelMeta | undefined;
    if (channel.channelMeta) {
      meta = {};
      if (channel.channelMeta.budget) {
        meta.budget = channel.channelMeta.budget.value ? {
          currency: channel.channelMeta.budget.currency,
          value: channel.channelMeta.budget.value
        } : undefined;
      }
      meta.geolocationData = channel.channelMeta.geolocationData?.formattedAddress ? channel.channelMeta.geolocationData : undefined;
      meta.requirements = this.getEditorContent(channel.channelMeta.requirements);
      meta.likes = this.getEditorContent(channel.channelMeta.likes);
      meta.dislikes = this.getEditorContent(channel.channelMeta.dislikes);
    }

    return meta;
  }

  // handle the custom fields for POST
  customFieldsPost (channel: ChannelPost): CustomFieldPost[] | undefined {
    let customFields: CustomFieldPost[] | undefined;
    if (channel.customFields && channel.customFields.length > 0) {
      customFields = channel.customFields.map((field) => {
        const customField: CustomFieldPost = {
          fieldType: field.fieldType,
          label: field.label,
          unit: field.unit
        };
        if (field.modifier) {
          customField.modifier = field.modifier;
        }
        if (field.options) {
          customField.options = field.options;
        }
        return customField;
      });
    }

    return customFields;
  }

  // handle the custom fields for PUT - _id may be present
  customFieldsPut (channel: ChannelPut): CustomFieldPut[] | undefined {
    let customFields: CustomFieldPut[] | undefined;
    if (channel.customFields && channel.customFields.length > 0) {
      customFields = channel.customFields.map((field) => {
        const customField: CustomFieldPut = {
          fieldType: field.fieldType,
          label: field.label,
          unit: field.unit
        };
        if (field.modifier) {
          customField.modifier = field.modifier;
        }
        if (field.options) {
          customField.options = field.options;
        }
        if (field._id) {
          customField._id = field._id;
        }
        return customField;
      });
    }

    return customFields;
  }

  // return POST shape
  POST (channel: ChannelPost, eventChannelSettings?: EventSettings): ChannelPost {
    const formattedChannel: ChannelPost = this.base(channel);
    formattedChannel.event = this.event(channel, eventChannelSettings);
    if (channel.channelType === ChannelType.ResearchConcierge) {
      formattedChannel.conciergeSettings = channel.conciergeSettings;
    }
    formattedChannel.channelMeta = this.meta(channel);
    formattedChannel.customFields = this.customFieldsPost(channel);

    return formattedChannel;
  }

  // return PUT shape
  PUT (channel: ChannelPut, eventChannelSettings?: EventSettings): ChannelPut {
    const formattedChannel: ChannelPut = this.base(channel);
    formattedChannel.event = this.event(channel, eventChannelSettings);
    formattedChannel.channelMeta = this.meta(channel);
    formattedChannel.customFields = this.customFieldsPut(channel);

    return formattedChannel;
  }
}

export default new ChannelHttpDataService();
