import HashtagService from '@/api/ms-hashtags/services/HashtagService';
import { Source } from '@/api/ms-hashtags/services/interfaces/HashtagSearchGetQuery';

export default async (
  inputText: string,
  source: Source,
  cb: (options: { key: string, value: string }[]) => any
): Promise<void> => {
  const { data } = await HashtagService.hashtagSearchGet({ source, q: inputText });
  cb(data.map((datum) => ({ key: datum.tag, value: datum.tag })));
};
