
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
  import { UserPlusIcon } from 'vue-feather-icons';

  @Component({
    components: {
      UserPlusIcon
    }
  })
  export default class ARouterLinkInvitations extends Vue {
    @Prop(Boolean)
    hasIcon!: boolean;

    name = RouteNames.ROUTE_SETTINGS_INVITATIONS;
  }
