
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { ItemActor } from '@/api/ms-item/services/interfaces/Item';
import { Datum as ConnectionsDatum } from '@/api/ms-authentication/services/interfaces/Connections';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import ConnectionsService from '@/api/ms-authentication/services/ConnectionsService';

import AImage from '@/components/atoms/AImage.vue';
import { AuthenticationStore, ShoppingListStore } from '@/store';
import ItemService from '@/api/ms-item/services/ItemService';
import { StateName } from '@/api/ms-item/services/interfaces/ItemGetgotPost';
import updateItemInStores from '@/utils/updateItemInStores';
import UserService from '@/api/ms-authentication/services/UserService';
import { State } from '@/api/ms-authentication/services/interfaces/Connection';
import OItemLists from '@/components/organisms/OItemLists.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { UserMeta } from '@/api/ms-authentication/services/interfaces';
import ellipsisString from '@/utils/ellipsisString';
import { format } from 'timeago.js';
import { translation } from '@/plugins/i18n/Translation';

@Component({
  components: {
    OItemLists,
    AImage
  },
  filters: {
    ellipsis: (input: string, limit = 50): string => {
      return ellipsisString(input, limit);
    },
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
  }
})
export default class MModalShoppingList extends Vue {
  @Prop({ required: true })
  item!: Item;

  userMeta: UserMeta = {
    channelMemberOfCount: 0,
    channelsOwnedCount: 0,
    connectionCount: 0,
    firstName: '',
    lastName: '',
    shoppingListCountNotGot: 0,
    username: ''
  };
  step: number = 1;
  connections: ConnectionsDatum[] = [];
  selectedConnection!: ItemActor;
  isForMyself: boolean = false;
  isPrivate: boolean = false;

  userImageSize = Size.The32X32;
  loading: boolean = false;
  snackBarTime: number = 5000;
  searchLoading: boolean = false;

  callerId = 'MModalShoppingList';

  get currentUser () {
    return AuthenticationStore.user;
  }

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get getUrl (): string | undefined {
    return this.item.urlCache?.url || undefined;
  }

  get getItemTitle (): string | undefined {
    if (this.item.urlCache && this.item.urlCache.meta && this.item.urlCache?.meta?.title) {
      return this.item.urlCache.meta.title;
    }
    if (this.item.editable.text.length) {
      return this.item.editable.text;
    }
    return 'Only has a picture...';
  }

  async created () {
    this.eventsBind();
    this.loading = true;

    if (this.item.actor.username === this.currentUser.username) {
      this.selectedConnection = { ...this.item.actor };
      this.isForMyself = true;
    } else {
      try {
        const state = await UserService.userUsernameIsConnectedToCurrentUserGet({
          username: this.item.actor.username
        });

        if (![State.RequestReceivedAccepted, State.RequestSentAccepted].includes(state.state)) {
          this.selectedConnection = {
            firstName: '',
            lastName: '',
            username: '',
          };
        } else {
          this.selectedConnection = { ...this.item.actor };
        }

        await this.itemOwnerDetailsFetch();
      } catch (e: any) {
        console.error(e);
        if (e.request.status === 403) {
          this.selectedConnection = { ...this.currentUser };
          this.isForMyself = true;
        }
      }
    }

    this.loading = false;
  }

  async itemOwnerDetailsFetch () {
    this.userMeta = await UserService.userUsernameGet({
      username: this.item.actor.username
    });
  }

  eventsBind () {
    const { callerId } = this;
    EventBus.$on(EventBusEvents.SHOPPING_LIST_MODAL_SUBMIT, callerId, this.shoppingListAdd);
  }

  eventsUnbind () {
    const { callerId } = this;
    EventBus.$remove(EventBusEvents.SHOPPING_LIST_MODAL_SUBMIT, callerId);
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  async searchPeople (qs: string) {
    if (qs.length >= 2) {
      this.searchLoading = true;

      const { data } = await ConnectionsService.connectionsGet({ qs });
      this.connections = data.map((payload: ConnectionsDatum) => {
        return payload;
      });

      this.searchLoading = false;
    }
  }

  handleSelection () {
    this.connections = [];
  }

  handleIsForMyself () {
    if (this.isForMyself) {
      this.selectedConnection = { ...this.currentUser };
    } else {
      this.selectedConnection = { ...this.item.actor };
    }
  }

  async shoppingListAdd () {
    this.loading = true;

    try {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotPost({
        private: this.isPrivate,
        stateName: StateName.StageGet,
        forActor: {
          username: this.selectedConnection.username,
          firstName: this.selectedConnection.firstName,
          lastName: this.selectedConnection.lastName,
        }
      }, {
        uniqueItemName: this.item.uniqueItemName
      });

      await ShoppingListStore.fetchShoppingListToGet();
      updateItemInStores(newItem);
      this.loading = false;
      this.$emit('close-modal');
    } catch (e: any) {
      console.error(e);
      this.loading = false;
      // TODO: catch the error and display to the user
      this.$buefy.toast.open({
        message: this.$t('dict.getGotSnackBarMessageAddedError').toString(),
        queue: false,
        type: 'is-danger',
        duration: this.snackBarTime,
      });
      this.$emit('close-modal');
    }
  }
}
