export interface ItemCommentPost {
  comment: string;
  /**
   * general means comments on the general platform c2b = customer to business comment b2c =
   * business to customer comment
   */
  type: Type;
}

/**
 * general means comments on the general platform c2b = customer to business comment b2c =
 * business to customer comment
 */
export enum Type {
  B2C = 'b2c',
  C2B = 'c2b',
  General = 'general',
}
