import { Getgot } from '@/api/ms-item/services/interfaces/Item';
import { StateName } from '@/api/ms-item/services/interfaces/ItemGetgotPatch';
import { ItemDetailStore, ShoppingListStore } from '@/store';

/**
 * Toggles state between got or not and returns the new state.
 *
 * @param itemName
 * @param myItem
 *
 * @return StateName
 */
export default async (itemName: string, myItem: Getgot): Promise<StateName> => {
  const newState = myItem.latestStage === String(StateName.StageGot) ? StateName.StageGet : StateName.StageGot;
  const newItem = await ItemDetailStore.getgotStateUpdate({
    itemName: itemName,
    privateState: myItem.private,
    username: myItem.actor.username,
    getGotId: String(myItem._id),
    forActor: myItem.forActor,
    stateName: newState
  });

  ShoppingListStore.UPDATE_ONE_ITEM(newItem);

  return newState;
};