import sysend from 'sysend';
import EventBus, { EventBusEvents } from '@/EventBus';

enum SysendEvents {
  reload = 'reload'
}

export default class SyncTabs {
  static init () {
    sysend.on(SysendEvents.reload, () => {
      window.location.reload();
    });

    EventBus.$on(EventBusEvents.NEW_APP_DOWNLOADED_RELOAD_HIDDEN, '_SyncTabs', () => {
      sysend.broadcast(SysendEvents.reload);
    });
  }
}
