
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import { FormsPublicBusinessAccountPricingRequestPost } from '@/api/ms-notification/services/interfaces';
import FormsService from '@/api/ms-notification/services/FormsService';
import clone from '@/utils/clone';

@Component({
  components: {
    AFormServerError,
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
  },
})
export default class OPricingContactForm extends Vue {
  loading: boolean = false;
  sent: boolean = false;

  topic: string = 'demo';
  form: FormsPublicBusinessAccountPricingRequestPost = {
    company: '',
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  async onSubmit () {
    this.loading = true;
    const toSend = clone(this.form);
    // eslint-disable-next-line quotes
    toSend.message = 'TOPIC: ' + this.$t('forBusiness.getInTouch.options.' + this.topic) + '\n\n' + '. MESSAGE: ' + toSend.message;
    await FormsService.formsPublicBusinessAccountPricingRequestPost(toSend);
    this.successMessage();
    this.sent = true;
    this.loading = false;
  }

  cancel () {
    this.$buefy.dialog.confirm({
      onConfirm: () => {
        this.$emit('cancel');
      },
      type: 'is-danger',
      title: 'Are you sure?',
      message: 'You will loose all data in the form when cancelling',
      confirmText: 'Yes, cancel',
      cancelText: 'Go back'
    });
  }

  successMessage () {
    this.$buefy.snackbar.open({
      indefinite: true,
      message: 'Great! Your contact details have been sent, one of the Liffery team will be in touch soon.',
      type: 'is-success',
      position: 'is-bottom-left',
      actionText: 'OK',
      queue: false
    });
  }
}
