export interface ChannelGenerateImage {
  credits?: string;
  /**
   * List of providers for images, for when the image is not provided by the user themselves
   */
  provider?: Provider;
  url: string;
}

/**
 * List of providers for images, for when the image is not provided by the user themselves
 */
export enum Provider {
  Liffery = 'Liffery',
  Pexels = 'Pexels',
}
