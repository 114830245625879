import HttpService from '@/services/HttpService';

import { Channel } from './interfaces/Channel';
import { ChannelArchiveResponse } from './interfaces/ChannelArchiveResponse';
import { ChannelCustomFields } from './interfaces/ChannelCustomFields';
import { ChannelGenerateImage } from './interfaces/ChannelGenerateImage';
import { ChannelGenerateImagePexelsGetQuery } from './interfaces/ChannelGenerateImagePexelsGetQuery';
import { ChannelMeta } from './interfaces/ChannelMeta';
import { ChannelPost } from './interfaces/ChannelPost';
import { ChannelPut } from './interfaces/ChannelPut';
import { ChannelSlugSlugArchivePatch } from './interfaces/ChannelSlugSlugArchivePatch';
import { ChannelSlugSlugArchivePatchPath } from './interfaces/ChannelSlugSlugArchivePatchPath';
import { ChannelSlugSlugConciergeAcceptJobPostPath } from './interfaces/ChannelSlugSlugConciergeAcceptJobPostPath';
import { ChannelSlugSlugConciergeCancelRequestPostPath } from './interfaces/ChannelSlugSlugConciergeCancelRequestPostPath';
import { ChannelSlugSlugConciergeCategoriesDeletePath } from './interfaces/ChannelSlugSlugConciergeCategoriesDeletePath';
import { ChannelSlugSlugConciergeCategoriesDeleteQuery } from './interfaces/ChannelSlugSlugConciergeCategoriesDeleteQuery';
import { ChannelSlugSlugConciergeCategoriesPutPath } from './interfaces/ChannelSlugSlugConciergeCategoriesPutPath';
import { ChannelSlugSlugConciergeCategoriesPutQuery } from './interfaces/ChannelSlugSlugConciergeCategoriesPutQuery';
import { ChannelSlugSlugConciergeCompleteEarlyPostPath } from './interfaces/ChannelSlugSlugConciergeCompleteEarlyPostPath';
import { ChannelSlugSlugConciergeCompleteJobPostPath } from './interfaces/ChannelSlugSlugConciergeCompleteJobPostPath';
import { ChannelSlugSlugCustomFieldOrderPut } from './interfaces/ChannelSlugSlugCustomFieldOrderPut';
import { ChannelSlugSlugCustomFieldOrderPutPath } from './interfaces/ChannelSlugSlugCustomFieldOrderPutPath';
import { ChannelSlugSlugCustomFieldsGetPath } from './interfaces/ChannelSlugSlugCustomFieldsGetPath';
import { ChannelSlugSlugDeletePath } from './interfaces/ChannelSlugSlugDeletePath';
import { ChannelSlugSlugGetPath } from './interfaces/ChannelSlugSlugGetPath';
import { ChannelSlugSlugImageGeneratedPutPath } from './interfaces/ChannelSlugSlugImageGeneratedPutPath';
import { ChannelSlugSlugImageGeneratedPutQuery } from './interfaces/ChannelSlugSlugImageGeneratedPutQuery';
import { ChannelSlugSlugImageHeroGeneratedPutPath } from './interfaces/ChannelSlugSlugImageHeroGeneratedPutPath';
import { ChannelSlugSlugImageHeroGeneratedPutQuery } from './interfaces/ChannelSlugSlugImageHeroGeneratedPutQuery';
import { ChannelSlugSlugImageHeroPutFormData } from './interfaces/ChannelSlugSlugImageHeroPutFormData';
import { ChannelSlugSlugImageHeroPutPath } from './interfaces/ChannelSlugSlugImageHeroPutPath';
import { ChannelSlugSlugImagePutFormData } from './interfaces/ChannelSlugSlugImagePutFormData';
import { ChannelSlugSlugImagePutPath } from './interfaces/ChannelSlugSlugImagePutPath';
import { ChannelSlugSlugMetaGetPath } from './interfaces/ChannelSlugSlugMetaGetPath';
import { ChannelSlugSlugPutPath } from './interfaces/ChannelSlugSlugPutPath';
import { ChannelSlugSlugReportPost } from './interfaces/ChannelSlugSlugReportPost';
import { ChannelSlugSlugReportPostPath } from './interfaces/ChannelSlugSlugReportPostPath';
import { ChannelSlugSlugUserCanPostGetPath } from './interfaces/ChannelSlugSlugUserCanPostGetPath';
import { ChannelWithRelationalMeta } from './interfaces/ChannelWithRelationalMeta';
import { GenericIsTrue } from './interfaces/GenericIsTrue';

export default class ChannelService {
  public static basePath = '/__/ms-channel/';

  /**
   *  Operation ID: channelGenerateImagePexelsGet
   *  Summary: Generates an image from Pexels
   *  Description: Generates an image from Pexels api
   */
  public static channelGenerateImagePexelsGet(
    query: ChannelGenerateImagePexelsGetQuery
  ): Promise<ChannelGenerateImage> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelService.basePath + 'channel/generate-image/pexels',
      qs: query,
    });
  }

  /**
   *  Operation ID: channelPost
   *  Summary: Create a channel
   *  Description: Creates a new channel
   */
  public static channelPost(body: ChannelPost): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ChannelService.basePath + 'channel',
      body,
    });
  }

  /**
   *  Operation ID: channelSlugSlugArchivePatch
   *  Summary: When a user archives or unarchives a channel they own
   *  Description:
   */
  public static channelSlugSlugArchivePatch(
    body: ChannelSlugSlugArchivePatch,
    pathParams: ChannelSlugSlugArchivePatchPath
  ): Promise<ChannelArchiveResponse> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: ChannelService.basePath + 'channel/slug/:slug/archive',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugConciergeAcceptJobPost
   *  Summary: Accept a research concierge job
   *  Description:
   */
  public static channelSlugSlugConciergeAcceptJobPost(
    pathParams: ChannelSlugSlugConciergeAcceptJobPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ChannelService.basePath + 'channel/slug/:slug/concierge/accept-job',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugConciergeCancelRequestPost
   *  Summary: Cancel a research concierge request
   *  Description:
   */
  public static channelSlugSlugConciergeCancelRequestPost(
    pathParams: ChannelSlugSlugConciergeCancelRequestPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path:
        ChannelService.basePath + 'channel/slug/:slug/concierge/cancel-request',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugConciergeCategoriesDelete
   *  Summary: Deletes a category from a research concierge channel
   *  Description:
   */
  public static channelSlugSlugConciergeCategoriesDelete(
    pathParams: ChannelSlugSlugConciergeCategoriesDeletePath,
    query: ChannelSlugSlugConciergeCategoriesDeleteQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: ChannelService.basePath + 'channel/slug/:slug/concierge/categories',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelSlugSlugConciergeCategoriesPut
   *  Summary: Adds a category to a research concierge channel
   *  Description:
   */
  public static channelSlugSlugConciergeCategoriesPut(
    pathParams: ChannelSlugSlugConciergeCategoriesPutPath,
    query: ChannelSlugSlugConciergeCategoriesPutQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug/concierge/categories',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelSlugSlugConciergeCompleteEarlyPost
   *  Summary: Finish a completed research concierge request before the grace period has passed
   *  Description:
   */
  public static channelSlugSlugConciergeCompleteEarlyPost(
    pathParams: ChannelSlugSlugConciergeCompleteEarlyPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path:
        ChannelService.basePath + 'channel/slug/:slug/concierge/complete-early',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugConciergeCompleteJobPost
   *  Summary: Mark a research concierge job as completed
   *  Description:
   */
  public static channelSlugSlugConciergeCompleteJobPost(
    pathParams: ChannelSlugSlugConciergeCompleteJobPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path:
        ChannelService.basePath + 'channel/slug/:slug/concierge/complete-job',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugCustomFieldOrderPut
   *  Summary: Update a channels custom field order
   *  Description:
   */
  public static channelSlugSlugCustomFieldOrderPut(
    body: ChannelSlugSlugCustomFieldOrderPut,
    pathParams: ChannelSlugSlugCustomFieldOrderPutPath
  ): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug/custom-field/order',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugCustomFieldsGet
   *  Summary: Fetch a channels custom fields
   *  Description: Does not require authentication or the user to be a member of the channel
   */
  public static channelSlugSlugCustomFieldsGet(
    pathParams: ChannelSlugSlugCustomFieldsGetPath
  ): Promise<ChannelCustomFields> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelService.basePath + 'channel/slug/:slug/custom-fields',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugDelete
   *  Summary: Delete a channel
   *  Description: Deletes a channel provided its id
   */
  public static channelSlugSlugDelete(
    pathParams: ChannelSlugSlugDeletePath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: ChannelService.basePath + 'channel/slug/:slug',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugGet
   *  Summary: Fetch a channel by slug - or UUID of channel if turned on
   *  Description: Fetches the full channel object by channel id passed in the path
   */
  public static channelSlugSlugGet(
    pathParams: ChannelSlugSlugGetPath
  ): Promise<ChannelWithRelationalMeta> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelService.basePath + 'channel/slug/:slug',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugPut
   *  Summary: Update a channel
   *  Description: Update an existing channel
   */
  public static channelSlugSlugPut(
    body: ChannelPut,
    pathParams: ChannelSlugSlugPutPath
  ): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugImageGeneratedPut
   *  Summary: Updates a channels photo with a generated image
   *  Description: Even for a new or old it will updates this channel's thumb photo
   */
  public static channelSlugSlugImageGeneratedPut(
    pathParams: ChannelSlugSlugImageGeneratedPutPath,
    query: ChannelSlugSlugImageGeneratedPutQuery
  ): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug/image/generated',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelSlugSlugImagePut
   *  Summary: Updates a channels photo
   *  Description: Even for a new or old it will updates this channels cover photo
   */
  public static channelSlugSlugImagePut(
    formData: ChannelSlugSlugImagePutFormData,
    pathParams: ChannelSlugSlugImagePutPath
  ): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug/image',
      formData,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugImageHeroGeneratedPut
   *  Summary: Updates a channels hero photo with a generated image
   *  Description: Even for a new or old it will updates this channel's hero photo
   */
  public static channelSlugSlugImageHeroGeneratedPut(
    pathParams: ChannelSlugSlugImageHeroGeneratedPutPath,
    query: ChannelSlugSlugImageHeroGeneratedPutQuery
  ): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug/image-hero/generated',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: channelSlugSlugImageHeroPut
   *  Summary: Updates a channels hero image
   *  Description: Hero image for a channel
   */
  public static channelSlugSlugImageHeroPut(
    formData: ChannelSlugSlugImageHeroPutFormData,
    pathParams: ChannelSlugSlugImageHeroPutPath
  ): Promise<Channel> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: ChannelService.basePath + 'channel/slug/:slug/image-hero',
      formData,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugMetaGet
   *  Summary: Fetch a channel meta
   *  Description:
   */
  public static channelSlugSlugMetaGet(
    pathParams: ChannelSlugSlugMetaGetPath
  ): Promise<ChannelMeta> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelService.basePath + 'channel/slug/:slug/meta',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugReportPost
   *  Summary: Report a channel
   *  Description:
   */
  public static channelSlugSlugReportPost(
    body: ChannelSlugSlugReportPost,
    pathParams: ChannelSlugSlugReportPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: ChannelService.basePath + 'channel/slug/:slug/report',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: channelSlugSlugUserCanPostGet
   *  Summary: Can post, true or false
   *  Description: Returns a boolean on whether a user can post to the channel by the given slug
   */
  public static channelSlugSlugUserCanPostGet(
    pathParams: ChannelSlugSlugUserCanPostGetPath
  ): Promise<GenericIsTrue> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelService.basePath + 'channel/slug/:slug/user-can-post',
      params: pathParams,
    });
  }
}
