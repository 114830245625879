interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

/**
 * Cycles through the address components to create a single string which users can search against.
 * String includes all parts that a user may find important but which isn't needed to create the formatted address.
 * This is to avoid any upset with anyone who feels like their search term is important.
 *
 * @param addressComponents
 * @param businessDetails
 */
export default (addressComponents: AddressComponent[], businessDetails?: { businessStatus?: string, name?: string }): string => {
  let keywordsAddress = '';
  if (businessDetails && businessDetails.businessStatus === 'OPERATIONAL') {
    keywordsAddress += businessDetails.name + ', ';
  }
  addressComponents.forEach((component) => {
    component.types.forEach((type: string) => {
      const value = component.long_name;
      const addressComponentsToSave = [
        'premise',
        'route',
        'locality',
        'sublocality',
        'neighborhood',
        'postal_town',
        'administrative_area_level_3',
        'administrative_area_level_2',
        'administrative_area_level_1',
        'postal_code',
        'country'
      ];
      if (addressComponentsToSave.includes(type)) {
        keywordsAddress += value + ', ';
      }
    });
  });

  return keywordsAddress.trim().replace(/,\s*$/, '');
};
