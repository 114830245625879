/**
 * NB: Vue-tour is not fully typed at time of implementation.
 */

export enum AvailableTours {
  WelcomeTour = 'welcomeTour',
  DashboardTour = 'dashboardTour',
  ProfileTour = 'profileTour',
  SelectATour = 'selectATour'
}

export enum VueTourPages {
  Welcome = 'welcome',
  Dashboard = 'dashboard',
  Profile = 'profile',
  TakeTourAgain = 'takeTourAgain',
  TourSelector = 'tourSelector'
}

export enum VueTourPlacement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left'
}

export enum VueTourDirection {
  Forwards = 'forwards',
  Backwards = 'backwards'
}

export interface VueTourButtons {
  all?: boolean,
  skip?: boolean,
  back?: boolean,
  next?: boolean,
  finish?: boolean
}

/**
 * This step shape has been expanded to include additional tour options that are unique to Liffery.
 */
export interface VueTourStep {
  /**
   * target uses document.querySelector().
   * Passing no target places in center of screen which would be nice for a welcome message. However, this creates an
   * intermittent annoying bug that it won't find the next target. So don't do this.
   * Issue recorded here: https://github.com/pulsardev/vue-tour/issues/218
   */
  target?: string,
  content: string,
  params?: {
    enableScrolling?: boolean,
    placement?: VueTourPlacement,
    /**
     * Enable/disable highlighting the current step on a step by step basis
     */
    highlight?: boolean,
    /**
     * Disable a button or all buttons for a single step
     */
    disableButtons?: VueTourButtons,
    /**
     * Hide a button or all buttons for a single step
     */
    hideButtons?: VueTourButtons
  },
  /**
   * The function in this promise will run before the step is loaded, use to retrieve async data or route or anything like that.
   * NB: Keep in mind the 'before' will be triggered whenever the user lands on this step, going forwards or backwards
   */
  before?: () => Promise<void>,
  /**
   * The function in this promise is a callback and will run after the step is loaded
   * NB: Keep in mind the 'after' will be triggered whenever the user lands on this step, going forwards or backwards
   */
  after?: () => Promise<void>
}

export interface VueTourOptions {
  debug: boolean,
  useKeyboardNavigation: boolean,
  labels: {
    buttonSkip: string,
    buttonPrevious: string,
    buttonNext: string,
    buttonStop: string
  },
  /**
   * If true the tour will highlight every step, unless overridden with the params of the step.
   * It's kind of ugly so unless we develop our own highlighting css to overwrite this it's best left off and just
   * turn on for any steps via params where necessary.
   */
  highlight?: boolean
}

export interface VueTourCallbacks {
  onStart?: any,
  onPreviousStep?: any,
  onNextStep?: any,
  onSkip?: any,
  onFinish?: any,
  onStop?: any
}

export interface VueTourObject {
  tourName: string,
  currentPage: VueTourPages,
  currentPageStep: number,
  steps: VueTourStep[],
  options: VueTourOptions,
  callbacks: VueTourCallbacks,
  tourRouterPlaceholder: string
}

export interface TourSettings {
  // forward/backward indicator to use with knowing what step content to pull out
  direction: VueTourDirection,

  routerActive: boolean,

  // box states can be edited during the tour depending on placement/screen etc
  vStepWide: boolean,
  vStepThin: boolean,

  // Determines if the current step should be spotlighted, ie the background blurred.
  vStepSpotlight: boolean;

  vStepHide: boolean,

  // a variable of the box width to check against during steps to calculate placement
  vueTourBoxWidth: number

  // if you need to stop navigation temporarily while loading is happening, set to true to disable all buttons
  disableButtons: VueTourButtons,

  // you may need to hide a button entirely
  hideButtons: VueTourButtons,

  // indicator to let us know user pressed skip, may want to hide buttons or display alternative final message
  userPressedSkip: boolean,

  // display finish as close
  finishIsClose: boolean
}
