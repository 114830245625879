import Cookies, { CookieAttributes } from 'js-cookie';
import isIosApp from '@/utils/isIosApp';
import { Preferences } from '@capacitor/preferences';
import isBrowserExt from '@/utils/isBrowserExt';
import config from '@/config';
import SetDetails = chrome.cookies.SetDetails;

/**
 * README!
 * This class handles cookie set/read/remove for:
 * - ios (as usual they are the new Bill Gates Internet Explorer)
 * - browser ext
 * - regular browser and android capacitor
 */
export default class Cookie {
  static async set (key: string, value: string, options: CookieAttributes) {
    const secure = Cookie.makeCookieSecure();
    // iOS and capacitor/ionic have issue - so we simply offset them to the capacitor storage plugin
    // https://github.com/ionic-team/capacitor/issues/1373#issuecomment-707822708
    if (isIosApp()) {
      await Preferences.set({ key, value });
    }
    // Browser extension has its own API
    else if (isBrowserExt()) {
      const setDetails: SetDetails = {
        url: config.api.baseUrl,
        name: key,
        secure,
        value,
        sameSite: secure ? 'no_restriction' : 'lax'
      };
      await chrome.cookies.set(setDetails);
    }
    // Regular browser API, no promises required
    else {
      const optionsToSet = Object.assign(options, {
        secure,
        sameSite: secure ? 'None' : 'Lax'
      });
      Cookies.set(key, value, optionsToSet);
    }
  }

  static makeCookieSecure (): boolean {
    return (isBrowserExt() || window.location.protocol === 'https:');
  }

  static get (key: string): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      if (isIosApp()) {
        // iOS the cookies are written to disk.
        Preferences.get({ key }).then((resp) => {
          return resolve(resp.value || undefined);
        }).catch(reject);
      } else if (isBrowserExt()) {
        // Chrome browser ext has its own API to access the cookies
        chrome.cookies.get({ url: config.api.baseUrl, name: key }, cookie => {
          resolve(cookie?.value || undefined);
        });
      } else {
        // Lastly the normal cookie API for regular browsers (and android)
        return resolve(Cookies.get(key));
      }
    });
  }

  static async remove (key: string) {
    if (isIosApp()) {
      await Preferences.remove({ key });
    } else if (isBrowserExt()) {
      await chrome.cookies.remove({ url: config.api.baseUrl, name: key });
    } else {
      Cookies.remove(key);
    }
  }
}
