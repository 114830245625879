import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { Generic422, User, UserMeta } from '@/api/ms-authentication/services/interfaces';
import { Gender, Language } from '@/api/ms-authentication/services/interfaces/User';

export interface IAuthenticationModule extends IStoreModule {
  loginCaptchaRequired: boolean;
  lastEmailLoginAttempt: string;
  authenticated: boolean;
  errorCaptcha: Generic422;
  errorRegister: Generic422;
  errorLogin: Generic422;
  prompt: {
    login: boolean,
    loginActiveTab: number
  }
  user: User;
  userMeta: UserMeta;
  userMetaOther: UserMeta;
}

export const InitialGenericError: Generic422 = {};

export const InitialUserState = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  language: Language.EnGB,
  birthday: undefined,
  captchaLocked: false,
  verified: false,
  gender: Gender.NoAnswer,
  measurements: {
    heightCM: 0,
    wasteCM: 0,
    shoeSizeEU: 0,
  }
};

export const InitialUserMetaState = {
  channelMemberOfCount: 0,
  channelsOwnedCount: 0,
  connectionCount: 0,
  firstName: '',
  lastName: '',
  shoppingListCountNotGot: 0,
  username: '',
};
