
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { AuthenticationStore } from '@/store';
import { ChevronRightIcon } from 'vue-feather-icons';

@Component({
  components: {
    ChevronRightIcon
  }
})
export default class ARouterLinkGetGot extends Vue {
  @Prop({ default: 0 })
  count!: number;

  name = RouteNames.ROUTE_GETGOT;
  username!: string;

  get itemCount () {
    return this.count;
  }

  created () {
    this.username = AuthenticationStore.currentUser.username;
  }
}
