import HttpService from '@/services/HttpService';

import { B2CMessages } from './interfaces/B2CMessages';
import { B2CMessagesAppDatas } from './interfaces/B2CMessagesAppDatas';
import { B2CMessagesStats } from './interfaces/B2CMessagesStats';
import { GenericNumber } from './interfaces/GenericNumber';
import { MessageDomainIdQtyGetPath } from './interfaces/MessageDomainIdQtyGetPath';
import { MessageMessageIdExpirePutPath } from './interfaces/MessageMessageIdExpirePutPath';
import { MessageMessageIdFeedbackPatchPath } from './interfaces/MessageMessageIdFeedbackPatchPath';
import { MessageMessageIdFeedbackPatchQuery } from './interfaces/MessageMessageIdFeedbackPatchQuery';
import { MessageMessageIdStatePatchPath } from './interfaces/MessageMessageIdStatePatchPath';
import { MessageMessageIdStatePatchQuery } from './interfaces/MessageMessageIdStatePatchQuery';
import { MessageMessageIdStatsGetPath } from './interfaces/MessageMessageIdStatsGetPath';
import { MessagePost } from './interfaces/MessagePost';
import { MessageUniqueItemNameSearchGetPath } from './interfaces/MessageUniqueItemNameSearchGetPath';
import { MessageUniqueItemNameSearchGetQuery } from './interfaces/MessageUniqueItemNameSearchGetQuery';

export default class MessageService {
  public static basePath = '/__/ms-b2c-messaging/';

  /**
   *  Operation ID: messagePost
   *  Summary: Create a message
   *  Description:
   */
  public static messagePost(body: MessagePost): Promise<B2CMessages> {
    return HttpService.sendRequest({
      method: 'POST',
      path: MessageService.basePath + 'message',
      body,
    });
  }

  /**
   *  Operation ID: messageDomainIdQtyGet
   *  Summary: Get qty, from message {domainId}
   *  Description:
   */
  public static messageDomainIdQtyGet(
    pathParams: MessageDomainIdQtyGetPath
  ): Promise<GenericNumber> {
    return HttpService.sendRequest({
      method: 'GET',
      path: MessageService.basePath + 'message/:domainId/qty',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: messageMessageIdExpirePut
   *  Summary: Update a expire, from message {messageId}
   *  Description: Business to expire a message before the expiry date, or manually expire a message without an expiry

   */
  public static messageMessageIdExpirePut(
    pathParams: MessageMessageIdExpirePutPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: MessageService.basePath + 'message/:messageId/expire',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: messageMessageIdFeedbackPatch
   *  Summary: Update part of a feedback, from message {messageId}
   *  Description: Set whether not interested or any other future feeback to b2c messages This route does not require r+w access middleware as is for the general user in the app giving feedback

   */
  public static messageMessageIdFeedbackPatch(
    pathParams: MessageMessageIdFeedbackPatchPath,
    query: MessageMessageIdFeedbackPatchQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: MessageService.basePath + 'message/:messageId/feedback',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: messageMessageIdStatePatch
   *  Summary: Update part of a state, from message {messageId}
   *  Description: This route does not require r+w access middleware as is for the general user update their own status for the given message

   */
  public static messageMessageIdStatePatch(
    pathParams: MessageMessageIdStatePatchPath,
    query: MessageMessageIdStatePatchQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: MessageService.basePath + 'message/:messageId/state',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: messageMessageIdStatsGet
   *  Summary: Get stats, from message {messageId}
   *  Description: Get any stats on this message not stored on the stat service (eg. number of people who received a notification) NB: there is no read middleware yet

   */
  public static messageMessageIdStatsGet(
    pathParams: MessageMessageIdStatsGetPath
  ): Promise<B2CMessagesStats> {
    return HttpService.sendRequest({
      method: 'GET',
      path: MessageService.basePath + 'message/:messageId/stats',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: messageUniqueItemNameSearchGet
   *  Summary: Get search, from message {uniqueItemName}
   *  Description:
   */
  public static messageUniqueItemNameSearchGet(
    pathParams: MessageUniqueItemNameSearchGetPath,
    query: MessageUniqueItemNameSearchGetQuery
  ): Promise<B2CMessagesAppDatas> {
    return HttpService.sendRequest({
      method: 'GET',
      path: MessageService.basePath + 'message/:uniqueItemName/search',
      params: pathParams,
      qs: query,
    });
  }
}
