
import { MapIcon } from 'vue-feather-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces/Item';
import clickOutToMap from '@/utils/clickOutToMap';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: {
    ATooltip,
    MapIcon
  },
  filters: {},
})
export default class AMapOutLink extends Vue {
  @Prop()
  item!: Item;

  @Prop({ default: false })
  dontClickOut!: boolean;

  clickOutToMap () {
    if (!this.dontClickOut) {
      clickOutToMap(this.item);
    }
  }
}
