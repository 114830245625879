import { DialogProgrammatic as Dialog } from 'buefy';
import { ShareOptions } from '@capacitor/share/dist/esm/definitions';
import EventBus, { EventBusEvents } from '@/EventBus';
import config, { BuildType } from '@/config';
import { i18n } from '@/plugins/i18n';

export enum SharedFrom {
  itemDetail,
  channelPage,
  inviteCode
}

export interface ShareOrCopy {
  text: string,
  url: string,
  sharedFrom: SharedFrom
}

export default (input: ShareOrCopy): void => {
  let dialogTitle;
  const title = dialogTitle = input.sharedFrom === SharedFrom.channelPage ? '' : '';
  const payload: ShareOptions = {
    text: input.text,
    url: input.url,
    title,
    dialogTitle
  };
  if (config.buildType === BuildType.CAPACITOR) {
    EventBus.$emit(
      EventBusEvents.ITEM_SHARE,
      payload
    );
  } else {
    // else we are in the browser, try and handle
    // @ts-ignore
    if (navigator.share) {
      // navigator.share's ShareData is slightly different to  capacitor's ShareOptions
      // the key diff is the File - as we don't use file, this is a non issue and the overlaps work
      navigator.share(payload as ShareData).catch(console.error);
    } else {
      // copy to the url to the users clipboard
      navigator.clipboard.writeText(input.url).then(() => {
        Dialog.alert({
          title: String(i18n.t('dict.sharedToClipboardTitle')),
          message: String(i18n.t('dict.sharedToClipboard')),
          confirmText: String(i18n.t('dict.ok'))
        });
      });
    }
  }
};
