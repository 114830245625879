
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PinnedItemsStoreItemsComponent } from '@/store/modules/interfaces/PinnedItemsModule';
import { AuthenticationStore, PinnedItemsStore } from '@/store';
import { SearchType } from '@/store/modules/enums/SearchType';
import { User } from '@/api/ms-authentication/services/interfaces';
import MNoItemsEmptySearch from '@/components/molecules/MNoItemsEmptySearch.vue';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import AToggleChannelView from '@/components/atoms/buttons/AToggleChannelView.vue';
import OItemCard from '@/components/organisms/OItemCard.vue';
import MItemCardSkeleton from '@/components/molecules/skeletons/MItemCardSkeleton.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { RefreshCwIcon, XIcon } from 'vue-feather-icons';
import calculateItemCardHeight from '@/utils/calculateItemCardHeight';
import vScrollHeightWidth from '@/utils/vScrollHeightWidth';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';
import { itemsFilterSortDefaultsHashMap } from './forms/OItemsFilterForm.vue';

@Component({
  components: {
    MNoItemsEmptySearch,
    MButtonWithIcon,
    AToggleChannelView,
    OItemCard,
    MItemCardSkeleton,
    InfiniteLoading,
    XIcon,
    RefreshCwIcon
  }
})
export default class OPinnedItemsContainer extends Vue {
  @Prop({
    default: () => {
      return {};
    }
  })
  cardControls!: {
    showGetGotControl?: boolean
  };

  @Prop({ required: true })
  searchType!: SearchType;

  @Prop()
  username!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ required: false, default: false })
  asAnon!: boolean;

  $refs!: {
    container: any
  };

  search: string = '';
  resizeGridTimeout: any;
  firstResizeComplete: boolean = false;
  noMoreResults: boolean = false;

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get userOtherMeta () {
    return AuthenticationStore.userMetaOtherData;
  }

  get items (): PinnedItemsStoreItemsComponent {
    return PinnedItemsStore.getPinnedItems;
  }

  get noItems (): boolean {
    if (!this.items.items.length) {
      return true;
    }
    if (this.items.items.length === PinnedItemsStore.getColumnCount) {
      let pCount = 0;
      this.items.items.forEach(item => {
        if (item.data.phantom) {
          ++pCount;
        }
      });
      if (pCount === PinnedItemsStore.getColumnCount) {
        return true;
      }
    }
    return false;
  }

  //todo - does this need to be made specific for pinned items?
  get hasSearchParams (): {
    content: boolean,
    filtersCount: number,
    qs: string
  } {
    return {
      content: this.getItemFiltersSortCount > 0 || this.search !== '',
      filtersCount: this.getItemFiltersSortCount,
      qs: this.search
    };
  }

  get getItemFiltersSortCount () {
    return countFilterSortsUsedInQuery(this.$route, itemsFilterSortDefaultsHashMap);
  }

  get newItemsCount (): number {
    return PinnedItemsStore.getNewItemsFetchedCount;
  }

  calculateItemCardHeight (item) {
    const { width, minDivisionValue } = vScrollHeightWidth(true);
    const cardWidth = width >= minDivisionValue ? 400 : width * .9;

    return calculateItemCardHeight(
      item,
      cardWidth,
      this.asAnon
    );
  }

  eventsBind () {
    window.addEventListener('resize', this.itemsChanged);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.itemsChanged);
  }

  created () {
    this.setSearch(this.$route.query?.text as string);
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  setSearch (searchText: string = '') {
    this.search = searchText;
  }

  //Watcher on items to resize the grid whenever new items appear, and to disable the infinity scroller when all results are loaded
  @Watch('items', { immediate: true, deep: true })
  itemsChanged () {
    clearTimeout(this.resizeGridTimeout);
    this.resizeGridTimeout = setTimeout(this.resizeAllGridItems, 100);
    this.noMoreResults = this.items.noMoreResults;
  }

  resizeGridItem (item) {
    const grid = document.getElementsByClassName('OPinnedItemsGrid')[0];
    const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    const cardHeight = parseInt(item.dataset.cardHeight);
    const rowSpan = Math.ceil((cardHeight + rowGap + 32) / (rowHeight + rowGap));
    item.style.gridRowEnd = 'span ' + rowSpan;
  }

  resizeAllGridItems () {
    const allItems = document.querySelectorAll('.OPinnedItemsGrid > div:not(.infinite-loading-container)');
    for (let i = 0; i < allItems.length; i++) {
      this.resizeGridItem(allItems[i]);
    }
    //boolean value used to ensure the infinity scroller doesn't get triggered before the first resize has finished
    this.firstResizeComplete = true;
  }

  scrollBottom () {
    PinnedItemsStore.paginate({ searchType: this.searchType });
  }

  isOwnProfile (): boolean {
    //if a username isn't found in the url you can only be looking at your own profile
    if (this.$route.path === '/-/your-profile/') {
      return true;
    }
    return this.currentUser.username === this.$route.params.username;
  }
}
