import { pause } from 'common-utils/time';

export const waitTillElementIsVisible = async (selector: string): Promise<Element> => {
  const element = document.querySelector(selector);
  if (!element) {
    await pause(10);
    return waitTillElementIsVisible(selector);
  } else {
    return element;
  }
};
