
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import OItemCard from '@/components/organisms/OItemCard.vue';
import MChannelSwipeActionIcons from '@/components/molecules/channel-swipe/MChannelSwipeActionIcons.vue';
import MChannelSwipeItemDraggable from '@/components/molecules/channel-swipe/MChannelSwipeItemDraggable.vue';
import MChannelSwipeFooterActions from '@/components/molecules/channel-swipe/MChannelSwipeFooterActions.vue';
import AChannelSwipeItemRight from '@/components/atoms/channel-swipe/AChannelSwipeItemRight.vue';
import AChannelSwipeItemLeft from '@/components/atoms/channel-swipe/AChannelSwipeItemLeft.vue';
import AChannelSwipeItemUp from '@/components/atoms/channel-swipe/AChannelSwipeItemUp.vue';
import { XIcon } from 'vue-feather-icons';
import SwipeScoresService from '@/api/ms-item/services/SwipeScoresService';
import { Datum, Items } from '@/api/ms-item/services/interfaces/Items';
import { Action } from '@/api/ms-item/services/interfaces/SwipeScoresItemNameUniqueItemNamePut';
import { KeyboardNavigationKeys } from '@/enums/KeyboardNavigationKeys';
import { Channel } from '@/api/ms-channel/services/interfaces';
import { SwipeScoresItemsGetQuery } from '@/api/ms-item/services/interfaces';
import MChannelSwipeHeaderActions from '@/components/molecules/channel-swipe/MChannelSwipeHeaderActions.vue';
import MChannelSwipeInfo from '@/components/molecules/channel-swipe/MChannelSwipeInfo.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import MChannelSwipeSendToOthers from '@/components/molecules/channel-swipe/MChannelSwipeSendToOthers.vue';
import MChannelSwipeNoItemsLeft from '@/components/molecules/channel-swipe/MChannelSwipeNoItemsLeft.vue';
import isTouchScreen from '@/utils/isTouchScreen';
import MKeyboardDirectionPad from '@/components/molecules/MKeyboardDirectionPad.vue';

export interface ActiveCardProps {
  isMoving: boolean;
  directionMoving: Action;
  iconOpacity: number;
  coordinates: Coordinates;
}

export interface Coordinates {
  x: number;
  y: number;
  rotation: number;
}

@Component({
  components: {
    MKeyboardDirectionPad,
    MChannelSwipeNoItemsLeft,
    MChannelSwipeSendToOthers,
    OModalWrapper,
    MChannelSwipeInfo,
    MChannelSwipeHeaderActions,
    MChannelSwipeItemDraggable,
    MChannelSwipeFooterActions,
    AChannelSwipeItemUp,
    AChannelSwipeItemLeft,
    AChannelSwipeItemRight,
    MChannelSwipeActionIcons,
    OItemCard,
    XIcon
  }
})
export default class OChannelSwipeItems extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: boolean;
  @Prop()
  channel!: Channel;
  @Prop({ required: false })
  startingUniqueItemName?: string;

  active: boolean = false;
  infoDisplay: boolean = false;
  sentToOthersDisplay: boolean = false;
  displayNone: boolean = false;
  items: Datum[] = [];
  allItemsGot: boolean = false;
  completedItems: Datum[] = [];
  skippedItems: Datum[] = [];
  // when this many items are left, load the next batch
  getNextItemsTriggerPoint: number = 2;
  firstGetItemsComplete: boolean = false;
  getError: boolean = false;

  // cannot use active index as this relates only to the current session, more may have been completed previously
  swipePosition: number = 0;
  totalSwipableItems: number = 0;

  activeCardPropsInit: ActiveCardProps = {
    isMoving: false,
    directionMoving: Action.SwipeRight,
    iconOpacity: 0,
    coordinates: {
      x: 0,
      y: 0,
      rotation: 0
    }
  };
  activeCardProps: ActiveCardProps = this.activeCardPropsInit;

  activeIndex: number = 0;
  footerActionClick: Action | null = null;

  KeyboardNavigation = Object.values(KeyboardNavigationKeys);

  // this is the max height value set on .item-image in OItemCard.scss
  imageMaxMaxHeight: number = 500;
  // this is roughly the amount of space needed for the header, footer and other item card components
  spaceNeeded: number = 350;
  // this is the value that we pass down to set the max height of the item card
  imageMaxHeight: number = this.imageMaxMaxHeight;

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get hasFinishedStack () {
    let finished;
    if (this.activeIndex + 1 > this.items.length) {
      finished = true;
    } else {
      finished = (this.activeIndex === 0 && this.items.length === 0);
    }
    // if finished also ensure the swipe position doesn't extend past the length
    if( finished ){
      this.swipePosition = this.totalSwipableItems;
    }
    return finished;
  }

  get isTouchscreen () {
    return isTouchScreen();
  }

  async created () {
    this.active = this.activeValue;
    await this.getNextItems();
    this.eventsBind();
    this.setImageMaxHeight();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    window.addEventListener('keyup', this.escapeCloseListener);
    window.addEventListener('resize', this.setImageMaxHeight);
  }

  eventsUnbind () {
    window.removeEventListener('keyup', this.escapeCloseListener);
    window.removeEventListener('resize', this.setImageMaxHeight);
  }

  setImageMaxHeight () {
    const windowHeight = window.innerHeight;
    const maxHeight = windowHeight - this.spaceNeeded;
    this.imageMaxHeight = maxHeight > this.imageMaxMaxHeight ? this.imageMaxMaxHeight : maxHeight;
  }

  /**
   * Get the next card to swipe.
   */
  async nextCard (direction: Action) {
    if (direction === Action.SwipeDown) {
      this.skippedItems.push(this.items[this.activeIndex]);
    } else {
      this.completedItems.push(this.items[this.activeIndex]);
    }
    ++this.activeIndex;
    if (direction !== Action.SwipeDown) {
      ++this.swipePosition;
    }
    // reset active card props and see if we need to load more items
    this.activeCardProps = this.activeCardPropsInit;
    await this.checkGetNextItems();
  }

  async checkGetNextItems () {
    if (!this.allItemsGot && this.items.length === this.activeIndex + this.getNextItemsTriggerPoint) {
      await this.getNextItems(this.getNextItemsTriggerPoint);
    } else if (this.allItemsGot && this.skippedItems.length > 0) {
      // if we have got all possible items to swipe, but there are items in the skipped pile
      this.appendSkippedItems();
    }
  }

  async getNextItems (offset = 0) {
    const firstGet = !this.firstGetItemsComplete;
    const query: SwipeScoresItemsGetQuery = {
      channel: this.channel.slug,
      offset: offset,
      first: firstGet
    };
    if (this.startingUniqueItemName) {
      query.uniqueItemName = this.startingUniqueItemName;
    }
    let get!: Items;
    try {
      get = await SwipeScoresService.swipeScoresItemsGet(query);
    } catch (e) {
      this.getError = true;
      return;
    }

    // if this is the first load, set the starting point
    if (firstGet && typeof get.meta.count !== 'undefined') {
      const plusValue = this.startingUniqueItemName ? 0 : 1;
      this.swipePosition = get.meta.count + plusValue;
    }
    if (get.meta.availableCount) {
      this.totalSwipableItems = get.meta.availableCount;
    }

    if (get.data.length < get.meta.limit) {
      this.allItemsGot = true;
    }

    this.items = this.items.concat(get.data);
    this.firstGetItemsComplete = true;
  }

  // check none of the skipped items have since been completed, then concat onto the items array
  appendSkippedItems () {
    // filter out any skipped items that have somehow since been completed
    const skipped = this.skippedItems.filter((skip) => this.completedItems.findIndex((complete) => complete.uniqueItemName === skip.uniqueItemName) === -1);

    // concat this onto the items array, then blank the skipped array in case they want to skip the same items again and again
    this.items = this.items.concat(skipped);
    this.skippedItems = [];
  }

  showInfo () {
    this.infoDisplay = true;
  }

  showSendToOthers () {
    if (this.getError) {
      this.closeModal();
    } else {
      this.sentToOthersDisplay = true;
    }
  }

  backgroundCloseModal () {
    if (!this.isTouchscreen) {
      this.closeModal();
    }
  }

  closeModal () {
    this.active = false;
  }

  /**
   * As the active card moves around the props are emitted out and passed back down
   * into every iteration of the MChannelSwipeItemDraggable component. This allows
   * us to know from the other cards, but really just the one directly behind the
   * active card is all we care about, what is happening to the active one. This
   * controls, for example, the opacity of item #2.
   */
  setCardMoving (input: ActiveCardProps) {
    const { isMoving, directionMoving, iconOpacity, coordinates } = input;
    this.activeCardProps = { isMoving, directionMoving, iconOpacity, coordinates };
  }

  // moves the card which triggers interact on end
  footerActionClicked (direction: Action) {
    this.footerActionClick = direction;
    setTimeout(() => this.footerActionClick = null, 10);
  }

  /**
   * Allow users to use the swiper with their direction keys. Right = like etc.
   */
  escapeCloseListener (e) {
    if (e.key === KeyboardNavigationKeys.Escape) {
      this.closeModal();
    }
  }

  keyboardNavigationControl (key: KeyboardNavigationKeys) {
    switch (key) {
      case KeyboardNavigationKeys.ArrowRight:
        this.footerActionClicked(Action.SwipeRight);
        break;

      case KeyboardNavigationKeys.ArrowLeft:
        this.footerActionClicked(Action.SwipeLeft);
        break;

      case KeyboardNavigationKeys.ArrowUp:
        this.footerActionClicked(Action.SwipeUp);
        break;

      case KeyboardNavigationKeys.ArrowDown:
        this.footerActionClicked(Action.SwipeDown);
        break;
    }
  }

  @Watch('active')
  updateValue () {
    if (this.active) {
      this.displayNone = false;
    } else {
      // hacky solution, the display: none from fadeOut isn't overwriting the display, so set it manually...
      setTimeout(() => {
        this.displayNone = true;
      }, 250);
    }
    this.$emit('updateModelValue', this.active);
  }

  @Watch('activeValue')
  updateActiveValue () {
    this.active = this.activeValue;
  }
}
