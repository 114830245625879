
import { Component, Vue } from 'vue-property-decorator';
import MItemComment from '@/components/molecules/comment/MItemComment.vue';
import { ItemDetailStore } from '@/store';
import { ChevronDownIcon } from 'vue-feather-icons';

@Component({
  components: { MItemComment, ChevronDownIcon }
})
export default class MCommentList extends Vue {
  limitComments = 3;

  get itemComments () {
    return ItemDetailStore.getItemComments;
  }

  get uniqueItemName () {
    return ItemDetailStore.getItemUniqueItemName;
  }

  getComments () {
    const comments = JSON.parse(JSON.stringify(this.itemComments));
    comments.reverse();
    return comments.splice(0, this.limitComments);
  }

  setLimitComments () {
    this.limitComments = this.itemComments.length;
  }
}
