import { Item } from '@/api/ms-item/services/interfaces';
import imageRatioFromFileName from '@/utils/imageRatioFromFileName';
import remToPx from '@/utils/remToPx';
import { FetchStatus } from '@/api/ms-item/services/interfaces/Item';
import isItemUrlCacheEmpty from '@/utils/isItemUrlCacheEmpty';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';

export const calculateImageHeight = (cardWidth: number, filePath: string): number => {
  const parsed = imageRatioFromFileName(filePath);
  const calculatedHeight = cardWidth * parsed.yRatio;
  const imageHeight = (parsed.originalHeight && calculatedHeight > parsed.originalHeight) ? parsed.originalHeight : calculatedHeight;
  return imageHeight > 500 ? 500 : imageHeight;
};

// eslint-disable-next-line max-lines-per-function
export default (item: Item, calculatedCardWidth: number, isAnon: boolean): number => {
  const remToPxCache = remToPx();
  let height = 0;

  // add the card top padding
  height += remToPxCache(1.3);

  if (!isAnon) {
    // add the card header as auth user
    height += 48;
  } else {
    // add the card header as anon
    height += 31;
  }

  // Add the card content container padding
  height += remToPxCache(/*top padding*/.8 + /*bottom padding*/1);

  // Card body can have differing content to show
  // Only user text without URL Cache or User Photos
  const itemCacheIsEmpty = isItemUrlCacheEmpty(item);

  // No URL cache and only user text
  if (itemCacheIsEmpty && !item.userPhotos?.length) {
    // Assume 5 lines of text
    height += remToPxCache(5);
  }

  // No URL cache but with user photos
  else if (itemCacheIsEmpty && item.userPhotos?.length) {
    // inject the height of the image + 1 line of user text
    height += remToPxCache(2);
    // inject the height of the image
    height += calculateImageHeight(calculatedCardWidth, item.userPhotos[0].filePath);
  }

  // // URL Cache
  else {
    // Add 1 line of text for the users text above the preview
    if (remove1stUrlFromString(item.editable.text).length > 0) {
      height += remToPxCache(2);
    }

    // If the data is still being fetched, we have a 250px placeholder "image"
    if (item.urlCache?.fetchStatus === FetchStatus.InProgress) {
      height += 260;
    }
    // If the URL cache doesn't have an image, add space for 3 lines of text
    else if (!item.urlCache?.image?.href) {
      height += remToPxCache(3);
    }
    // Add the height of the url image
    else if (item.urlCache?.image?.href) {
      height += calculateImageHeight(calculatedCardWidth, item.urlCache?.image?.href);
    }

    // Add the height of the title of the url
    if (item.urlCache?.meta?.title) {
      height += remToPxCache(1);
    }
    // Add the url description height
    if (item.urlCache?.meta?.description) {
      height += remToPxCache(1.8);
    }

    // Add the url itself
    height += remToPxCache(1);

    // Add the URL Data padding
    height += remToPxCache(1 + .5);
  }

  // Lastly, add the card footer
  if (!isAnon) {
    height += remToPxCache(3);
  }

  return height;
};
