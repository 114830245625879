import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import { IShoppingListModule } from '@/store/modules/interfaces/ShoppingListModule';
import ItemsService from '@/api/ms-item/services/ItemsService';
import { AuthenticationStore } from '@/store';
import { Item } from '@/api/ms-item/services/interfaces';
import { GetGotStage } from '@/api/ms-item/services/interfaces/ItemsOnShoppingListUsernameGetQuery';

@Module({
  name: StoreNames.SHOPPING_LIST_STORE,
  namespaced: true,
})
export default class ShoppingListModule extends VuexModule implements IShoppingListModule {
  @Mutation
  public RESET (): void {
    this.items = [];
    this.itemsToGet = [];
    this.itemsToGetAvailableCount = 0;
  }

  items: Item[] = [];
  itemsToGet: Item[] = [];
  itemsToGetAvailableCount: number = 0;

  get getItems () {
    return this.items;
  }

  get getItemsToGet () {
    return this.itemsToGet;
  }

  get getItemsToGetAvailableCount () {
    return this.itemsToGetAvailableCount;
  }

  @Action({ rawError: true })
  public async fetchShoppingListToGet () {
    const { username } = AuthenticationStore.currentUser;
    const results = await ItemsService.itemsOnShoppingListUsernameGet({ username }, {
      limit: 5,
      getGotStage: [GetGotStage.StageGet]
    });
    this.SET_ITEMS_TO_GET({
      items: results.data,
      availableCount: results.meta.availableCount as number
    });
  }

  @Action({ rawError: true })
  public async fetchShoppingList () {
    const { username } = AuthenticationStore.currentUser;
    const results = await ItemsService.itemsOnShoppingListUsernameGet(
      { username },
      { limit: 20 }
    );
    this.SET_ITEMS(results.data);
  }

  @Action({ rawError: true })
  async paginateShoppingList () {
    const { username } = AuthenticationStore.currentUser;
    const results = await ItemsService.itemsOnShoppingListUsernameGet(
      { username },
      {
        limit: 20,
        offset: this.items.length
      }
    );
    this.APPEND_ITEMS(results.data);
  }

  @Mutation
  UPDATE_ONE_ITEM (newItem: Item) {
    const index = this.items.findIndex((item) => item.uniqueItemName === newItem.uniqueItemName);
    if (index !== -1) {
      this.items.splice(index, 1, newItem);
    }
  }

  @Mutation
  DELETE_ONE_ITEM (itemName: string) {
    const index = this.items.findIndex((item) => item.uniqueItemName === itemName);

    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }

  @Mutation
  SET_ITEMS_TO_GET (input: { items: Item[], availableCount: number }) {
    this.itemsToGet = input.items;
    this.itemsToGetAvailableCount = input.availableCount;
  }

  @Mutation
  SET_ITEMS (items: Item[]) {
    // sets the items from cold start
    this.items = items;
  }

  @Mutation
  PREPEND_ITEM (item: Item) {
    // used when the user adds an item to the list
    this.items.unshift(item);
  }

  @Mutation
  APPEND_ITEMS (items: Item[]) {
    // used when the user paginates the list of items
    this.items = this.items.concat(items);
  }
}
