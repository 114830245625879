
import { Component, Prop, Vue } from 'vue-property-decorator';
import OItemCard from '@/components/organisms/OItemCard.vue';
import { AuthenticationStore } from '@/store';
import { Item } from '@/api/ms-item/services/interfaces/Item';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: { OItemCard }
})
export default class OItemCardMapView extends Vue {
  @Prop()
  item!: Item;

  @Prop({ default: false })
  smallWindow!: boolean;

  data: {
    itemViewed: any
  } = {
    itemViewed: {}
  };

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  created () {
    this.data.itemViewed = this.item;
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.ITEM_EDITED, 'OItemCardMapView', (item) => {
      this.data.itemViewed = item;
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.ITEM_EDITED, 'OItemCardMapView');
  }

}
