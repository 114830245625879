import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Item, ItemsGetQuery } from '@/api/ms-item/services/interfaces';
import ItemsService from '@/api/ms-item/services/ItemsService';
import { AuthenticationStore, ItemsStore, NavigationStore } from '@/store';
import { SearchType } from '@/store/modules/enums/SearchType';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  ICalculateItemForDisplay,
  IItemsModule,
  initialVScrollDims,
  ItemDisplay,
  ItemsStoreItemsComponent,
  VScrollDims
} from '@/store/modules/interfaces/ItemsModule';
import calculateItemCardHeight from '@/utils/calculateItemCardHeight';
import vScrollHeightWidth from '@/utils/vScrollHeightWidth';
import clone from '@/utils/clone';
import { initialDataObject } from '@/store/modules/constants/initialDataObject';
import itemsFetchQueryBuilder from '@/store/modules/utils/itemsFetchQueryBuilder';
import ItemService from '@/api/ms-item/services/ItemService';
import waitTillHeaderIsVisible from '@/utils/waitTillHeaderIsVisible';
import { pause } from 'common-utils/time';
import { RouteNames } from '@/router/RouteNames';
import { Route } from 'vue-router/types/router';
import channelSlugFromRoute from '@/utils/channelSlugFromRoute';
import itemsSearchQuery from '@/utils/itemsSearchQuery';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';
import { itemsFilterSortDefaultsHashMap } from '@/components/organisms/forms/OItemsFilterForm.vue';

@Module({
  name: StoreNames.ITEMS_STORE,
  namespaced: true,
})
export default class ItemsModule extends VuexModule implements IItemsModule {
  vScrollDims: VScrollDims = clone(initialVScrollDims);
  newItemsFetched: Item[] = [];
  items: ItemsStoreItemsComponent = clone(initialDataObject);
  search: ItemsGetQuery = {};
  lastSearchType: SearchType = SearchType.dashboard;
  continuedState: boolean = false;

  get getVScrollDims () {
    return this.vScrollDims;
  }

  get getItems (): ItemsStoreItemsComponent {
    return this.items;
  }

  get getNewItemsFetchedCount (): number {
    return this.newItemsFetched.length;
  }

  get getSearchQuery () {
    return this.search;
  }

  get getLastSearchType (): SearchType {
    return this.lastSearchType;
  }

  get getContinuedState (): boolean {
    return this.continuedState;
  }

  @Mutation
  RESET () {
    this.items = clone(initialDataObject);
    this.search = {};
    this.vScrollDims = clone(initialVScrollDims);
    this.newItemsFetched = [];
    this.lastSearchType = SearchType.dashboard;
  }

  @Mutation
  CLEAR_ITEMS () {
    this.items = clone(initialDataObject);
    this.vScrollDims = clone(initialVScrollDims);
    this.newItemsFetched = [];
  }

  @Action({ rawError: true })
  calculateItemForDisplay (input: ICalculateItemForDisplay): ItemDisplay {
    const { index, item } = input;
    const { columnCount } = this.vScrollDims;
    const columnNumber = index % columnCount;
    const width = this.vScrollDims.cardWidth;
    let height = this.vScrollDims.skeletonCardHeight;
    if (item && !item.phantom && !item.skeleton) {
      height = calculateItemCardHeight(item, width, !!item.anonymous);
    }

    const x = this.vScrollDims.outerCardSpace + (columnNumber * (this.vScrollDims.cardWidth + this.vScrollDims.innerCardSpace));
    const y = this.items.columnHeights[columnNumber] || 0;
    const result: ItemDisplay = {
      data: {
        item,
        phantom: !!item?.phantom,
        skeleton: !!item?.skeleton
      },
      column: columnNumber,
      height,
      width,
      x,
      y
    };
    this.UPDATE_COLUMN_HEIGHTS({
      height,
      column: columnNumber,
      spacer: this.vScrollDims.innerCardSpace
    });
    return result;
  }

  @Action({ rawError: true })
  async prepareItemsForDisplay (input: { items: any[] }): Promise<ItemDisplay[]> {
    const { items } = input;
    // prepare the sizes for the cards
    const itemsReadyForDisplay: ItemDisplay[] = [];
    const { length } = this.items.items;
    for (let i = 0; i < items.length; ++i) {
      const index = length + i; // injecting on top of the existing index for pagination
      itemsReadyForDisplay.push(await this.calculateItemForDisplay({
        index,
        item: items[i] as Item,
      }));
    }
    return itemsReadyForDisplay;
  }

  @Action({ rawError: true })
  async recalculateVirtualScroll (): Promise<void> {
    this.VIRTUAL_SCROLL_RESET();
    const { length } = this.items.items;
    const itemsReadyForDisplay: ItemDisplay[] = [];
    for (let i = 0; i < length; ++i) {
      itemsReadyForDisplay.push(await this.calculateItemForDisplay({
        index: i,
        item: this.items.items[i].data.item,
      }));
    }
    this.ITEMS_FETCHED_NEW({
      items: itemsReadyForDisplay
    });

    // Mark the last card in the 1st row (phantom or not)
    this.SET_LAST_COL_1ST_ROW();
  }

  // eslint-disable-next-line max-lines-per-function
  @Action({ rawError: true })
  async itemSearch (input: {
    searchType: SearchType,
    search: ItemsGetQuery,
    mutationKey?: string,
    asAnon?: boolean
  }): Promise<void> {
    const { search, searchType } = input;
    this.SET_API_BUSY({ busy: true });
    this.SET_LAST_SEARCH_TYPE({ searchType: searchType });
    // WARNING! This action is used by pagination too, resetting the store only for a new search
    const mutationKey = input.mutationKey || 'ITEMS_FETCHED_NEW';
    if (mutationKey === 'ITEMS_FETCHED_NEW') {
      this.CLEAR_ITEMS();
      await waitTillHeaderIsVisible();
      this.VIRTUAL_SCROLL_RESET();
    }

    // Remove the any potential phantom cards
    this.REMOVE_PHANTOMS();

    // We inject the skeletons now before anything else
    await this.injectSkeletons();

    // Call the api
    const start = new Date();
    const { data } = await itemsFetchQueryBuilder({ search, searchType });
    const end = new Date();
    if (end.getTime() - start.getTime() < 250) {
      await pause(250 - (end.getTime() - start.getTime()));
    }
    // wipe the store and start again for new search
    if (mutationKey === 'ITEMS_FETCHED_NEW') {
      this.CLEAR_ITEMS();
      await waitTillHeaderIsVisible();
      this.VIRTUAL_SCROLL_RESET();
    } else {
      // remove the skeletons and recalculate stuffs
      this.REMOVE_SKELETONS();
      await this.recalculateVirtualScroll();
    }

    // this means we have a qty count less than the qty requested, which means we have reached the end of available results
    this.SET_NO_MORE_RESULTS({ on: (data.length < 20) });

    const items = await this.prepareItemsForDisplay({ items: data });

    // lastly apply the mutations, inject the items, save the search params and mark this items type as not busy
    this[mutationKey]({
      items,
      search
    });

    // Inject phantoms to ensure horizontal fill of the v-container
    await this.injectPhantomCards();

    // Mark the last card in the 1st row (phantom or not)
    this.SET_LAST_COL_1ST_ROW();

    this.SET_SEARCH({ search });
    this.SET_API_BUSY({ busy: false });
  }

  /**
   * Adding a new item.
   *
   * Get existing items in the store, extract the item data out of the computed sizes data, unshift this new item into
   * the start of that array, then recalculate all the sizes based on new item array.
   */
  @Action({ rawError: true })
  async addNewItem (newItem: Item) {
    this.SET_API_BUSY({ busy: true });

    // Creating a new data array
    const data: Item[] = [];
    for (let i = 0; i < this.items.items.length; i++) {
      if (this.items.items[i].data.item) {
        data.push(this.items.items[i].data.item as Item);
      }
    }
    data.unshift(newItem);

    // Reset the scroller dimensions
    this.CLEAR_ITEMS();
    this.VIRTUAL_SCROLL_RESET();

    const items = await this.prepareItemsForDisplay({ items: data });

    // Mimic the new search mutation as this will just replace the items array with what we've created above
    this.ITEMS_FETCHED_NEW({
      items
    });

    // Inject phantoms to ensure horizontal fill of the v-container
    await this.injectPhantomCards();

    // Mark the last card in the 1st row (phantom or not)
    this.SET_LAST_COL_1ST_ROW();

    this.SET_API_BUSY({ busy: false });
  }

  /**
   * Editing an item.
   *
   * Get existing items in the store and create a new items array leaving behind the computed sizes data. Filter the
   * array to find the position of our edited item. Splice the edited item into the position where it was, then
   * recalculate all the sizes based on new item array.
   */
  // eslint-disable-next-line max-lines-per-function
  @Action({ rawError: true })
  async spliceEditedItem (input: { editedItem: Item, route: Route }) {
    const { editedItem, route } = input;
    this.SET_API_BUSY({ busy: true });

    // if on a channel and the item is no longer in this channel, remove from the store
    let deleteItem = false;
    if (route.name === RouteNames.ROUTE_CHANNEL_VIEW) {
      const channelSlug = channelSlugFromRoute(route) as string;
      if (channelSlug !== editedItem.editable.channel?.slug) {
        deleteItem = true;
      }
    }

    // Creating a new data array copy
    const data: Item[] = [];
    for (let i = 0; i < this.items.items.length; i++) {
      if (this.items.items[i].data.item) {
        data.push(this.items.items[i].data.item as Item);
      }
    }

    // Find the array index and splice the edited item in
    const index = data.findIndex((item) => {
      return item.uniqueItemName === editedItem.uniqueItemName;
    });
    if (index === -1) {
      console.error('Unknown item being edited...');
    }
    // either delete entirely or splice back in
    if (deleteItem) {
      data.splice(index, 1);
    } else {
      data.splice(index, 1, editedItem);
    }

    // Reset the scroller dimensions
    this.CLEAR_ITEMS();
    this.VIRTUAL_SCROLL_RESET();

    const items = await this.prepareItemsForDisplay({ items: data });

    // Mimic the new search mutation as this will just replace the items array with what we've created above
    this.ITEMS_FETCHED_NEW({
      items
    });

    // Inject phantoms to ensure horizontal fill of the v-container
    await this.injectPhantomCards();

    // Mark the last card in the 1st row (phantom or not)
    this.SET_LAST_COL_1ST_ROW();
    this.SET_API_BUSY({ busy: false });
  }

  @Action({ rawError: true })
  async paginate (input: { searchType: SearchType, asAnon: boolean }): Promise<void> {
    //if items is empty or there are any phantoms or skeletons on the page the pagination should not run
    if (!this.items.items.length) {
      return;
    }
    for (let i = 0; i < this.items.items.length; i++) {
      if (this.items.items[i].data.phantom || this.items.items[i].data.skeleton) {
        return;
      }
    }
    if (this.items.noMoreResults || this.items.busy) {
      return;
    }
    this.SET_API_BUSY({ busy: true });
    // we run everything through the main search to ensure there are no params dropped from the original search and reduction of duped code
    const search = this.search;
    // Start the offset at the current count we have in the store
    search.offset = this.items.items.length;
    await this.itemSearch({
      searchType: input.searchType,
      search,
      mutationKey: 'ITEMS_FETCHED_PAGINATION',
      asAnon: input.asAnon
    });
  }

  @Action({ rawError: true })
  async fetchLatest ($route: Route) {
    if (!AuthenticationStore.getAuthenticated) {
      return;
    }
    const search = itemsSearchQuery($route);
    const filterSortsApplied = countFilterSortsUsedInQuery($route, itemsFilterSortDefaultsHashMap);
    const fetchLatest: ItemsGetQuery = {
      channel: this.search.channel,
      dateGT: this.items.items.length > 0 && filterSortsApplied === 0 ? this.items.items[0].data.item?.createdAt : new Date(),
      offset: 0,
      text: this.search.text,
      username: this.search.username
    };
    ItemsService.itemsGet(Object.assign(
      fetchLatest,
      search,
      { from: NavigationStore.getLastRouterHistoryFullpath }
    )).then((result) => {
      if (result.data.length > 0) {
        this.ITEMS_FETCHED_LATEST({
          items: result.data
        });
      }
    }).catch((e) => console.error(e.message));
  }

  @Action({ rawError: true })
  async injectLatestFetched () {
    const search = Object.assign({}, this.search);
    delete search.offset;
    search.dateLT = new Date();
    await this.itemSearch({
      searchType: this.lastSearchType,
      search: search
    });
  }

  //A skeleton is injected temporarily to indicate to the user something is happening. Skeletons are always removed at the end of the XHR request
  @Action({ rawError: true })
  async injectSkeletons (input?: { qty?: number }) {
    const qty = input?.qty || 8;
    const items = await this.prepareItemsForDisplay({
      items: new Array(qty).fill({
        skeleton: true
      })
    });
    this.ITEMS_FETCHED_PAGINATION({ items });
  }

  //The phantom card only lives when the qty of cards is less that the number of columns that could fit the width of the screen
  @Action
  async injectPhantomCards () {
    const count = this.items.items.filter(item => !item.data.phantom && !item.data.skeleton).length;
    if (count >= this.vScrollDims.columnCount) {
      return;
    }
    const items = await this.prepareItemsForDisplay({
      items: new Array(this.vScrollDims.columnCount - count).fill({
        phantom: true
      })
    });
    this.ITEMS_FETCHED_PAGINATION({ items });
  }

  @Action
  public async itemLikeToggle (input: { newLikeState: boolean, uniqueItemName: string, item: Item }) {
    const { item, newLikeState, uniqueItemName } = input;
    const newItem = await ItemService.itemNameUniqueItemNameLikePatch({
      like: newLikeState
    }, {
      uniqueItemName
    });
    newItem.countInGroup = item.countInGroup;
    ItemsStore.SET_ITEM_DETAIL(newItem);

    return newItem;
  }

  @Action
  public async itemPinToggle (input: { newPinState: boolean, uniqueItemName: string, item: Item }) {
    const { item, newPinState, uniqueItemName } = input;
    const newItem = await ItemService.itemNameUniqueItemNamePinPatch({
      pin: newPinState
    }, {
      uniqueItemName
    });
    newItem.countInGroup = item.countInGroup;
    ItemsStore.SET_ITEM_DETAIL(newItem);

    return newItem;
  }

  /**
   * Return a boolean letting us know whether the user is continuing a journey. I.e. we can use the items in the store
   * or a new query is needed. Compare current search type against last search type. If same, compare current search
   * query against stored search query. If we have different search type or different query string then we are not in a
   * continued state and should fire a new API call for the user.
   */
  @Action
  queryContinuedState (input: { $route: any, searchType: SearchType }) {
    const { $route, searchType } = input;
    let continuedState = true;
    if (searchType !== this.lastSearchType) {
      continuedState = false;
    } else {
      const searchQuery = itemsSearchQuery($route);
      // compare the keys in the search query against the last search sent, if something is different, this is a new search
      const queryKeys = Object.keys(searchQuery);
      for (let i = 0; i < queryKeys.length; i++) {
        if ((typeof searchQuery[queryKeys[i]] !== 'undefined' && typeof this.search[queryKeys[i]] === 'undefined') || this.search[queryKeys[i]] !== searchQuery[queryKeys[i]]) {
          continuedState = false;
          break;
        }
      }
      // if there was a search string, check they are the same
      if (this.search.text && this.search.text.length > 0) {
        if( !$route.query || !$route.query.text || this.search.text !== $route.query.text ) {
          continuedState = false;
        }
      }
    }

    this.SET_CONTINUED_STATE(continuedState);
  }

  @Mutation
  SET_API_BUSY (input: { busy: boolean }) {
    this.items.busy = input.busy;
  }

  @Mutation
  SET_LAST_SEARCH_TYPE (input: { searchType: SearchType }) {
    this.lastSearchType = input.searchType;
  }

  @Mutation
  SET_NO_MORE_RESULTS (input: { on: boolean }) {
    this.items.noMoreResults = input.on;
  }

  @Mutation
  SET_ITEM_DETAIL (newItem: Item) {
    const index = this.items.items.findIndex((item: ItemDisplay) => item.data.item?.uniqueItemName === newItem.uniqueItemName);
    if (index !== -1) {
      this.items.items[index].data.item = {
        ...this.items.items[index].data.item,
        ...clone(newItem)
      };
    }
  }

  @Mutation
  SET_SEARCH (input: { search: ItemsGetQuery }) {
    this.search = input.search;
  }

  @Mutation
  SET_LAST_COL_1ST_ROW () {
    const columnCountIndex = this.vScrollDims.columnCount - 1;
    if (this.items.items[columnCountIndex]) {
      this.items.items[columnCountIndex].data.lastIn1stRow = true;
    }
  }

  @Mutation
  UPDATE_COLUMN_HEIGHTS (input: { column: number, height: number, spacer: number }) {
    // Add the new column height to the record, where spacer is the vertical space between cards
    this.items.columnHeights[input.column] += input.height + (input.spacer * 1.5);
  }

  @Mutation
  SET_SCROLL_OFFSET (input: { offset: number }) {
    // this value is retained to allow the browser back to scroll the div to the correct position
    this.items.offset = input.offset;
  }

  @Mutation
  ITEMS_FETCHED_NEW (input: { items: ItemDisplay[] }): void {
    this.items.items = input.items;
  }

  @Mutation
  ITEMS_FETCHED_LATEST (input: { items: any[] }): void {
    // add the items to the very top of the newly fetched, could be more than 1 batch waiting to be injected
    this.newItemsFetched = input.items.concat(this.newItemsFetched);
  }

  @Mutation
  ITEMS_FETCHED_PAGINATION (input: { items: ItemDisplay[] }): void {
    const { items } = input;
    this.items.items = this.items.items.concat(items);
  }

  @Mutation
  REMOVE_SKELETONS (input?: { qty: number }) {
    const qty = input?.qty || 8;
    this.items.items.splice(
      this.items.items.length - qty,
      qty
    );
  }

  @Mutation
  REMOVE_PHANTOMS () {
    // get the starting index of the 1st phantom to start the splice from
    const index = this.items.items.findIndex(item => item.data.phantom);
    if (index !== -1) {
      this.items.items.splice(
        index,
        this.items.items.length // splice will gracefully omit splicing after the end
      );
    }
  }

  // eslint-disable-next-line max-lines-per-function
  @Mutation
  VIRTUAL_SCROLL_RESET () {
    const { width, height, minDivisionValue } = vScrollHeightWidth();
    // now re-calculate numbers for the virtual scroller
    this.vScrollDims.boxWidth = width;

    // the height of the virtual scroll is the window minus the header
    this.vScrollDims.boxHeight = height;

    // the col. count is relative to the viewport width
    this.vScrollDims.columnCount = width > minDivisionValue ? Math.floor(width / minDivisionValue) : 1;

    const setDims = () => {
      if (width >= minDivisionValue) {
        // calculate the spacer and gutters
        this.vScrollDims.cardWidth = 400;
      } else {
        // default to 44px margins
        this.vScrollDims.cardWidth = width - 44;
      }

      // calculate the spacers
      if (this.vScrollDims.columnCount === 1) {
        this.vScrollDims.outerCardSpace = (width - this.vScrollDims.cardWidth) / 2;
        // the inner card space is used as both the vertical and horizontal space
        this.vScrollDims.innerCardSpace = 32;
      } else {
        this.vScrollDims.innerCardSpace = 32;
        this.vScrollDims.outerCardSpace = (width - ((this.vScrollDims.cardWidth * this.vScrollDims.columnCount) + (this.vScrollDims.innerCardSpace * ((this.vScrollDims.columnCount + 1) - 2)))) / 2;
      }
    };
    setDims();

    if (this.vScrollDims.columnCount > 1 && this.vScrollDims.innerCardSpace > this.vScrollDims.outerCardSpace) {
      --this.vScrollDims.columnCount;
      setDims();
    }

    // Set an array as a list marker for the virtual scroll col. marker,
    // each column needs its own height due to cards differing heights
    // this is specific to the the items container in view - ie the search type
    this.items.columnHeights = new Array(this.vScrollDims.columnCount).fill(0);
  }

  @Mutation
  SET_CONTINUED_STATE (state: boolean) {
    this.continuedState = state;
  }
}
