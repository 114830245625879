
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Type } from '@/api/ms-notification/services/interfaces/Notifications';

import {
  ActivityIcon,
  AtSignIcon,
  BellOffIcon,
  CalendarIcon,
  CheckSquareIcon,
  Edit3Icon,
  MessageSquareIcon,
  PlusCircleIcon,
  PlusSquareIcon,
  SendIcon,
  ThumbsUpIcon,
  UserCheckIcon,
  UserPlusIcon,
  XCircleIcon
} from 'vue-feather-icons';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import AAlarmClockIcon from '@/components/atoms/icon/svg/AAlarmClockIcon.vue';
import ADollarStrikethroughIcon from '@/components/atoms/icon/svg/ADollarStrikethroughIcon.vue';
import AOutOfStockIcon from '@/components/atoms/icon/svg/AOutOfStockIcon.vue';
import AStopIcon from '@/components/atoms/icon/svg/AStopIcon.vue';
import AFoldersSvgIcon from '@/components/atoms/icon/svg/AFoldersSvgIcon.vue';
import AFireIconSvg from '@/components/atoms/icon/svg/AFireIconSvg.vue';

@Component({
  components: {
    AFireIconSvg,
    AFoldersSvgIcon,
    AStopIcon,
    AOutOfStockIcon,
    ADollarStrikethroughIcon,
    AAlarmClockIcon,
    ALightbulbOnOutlineIcon,
    ActivityIcon,
    AtSignIcon,
    BellOffIcon,
    CalendarIcon,
    CheckSquareIcon,
    Edit3Icon,
    MessageSquareIcon,
    PlusCircleIcon,
    PlusSquareIcon,
    SendIcon,
    ThumbsUpIcon,
    UserCheckIcon,
    UserPlusIcon,
    XCircleIcon
  },
})
export default class ANotificationIcon extends Vue {
  @Prop()
  type!: Type;
  @Prop({ default: '1x' })
  size!: string;

  notificationTypes = Type;

  // Removes the x from size so can be used on in-house icons
  get sizeB() {
    return parseInt(this.size);
  }
}
