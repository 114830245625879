import { ItemsStoreItemsComponent } from '@/store/modules/interfaces/ItemsModule';
import { Connections } from '@/api/ms-authentication/services/interfaces';

export const initialDataObject: ItemsStoreItemsComponent = {
  itemsNew: [],
  items: [],
  noMoreResults: false,
  busy: false,
  // Virtual scroller content
  columnHeights: [],
  offset: 0
};

export const initialDataObjectNonItem: Connections = {
  data: [],
  meta: {
    limit: 0,
    offset: 0
  }
};