import { ItemsGetQuery, SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';

/**
 * @param $route ==> this.$route from Vue
 */
export default ($route: any): ItemsGetQuery => {
  const { sortBy, sortDir, sortId, withComments, withImage, withLikes } = $route.query;
  return {
    sortBy: sortBy as unknown as SortBy,
    sortDir: sortDir as unknown as SortDir,
    sortId: sortId,
    withComments: typeof withComments !== 'undefined' ? withComments : undefined,
    withImage: typeof withImage !== 'undefined' ? withImage : undefined,
    withLikes: typeof withLikes !== 'undefined' ? withLikes : undefined
  };
};
