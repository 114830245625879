export interface ItemGetgotPatch {
  _id?: string;
  forActor?: ForActor;
  private: boolean;
  stateName: StateName;
}

export interface ForActor {
  firstName: string;
  lastName: string;
  username: string;
}

export enum StateName {
  StageGet = 'stageGet',
  StageGot = 'stageGot',
}
