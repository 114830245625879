
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon, XIcon } from 'vue-feather-icons';

@Component({
  components: {
    ChevronDownIcon,
    XIcon
  }
})
export default class AEventRepeats extends Vue {
  @Prop()
  selectionText!: string;

  @Prop()
  dropdownActive!: boolean;

  @Prop({required: false, default: ''})
  classes!: string;

  @Prop({required: false, default: '0.9x'})
  iconSize!: string;

  @Prop({required: false, default: true})
  useIcon!: boolean;
}
