import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {StoreNames} from '@/store/modules/enums/StoreNames';
import NotificationService from '@/api/ms-notification/services/NotificationService';
import {Datum as Notification} from '@/api/ms-notification/services/interfaces/Notifications';
import {State} from '@/api/ms-notification/services/interfaces/NotificationPatch';
import {
  INotificationsModule,
  NotificationTypes,
  patchNotifications
} from '@/store/modules/interfaces/NotificationsModule';
import {NotificationGetQuery} from '@/api/ms-notification/services/interfaces';
import removeDuplicatedArrayItems from '@/utils/removeDuplicatedArrayItems';
import { clone } from 'common-utils/object';

@Module({
  name: StoreNames.NOTIFICATIONS_STORE,
  namespaced: true,
})
export default class NotificationsModule extends VuexModule implements INotificationsModule {
  @Mutation
  RESET () {
    this.notifications = {
      header: [],
      page: []
    };

    this.noMoreResults = true;
  }

  notifications: NotificationTypes = {
    header: [],
    page: []
  };
  noMoreResults: boolean = true;

  get getHeaderNotifications (): Notification[] {
    return this.notifications.header;
  }

  get getPageNotifications (): Notification[] {
    return this.notifications.page;
  }

  get getUnseenStateCount (): number {
    const unseen = this.notifications.header.filter(item => item.interactionStates[item.interactionStates.length - 1].state === State.Unseen);
    return unseen.length;
  }

  get getNoMoreResults () {
    return this.noMoreResults;
  }

  @Action({ rawError: true })
  async getLatestHeader () {
    const { data } = await NotificationService.notificationGet({
      limit: 10,
    });

    this.SET_NOTIFICATION_HEADER(removeDuplicatedArrayItems(data, '_id'));
  }

  @Action({ rawError: true })
  async getNotificationsPage (search: NotificationGetQuery = {}) {
    const { data } = await NotificationService.notificationGet(search);
    this.SET_NOTIFICATION_PAGE(data);
    this.SET_NO_MORE_RESULTS(data.length < 20);
  }

  @Action({ rawError: true })
  async getLatestNotificationsPage (search: NotificationGetQuery = {}) {
    search.offset = this.notifications.page.length;
    const { data } = await NotificationService.notificationGet(search);
    this.APPEND_NOTIFICATION_PAGE(data);
    this.SET_NO_MORE_RESULTS(data.length < 20);
  }

  @Action
  async markLatestHeaderAsRead () {
    const notifsToBeUpdated = this.notifications.header.filter(
      (notif) => notif.interactionStates[notif.interactionStates.length - 1].state !== State.Interacted
    );

    await this.patchNotifications({
      newState: State.Seen,
      notifications: notifsToBeUpdated
    });
  }

  /**
   * Mark all as seen actually marks as interacted to remove the bold highlight, which is what a user expects when
   * marking as seen. Server-side marks every notification of a user as seen. Front-end merely updates the objects in
   * the store to push interacted as the latest state.
   */
  @Action
  async markAllAsSeen () {
    // server will update the db records
    await NotificationService.notificationMarkAllAsSeenPut();

    // loop through all the notifications stored in the header and page objects, and if not marked as interacted, push that state into the array
    const notificationsHeader = clone(this.notifications.header);
    const notificationsPage = clone(this.notifications.page);
    const newDate = new Date();
    for (let i = 0; i < notificationsHeader.length; i++) {
      if( notificationsHeader[i].interactionStates[notificationsHeader[i].interactionStates.length - 1].state !== State.Interacted ){
        notificationsHeader[i].interactionStates.push({
          state: State.Interacted,
          date: newDate
        });
      }
    }
    this.SET_NOTIFICATION_HEADER(notificationsHeader as Notification[]);

    // same as above, for page notifications object
    for (let i = 0; i < notificationsPage.length; i++) {
      if( notificationsPage[i].interactionStates[notificationsPage[i].interactionStates.length - 1].state !== State.Interacted ){
        notificationsPage[i].interactionStates.push({
          state: State.Interacted,
          date: newDate
        });
      }
    }
    this.SET_NOTIFICATION_PAGE(notificationsPage as Notification[]);
  }

  @Action
  async patchNotifications (input: patchNotifications) {
    if (!Array.isArray(input.notifications)) {
      input.notifications = [input.notifications];
    }
    const notifsToBeUpdated = input.notifications.filter(
      (notif) => notif.interactionStates[notif.interactionStates.length - 1].state !== State.Interacted
    );

    const ids = notifsToBeUpdated.map((notif) => {
      return String(notif._id);
    });

    if (ids && ids.length > 0) {
      const newState = {
        state: input.newState,
        date: new Date()
      };
      const updated = await NotificationService.notificationPatch({
        updateIds: ids,
        interactionState: newState
      });
      this.INJECT_UPDATED_NOTIFICATIONS_HEADER(updated.data);
    }
  }

  @Mutation
  INJECT_NEW_NOTIFICATION (input: Notification) {
    this.notifications.header.unshift(input);
  }

  @Mutation
  SET_NOTIFICATION_HEADER (input: Notification[]) {
    this.notifications.header = input;
  }

  @Mutation
  SET_NOTIFICATION_PAGE (input: Notification[]) {
    this.notifications.page = input;
  }

  @Mutation
  APPEND_NOTIFICATION_PAGE (input: Notification[]) {
    this.notifications.page = this.notifications.page.concat(input);
  }

  @Mutation
  SET_NO_MORE_RESULTS (value) {
    this.noMoreResults = value;
  }

  @Mutation
  INJECT_UPDATED_NOTIFICATIONS_HEADER (notifications: Notification[]) {
    // loop the existing notifications
    this.notifications.header.forEach((notification: Notification, index: number) => {
      // find a matching one in the given arr
      notifications.forEach((newNotification: Notification) => {
        if (notification._id === newNotification._id) {
          this.notifications.header.splice(index, 1, newNotification);
        }
      });
    });
  }
}
