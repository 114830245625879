
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HelpCircleIcon, MessageSquareIcon } from 'vue-feather-icons';
import { CommentLike, Reply } from '@/api/ms-item/services/interfaces/Item';
import { User } from '@/api/ms-authentication/services/interfaces';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

import { format } from 'timeago.js';
import ALikeIcon, { ALikeChildOutput } from '@/components/atoms/icon/ALikeIcon.vue';
import AEditIcon from '@/components/atoms/icon/AEditIcon.vue';
import OItemCommentForm from '@/components/organisms/forms/OItemCommentForm.vue';
import ADeleteIcon from '@/components/atoms/icon/ADeleteIcon.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ADotDivide from '@/components/atoms/ADotDivide.vue';
import OItemCommentReplyForm from '@/components/organisms/forms/OItemCommentReplyForm.vue';
import removeEmojis from '@/utils/removeEmojis';
import linkedText from '@/utils/linkedText';
import { RouteNames } from '@/router/RouteNames';
import { formatMentionUserContent } from '@/utils/formatMentionUsersData';
import doesThisPassModeration from '@/utils/doesThisPassModeration';

@Component({
  components: {
    OItemCommentReplyForm,
    ADotDivide,
    ARouterLinkProfile,
    ADeleteIcon,
    OItemCommentForm,
    AEditIcon,
    ALikeIcon,
    AUserProfilePic,
    HelpCircleIcon,
    MessageSquareIcon
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
  },
})
export default class MItemCommentReply extends Vue {
  @Prop({ required: true })
  uniqueItemName!: string;

  @Prop({ required: true })
  commentId!: string;

  @Prop({ required: true })
  commentReplyId!: string;

  @Prop({ required: true })
  comment!: Reply;

  currentUser: User = AuthenticationStore.currentUser;
  showReplyBox: boolean = false;
  likeLoading: boolean = false;
  editLoading: boolean = false;
  edit: boolean = false;
  userImageSize = Size.The60X60;

  // Easier with replies as nothing can be left hanging. If not passes moderation just don't display component. Simple
  get commentReplyPassesModeration () {
    return doesThisPassModeration(this.comment.moderation, this.comment.actor, this.currentUser);
  }

  get currentUserLikesThisComment (): boolean {
    const like = this.comment.likes.filter((like: CommentLike) => {
      return like.actor.username === this.currentUser.username && like.like;
    });
    return like.length > 0;
  }

  get commentLikeCount (): number {
    const likes = this.comment.likes.filter((like: CommentLike) => {
      return like.like;
    });
    return likes.length;
  }

  mounted () {
    const mentionedProfiles: any = document.querySelectorAll('.mentioned-user-link');
    Array.from(mentionedProfiles, (item: any) => {
      item.addEventListener('click', (e) => {
        this.$router.push({
          name: RouteNames.ROUTE_PROFILE, params: {
            username: e.target.innerText,
          }
        });
      });
    });
  }

  formatCommentContent (input: string): string {
    let comment = linkedText(input);

    return formatMentionUserContent(comment);
  }

  async likeComment (input: ALikeChildOutput): Promise<void> {
    this.likeLoading = true;
    const item = await ItemDetailStore.itemCommentReplyLikePatch({
      commentId: this.commentId,
      replyCommentId: this.commentReplyId,
      like: input.like
    });
    // update the item in the main items store as well if it exists
    ItemsStore.SET_ITEM_DETAIL(item);
    this.likeLoading = false;
  }

  hasLinkedText (input: string): string {
    return linkedText(input);
  }

  displayEditToggle (): void {
    this.edit = !this.edit;
  }

  async deleteHandle (): Promise<void> {
    const item = await ItemDetailStore.itemCommentReplyDelete({
      commentId: this.commentId,
      replyCommentId: this.commentReplyId
    });

    // update the item in the main items store as well if it exists
    ItemsStore.SET_ITEM_DETAIL(item);
  }

  isEmojiOnly (): boolean {
    return !removeEmojis(this.comment.comment);
  }
}
