
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField as ItemCustomField } from '@/api/ms-item/services/interfaces/Item';
import {
  CustomFieldsUnitGroupeds,
  FieldType,
  MeasurementSystem
} from '@/api/ms-static-api-data/services/interfaces/CustomFieldsUnitGroupeds';
import { CustomField as ChannelCustomField } from '@/api/ms-channel/services/interfaces/Channel';
import StaticApiDataService from '@/services/StaticApiDataService';
import ACustomFieldDisplayRating from '@/components/atoms/custom-fields/ACustomFieldDisplayRating.vue';
import ACustomFieldDisplayDate from '@/components/atoms/custom-fields/ACustomFieldDisplayDate.vue';
import ACustomFieldDisplayCompass from '@/components/atoms/custom-fields/ACustomFieldDisplayCompass.vue';
import ACustomFieldDisplayPrice from '@/components/atoms/custom-fields/ACustomFieldDisplayPrice.vue';
import ACustomFieldDisplayCheckbox from '@/components/atoms/custom-fields/ACustomFieldDisplayCheckbox.vue';
import { ChannelsStore } from '@/store';

@Component({
  components: {
    ACustomFieldDisplayCheckbox,
    ACustomFieldDisplayPrice,
    ACustomFieldDisplayCompass,
    ACustomFieldDisplayDate,
    ACustomFieldDisplayRating
  }
})
export default class MCustomFieldDisplay extends Vue {
  @Prop({ required: true })
  field!: ItemCustomField;
  @Prop({ required: true })
  customChannelFieldData!: ChannelCustomField;
  @Prop({ required: false, default: true })
  displayUnits!: boolean;
  @Prop({required:true})
  channelSlug!: string;
  @Prop({ required: true })
  uniqueItemName!: string;

  dataTypes: CustomFieldsUnitGroupeds = [];
  fieldTypes = FieldType;
  alwaysLoadFieldTypes: FieldType[] = [
      FieldType.Checklist
  ];

  get isUserManagerOfChannel (): boolean {
    return !!ChannelsStore.getChannelsManagerOf.find((channel) => channel.slug === this.channelSlug);
  }

  async created () {
    // locally cached, only first run will demand an API call
    this.dataTypes = await StaticApiDataService.channelsCustomFieldsAllGet();
  }

  // Not all units should be displayed, e.g. custom text (text) looks dumb
  displayUnit (field: ItemCustomField): boolean {
    const dataField = this.dataTypes.filter((el) => el.fieldType === field.fieldType)[0];
    if (!dataField) {
      return false;
    }
    const matchValue = dataField.values.filter((el) => el.value === field.unit);
    return matchValue[0].measurementSystem !== MeasurementSystem.Na || [FieldType.Electrical, FieldType.Sound].includes(dataField.fieldType);
  }
}
