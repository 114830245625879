
import { Component, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import { RouteNames } from '@/router/RouteNames';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import MFooterGeneralSidebar from '@/components/molecules/MFooterGeneralSidebar.vue';
import { MenuIcon } from 'vue-feather-icons';
import ALink from '@/components/atoms/link/ALink.vue';

@Component({
  components: {
    ALink,
    MFooterGeneralSidebar,
    MLanguageSwitcher,
    MenuIcon
  }
})
export default class MFooterGeneral extends Vue {
  routeName = RouteNames;
  open = false;
  footerInMenu = false;

  footerLinks: {
    name?: RouteNames,
    link?: string,
    textKey: string,
    activeAlsoWith: RouteNames[]
  }[] = [{
    name: RouteNames.ROUTE_INFO_WHAT_IS,
    activeAlsoWith: [RouteNames.ROUTE_DASHBOARD],
    textKey: 'footer.forPeople'
  }, {
    name: RouteNames.ROUTE_INFO_BUSINESS,
    activeAlsoWith: [],
    textKey: 'footer.forBusiness'
  }, {
    name: RouteNames.ROUTE_INFO_CONTACT,
    activeAlsoWith: [],
    textKey: 'footer.contact'
  }, {
    link: 'https://blog.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.blog'
  }, {
    name: RouteNames.ROUTE_INFO_CAREERS,
    activeAlsoWith: [],
    textKey: 'footer.careers'
  }, {
    link: 'https://help.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.help'
  }];

  created () {
    this.hideShow();
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  closeSidebar () {
    this.open = false;
  }

  scrollToTop () {
    scrollWindowToElement();
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.hideShow);
  }

  eventsBind () {
    window.addEventListener('resize', this.hideShow);
  }

  hideShow () {
    this.footerInMenu = window.innerWidth <= 640;
  }
}
