import googleMapsStoreKeywordsAddress from '@/utils/googleMapsStoreKeywordsAddress';
import { GeolocationData } from '@/api/ms-item/services/interfaces/Item';
import injectGoogleMapScript from '@/utils/injectGoogleMapScript';

/**
 * Provided a lat/lng it will attempt to return a sensible address back from google reverse geocoder
 */
export default async (input: { lat: number, lng: number }): Promise<GeolocationData> => {
  await injectGoogleMapScript();
  const geocoder = new window.google.maps.Geocoder();
  const position = { lat: input.lat, lng: input.lng };
  const response = await geocoder.geocode({ location: position });
  const place = response.results[0];
  const respObj: GeolocationData = {
    formattedAddress: '',
    placeId: '',
    keywordsAddress: '',
    position: {
      // !important - the order has to be lng the lat for the database geo object
      coordinates: [input.lng, input.lat],
      type: 'Point'
    }
  };
  if (place) {
    respObj.formattedAddress = place.formatted_address;
    respObj.keywordsAddress = googleMapsStoreKeywordsAddress(place.address_components);
    respObj.placeId = place.place_id;
  }
  return respObj;
};
