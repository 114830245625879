
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component
export default class MFullPageErrors extends Vue {
  @Prop({ required: true })
  errorCode!: number;

  @Prop({ required: true })
  message!: string;

  launchLogin () {
    AuthenticationStore.setCurrentRouteAsForwardingLocation(this.$route);
    EventBus.$emit(EventBusEvents.AUTH_LOGIN_CLICKED);
  }
}
