
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SearchType } from '@/store/modules/enums/SearchType';
import { Connections, Invitation, UserSearchs } from '@/api/ms-authentication/services/interfaces';
import { AuthenticationStore, PeopleStore } from '@/store';
import { People } from '@/store/modules/interfaces/PeopleModule';

import { User } from '@/api/ms-authentication/services/interfaces/Login';
import MPersonConnectedCard from '@/components/molecules/MPersonConnectedCard.vue';
import MPersonNotConnectedCard from '@/components/molecules/MPersonNotConnectedCard.vue';
import MPersonCardSkeleton from '@/components/molecules/skeletons/MPersonCardSkeleton.vue';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import MHeaderLoginTrigger from '@/components/molecules/MHeaderLoginTrigger.vue';
import { XIcon } from 'vue-feather-icons';
import { PeopleFetchEnum } from '@/store/modules/enums/PeopleFetchTypes';
import { RouteNames } from '@/router/RouteNames';
import OPeopleManagement from '@/components/organisms/OPeopleManagement.vue';
import { ChannelOrPeopleContainerSelectedTab } from '@/enums/ChannelOrPeopleContainerSelectedTab';
import EventBus, { EventBusEvents } from '@/EventBus';
import { OModalsContainerInviteByEmail } from '@/components/organisms/OModalsContainer.vue';
import { splitKeepLeftover } from 'common-utils/string';

@Component({
  components: {
    OPeopleManagement,
    MPersonConnectedCard,
    MPersonNotConnectedCard,
    MPersonCardSkeleton,
    MAuthenticationTabs,
    MHeaderLoginTrigger,
    XIcon
  }
})
export default class OPeopleContainer extends Vue {
  @Prop({ default: false })
  typeChannel!: boolean;

  @Prop({ default: false })
  typeProfile!: boolean;

  @Prop({ default: false })
  ownProfile!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  // Used to determine what message to display to users
  @Prop({ default: '' })
  searchString!: string;

  @Prop()
  loadingMore!: boolean;

  @Prop({ default: false })
  displayOnlyMine!: boolean;

  callerId = 'OPeopleContainer';

  availableTabs = ChannelOrPeopleContainerSelectedTab;
  selectedTab: ChannelOrPeopleContainerSelectedTab = ChannelOrPeopleContainerSelectedTab.yours;

  activeLoading: string = '';
  peopleTypes = { ...PeopleFetchEnum };

  $refs!: {
    parent: Element
  };

  isVertical = true;

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get people (): People {
    return PeopleStore.getPeople;
  }

  get getConnectionRequests () {
    return PeopleStore.getConnectionRequests;
  }

  get peopleConnected (): Connections {
    return this.people[
        this.typeChannel ?
            SearchType.channel :
            this.typeProfile ?
                SearchType.profile :
                SearchType.dashboard
        ].connected;
  }

  get peopleNotConnected (): UserSearchs {
    return this.people[
        this.typeChannel ?
            SearchType.channel :
            this.typeProfile ?
                SearchType.profile :
                SearchType.dashboard
        ].searchResults;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get noMoreResults () {
    return PeopleStore.getNoMoreResults;
  }

  created () {
    this.loadTabFromQuery();
    this.setIsVertical();
    this.bindEvents();
  }

  loadTabFromQuery () {
    if (this.$route.query.stab) {
      this.selectedTab = this.$route.query.stab as ChannelOrPeopleContainerSelectedTab;
    }
  }

  loadMorePeople (isConnected: boolean): void {
    this.activeLoading = isConnected ? this.peopleTypes.connected : this.peopleTypes.notConnected;
    let type: string;

    if (this.typeProfile && !this.isOwnProfile()) {
      type = isConnected ? this.peopleTypes.matching : this.peopleTypes.notMatching;
    } else {
      type = isConnected ? this.peopleTypes.connected : this.peopleTypes.notConnected;
    }

    this.$emit('child-output', type);
  }

  getNumberOfCardSkeletons (size: number): number {
    return 6 - (size + 3) % 3;
  }

  isOwnProfile (): boolean {
    return this.currentUser.username === this.$route.params.username ||
        this.$route.name === RouteNames.ROUTE_YOUR_PROFILE;
  }

  isOtherProfilePage () {
    if (this.$route.name === RouteNames.ROUTE_YOUR_PROFILE) {
      return false;
    } else {
      return this.$route.name === RouteNames.ROUTE_PROFILE && this.currentUser.username !== this.$route.params.username;
    }
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  unbindEvents () {
    window.addEventListener('resize', this.setIsVertical);
    EventBus.$remove(EventBusEvents.TOUR_OPEN_FIND_CONNECTIONS_TAB, this.callerId);
    EventBus.$remove(EventBusEvents.INVITE_BY_EMAIL_SENT, this.callerId);
  }

  bindEvents () {
    window.addEventListener('resize', this.setIsVertical);
    EventBus.$on(EventBusEvents.TOUR_OPEN_FIND_CONNECTIONS_TAB, this.callerId, () => {
      this.selectedTab = ChannelOrPeopleContainerSelectedTab.discover;
    });
    EventBus.$on(EventBusEvents.INVITE_BY_EMAIL_SENT, this.callerId, this.inviteSent);
  }

  setIsVertical () {
    this.isVertical = window.innerWidth > 545;
  }

  loadInvitationForm () {
    const payload: OModalsContainerInviteByEmail = {
      includeCodeToCopy: false,
      message: this.$t('page.invitations.defaultMessage') as string
    };
    // if search string looks like an email, pass the string as an email
    if(this.searchString.match(/^\S+@\S+\.\S+$/)){
      payload.email = this.searchString;
    } else if( this.searchString.match(' ')){
      // if there is a space, split at the first space and pass that as first name and rest as last name
      const strSplit = splitKeepLeftover(this.searchString, ' ', 1);
      payload.firstName = strSplit[0];
      payload.lastName = strSplit[1];
    } else {
      // else assume it's just the first name only
      payload.firstName = this.searchString;
    }
    EventBus.$emit(EventBusEvents.INVITE_BY_EMAIL, payload);
  }

  inviteSent (sent: Invitation) {
    if( sent.invitedTo && sent.invitedTo.length > 0 ){
      this.$buefy.dialog.alert({
        title: this.$t('page.invitations.invitationSent.title') as string,
        message: this.$t('page.invitations.invitationSent.message', {
          firstName: sent.invitedTo[0].firstName,
          lastName: sent.invitedTo[0].lastName,
          email: sent.invitedTo[0].email,
        }) as string
      });
    }
  }
}
