
import isApp from '@/utils/isApp';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { types as imageTypes } from '@/constants/imageTypes';
import { CameraIcon } from 'vue-feather-icons';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: {
    CameraIcon
  }
})
export default class MImageUpload extends Vue {
  @Prop({ required: true })
  value!: any;

  @Prop({ required: true })
  maxImageUploadReached!: boolean;

  loading: boolean = false;

  imageTypesAllowed = imageTypes.join(',');

  get isApp (): boolean {
    return isApp();
  }

  created () {
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.CAPJS_GOT_PIC, 'MImageUpload', async (output: { imageBase64: string }) => {
      this.$emit('input', [output]);
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.CAPJS_GOT_PIC, 'MImageUpload');
  }

  deviceCamera () {
    EventBus.$emit(EventBusEvents.CAPJS_GET_PIC);
  }
}
