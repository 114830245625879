import { render, staticRenderFns } from "./MUrlPreview.vue?vue&type=template&id=4480532d&scoped=true&"
import script from "./MUrlPreview.vue?vue&type=script&lang=ts&"
export * from "./MUrlPreview.vue?vue&type=script&lang=ts&"
import style0 from "./MUrlPreview.vue?vue&type=style&index=0&id=4480532d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4480532d",
  null
  
)

export default component.exports