
import { Component, Vue } from 'vue-property-decorator';
import { Language } from '@/api/ms-authentication/services/interfaces/UserPut';
import { AuthenticationStore } from '@/store';
import { translation } from '@/plugins/i18n/Translation';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import setLanguage from '@/utils/setLanguage';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';

@Component({
  components: { MLanguageSwitcher }
})
export default class MSelectLanguageModal extends Vue {
  languages: { label: TranslateResult; value: string; }[] = [];
  languageForm: Language = Language.EnGB;
  loading = false;

  get language () {
    return translation.currentLanguage;
  }

  get supportedLanguages () {
    return translation.supportedLanguages;
  }

  created () {
    this.languageForm = AuthenticationStore.currentUser.language;
    this.getSupportedLanguages();
  }

  getSupportedLanguages () {
    this.languages = this.supportedLanguages.map(lang => {
      return { label: this.$t('language.' + lang), value: lang };
    });
  }

  async selectLanguage () {
    this.loading = true;
    await setLanguage(this.languageForm);
    this.loading = false;
  }

  close () {
    this.$emit('close');
  }
}
