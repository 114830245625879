
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChannelsService from '@/api/ms-channel/services/ChannelsService';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { AuthenticationStore, ItemEntryStore } from '@/store';
import { User } from '@/api/ms-authentication/services/interfaces';
import { Datum as ChannelsDatum } from '@/api/ms-channel/services/interfaces/Channels';
import { Privacy } from '@/api/ms-channel/services/interfaces/Channel';

import AImage from '@/components/atoms/AImage.vue';
import { ArrowLeftIcon, CalendarIcon, EyeIcon, EyeOffIcon, PlusIcon, UsersIcon } from 'vue-feather-icons';
import MChannelSelectorSkeleton from '@/components/molecules/skeletons/MChannelSelectorSkeleton.vue';
import { Datum } from '@/api/ms-channel/services/interfaces/ChannelWithRelationalMetas';

import { Channel } from '@/api/ms-channel/services/interfaces';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import updateItemEntryLastChannelUsed, { FormattedChannel } from '@/utils/updateItemEntryLastChannelUsed';
import { ChannelType } from '@/api/ms-item/services/interfaces/Item';
import { translation } from '@/plugins/i18n/Translation';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import OChannelUpsertFormStepped from '@/components/organisms/forms/OChannelUpsertFormStepped.vue';
import seoInjector from '@/services/SeoInjector';
import { stripTags } from 'common-utils/string';
import MChannelsRecentlyAddedTo from '@/components/molecules/channel/MChannelsRecentlyAddedTo.vue';
import { clone } from 'common-utils/object';

export interface OChannelUpsertFormInterfacesChildOutput {
  action: string,
  channel: Channel
}

@Component({
  components: {
    MChannelsRecentlyAddedTo,
    OChannelUpsertFormStepped,
    ALightbulbOnOutlineIcon,
    MInputWithValidation,
    MChannelSelectorSkeleton,
    AImage,
    ArrowLeftIcon,
    CalendarIcon,
    EyeIcon,
    EyeOffIcon,
    UsersIcon,
    PlusIcon
  },
  filters: {
    stripTags: stripTags
  }
})
export default class MItemEntryChannelSelector extends Vue {
  @Prop({ required: true })
  isMobileView!: boolean;
  @Prop({ required: true })
  displayCustomFieldWarning!: boolean;

  loading: boolean = false;
  loadingMore: boolean = false;
  channelsFetched: ChannelsDatum[] = [];
  formattedChannels: FormattedChannel[] = [];
  imageSize = Size.The60X60;
  privacy = Privacy;
  noMoreResults: boolean = false;
  showCreateChannelForm: boolean = false;
  search: string = '';
  channelTypes = ChannelType;

  storePageTitle = {
    str: '',
    get title () {
      return this.str.replace(' | Liffery', '');
    }
  };

  get selectedChannel () {
    return ItemEntryStore.lastChannelAddedToGet;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get selectedOptionText (): string {
    return this.selectedChannel.name === '' || this.selectedChannel.name === 'default' ?
        this.$t('dict.general') as string :
        this.selectedChannel.name;
  }

  get language () {
    return translation.currentLanguage;
  }

  get loadingSomething () {
    return this.loading || this.loadingMore;
  }

  hideChannelSelector (): void {
    this.$emit('hide-channel-selector');
  }

  beforeDestroy () {
    seoInjector.setPageTitle(this.storePageTitle.title);
  }

  async created () {
    this.storePageTitle.str = seoInjector.getPageTitle();
    seoInjector.setPageTitle('Select Channel');

    await this.setChannels();
    await this.checkSelectedChannel();
  }

  formatChannels (channels): FormattedChannel[] {
    return channels.map((channel: Datum) => ({
      imagePath: channel.imagePath,
      isDefaultChannel: false,
      allCanAdd: channel.allCanAdd,
      currentUserCanAdd: channel.currentUserCanAdd,
      event: channel.event,
      name: channel.name,
      description: channel.description,
      privacy: channel.privacy,
      slug: channel.slug,
      channelType: channel.channelType,
      memberCount: channel.memberCount,
      customFields: channel.customFields
    }));
  }

  async setChannels (): Promise<void> {
    this.loading = true;
    const { data } = await ChannelsService.channelsGet({
      memberOf: true,
      text: this.search
    });
    this.channelsFetched = data;
    this.noMoreResults = data.length < 20;

    const newChannels: FormattedChannel[] = this.formatChannels(data);

    newChannels.unshift({
      name: String(this.$t('dict.general')),
      currentUserCanAdd: true,
      slug: '',
      channelType: ChannelType.General,
      description: String(this.$t('dict.generalChannelDescription')),
      isDefaultChannel: true,
      memberCount: -1,
      customFields: []
    });
    this.formattedChannels = newChannels;
    this.loading = false;
  }

  searchChannelsTimeout: any = null;

  searchChannels (input: string) {
    clearTimeout(this.searchChannelsTimeout);
    this.searchChannelsTimeout = setTimeout(() => {
      this.search = input;
      this.setChannels();
    }, 500);
  }

  /**
   * Preloading form with a channel selected, i.e. editing an item and changing channel, moving an item.
   * Ensure the selected channel is loaded, and also splice at the top of the list beneath general.
   */
  async checkSelectedChannel () {
    const selectedChannelSlug = this.selectedChannel.channel?.slug;
    if (selectedChannelSlug) {
      if (!this.channelsFetched.find((channel: ChannelsDatum) => channel.slug === selectedChannelSlug)) {
        // if there are no more channels to load, the channel must be deleted or user removed, clear
        if (this.noMoreResults) {
          ItemEntryStore.CLEAR_LAST_CHANNEL_USED();
        } else {
          // otherwise load the next batch of channels and try to find again
          await this.handleLoadMore();
          await this.checkSelectedChannel();
          this.spliceSelectedChannelToTop(selectedChannelSlug);
        }
      } else {
        this.spliceSelectedChannelToTop(selectedChannelSlug);
      }
    }
  }

  /**
   * Moves the selected channel from whatever position it is in to the top of the list, directly beneath general
   */
  spliceSelectedChannelToTop (slug: string) {
    const index = this.formattedChannels.findIndex((channel) => channel.slug === slug);
    if (index !== -1) {
      const channel = clone(this.formattedChannels[index]);
      // delete the old position then place into the top position beneath general
      this.formattedChannels.splice(index, 1);
      this.formattedChannels.splice(1, 0, channel);
    }
  }

  async handleSelection (channel: FormattedChannel): Promise<void> {
    if (!channel.currentUserCanAdd && !channel.allCanAdd) {
      return;
    }
    if (!channel) {
      ItemEntryStore.CLEAR_LAST_CHANNEL_USED();
      return;
    }

    // set the selected channel
    updateItemEntryLastChannelUsed(channel);

    this.hideChannelSelector();
  }

  channelCreated (output: OChannelUpsertFormInterfacesChildOutput): void {
    ItemEntryStore.UPDATE_LAST_CHANNEL_USED({
      name: output.channel.name,
      channel: {
        name: output.channel.name,
        slug: output.channel.slug as string,
        channelType: output.channel.channelType || ChannelType.General
      },
      channelPrivacy: output.channel.privacy,
      customFields: output.channel.customFields
    });

    this.hideChannelSelector();
  }

  async handleLoadMore () {
    this.loadingMore = true;

    const { data } = await ChannelsService.channelsGet({ memberOf: true, offset: this.formattedChannels.length - 1 });
    this.channelsFetched = data;
    this.noMoreResults = data.length < 20;

    const newChannels: FormattedChannel[] = this.formatChannels(data);
    this.formattedChannels = this.formattedChannels.concat(newChannels);

    this.loadingMore = false;
  }
}
