export interface ItemsOnShoppingListUsernameGetQuery {
  /**
   * The state of the shoppinglist item, to get or already got
   */
  getGotStage?: GetGotStage[];
  /**
   * Refers the the [max] number of results returned
   */
  limit?: number;
  /**
   * The number of items to skip before starting to collect the result set.
   */
  offset?: number;
}

export enum GetGotStage {
  StageGet = 'stageGet',
  StageGot = 'stageGot',
}
