
import { Component, Prop, Vue } from 'vue-property-decorator';
import AFireIconSvg from '@/components/atoms/icon/svg/AFireIconSvg.vue';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';

@Component({
  components: { ARouterLinkIssueDetail, ADecisionMakerIconSvg, AFireIconSvg }
})
export default class ADecisionMakerIcon extends Vue {
  @Prop({ default: false })
  loading!: boolean | undefined;

  @Prop({ required: true })
  uniqueItemName!: string;

  @Prop({ default: false })
  swipe!: boolean;

  @Prop({ required: true })
  swipeScore!: number;

  @Prop()
  iconSize!: string;

  @Prop()
  disabled!: string;

  @Prop({ default: false })
  numericOnly!: boolean;
}
