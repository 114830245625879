
import { EditIcon, EyeIcon, EyeOffIcon, UsersIcon } from 'vue-feather-icons';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';

import EventBus, { EventBusEvents } from '@/EventBus';

import { SearchType } from '@/store/modules/enums/SearchType';

import { AuthenticationStore, ChannelsStore, ChannelStore, ItemsStore, PeopleStore, PinnedItemsStore } from '@/store';
import itemsFetchQueryBuilder from '@/store/modules/utils/itemsFetchQueryBuilder';

import { CHILD_EMIT_403 } from '@/constants/childEmitEventNames';

import { SubscriptionStatus } from '@/api/ms-channel/services/interfaces/ChannelMember';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { Connections } from '@/api/ms-authentication/services/interfaces';
import { Item, ItemsGetQuery } from '@/api/ms-item/services/interfaces';
import { SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';

import OItemsContainer from '@/components/organisms/OItemsContainer.vue';
import MSearchBarWithTabs, { MSearchBarWithTabsIOutput } from '@/components/molecules/MSearchBarWithTabs.vue';
import OPeopleContainer from '@/components/organisms/OPeopleContainer.vue';
import OChannelMembersContainer from '@/components/organisms/OChannelMembersContainer.vue';
import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ARouterLinkChannelUpsert from '@/components/atoms/link/ARouterLinkChannelUpsert.vue';
import OChannelHeader from '@/components/organisms/OChannelHeader.vue';
import MChannelHeaderSkeleton from '@/components/molecules/skeletons/MChannelHeaderSkeleton.vue';
import MBreadcrumbs from '@/components/molecules/MBreadcrumbs.vue';
import MFullPageErrors from '@/components/molecules/MFullPageErrors.vue';
import AToggleChannelView from '@/components/atoms/buttons/AToggleChannelView.vue';
import OChatSidebar from '@/components/organisms/OChatSidebar.vue';
import OChannelItemsTable from '@/components/organisms/OChannelItemsTable.vue';
import vScrollShouldBeRecalculated from '@/utils/vScrollShouldBeRecalculated';
import channelSlugFromRoute from '@/utils/channelSlugFromRoute';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';
import OChannelItemsMap from '@/components/organisms/OChannelItemsMap.vue';
import { pause } from 'common-utils/time';
import OChannelItemsTableCustomFields from '@/components/organisms/OChannelItemsTableCustomFields.vue';
import { AChannelViewEnums } from '@/store/modules/interfaces/ChannelViewModule';
import { OModalsContainerChannelSwipeItems } from '@/components/organisms/OModalsContainer.vue';
import itemsSearchQuery from '@/utils/itemsSearchQuery';
import mergeObjIntoRouteQuery from '@/utils/mergeObjIntoRouteQuery';
import { itemsFilterSortDefaultsHashMap } from '../organisms/forms/OItemsFilterForm.vue';

export interface itemsTableMeta {
  total: number,
  offset: number,
  perPage: number,
  availableCount: boolean
  sortBy: SortBy;
  sortDir: SortDir;
  sortId?: string;
  defaultSortOrder: SortDir;
}

export const initialItemsTableMeta = {
  total: 0,
  offset: 0,
  perPage: 20,
  availableCount: true,
  sortBy: SortBy.CreatedAt,
  sortDir: SortDir.Desc,
  defaultSortOrder: SortDir.Desc,
};

export const joinedSubscriptionStatuses = [
  SubscriptionStatus.ConciergeJobAccepted,
  SubscriptionStatus.InviteAccepted,
  SubscriptionStatus.JoinRequestAccepted
];

export enum AutoOpen {
  SwipeScore = 'swipe-score'
}

@Component({
  components: {
    OChannelItemsTableCustomFields,
    OChannelItemsTable,
    OChannelItemsMap,
    OChatSidebar,
    AToggleChannelView,
    MFullPageErrors,
    MBreadcrumbs,
    OChannelHeader,
    ARouterLinkChannelUpsert,
    OChannelMembersContainer,
    OPeopleContainer,
    MSearchBarWithTabs,
    MChannelHeaderSkeleton,
    OItemsContainer,
    AImage,
    AUserProfilePic,
    ARouterLinkProfile,
    EditIcon,
    EyeIcon,
    EyeOffIcon,
    UsersIcon
  }
})
export default class TChannel extends Vue {
  searchInput: string = '';
  selectedTab: ASearchBarTabType = ASearchBarTabType.items;
  aSearchBarTabType = ASearchBarTabType;
  isMemberStatuses = joinedSubscriptionStatuses;
  requestSent: boolean = false;
  channelHeaderHeight: number = 0;

  channelSlug: any = {
    value: ''
  };
  requestingToJoinLoading: boolean = false;
  loadingChannelMeta: boolean = false;
  loadingItemsOrPeople: boolean = false;
  loadingMore: boolean = false;
  errorCode: number | null = null;
  fetchingItems: boolean = false;

  activeView: AChannelViewEnums = AChannelViewEnums.grid;
  pageTabs: string[] = [ASearchBarTabType.items, ASearchBarTabType.pinned, ASearchBarTabType.people];
  itemsTableView: Item[] = [];
  itemsTableMeta: itemsTableMeta = initialItemsTableMeta;

  lastSearchTexts: {
    items: string,
    people: string,
  } = {
    items: '',
    people: '',
  };

  lastSearchFilterSorts: {
    items: ItemsGetQuery
  } = { items: {} };

  get channel () {
    return ChannelStore.getCurrentChannelViewed;
  }

  get hasCustomfields (): boolean {
    return !!(this.channel.customFields && this.channel.customFields.length > 0);
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  get notMemberOf (): boolean {
    return ChannelsStore.getCurrentChannelNotMemberOf;
  }

  get peopleConnected (): Connections {
    return PeopleStore.getPeople[SearchType.channel].connected;
  }

  get tableViewLoading (): boolean {
    return (this.loadingChannelMeta || this.fetchingItems);
  }

  beforeRouteEnter (to: Route, from: Route, next) {
    // todo clear previous channel data - or alternatively on exit
    const lastSearch = ItemsStore.getSearchQuery;
    const currentSlug = channelSlugFromRoute(to);
    if (
        ItemsStore.getLastSearchType !== SearchType.channel ||
        ItemsStore.getLastSearchType === SearchType.channel && lastSearch?.channel !== currentSlug
    ) {
      // clear the items from the stores
      ItemsStore.CLEAR_ITEMS();
      PinnedItemsStore.CLEAR_PINNED_ITEMS();
    }
    ChannelStore.CLEAR_CHANNEL();
    PeopleStore.RESET();
    next();
  }

  /**
   * Guard against a user trying to navigate to the same channel by mistake with filter sorts applied. Ensures the query
   * parameters stay in the URL.
   */
  beforeRouteUpdate (to: Route, from: Route, next: any) {
    const fromUsername = from.params.channelSlug0;
    const fromChannelName = from.params.channelSlug1;
    const toUsername = to.params.channelSlug0;
    const toChannelName = to.params.channelSlug1;
    if (fromUsername === toUsername && fromChannelName === toChannelName) {
      if (!this.hasQueryParams(to) && this.hasQueryParams(from)) {
        // this is same channel to same channel and query parameters are missing, add them back in
        const toWithQuery = Object.assign({}, to, { query: from.query });
        next(toWithQuery);
      } else {
        next();
      }
    } else {
      next();
    }
  }

  hasQueryParams (route: Route): boolean {
    return !!Object.keys(route.query).length;
  }

  @Watch('selectedTab')
  selectedTableHandle (newVal: ASearchBarTabType) {
    if (newVal === this.aSearchBarTabType.people) {
      this.activeView = AChannelViewEnums.grid;
    }
  }

  @Watch('activeView')
  activeViewHandle () {
    this.setActiveView();
    if (this.activeView === AChannelViewEnums.table) {
      this.refetchItems();
    } else {
      this.reloadTab();
    }
  }

  setActiveView () {
    this.activeView = this.$route.params.tab as unknown as AChannelViewEnums || AChannelViewEnums.grid;
  }

  setOnLoadSortBy () {
    const searchQuery = itemsSearchQuery(this.$route);
    if (Object.values(searchQuery).some(el => typeof el !== 'undefined')) {
      this.itemsTableMeta.sortBy = searchQuery.sortBy ? searchQuery.sortBy : this.itemsTableMeta.sortBy;
      this.itemsTableMeta.sortDir = searchQuery.sortDir ? searchQuery.sortDir : this.itemsTableMeta.sortDir;
      this.itemsTableMeta.sortId = searchQuery.sortId ? searchQuery.sortId : this.itemsTableMeta.sortId;
    }
  }

  async created () {
    this.loadingChannelMeta = true;
    this.channelSlug.value = channelSlugFromRoute(this.$route);
    this.setActiveView();
    this.setOnLoadSortBy();

    if (!this.notMemberOf && !this.peopleConnected.data.length && this.isAuthenticated) {
      await PeopleStore.peopleSearch({
        search: this.searchInput,
        searchType: SearchType.channel,
        searchTypeId: this.currentUser.username
      });
    }

    this.eventsBind();
    await this.reloadTab();
    // await pause(3000);
    try {
      await ChannelStore.fetchChannelData(this.channelSlug.value);
      await ChannelStore.areNotificationsEnabledForChannel();
    } catch (e) {
      this.$emit(CHILD_EMIT_403, e);
    }

    this.loadingChannelMeta = false;
  }

  mounted () {
    this.getHeaderHeight();
    this.autoOpen();
  }

  beforeDestroy () {
    EventBus.$emit(EventBusEvents.CHANNEL_SWIPE_ITEMS_CLEAR);
    this.eventsUnbind();
  }

  eventsBind () {
    window.addEventListener('resize', this.getHeaderHeight);
    const callerId = 'TChannel';
    EventBus.$on(EventBusEvents.AUTH_CHANGE, callerId, this.newSearch);
    EventBus.$on(EventBusEvents.ITEM_FILTERS_APPLY, callerId, this.newSearch);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.getHeaderHeight);
    const callerId = 'TChannel';
    EventBus.$remove(EventBusEvents.AUTH_CHANGE, callerId);
    EventBus.$remove(EventBusEvents.ITEM_FILTERS_APPLY, callerId);
  }

  openWatch: () => void = () => {};

  autoOpen () {
    const open: AutoOpen = this.$route.query.open as AutoOpen;
    if (open) {
      switch (open) {
        case AutoOpen.SwipeScore:
          this.openWatch = this.$watch('channel', function (newValue) {
            // wait for channel load - once a slug has loaded into newValue (it's cleared on beforeEnter), the getter has returned this channel
            if (newValue.slug !== '') {
              this.launchSwipeItems();
              this.openWatch(); // clear watcher
            }
          }, { immediate: true });
          break;
      }
    }
  }

  toggleChannelView (type: AChannelViewEnums): void {
    this.activeView = type;

    switch (this.activeView) {
      case AChannelViewEnums.table: {
        this.newSearch();
      }
        break;
      case AChannelViewEnums.grid: {
        this.fakeResizeWindow();
      }
        break;
    }

    // Create a new route for the tab and merge in the existing query params.
    this.$router.push({
      name: this.$route.name as string,
      params: {
        channelSlug0: this.$route.params.channelSlug0,
        channelSlug1: this.$route.params.channelSlug1,
        tab: this.activeView
      },
      query: { ...this.$route.query }
    });
  }

  fakeResizeWindow (): void {
    // TODO: "creative" solution for now
    // virtual scroller wasn't rendering the items when the window was resized on table view mode and switched back to grid view
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  getHeaderHeight (): void {
    const channelHeader = document.querySelector('.channel-header-items') as any;

    if (channelHeader) {
      this.channelHeaderHeight = channelHeader.offsetHeight - 40;
    }
  }

  async reloadTab (): Promise<void> {
    this.loadingItemsOrPeople = true;

    switch (this.selectedTab) {
      case ASearchBarTabType.items:
        ItemsStore.queryContinuedState({ $route: this.$route, searchType: SearchType.channel });
        // if no items in store or they are all skeletons run a new search
        if (ItemsStore.getItems.items.length === 0 || ItemsStore.getItems.items.filter((element) => !element.data.skeleton).length === 0 || !ItemsStore.getContinuedState) {
          return this.newSearch();
        } else {
          if (await vScrollShouldBeRecalculated()) {
            await ItemsStore.recalculateVirtualScroll();
          }
          await ItemsStore.fetchLatest(this.$route);
        }
        break;
      case ASearchBarTabType.people:
        return this.newSearch();
    }
    await pause(1000);
    this.loadingItemsOrPeople = false;
  }

  // eslint-disable-next-line max-lines-per-function
  async newSearch (): Promise<void> {
    if (this.activeView === AChannelViewEnums.grid || [ASearchBarTabType.pinned, ASearchBarTabType.people].includes(this.selectedTab)) {
      this.loadingItemsOrPeople = true;
      switch (this.selectedTab) {
        case ASearchBarTabType.items:
          try {
            const searchQuery = itemsSearchQuery(this.$route);
            this.lastSearchFilterSorts.items = searchQuery;
            await ItemsStore.itemSearch({
              search: {
                text: this.searchInput,
                channel: this.channelSlug.value,
                dateLT: new Date(),
                ...searchQuery
              },
              searchType: SearchType.channel
            });
          } catch (e: any) {
            this.errorCode = e.response.status;
          }
          break;

        case ASearchBarTabType.pinned:
          try {
            const searchObj = {
              channel: this.channelSlug.value,
              dateLT: new Date(),
              sortBy: SortBy.PinCount,
              sortDir: SortDir.Desc,
              text: this.searchInput,
            };
            await PinnedItemsStore.pinnedItemSearch({
              search: searchObj,
              searchType: SearchType.channel
            });
          } catch (e: any) {
            this.errorCode = e.response.status;
          }
          break;

        case ASearchBarTabType.people:
          await ChannelsStore.fetchChannelMembers({
            slug: this.channelSlug.value,
            searchString: this.searchInput
          });
          break;
      }
      this.loadingItemsOrPeople = false;
    } else if (this.activeView === AChannelViewEnums.table) {
      await this.fetchChannelItemsTableView();
    }
  }

  handleItemEdited (newItem) {
    ItemsStore.SET_ITEM_DETAIL(newItem);
  }

  async fetchChannelItemsTableView (): Promise<void> {
    this.fetchingItems = true;
    const { offset, availableCount } = this.itemsTableMeta;

    const searchQuery = itemsSearchQuery(this.$route);
    this.lastSearchFilterSorts.items = searchQuery;
    const query: ItemsGetQuery = {
      channel: this.channelSlug.value,
      offset: offset,
      availableCount,
      ...searchQuery
    };

    try {
      const { data, meta } = await itemsFetchQueryBuilder({ search: { ...query }, searchType: SearchType.channel });
      this.itemsTableView = data as unknown as Item[];
      this.itemsTableMeta.total = +meta.availableCount!;
    } catch (e) {
      // TODO: handle error
      console.log(e);
    }
    this.fetchingItems = false;
  }

  onPageChange (page: number) {
    this.itemsTableMeta.offset = (page - 1) * this.itemsTableMeta.perPage;
    this.fetchChannelItemsTableView();
  }

  async onSort (input: { sortBy: SortBy, sortDir: SortDir, sortId?: string }): Promise<void> {
    const { sortBy, sortDir, sortId } = input;
    this.itemsTableMeta.sortDir = sortDir;
    this.itemsTableMeta.sortBy = sortBy;
    this.itemsTableMeta.sortId = sortId;
    const query = {
      ...this.$route.query,
      ...input
    };
    await this.$router.push({ query });

    await this.fetchChannelItemsTableView();
  }

  async refetchItems () {
    await this.fetchChannelItemsTableView();
  }

  /**
   * Search string and/or tab change
   */
  async handleSearchBarOutput (output: MSearchBarWithTabsIOutput) {
    this.searchInput = output.search;
    this.selectedTab = output.selectedTab;
    const newSearch = this.lastSearchTexts[this.selectedTab] !== output.search;

    this.lastSearchTexts[this.selectedTab] = this.searchInput;

    // if returning to the items tab, ensure the search query is returned to the url
    if (this.selectedTab === ASearchBarTabType.items) {
      await this.restoreItemsSearchQuery();
    }

    if (newSearch) {
      await this.newSearch();
    } else {
      await this.reloadTab();
    }

    if (![ASearchBarTabType.items, ASearchBarTabType.pinned].includes(this.selectedTab)) {
      ItemsStore.SET_SCROLL_OFFSET({
        offset: 0
      });
    } else {
      this.fakeResizeWindow();
    }
  }

  async restoreItemsSearchQuery () {
    const query = mergeObjIntoRouteQuery(this.$route, this.lastSearchFilterSorts.items, itemsFilterSortDefaultsHashMap);
    if (query) {
      return this.$router.push({ query });
    }
  }

  handleLoadMoreMembers () {
    ChannelsStore.fetchLatestChannelMembers(this.channelSlug.value);
  }

  async requestToJoin () {
    this.requestingToJoinLoading = true;
    await ChannelMemberService.channelMemberSlugJoinRequestPost({
      slug: this.channelSlug.value
    });
    this.requestSent = true;
    this.requestingToJoinLoading = true;
  }

  launchSwipeItems () {
    const payload: OModalsContainerChannelSwipeItems = {
      channel: this.channel
    };
    EventBus.$emit(EventBusEvents.CHANNEL_SWIPE_ITEMS, payload);
  }
}
