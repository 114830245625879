
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import ARouterLinkItemUrl from '@/components/atoms/link/ARouterLinkItemUrl.vue';
import { SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  MessageSquareIcon,
  MoreVerticalIcon,
  SettingsIcon,
  ThumbsUpIcon
} from 'vue-feather-icons';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import { Channel } from '@/api/ms-channel/services/interfaces';
import { CustomField } from '@/api/ms-channel/services/interfaces/Channel';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import { AuthenticationStore, ChannelViewStore, ItemsStore } from '@/store';
import StaticApiDataService from '@/services/StaticApiDataService';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { clone, getObjectValue } from 'common-utils/object';
import { CustomFieldDisplay, NonCustomField, NonCustomFields } from '@/store/modules/interfaces/ChannelViewModule';
import MChannelCustomFieldTableSettings from '@/components/molecules/channel/MChannelCustomFieldTableSettings.vue';
import CustomFields from '@/services/CustomFields';
import MCustomFieldDisplay from '@/components/molecules/MCustomFieldDisplay.vue';
import MItemActionsDropdown from '@/components/molecules/MItemActionsDropdown.vue';
import { RouteNames } from '@/router/RouteNames';
import MImageWithLightbox from '@/components/molecules/MImageWithLightbox.vue';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import AItemPrice from '@/components/atoms/AItemPrice.vue';

@Component({
  components: {
    MImageWithLightbox,
    MItemActionsDropdown,
    MCustomFieldDisplay,
    MChannelCustomFieldTableSettings,
    MButtonWithIcon,
    ARouterLinkIssueDetail,
    ARouterLinkItemUrl,
    AImage,
    AItemPrice,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ThumbsUpIcon,
    MessageSquareIcon,
    MoreVerticalIcon,
    SettingsIcon
  },
  filters: {
    removeUrl: remove1stUrlFromString,
  },
})
export default class OChannelItemsTableCustomFields extends Vue {
  @Prop()
  loading!: boolean;
  @Prop({ required: true })
  items!: Item[];
  @Prop({ required: true })
  channel!: Channel;
  @Prop({ required: true })
  total!: number;
  @Prop({ default: 20 })
  perPage!: number;
  @Prop({ required: true })
  sortingDirection!: SortDir;
  @Prop({ required: true })
  sortBy!: SortBy;
  @Prop({ required: true })
  sortDir!: SortDir;
  @Prop({ required: true })
  sortId!: string;

  loadingInternal: boolean = false;
  imageSize: Size = Size.The200X;
  sortByOptionsArray = Object.values(SortBy);
  sortDirections = SortDir;
  customFieldDisplay: CustomFieldDisplay[] = [];
  staticApiData: CustomFieldsUnitGroupeds = [];
  dropdownPosition = 'is-top-right';
  refetchItemsTimeout: any = {};
  tableKey = 0;

  get channelViewSetting () {
    return ChannelViewStore.getChannelsViewed.find((channel) => channel.slug === this.channel.slug);
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get currentUserOwnsChannel (): boolean {
    return this.currentUser.username === this.channel.owner.username;
  }

  get noItemsInChannel () {
    return ItemsStore.getItems.items.length;
  }

  eventsBind () {
    window.addEventListener('resize', this.tableHitModalBreakpoint);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.tableHitModalBreakpoint);
  }

  async created () {
    ChannelViewStore.pruneOldChannels();
    this.setColumns();
    this.eventsBind();
    this.tableHitModalBreakpoint();
    this.staticApiData = await StaticApiDataService.channelsCustomFieldsAllGet();
    this.checkLoadedSortBy();
  }

  mounted () {
    scrollWindowToElement('smooth', '.OChannelItemsTable', true);
    //if using browser back no items will have been loaded yet, emit to fetch the first page of results.
    if (!this.items.length) {
      this.$emit('page-change', 1);
    }
  }

  tableHitModalBreakpoint () {
    // 769px is the tablet breakpoint https://bulma.io/documentation/overview/responsiveness/
    this.dropdownPosition = window.innerWidth > 768 ? 'is-top-right' : 'is-bottom-left';
  }

  getFieldValue (object, path) {
    return getObjectValue(object, path);
  }

  allStandardOff () {
    ChannelViewStore.setAllStandardOff({ slug: this.channel.slug });
    this.forceTableReload();
  }

  allStandardOn () {
    ChannelViewStore.setAllStandardOn({ slug: this.channel.slug });
    this.forceTableReload();
  }

  standardReset () {
    ChannelViewStore.setStandardToDefault({ slug: this.channel.slug });
    this.forceTableReload();
  }

  allCustomOff () {
    ChannelViewStore.setAllCustomOff({ slug: this.channel.slug });
    this.forceTableReload();
  }

  allCustomOn () {
    ChannelViewStore.setAllCustomOn({ slug: this.channel.slug });
    this.forceTableReload();
  }

  async saveCurrentSettingsAsChannelDefault (input: {
    customFieldDisplay: CustomFieldDisplay[],
    nonCustomFields: NonCustomFields
  }) {
    await ChannelService.channelSlugSlugCustomFieldOrderPut({
      customFieldDisplay: input.customFieldDisplay.map((field) => ({
        _id: field._id,
        show: field.show
      })),
      nonCustomFields: input.nonCustomFields.map((field) => ({
        key: field.key,
        show: field.show
      })),
    }, {
      slug: this.channel.slug
    });
  }

  forceTableReload () {
    ++this.tableKey;
    this.checkSortByOverride();
  }

  // either adds the channel columns entirely, or updates the custom fields columns for an existing channel
  setColumns (): void {
    if (this.channel.customFields) {
      const channelFields = clone(this.channel.customFields);
      // if a channel doesn't exist in store yet, adds it in with the relevant custom fields so getter returns object to use
      if (!this.channelViewSetting) {
        // if we have settings stored against the channel, set these, else set the default
        ChannelViewStore.addNewChannel({
          slugCustomFields: {
            slug: this.channel.slug,
            fields: channelFields.map((field) => {
                  return {
                    ...{ show: true },
                    ...field
                  };
                }
            )
          },
          savedCustomFieldsOrder: this.channel.customFieldsOrder
        });
      } else {
        // the channel exists in the store, update the fields with channels object as names may change or fields added/removed
        ChannelViewStore.setAllCustomFields({
          slug: this.channel.slug,
          fields: channelFields.map((field) => {
            // @ts-ignore
            const exists = this.channelViewSetting.displayCustomFields.find((f) => f._id === field._id);
            return {
              ...{ show: typeof exists !== 'undefined' ? exists.show : true },
              ...field
            };
          })
        });
      }
    }
  }

  standardFieldToggle (input: { show: boolean, field: NonCustomField }) {
    const { show, field } = input;
    field.show = show;
    // save the change to the store
    ChannelViewStore.setOneStandardField({ slug: this.channel.slug, field });
    this.forceTableReload();
  }

  customFieldToggle (input: { show: boolean, field: CustomFieldDisplay }) {
    const { show, field } = input;
    field.show = show;
    // save the change to the store
    ChannelViewStore.setOneCustomField({ slug: this.channel.slug, field });
    this.forceTableReload();
  }

  customChannelFieldData (id: string): CustomField {
    return this.channel.customFields?.find((field) => field._id === id) as CustomField;
  }

  customFieldValueById (id: string, item: Item) {
    return item.editable.channel?.customFields?.find((field) => field._id === id);
  }

  getCustomColumnLabel (column) {
    const unit = CustomFields.channelUnitDisplay(column);
    return column.label + (unit ? ` (${column.unit})` : '');
  }

  onPageChange (page: number): void {
    this.$emit('page-change', page);
  }

  onSort (sortBy: SortBy, sortDir: SortDir): void {
    let sortId: string | undefined;
    let sortColumn = sortBy;
    if (!this.sortByOptionsArray.includes(sortBy)) {
      sortColumn = SortBy.CustomFields;
      sortId = sortBy;
    }
    this.$emit('sort-change', { sortBy: sortColumn, sortDir, sortId });
  }

  goToItemDetail (item: Item) {
    this.$router.push({
      name: RouteNames.ROUTE_ITEM_DETAIL_VIEW, params: {
        uniqueItemName: item.uniqueItemName,
      }
    });
  }

  channelViewSettingWatch: () => void = () => {};

  checkLoadedSortBy () {
    this.channelViewSettingWatch = this.$watch('channelViewSetting', function (newValue) {
      // wait for channel setting load, then check the sortBy is applicable
      if (typeof newValue !== 'undefined') {
        // find the column we're sorting by, look in defaults first
        if (this.channelViewSetting) {
          this.checkSortByOverride();
        }
        this.channelViewSettingWatch(); // clear watcher
      }
    }, { immediate: true });
  }

  checkSortByOverride () {
    if (!this.channelViewSetting) {
      return;
    }
    let sortObj: NonCustomFields | Partial<CustomFieldDisplay> | undefined = this.channelViewSetting.nonCustomFields.find((field) => field.key === this.sortBy);
    if (!sortObj) {
      const sortingBy = this.sortBy === SortBy.CustomFields ? this.sortId : this.sortBy;
      // allows a text representation of the custom key or the actual id as when pressed on sortable column
      sortObj = this.channelViewSetting.displayCustomFields.find((field) => field._id === sortingBy || field.fieldType === sortingBy as string);
    }
    // if no sort object found or it's set to no show, reset the sort by
    if (!sortObj || !sortObj.show) {
      // get the first default column
      const sortByOptions = Object.values(SortBy);
      const defaultSort = this.channelViewSetting.nonCustomFields.find((field) => field.show && sortByOptions.includes(field.key as SortBy));
      if( defaultSort ){
        this.onSort(defaultSort.key as SortBy, SortDir.Desc);
      } else {
        // if no default column, get the first custom
        const customSort = this.channelViewSetting.displayCustomFields.find((field) => field.show);
        if (customSort) {
          this.onSort(customSort._id as unknown as SortBy, SortDir.Desc);
        } else {
          console.error('No fields are turned on, cannot sort by anything.');
        }
      }
    }
  }

  @Watch('items')
  itemsUpdated () {
    this.forceTableReload();
  }

  @Watch('noItemsInChannel')
  itemsStoreUpdated () {
    clearTimeout(this.refetchItemsTimeout);
    this.refetchItemsTimeout = setTimeout(() => this.$emit('refetch-items'), 350);
  }
}
