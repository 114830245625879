
import { Component, Prop, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AConnectionIcon from '@/components/atoms/buttons/AConnectionIcon.vue';
import { Datum, State } from '@/api/ms-authentication/services/interfaces/Connections';
import { PeopleStore } from '@/store';
import ellipsisString from '@/utils/ellipsisString';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    AConnectionIcon,
  },
  filters: {
    ellipsisString
  }
})
export default class MPersonNotConnectedCard extends Vue {
  @Prop(Object)
  person!: Datum;
  @Prop({ default: true })
  showConnectIcon!: boolean;

  cancelText!: string;
  rejectText!: string;
  acceptText!: string;
  disconnectText!: string;
  reconnectText!: string;

  reconnectLoading: boolean = false;
  cancelLoading: boolean = false;
  acceptLoading: boolean = false;
  state!: State;
  states = State;

  iconSize = '1.6x';

  created () {
    this.cancelText = String(this.$t('dict.cancel'));
    this.rejectText = String(this.$t('dict.reject'));
    this.acceptText = String(this.$t('dict.accept'));
    this.disconnectText = String(this.$t('dict.disconnect'));
    this.reconnectText = String(this.$t('connectionState.reconnect'));
    this.state = this.person.state;
  }

  async removeRequest () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: 'You will not be connected to this person after this action',
      confirmText: 'Yes',
      onConfirm: async () => {
        this.cancelLoading = true;
        const { state } = await PeopleStore.connectionRequestRemove(this.person.username);
        this.state = state;
        this.cancelLoading = false;
      }
    });
  }

  async acceptRequest () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: 'You will be connected to this person after this action',
      confirmText: 'Yes',
      onConfirm: async () => {
        this.acceptLoading = true;
        const { state } = await PeopleStore.connectionRequestUpdate(this.person.username);
        this.state = state;
        this.acceptLoading = false;
      }
    });
  }
}
