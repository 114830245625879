
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Channel, SwipeScoresSlugNudgePost } from '@/api/ms-channel/services/interfaces';
import { Datum as Member } from '@/api/ms-channel/services/interfaces/ChannelMembers';
import { AuthenticationStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { SendIcon } from 'vue-feather-icons';
import SwipeScoresService from '@/api/ms-channel/services/SwipeScoresService';

@Component({
  components: { AUserProfilePic, SendIcon }
})
export default class MChannelSwipeSendToOthers extends Vue {
  @Prop()
  channel!: Channel;

  inviteMembers: Member[] = [];
  loadingInvites: boolean = true;
  userImageSize = Size.The32X32;

  checkedRows: Member[] = [];

  publicChannelNonOwnerManager: boolean = false;

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  async created () {
    await this.getChannelMembers();
  }

  async getChannelMembers () {
    this.loadingInvites = true;
    let members;
    try {
      members = await SwipeScoresService.swipeScoresSlugMembersGet({
        slug: this.channel.slug
      }, {});
    } catch (e: any) {
      // if status is 422 this is a public channel and the user is a non owner/manager
      if (e.response.status === 422) {
        this.publicChannelNonOwnerManager = true;
        this.loadingInvites = false;
      }

      return;
    }
    const filterSelf = members.data.filter((member) => member.username !== this.currentUser.username);

    this.inviteMembers = this.inviteMembers.concat(filterSelf);
    this.loadingInvites = false;
  }

  async sendNudges () {
    if (!this.checkedRows.length) {
      return;
    }

    const post: SwipeScoresSlugNudgePost = {
      usernames: this.checkedRows.map((row) => {
        return {
          username: row.username
        };
      })
    };
    await SwipeScoresService.swipeScoresSlugNudgePost(post, { slug: this.channel.slug }).then(this.nudgesSent);
  }

  nudgesSent () {
    this.$buefy.dialog.alert({
      title: this.$t('dict.sent') as string,
      message: this.$t('item.swipeScore.nudge.sent.message') as string,
      onConfirm: this.closeModal
    });
  }

  closeModal () {
    this.$emit('close');
  }
}
