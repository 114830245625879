
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AUserContentDisplay extends Vue {
  @Prop()
  content!: string;

  @Prop({required:false})
  label?: string;

  @Prop({default: false})
  inline!: boolean;

  @Prop({required: false})
  minWidth?: string;
}
