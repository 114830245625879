import { RouteNames } from '@/router/RouteNames';
import { Location, Route } from 'vue-router/types/router';
import TMainLayout from '@/components/templates/TMainLayout.vue';
import yourProfileChildren from '@/router/routes/yourProfileChildren';

export default [
  {
    name: RouteNames.ROUTE_DASHBOARD,
    meta: { auth: true },
    path: '',
    component: () => import(/* webpackChunkName: "TDashboard" */ '@/components/templates/TDashboard.vue'),
  },
  {
    name: RouteNames.ROUTE_ACTIVATE_ACCOUNT,
    path: 'activate-account/:hash/:email',
    component: () => import(/* webpackChunkName: "TActivateAccount" */ '@/components/templates/TActivateAccount.vue'),
  },
  {
    name: RouteNames.ROUTE_UNSUB_WELCOME,
    path: 'unsubscribe/welcome/:id',
    component: () => import(/* webpackChunkName: "TUnsubscribeWelcome" */ '@/components/templates/TUnsubscribeWelcome.vue'),
  },
  {
    name: RouteNames.ROUTE_PASSWORD_RESET,
    path: 'password-reset/:hash/:email',
    component: () => import(/* webpackChunkName: "TPasswordReset" */ '@/components/templates/TPasswordReset.vue'),
  },
  {
    path: 'redirect/:routeName',
    redirect: (to: Route): Location => {
      return {
        name: to.params.routeName
      };
    }
  },
  {
    path: 'your-profile',
    component: TMainLayout,
    meta: { auth: true },
    children: yourProfileChildren
  }
];
