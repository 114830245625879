import { register } from 'timeago.js';
import * as locales from 'timeago.js/lib/lang';

// timeago key: i18n code
const map = {
  de: 'deDE',
  en_US: 'enGB',
  es: 'esES',
  fr: 'frFR',
  it: 'itIT',
  nl: 'nlNL',
  pt_BR: 'ptBR',
};

const registerTimeAgoLocales = () => {
  Object.entries(map).forEach(([key, value]) => {
    register(value, locales[key]);
  });
};

export default registerTimeAgoLocales;
