import vScrollHeightWidth from '@/utils/vScrollHeightWidth';
import { ItemsStore } from '@/store';
import { waitTillElementIsVisible } from '@/utils/waitTillElementIsVisible';

/**
 * A custom function to return the vScroll box height and width
 * used in the items store but also in the pages to check previously
 * configured outer sizes to decide to recalculate or not
 */
export default async (): Promise<boolean> => {
  await waitTillElementIsVisible('.MHeader');
  // check to resize
  const calculatedSize = vScrollHeightWidth();
  const previousDims = ItemsStore.getVScrollDims;
  return (calculatedSize.height !== previousDims.boxHeight || calculatedSize.width !== previousDims.boxWidth);
};
