
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import ADropdownMenuSelector from '@/components/atoms/ADropdownMenuSelector.vue';
import { DatePickerLang, getLang } from '@/utils/vue2DatepickerLocale';
import { translation } from '@/plugins/i18n/Translation';
import DatePicker from 'vue2-datepicker';
import { ItemPostReminder, ReminderType } from '@/api/ms-item/services/interfaces/ItemPost';
import { XIcon } from 'vue-feather-icons';

@Component({
  components: {
    ADropdownMenuSelector,
    DatePicker,
    XIcon
  }
})
export default class MItemEntrySetReminder extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ItemPostReminder;
  @Prop({ required: false, default: 0 })
  openMenu!: number;

  value: ItemPostReminder = {
    isSet: false,
    reminder: {
      reminderType: ReminderType.OneWeek,
      date: new Date()
    }
  };

  itemReminderTypes: ReminderType[] = [
    ReminderType.OneWeek,
    ReminderType.OneMonth,
    ReminderType.ThreeMonths,
    ReminderType.CustomDate
  ];
  itemReminderOptions: { [key: string]: string } = {
    [ReminderType.OneWeek]: this.$t('item.entry.reminders.oneWeek') as string,
    [ReminderType.OneMonth]: this.$t('item.entry.reminders.oneMonth') as string,
    [ReminderType.ThreeMonths]: this.$t('item.entry.reminders.threeMonths') as string,
    [ReminderType.CustomDate]: this.$t('item.entry.reminders.customDate') as string
  };

  customDate: boolean = false;
  active: boolean = false;
  formComponentClicked: boolean = false;
  defaultSelectionText: string = this.$t('item.entry.reminders.set') as string;
  selectionText: string = this.defaultSelectionText;
  datePickerLang: DatePickerLang['lang'] = 'en';

  $refs!: {
    setReminderDropdown: any
  };

  get language () {
    return translation.currentLanguage;
  }

  created () {
    this.value = this.activeValue;
    this.getLocale();
    this.preloadForm();
  }

  setActive (act) {
    this.active = act;
    this.formComponentClicked = true;
  }

  // Date must be in the future, tomorrow is the earliest date
  disabledDate (date) {
    // return false;
    return date < new Date();
  }

  formatDate () {
    return 'YYYY-MM-DD';
  }

  getLocale () {
    this.datePickerLang = getLang(this.language);
  }

  preloadForm () {
    if (!this.value || !this.value.isSet) {
      return;
    }
    if (this.value.reminder) {
      const date = new Date(this.value.reminder.date);
      this.setReminderType(this.value.reminder.reminderType);
      this.value.reminder.date = date;
    }
  }

  setReminderType (reminder: ReminderType) {
    this.value.isSet = true;
    this.customDate = false;
    let date = new Date();
    switch (reminder) {
      case ReminderType.CustomDate:
        this.customDate = true;
        date.setDate(date.getDate() + 7);
        break;

      case ReminderType.OneWeek:
        date.setDate(date.getDate() + 7);
        break;

      case ReminderType.OneMonth:
        date.setMonth(date.getMonth() + 1);
        break;

      case ReminderType.ThreeMonths:
        date.setMonth(date.getMonth() + 3);
        break;
    }
    this.value.reminder = {
      reminderType: reminder,
      date: date
    };
    this.selectionText = this.itemReminderOptions[reminder];
  }

  clearReminder () {
    this.value.isSet = false;
    this.customDate = false;
    this.selectionText = this.defaultSelectionText;
  }

  @Watch('value', { deep: true })
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }

  // if open menu number is incremented, toggle the display programmatically
  @Watch('openMenu')
  openMenuProgrammatically () {
    this.$refs.setReminderDropdown.toggle();
  }

  @Watch('formError')
  highlightFormError () {
    this.$refs.setReminderDropdown.toggle();
  }
}
