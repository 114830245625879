
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { HelpCircleIcon, MessageSquareIcon } from 'vue-feather-icons';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';
import { Item, ItemNameUniqueItemNameCommentCommentIdReplyPost } from '@/api/ms-item/services/interfaces';
import { Comment } from '@/api/ms-item/services/interfaces/Item';
import { Type } from '@/api/ms-item/services/interfaces/ItemNameUniqueItemNameCommentCommentIdReplyPost';
import ConnectionsService from '@/api/ms-authentication/services/ConnectionsService';
import { formatMentionUserComponentData } from '@/utils/formatMentionUsersData';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';

@Component({
  components: {
    MInputWithValidation,
    ValidationObserver,
    HelpCircleIcon,
    MessageSquareIcon
  }
})
export default class OItemCommentReplyForm extends Vue {
  @Prop({ required: true })
  parentComment!: Comment;
  @Prop({ required: true })
  commentId!: string;
  @Prop()
  comment?: string;
  @Prop({ default: true })
  add!: boolean;
  @Prop({ default: false })
  isEdit!: boolean;

  loading: boolean = false;
  failedSubmit: boolean = false;
  placeholder!: string;
  isGeneralChannel: boolean = true;
  notFoundMessage: string = '';

  commentTypes: any[] = [{
    value: Type.General,
    label: this.$t('item.comment.type.general')
  }, {
    value: Type.C2B,
    label: this.$t('item.comment.type.c2b')
  }];

  form: ItemNameUniqueItemNameCommentCommentIdReplyPost = {
    comment: '',
    type: Type.General
  };

  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };

  get item (): Item {
    return ItemDetailStore.getItemDetail;
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  mounted () {
    this.$refs.form;
  }

  created () {
    this.placeholder = String(this.add ? this.$t('dict.leaveAReply') : this.$t('dict.editReply'));
    this.form.comment = this.comment || '';
    if (this.parentComment.type === Type.C2B) {
      this.form.type = Type.C2B;
    }

    this.isGeneralChannel = !!this.item.editable.channel!.isDefault;

    if (this.isGeneralChannel) {
      this.notFoundMessage = this.item.actor.username === this.currentUser.username ?
        this.$t('dict.notFoundMessageMentionedConnections').toString() :
        this.$t('dict.notFoundMessageMentionedCommonConnections', { name: this.item.actor.firstName }).toString();
    } else {
      this.notFoundMessage = this.$t('dict.notFoundMessageMentionedChannelMembers').toString();
    }
  }

  async fetchUsersToMention (searchText: string, cb): Promise<void> {
    let resp;

    if (this.isGeneralChannel) {
      if (this.item.actor.username === this.currentUser.username) {
        resp = await ConnectionsService.connectionsGet({
          offset: 0,
          qs: searchText
        });
      } else {
        resp = await ConnectionsService.connectionsUsernameMatchingGet(
          { username: this.item.actor.username },
          { offset: 0, qs: searchText, includeRequestedUsername: true }
        );
      }
    } else {
      resp = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.item.editable.channel!.slug },
        { text: searchText }
      );
    }

    const userObj = formatMentionUserComponentData(resp.data, this.currentUser.username);
    cb(userObj);
  }

  async onSubmit () {
    // https://logaretm.github.io/vee-validate/guide/forms.html#programmatic-access-with-refs
    if (!await this.$refs.form.validate()) {
      this.failedSubmit = true;
      return;
    }
    this.loading = true;

    const item = await ItemDetailStore.itemCommentSubmitNewReply({
      body: this.form,
      commentId: this.commentId
    });

    // update the item in the main items store as well if it exists
    ItemsStore.SET_ITEM_DETAIL(item);

    this.form.comment = '';
    await this.$nextTick(() => {
      this.$refs.form.reset();
    });
    this.failedSubmit = false;
    this.$emit('close-reply');
    this.loading = false;
  }
}
