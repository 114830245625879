
import { Component, Prop, Vue } from 'vue-property-decorator';
import AChannelSwipeItemLeft from '@/components/atoms/channel-swipe/AChannelSwipeItemLeft.vue';
import AChannelSwipeItemUp from '@/components/atoms/channel-swipe/AChannelSwipeItemUp.vue';
import AChannelSwipeItemRight from '@/components/atoms/channel-swipe/AChannelSwipeItemRight.vue';
import AChannelSwipeItemDown from '@/components/atoms/channel-swipe/AChannelSwipeItemDown.vue';
import { XIcon } from 'vue-feather-icons';
import { Action } from '@/api/ms-item/services/interfaces/SwipeScoresItemNameUniqueItemNamePut';

@Component({
  components: { AChannelSwipeItemDown, AChannelSwipeItemRight, AChannelSwipeItemUp, AChannelSwipeItemLeft, XIcon }
})
export default class MChannelSwipeFooterActions extends Vue {
  @Prop({ default: false })
  isMoving!: boolean;
  @Prop()
  directionMoving!: Action;
  @Prop()
  iconOpacity!: number;
  @Prop({required: false, default: false})
  disabled!: boolean;

  footerIconSize = '3x';
  footerIconSizeLarge = '4.5x';
  SwipeDirection = Action;

  // if the card is dragged far enough to trigger an action, also highlight the corresponding footer action
  get highlightWhichIcon (): Action|null {
    if(!this.isMoving || this.iconOpacity < 1){
      return null;
    }

    return this.directionMoving;
  }

  emitAction (direction: Action) {
    this.$emit('swipe-action', direction);
  }

  likeItem () {
    this.emitAction(Action.SwipeRight);
  }

  dislikeItem () {
    this.emitAction(Action.SwipeLeft);
  }

  likeAndPinItem () {
    this.emitAction(Action.SwipeUp);
  }

  skipItem () {
    this.emitAction(Action.SwipeDown);
  }
}
