var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-dropdown',{staticClass:"card-dropdown",attrs:{"mobile-modal":false,"scrollable":_vm.scrollable,"max-height":_vm.maxHeight,"aria-role":"list","position":_vm.dropdownPosition,"append-to-body":_vm.appendToBody}},[_c('more-vertical-icon',{staticClass:"card-actions-icon",attrs:{"slot":"trigger","size":"1.5x"},slot:"trigger"}),(_vm.includeItemDetailLink)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.goToItemDetail}},[_c('chevron-right-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.itemDetail'))+" ")],1):_vm._e(),(_vm.authenticated)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":!!(!_vm.itemHasPrice || _vm.isAlreadyPriceWatched)},on:{"click":_vm.priceWatchItem}},[_c('activity-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.isAlreadyPriceWatched ? _vm.$t('itemMenu.priceAlreadyWatched') : _vm.itemHasPrice ? _vm.$t('itemMenu.priceWatch') : _vm.$t('itemMenu.priceWatchNotAvailable'))+" ")],1):_vm._e(),(_vm.item.editable.geolocationData)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.clickOutToMap}},[_c('a-map-out-link',{attrs:{"item":_vm.item,"label-show":true,"dont-click-out":true}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.openInMaps'))+" ")],1):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.shareItem}},[(_vm.isIos)?_c('share-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}):_c('share2-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.share'))+" ")],1),(
              _vm.authenticated &&
              _vm.item.actor &&
              (_vm.currentUser.username === _vm.item.actor.username || _vm.isUserManagerOfChannel)
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.editItem}},[_c('a-alarm-clock-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.lifferyIconSize,"keep-y-zero":true}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.reminder'))+" ")],1):_vm._e(),(
              _vm.authenticated &&
              _vm.item.actor &&
              (_vm.currentUser.username === _vm.item.actor.username || _vm.isUserManagerOfChannel)
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.editItem}},[_c('edit2-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.edit'))+" ")],1):_vm._e(),(_vm.authenticated)?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.openCopyItem}},[_c('a-add-item-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.lifferyIconSize,"size-units":"em"}}),_vm._v(" "+_vm._s(_vm.$t('dict.addToChannel'))+" ")],1):_vm._e(),(
              _vm.authenticated &&
              _vm.item.actor &&
              _vm.currentUser.username === _vm.item.actor.username
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.moveItem}},[_c('move-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.moveTo'))+" ")],1):_vm._e(),(_vm.item.actor && (_vm.currentUser.username === _vm.item.actor.username || _vm.isUserManagerOfChannel))?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.goToDeleteItem}},[_c('x-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.$t('itemMenu.delete'))+" ")],1):_vm._e(),(_vm.displayReportItem())?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.openReportItem}},[_c('alert-circle-icon',{staticClass:"dropdown-item-icon",attrs:{"size":_vm.featherIconSize}}),_vm._v(" "+_vm._s(_vm.$t('dict.reportItem'))+" ")],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }