
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons';
import isTouchScreen from '@/utils/isTouchScreen';

@Component({
  components: {
    ChevronUpIcon,
    ChevronDownIcon
  }
})
export default class MChatToggleHandle extends Vue {
  @Prop({ default: 0 })
  unreadCount!: number;

  @Prop({ default: true })
  forceShow!: boolean;

  @Prop()
  isOpen!: boolean;

  isTouchScreen = isTouchScreen();
}
