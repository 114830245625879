import EventBus, { EventBusEvents } from '@/EventBus';

export default () => {
  navigator.serviceWorker.getRegistrations()
    .then((regs) => {
      if (!Array.isArray(regs)) {
        return;
      }
      return Promise.all(
        regs.map(reg => reg.unregister())
      );
    })
    .then(() => {
      EventBus.$emit(EventBusEvents.NEW_APP_DOWNLOADED);
    })
    .catch((err) => {
      console.error('Service worker unregister error:', err);
      throw err;
    });
};
