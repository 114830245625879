/**
 * Returns a base 64 string of the image
 * @param file
 */
export default (file: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    // Define a callback function to run, when FileReader finishes its job
    reader.onload = (ev: ProgressEvent) => {
      resolve((ev.target as FileReader).result as string);
    };
    // Start the reader job - read file as a data url (base64 format)
    reader.readAsDataURL(file);
  });
};
