import { Item } from '@/api/ms-item/services/interfaces';
import { ItemDetailStore, ItemsStore, PinnedItemsStore, ShoppingListStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

export default (newItem: Item): void => {
  // Update the detail view if required
  if (ItemDetailStore.getItemDetail.uniqueItemName === newItem.uniqueItemName) {
    ItemDetailStore.ITEM_CLEAR_SET(newItem);
  }

  // update the shopping list store
  ShoppingListStore.UPDATE_ONE_ITEM(newItem);

  // update the items and pinned items stores, this will catch dashboard, profile page and channel page items
  ItemsStore.SET_ITEM_DETAIL(newItem);
  PinnedItemsStore.SET_ITEM_DETAIL(newItem);

  ItemsStore.recalculateVirtualScroll().catch(console.error);

  //emitted to anybody who is interested in an item edit
  EventBus.$emit(EventBusEvents.ITEM_EDITED, newItem);
};
