
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import AMapOutLink from '@/components/atoms/link/AMapOutLink.vue';
import { RouteNames } from '@/router/RouteNames';
import clickOutToMap from '@/utils/clickOutToMap';
import shareOrCopyItem from '@/utils/shareOrCopyItem';
import isIosApp from '@/utils/isIosApp';
import { AuthenticationStore, ChannelsStore, WatchersStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';
import { User } from '@/api/ms-authentication/services/interfaces';
import {
  ActivityIcon,
  AlertCircleIcon,
  ChevronRightIcon,
  Edit2Icon,
  MoreVerticalIcon,
  MoveIcon,
  Share2Icon,
  ShareIcon,
  XIcon
} from 'vue-feather-icons';
import itemHasPrice from '@/utils/itemHasPrice';
import AAlarmClockIcon from '@/components/atoms/icon/svg/AAlarmClockIcon.vue';
import AAddItemIcon from '@/components/atoms/icon/AAddItemIcon.vue';

@Component({
  components: {
    AAddItemIcon,
    AAlarmClockIcon,
    AMapOutLink,
    ActivityIcon,
    AlertCircleIcon,
    ChevronRightIcon,
    Edit2Icon,
    MoreVerticalIcon,
    MoveIcon,
    ShareIcon,
    Share2Icon,
    XIcon
  }
})
export default class MItemActionsDropdown extends Vue {
  @Prop()
  item!: Item;
  @Prop()
  currentUser!: User;
  @Prop({ required: false, default: false })
  loadedFromItemDetail!: boolean;
  @Prop({ required: false, default: 'is-bottom-left' })
  dropdownPosition!: string;
  @Prop({ required: false, default: false })
  includeItemDetailLink!: boolean;
  // true on table view, otherwise is clipped by table edges
  @Prop({ required: false, default: false })
  appendToBody!: boolean;
  @Prop()
  cardHeight!: number;

  isIos = isIosApp();
  featherIconSize = '1.5x';
  lifferyIconSize = 1.5;

  get scrollable (): boolean {
    return this.maxHeight < 234;
  }

  get maxHeight (): number {
    return this.cardHeight - 70 + 25;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get isUserManagerOfChannel (): boolean {
    return !!ChannelsStore.getChannelsManagerOf.find((channel) => channel.slug === this.item.editable.channel?.slug);
  }

  get itemHasPrice (): boolean {
    return itemHasPrice(this.item);
  }

  get isAlreadyPriceWatched () {
    return WatchersStore.getWatchers.find((watcher) => watcher.itemUniqueName === this.item.uniqueItemName);
  }

  showLogin () {
    AuthenticationStore.TOGGLE_PROMPT_LOGIN(true);
  }

  goToItemDetail () {
    this.$router.push({
      name: RouteNames.ROUTE_ITEM_DETAIL_VIEW, params: {
        uniqueItemName: this.item.uniqueItemName,
      }
    });
  }

  clickOutToMap () {
    clickOutToMap(this.item);
  }

  priceWatchItem () {
    EventBus.$emit(EventBusEvents.ITEM_PRICE_WATCH, this.item);
  }

  shareItem () {
    const fullPathOfItem = this.$router.resolve({
      name: RouteNames.ROUTE_ITEM_DETAIL_VIEW,
      params: {
        uniqueItemName: this.item.uniqueItemName
      }
    });
    shareOrCopyItem(this.item, fullPathOfItem.href);
  }

  openCopyItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_COPY, {
      uniqueItemName: this.item.uniqueItemName
    });
  }

  moveItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_EDIT, {
      uniqueItemName: this.item.uniqueItemName,
      isMovingItem: true
    });
  }

  editItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_EDIT, {
      uniqueItemName: this.item.uniqueItemName
    });
  }

  async goToDeleteItem () {
    if (!this.loadedFromItemDetail) {
      await this.$router.push({
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW, params: {
          uniqueItemName: this.item.uniqueItemName,
        }
      });
    }

    EventBus.$emit(EventBusEvents.ITEM_DELETE, {
      uniqueItemName: this.item.uniqueItemName,
      title: this.item.urlCache?.meta?.title || this.$t('dict.thisItem')
    });
  }

  openReportItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_REPORT, {
      item: JSON.parse(JSON.stringify(this.item)),
    });
  }

  displayReportItem (): boolean {
    if (!this.authenticated) {
      return true;
    } else {
      return this.currentUser?.username !== this.item?.actor?.username;
    }
  }
}
