
  /**
  * DO NOT EDIT THIS FILE DIRECTLY!
  * main.ts is generated from the scripts/buildNujucksAppFiles.js file.
  * If you need to make changes to the main.ts file you can only edit the 
  * njk file.
  **/



// Import the vue and global plugins/filters
import Vue from 'vue';
import { i18n } from '@/plugins/i18n';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import './class-component-hooks';
import VirtualCollection from 'vue-virtual-collection';
import PortalVue from 'portal-vue';


import getHtmlDataAppVersion from './utils/getHtmlDataAppVersion';



import VueGtm from 'vue-gtm';


import { extend, localize, setInteractionMode } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// Import buefy requirement components
import Buefy from 'buefy';

// Import the flags css
import "/node_modules/flag-icons/css/flag-icons.min.css";

// Import Styling - this the the core styles from the framrwork + customisations
import '@/styles/index.scss';

// Import our actual app
// import '@/registerServiceWorker'; disabled the service worker for now, causes more issues that is needed
import App from '@/components/App.vue';
import router from '@/router';
import store from '@/store';
import ApiHelper from '@/services/ApiHelper';
import registerTimeAgoLocales from './utils/timeagoLocale';
import VueFeatherIconPack from '@/components/atoms/icon/AVueFeatherIconPack.vue';
import buefyVueFeatherIcons from '@/constants/buefyVueFeatherIcons';
import VueTour from 'vue-tour';


import MemoryStore from '@/services/MemoryStore';
import SyncTabs from '@/services/SyncTabs';


// developerMode to access hidden elements of liffery on live - e.g. logs, debugging or testing features
window.lifferyDeveloperMode = false;
// @ts-ignore
window.setLifferyDeveloperMode = (on: boolean = false) => {
  if( on ){
    console.error('WARNING! Developer mode is ON. Liffery may be unstable.');
  } else {
    console.error('Developer mode is OFF.');
  }
  console.error('Note: The immediate view does not update automatically.');
  window.lifferyDeveloperMode = on;
};

// Block the PWA installer: https://stackoverflow.com/questions/41711522/prevent-pwa-from-being-installed
window.addEventListener('beforeinstallprompt', (event) => event.preventDefault());

// eslint-disable-next-line max-lines-per-function
const initialiseApp = () => {
  
    Vue.use(VueTour);
    // import and initialise the memory
    MemoryStore.eventsBind();
    SyncTabs.init();
  

    
  /**
  * DO NOT EDIT THIS FILE DIRECTLY!
  * main.ts is generated from the scripts/buildNujucksAppFiles.js file.
  * If you need to make changes to the main.ts file you can only edit the 
  * njk file.
  **/


    registerTimeAgoLocales();

    // Prod tip off
    Vue.config.productionTip = false;

    // Add Vue feather icons to Buefy components
    Vue.component('vue-feather-icon-pack', VueFeatherIconPack);

    // Use plugins and filters
    Vue.use(Buefy, {
      defaultTabsAnimated: false,
      defaultIconComponent: 'vue-feather-icon-pack',
      defaultIconPack: 'vfi',
      customIconPacks: { ...buefyVueFeatherIcons }
    });

    Vue.use(VueFilterDateFormat);
    Vue.use(VirtualCollection);
    Vue.use(PortalVue);

    
  /**
  * DO NOT EDIT THIS FILE DIRECTLY!
  * main.ts is generated from the scripts/buildNujucksAppFiles.js file.
  * If you need to make changes to the main.ts file you can only edit the 
  * njk file.
  **/


    
      Vue.use(VueGtm, {
        id: 'GTM-MC68667',
        debug: false,
        loadScript: true,
        vueRouter: router,
        trackOnNextTick: false,
      });
    

  // configure bootstrapping
  ApiHelper.setupAxios();
  Object.keys(rules).forEach(rule => extend(rule, rules[rule]));
  localize('en', en);
  setInteractionMode('eager');

  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};


  /**
  * DO NOT EDIT THIS FILE DIRECTLY!
  * main.ts is generated from the scripts/buildNujucksAppFiles.js file.
  * If you need to make changes to the main.ts file you can only edit the 
  * njk file.
  **/



  
    window.appVersion = getHtmlDataAppVersion();
  

  initialiseApp();



  /**
  * DO NOT EDIT THIS FILE DIRECTLY!
  * main.ts is generated from the scripts/buildNujucksAppFiles.js file.
  * If you need to make changes to the main.ts file you can only edit the 
  * njk file.
  **/



