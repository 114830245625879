
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Datum as ChatsDatum } from '@/api/ms-chat/services/interfaces/Chats';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import MChatMessage from '@/components/molecules/MChatMessage.vue';
import isTouchDevice from '@/utils/isTouchScreen';
import MChatDefaultMessage from '@/components/molecules/MChatDefaultMessage.vue';
import { RouteNames } from '@/router/RouteNames';
import OChannelChatForm from '@/components/organisms/forms/OChannelChatForm.vue';
import { ZapOffIcon } from 'vue-feather-icons';

@Component({
  components: {
    ZapOffIcon,
    OChannelChatForm,
    MChatDefaultMessage,
    MChatMessage,
    MInputWithValidation
  }
})
export default class MChatBox extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  messages!: ChatsDatum[];

  @Prop()
  firstUnreadMessage?: string;

  @Prop({ default: 0 })
  unreadCount!: number;

  @Prop()
  hasNoMoreMessages!: boolean;

  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ default: true })
  chatEnabled!: boolean;

  @Prop({ required: false, default: false })
  socketConnectionFailed?: boolean;

  chatBox: any = null;
  mentionedProfiles: any = null;

  get isTouchScreen (): boolean {
    return isTouchDevice();
  }

  mounted () {
    this.chatBox = document.querySelector('.chat-messages-wrapper');
    this.chatBox.addEventListener('scroll', this.handleScroll);
  }

  updated () {
    const tempMentions: any = document.querySelectorAll('.mentioned-user-link');

    if (JSON.stringify(tempMentions) !== JSON.stringify(this.mentionedProfiles)) {
      this.mentionedProfiles = tempMentions;

      Array.from(this.mentionedProfiles, (item: any) => {
        item.addEventListener('click', (e) => {
          this.$router.push({
            name: RouteNames.ROUTE_PROFILE, params: {
              username: e.target.innerText,
            }
          });
        });
      });
    }
  }

  // updated () {
  //   if (this.unreadCount > 0 && this.firstUnreadMessage && this.$refs[this.firstUnreadMessage]) {
  //     this.$nextTick(() => this.$refs[this.firstUnreadMessage as string][0].scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center'
  //     }));
  //   }
  // }

  destroyed () {
    this.chatBox.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll (): void {
    const scrollTop = Math.abs(this.chatBox.scrollTop);

    if (scrollTop + this.chatBox.offsetHeight >= this.chatBox.scrollHeight - 10) {
      this.$emit('chatbox-scrolled-to-top');
    }
    // check if the distance from the bottom of the container to the bottom of the viewport of the container is greater than 55
    if (scrollTop >= 55) {
      this.$emit('chatbox-scrolled-not-bottom');
    } else {
      this.$emit('chatbox-scrolled-at-bottom');
    }
  }

  chatFormSubmitHandle (msg: string) {
    this.$emit('send-message', msg);
    this.scrollChatBoxToBottom();
  }

  scrollChatBoxToBottom (): void {
    this.chatBox.scrollTop = 0;
  }
}
