import getFirstUrlFromString from 'get-first-url-from-string';

export default (input: string, displayFullHref?: boolean): string => {
  const link = getFirstUrlFromString(input);
  if (!link) {
    return input;
  }
  const url = new URL(link);
  input = input.replace(link, `<a href="${link}" target="_blank" class="linked-text">${displayFullHref ? url.href : url.origin}</a>`);
  return input;
};
