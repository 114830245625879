import getFirstUrlFromString from 'get-first-url-from-string';

/**
 * Replaces the 1st URL in a string with an empty string, ie it removes the 1st url found
 * The content is then trimmed before returning to ensure excess whitespace is removed
 */
export default (input: string): string => {
  if (!input || !input.length) {
    return input;
  }
  const url = getFirstUrlFromString(input);
  if (url) {
    input = input.split(url).join('');
  }
  return input.trim();
};
