
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore, NotificationsStore } from '@/store';
import { Datum as Notification } from '@/api/ms-notification/services/interfaces/Notifications';

import ARouterLinkNotifications from '@/components/atoms/link/ARouterLinkNotifications.vue';
import MNotificationItem from '@/components/molecules/notification/MNotificationItem.vue';
import MNotificationBell from '@/components/molecules/notification/MNotificationBell.vue';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';

@Component({
  components: {
    ACloseModalButton,
    ARouterLinkNotifications,
    MNotificationItem,
    MNotificationBell,
    MAuthenticationTabs,
  },
})
export default class MNotificationHeader extends Vue {
  visible = false;
  maxHeight = 0;

  get notifications (): Notification[] {
    return NotificationsStore.getHeaderNotifications;
  }

  get getUnreadNotifications (): number {
    return NotificationsStore.getUnseenStateCount;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  created () {
    if (AuthenticationStore.getAuthenticated) {
      // there is no point in collecting notifications for the unauthenticated user
      NotificationsStore.getLatestHeader();
    }
    this.bindEvents();
  }

  mounted () {
    this.setMaxSize();
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  unbindEvents () {
    window.addEventListener('resize', this.setMaxSize);
  }

  bindEvents () {
    window.addEventListener('resize', this.setMaxSize);
  }

  setMaxSize () {
    const navBar = document.querySelector('.TMainLayout .MHeader');
    if (navBar) {
      this.maxHeight = window.innerHeight - navBar.getBoundingClientRect().height - 15;
    }
  }

  dropdownClick (open: boolean) {
    if (!open) {
      // when the ddm closes we mark as read
      setTimeout(() => {
        NotificationsStore.markLatestHeaderAsRead();
      }, 500);
    }
  }
}
