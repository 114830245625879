
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getgot, Item } from '@/api/ms-item/services/interfaces/Item';
import EventBus, { EventBusEvents } from '@/EventBus';
import { ShoppingBagIcon } from 'vue-feather-icons';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';

@Component({
  components: {
    MButtonWithIcon,
    ShoppingBagIcon
  }
})
export default class AShoppingListButton extends Vue {
  @Prop({ default: true })
  isIcon!: boolean;
  @Prop()
  classes!: string;
  @Prop()
  username!: string;
  @Prop({ required: true })
  getGot!: Getgot | undefined;
  @Prop({ required: true })
  item!: Item;

  loading = false;

  addOrRemoveHandle () {
    if (this.getGot) {
      // we assume we remove from the current users shopping list
      this.openShoppingListRemoveModal();
    } else {
      this.openShoppingListModal();
    }
  }

  openShoppingListModal () {
    EventBus.$emit(EventBusEvents.SHOPPING_LIST_MODAL, JSON.parse(
      JSON.stringify(this.item)
    ));
  }

  openShoppingListRemoveModal () {
    EventBus.$emit(EventBusEvents.SHOPPING_LIST_REMOVE_MODAL,
      { itemName: this.item.uniqueItemName, getGotId: this.getGot?._id }
    );
  }
}
