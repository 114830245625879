import { Payload, Variable } from '@/api/ms-notification/services/interfaces/Notifications';

export default (payload: Payload | undefined): any => {
  const obj: any = {};
  if (payload) {
    payload.variables.forEach((value: Variable) => {
      obj[value.key] = value.value;
    });
  }
  return obj;
};