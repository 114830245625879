
import { Component, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ARouterLinkChannelManagement from '@/components/atoms/link/ARouterLinkChannelManagement.vue';
import ARouterLinkDashboard from '@/components/atoms/link/ARouterLinkDashboard.vue';
import ARouterLinkLogout from '@/components/atoms/link/ARouterLinkLogout.vue';
import ARouterLinkSettings from '@/components/atoms/link/ARouterLinkSettings.vue';
import ARouterLinkShoppingList from '@/components/atoms/link/ARouterLinkShoppingList.vue';
import ARouterLinkConnections from '@/components/atoms/link/ARouterLinkConnections.vue';
import ARouterLinkSecurity from '@/components/atoms/link/ARouterLinkSecurity.vue';
import ARouterLinkLanguage from '@/components/atoms/link/ARouterLinkLanguage.vue';
import {
  BriefcaseIcon,
  ChevronDownIcon,
  ExternalLinkIcon,
  GlobeIcon,
  HelpCircleIcon,
  MapPinIcon,
  MessageSquareIcon,
  TruckIcon,
  UsersIcon,
  XIcon
} from 'vue-feather-icons';

import { AuthenticationStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import ARouterLinkEmailNotifications from '@/components/atoms/link/ARouterLinkEmailNotifications.vue';
import ARouterLinkYourProfile from '@/components/atoms/link/ARouterLinkYourProfile.vue';
import ARouterLinkInvitations from '@/components/atoms/link/ARouterLinkInvitations.vue';
import isTouchScreen from '@/utils/isTouchScreen';
import ATourSvgIcon from '@/components/atoms/icon/svg/ATourSvgIcon.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { AvailableTours } from '@/components/organisms/OVueTour.types';
import ARouterLinkYourChannels from '../atoms/link/ARouterLinkYourChannels.vue';
import truncateText from '@/utils/truncateText';
import ARouterLinkSettingWatchers from '@/components/atoms/link/ARouterLinkSettingWatchers.vue';

@Component({
  components: {
    ARouterLinkSettingWatchers,
    ARouterLinkChannelManagement,
    ARouterLinkConnections,
    ARouterLinkDashboard,
    ARouterLinkEmailNotifications,
    ARouterLinkInvitations,
    ARouterLinkLanguage,
    ARouterLinkLogout,
    ARouterLinkProfile,
    ARouterLinkSecurity,
    ARouterLinkSettings,
    ARouterLinkShoppingList,
    ARouterLinkYourChannels,
    ARouterLinkYourProfile,
    ATourSvgIcon,
    AUserProfilePic,
    MLanguageSwitcher,
    BriefcaseIcon,
    ChevronDownIcon,
    ExternalLinkIcon,
    GlobeIcon,
    HelpCircleIcon,
    MapPinIcon,
    MessageSquareIcon,
    UsersIcon,
    TruckIcon,
    XIcon
  },
  filters: {
    maxForenameLength: (name) => truncateText(name, 25)
  }
})
export default class MUserProfileHeader extends Vue {
  isDropdownOpen: boolean = false;
  name = RouteNames.ROUTE_PROFILE;

  eventCallerId = 'TProfile';

  dropdownMenu!: HTMLElement | null;
  routeName = RouteNames;
  maxHeight = 0;

  $refs!: {
    dropdownMenu: any
  };

  get currentLanguage () {
    return this.$i18n.locale;
  }

  get appVersion () {
    return window.appVersion;
  }

  get user () {
    return AuthenticationStore.user;
  }

  created () {
    this.bindEvents();
  }

  mounted () {
    this.dropdownMenu = document.querySelector('.MUserProfileHeader .dropdown');
    this.setMaxSize();
    this.toggleMenuIcon();
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  unbindEvents () {
    const { eventCallerId } = this;
    window.removeEventListener('resize', this.setMaxSize);
    EventBus.$remove(EventBusEvents.TOUR_SHOW_PROFILE_HEADER, eventCallerId);
    EventBus.$remove(EventBusEvents.TOUR_HIDE_PROFILE_HEADER, eventCallerId);
  }

  bindEvents () {
    const { eventCallerId } = this;
    window.addEventListener('resize', this.setMaxSize);
    EventBus.$on(EventBusEvents.TOUR_SHOW_PROFILE_HEADER, eventCallerId, this.showMenu);
    EventBus.$on(EventBusEvents.TOUR_HIDE_PROFILE_HEADER, eventCallerId, this.hideMenu);
  }

  setMaxSize () {
    const navBar = document.querySelector('.TMainLayout .MHeader');
    if (navBar) {
      // we're in the app
      this.maxHeight = window.innerHeight - navBar.getBoundingClientRect().height - 15;
    } else {
      // we're on a public landing page
      const navBarTop = document.querySelector('.header-top');
      const navBarMain = document.querySelector('.header-main');
      if (navBarTop && navBarMain) {
        this.maxHeight = window.innerHeight - (
          navBarTop.getBoundingClientRect().height + navBarMain.getBoundingClientRect().height
        ) - 15;
      }
    }
  }

  localeToISOA2 (locale: string): string {
    return locale.slice(-2).toLowerCase();
  }

  toggleMenuIcon (active?: boolean) {
    this.$nextTick(() => {
      this.isDropdownOpen = !!this.dropdownMenu?.classList.contains('is-active');
    });
    if (isTouchScreen()) {
      document.documentElement.className = active ? 'is-clipped' : '';
    }
  }

  showMenu () {
    //if it's not open, ensure it is
    if (!this.dropdownMenu?.classList.contains('is-active')) {
      this.$refs.dropdownMenu.toggle();
    }
  }

  hideMenu () {
    //if it is open, ensure it is closed
    if (this.dropdownMenu?.classList.contains('is-active')) {
      this.$refs.dropdownMenu.toggle();
    }
  }

  triggerTour () {
    EventBus.$emit(EventBusEvents.TOUR_START, AvailableTours.SelectATour);
  }

  openLanguageSelectionModal () {
    EventBus.$emit(EventBusEvents.SELECT_LANGUAGE);
  }

  openChat (): void {
    window.HelpCrunch('openChat');
  }
}
