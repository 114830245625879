import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { Datum as ChannelDatum } from '@/api/ms-channel/services/interfaces/Channels';
import { ChannelPost } from '@/api/ms-channel/services/interfaces';
import { ConciergeType, Currency, Privacy, Urgency } from '@/api/ms-channel/services/interfaces/ChannelPost';
import { ChannelType } from '@/api/ms-channel/services/interfaces/Channel';

interface NotificationsEnabled {
  stateGot: boolean;
  true: boolean;
}

export interface ChannelDatumExtended extends ChannelDatum {
  notificationsEnabled?: NotificationsEnabled;
}

export interface IChannelsModule extends IStoreModule {
  currentChannelViewedMembers: any[]
  channelsOfProfile: ChannelDatumExtended[];
  channelsMemberOf: ChannelDatumExtended[];
  channelsNotMemberOf: ChannelDatumExtended[];
  channelEditing: ChannelPost;
}

export const defaultChannelUpsertObject: ChannelPost = {
  allCanAdd: false,
  shareLinkEnabled: true,
  chatEnabled: true,
  description: '',
  name: '',
  privacy: Privacy.FriendsOnly,
  channelType: ChannelType.General,
  conciergeSettings: {
    urgency: Urgency.OneWeek,
    qtyItems: 3,
    conciergeType: ConciergeType.SingleItem
  },
  channelMeta: {
    budget: {
      currency: Currency.Gbp,
      value: NaN
    },
    dislikes: '',
    likes: '',
    requirements: ''
  },
  customFields: []
};
