
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, CircleIcon } from 'vue-feather-icons';

/**
 * Title and value are required variables. Description is optional. AccordionOpen is also required - this enables the
 * accordion to open without updating the selected value which is handled by clicking the name.
 *
 * There is a slot placed below the description which can be used to send custom content to. E.g. An image.
 *
 * Please note: MRadioCards must be used inside a container element containing only MRadioCards and no other elements.
 * Wrap in a div or similar so the automatic calculations work on determining whether to use accordion or not. This
 * component will automatically add and remove 'is-flex' and 'is-justify-content-space-between' to the parent element
 * depending on whether we are using accordion effect or not.
 */
@Component({
  components: {
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CircleIcon
  }
})
export default class MRadioCards extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: any;
  @Prop()
  value!: any;
  @Prop()
  title!: string;
  @Prop()
  description?: string;
  @Prop({ required: false, default: false })
  disabled!: boolean|string;
  @Prop({required: false, default: false})
  recalculateAccordion!: boolean;
  @Prop({required: false, default: ''})
  elementTitle!: string;

  isDisabled: boolean = false;
  asAccordion: boolean = false;
  recalculatedAccordion: boolean = false;
  cardWidth: number = 200;
  hasSlotContent: boolean = false;
  loadAttempt: number = 0;

  // to ensure all the accordions are closed on first open, we are comparing against this random string, the accordion will open and close as expected but won't load open
  accordionIsOpen: string = '__null__';

  $refs!: {
    MRadioCardsSection: HTMLElement
  };

  updateActiveValue () {
    if (!this.isDisabled) {
      this.$emit('updateModelValue', this.value);
      if( this.asAccordion ){
        this.accordionIsOpen = this.value;
      }
    }
  }

  created () {
    this.bindEvents();
  }

  mounted () {
    this.setUseAccordion();
    this.setHasSlotContent();
  }

  bindEvents () {
    window.addEventListener('resize', this.setUseAccordion);
  }

  unbindEvents () {
    window.removeEventListener('resize', this.setUseAccordion);
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  setHasSlotContent () {
    this.hasSlotContent = !!(this.$slots && this.$slots.default);
  }

  /**
   * Sets accordion to true or false by calculating the container width, counting the total number of elements in the
   * parent element, then ensuring all the cards will fit with a minimum width of 150px and a buffer of 40px between
   * each card.
   * If accordion is false, i.e. using the cards, then will also set the width of the cards.
   */
  setUseAccordion () {
    if (this.$refs.MRadioCardsSection.parentElement) {
      const containerWidth = this.$refs.MRadioCardsSection.parentElement.offsetWidth;
      const totalCards = this.$refs.MRadioCardsSection.parentElement.childElementCount;
      const cardMinWidth = 150;
      const buffer = containerWidth > 700 && totalCards < 4 ? 30 : 20;
      const calc = (containerWidth - (totalCards * cardMinWidth) - (totalCards * buffer));
      this.asAccordion = calc < 0;
      // as cards
      if (!this.asAccordion) {
        const columnWidth = Math.floor(containerWidth / totalCards);
        this.cardWidth = columnWidth - buffer;
        this.$refs.MRadioCardsSection.parentElement.classList.add('is-flex');
        this.$refs.MRadioCardsSection.parentElement.classList.add('is-justify-content-space-between');
      } else {
        // as accordion
        this.$refs.MRadioCardsSection.parentElement.classList.remove('is-flex');
        this.$refs.MRadioCardsSection.parentElement.classList.remove('is-justify-content-space-between');
      }
      // attempt to use radio cards up to 5 times
      if (containerWidth === 0 && this.loadAttempt < 5) {
        ++this.loadAttempt;
        setTimeout(this.setUseAccordion, 100);
      }
      // console.log(containerWidth, totalCards, calc, this.asAccordion);
    }
  }

  /**
   * If using radio cards in a tabbed interface where you cannot hide using v-if, e.g. it forms part of an entire form
   * which is posted, then use the Prop recalculateAccordion set to true or false to trigger a recalculation. Will run
   * the recalculation once per tab switch.
   */
  @Watch('recalculateAccordion')
  recalculateIsAccordion () {
    if( this.recalculateAccordion && !this.recalculatedAccordion ) {
      this.recalculatedAccordion = true;
      this.setUseAccordion();
    } else if( !this.recalculateAccordion ) {
      this.recalculatedAccordion = false;
    }
  }

  @Watch('disabled', {immediate: true})
  updateIsDisabled () {
    this.isDisabled = typeof this.disabled !== 'boolean' ? true : this.disabled;
  }
}
