
import { Component, Vue } from 'vue-property-decorator';
import AAddNewItem from '@/components/atoms/buttons/AAddNewItem.vue';
import AGoToAddChannel from '@/components/atoms/buttons/AGoToAddChannel.vue';

@Component({
  components: { AGoToAddChannel, AAddNewItem }
})
export default class MNoItemsEmptyExploreSearch extends Vue {
  activeStep = 0;

  focusSearch () {
    const input = document.querySelector('.search-wrapper input') as any;
    if (input) {
      input.focus();
    }
  }
}
