import HttpService from '@/services/HttpService';

import { UrlResolver } from './interfaces/UrlResolver';
import { UrlResolverGetQuery } from './interfaces/UrlResolverGetQuery';

export default class UrlResolverService {
  public static basePath = '/__/ms-item/';

  /**
   *  Operation ID: urlResolverGet
   *  Summary: Fetch URL to resolve
   *  Description: Fetches the URL preview
   */
  public static urlResolverGet(
    query: UrlResolverGetQuery
  ): Promise<UrlResolver> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UrlResolverService.basePath + 'url-resolver',
      qs: query,
    });
  }
}
