
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MItemEntryCustomFieldLabel extends Vue {
  @Prop({ required: true })
  label!: string;

  @Prop({ required: true })
  fieldNumber!: number;

  @Prop({ required: true })
  unit?: string;
}
