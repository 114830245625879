import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import { ChannelsGetQuery } from '@/api/ms-channel/services/interfaces';
import {
  ConnectionsUsernameMatchingGetQuery,
  UserSettingsAppSettingsSearchSettings
} from '@/api/ms-authentication/services/interfaces';
import {
  SortBy as PeopleSortBy
} from '@/api/ms-authentication/services/interfaces/ConnectionsUsernameMatchingGetQuery';
import { ISearchSettingsModule } from '@/store/modules/interfaces/SearchSettingsModule';
import {
  ChannelsSearchSettings,
  SortBy,
  SortDir
} from '@/api/ms-authentication/services/interfaces/UserSettingsAppSettingsSearchSettings';
import { clone } from 'common-utils/object';

@Module({
  name: StoreNames.SEARCH_SETTINGS_STORE,
  namespaced: true
})
export default class SearchSettingsModule extends VuexModule implements ISearchSettingsModule {
  defaultSearchSettings: UserSettingsAppSettingsSearchSettings = {
    itemsSearchRecents: [],
    channelsSearchSettings: {
      sortBy: SortBy.Name,
      sortDir: SortDir.Asc
    }
  };
  searchSettings: UserSettingsAppSettingsSearchSettings = clone(this.defaultSearchSettings);

  // PEOPLE SEARCH SETTINGS
  peopleSearchSettings: ConnectionsUsernameMatchingGetQuery = {
    qs: '',
    sortBy: PeopleSortBy.FirstName,
    sortDir: SortDir.Asc,
  };

  get getItemsSearchRecents () {
    return this.searchSettings.itemsSearchRecents;
  }

  get getChannelsSearchSettingsSortOnly (): ChannelsGetQuery {
    return {
      sortBy: this.searchSettings.channelsSearchSettings.sortBy,
      sortDir: this.searchSettings.channelsSearchSettings.sortDir,
    };
  }

  get getPeopleSearchSettings () {
    return this.peopleSearchSettings;
  }

  @Mutation
  public RESET () {
    this.searchSettings = clone(this.defaultSearchSettings);
    this.peopleSearchSettings = {
      qs: '',
      sortBy: PeopleSortBy.FirstName,
      sortDir: SortDir.Asc,
    };
  }

  @Mutation
  REHYDRATE (input: any) {
    if (input.channelsSearchSettings) {
      this.searchSettings.channelsSearchSettings = input.channelsSearchSettings;
    }
    if (input.itemsSearchRecents) {
      this.searchSettings.itemsSearchRecents = input.itemsSearchRecents;
    }
  }

  @Mutation
  ITEMS_SEARCH_RECENT_ADD (input: string) {
    if (input.length < 2) {
      return;
    }
    const exists = this.searchSettings.itemsSearchRecents.findIndex((item) => item === input);
    if (exists !== -1) {
      this.searchSettings.itemsSearchRecents.splice(exists, 1);
    }
    this.searchSettings.itemsSearchRecents.unshift(input);
    if (this.searchSettings.itemsSearchRecents.length > 10) {
      this.searchSettings.itemsSearchRecents.pop();
    }
  }

  @Mutation
  ITEMS_SEARCH_RECENT_CLEAR_ALL () {
    this.searchSettings.itemsSearchRecents = [];
  }

  @Mutation
  CHANNEL_SEARCH_SETTINGS_ADD (input: ChannelsSearchSettings) {
    this.searchSettings.channelsSearchSettings = input;
  }
}
