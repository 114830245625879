import HttpService from '@/services/HttpService';

import { GenericMessage } from './interfaces/GenericMessage';
import { RecommendNameUniqueItemNameGetPath } from './interfaces/RecommendNameUniqueItemNameGetPath';
import { RecommendNameUniqueItemNameGetQuery } from './interfaces/RecommendNameUniqueItemNameGetQuery';
import { RecommendNameUniqueItemNameIgnorePut } from './interfaces/RecommendNameUniqueItemNameIgnorePut';
import { RecommendNameUniqueItemNameIgnorePutPath } from './interfaces/RecommendNameUniqueItemNameIgnorePutPath';
import { RecommendNameUniqueItemNameImagePostFormData } from './interfaces/RecommendNameUniqueItemNameImagePostFormData';
import { RecommendNameUniqueItemNameImagePostPath } from './interfaces/RecommendNameUniqueItemNameImagePostPath';
import { RecommendNameUniqueItemNameItemGetPath } from './interfaces/RecommendNameUniqueItemNameItemGetPath';
import { RecommendNameUniqueItemNameReportPost } from './interfaces/RecommendNameUniqueItemNameReportPost';
import { RecommendNameUniqueItemNameReportPostPath } from './interfaces/RecommendNameUniqueItemNameReportPostPath';
import { RecommendPost } from './interfaces/RecommendPost';
import { Recommendation } from './interfaces/Recommendation';

export default class RecommendService {
  public static basePath = '/__/ms-item/';

  /**
   *  Operation ID: recommendNameUniqueItemNameGet
   *  Summary: Fetch a recommendation
   *  Description: Fetches the full recommendation object by item uuid passed in the path
   */
  public static recommendNameUniqueItemNameGet(
    pathParams: RecommendNameUniqueItemNameGetPath,
    query: RecommendNameUniqueItemNameGetQuery
  ): Promise<Recommendation> {
    return HttpService.sendRequest({
      method: 'GET',
      path: RecommendService.basePath + 'recommend/name/:uniqueItemName',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: recommendNameUniqueItemNameIgnorePut
   *  Summary:
   *  Description: Ignore an item recommendation, either with or without a notification and message
   */
  public static recommendNameUniqueItemNameIgnorePut(
    body: RecommendNameUniqueItemNameIgnorePut,
    pathParams: RecommendNameUniqueItemNameIgnorePutPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: RecommendService.basePath + 'recommend/name/:uniqueItemName/ignore',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: recommendNameUniqueItemNameImagePost
   *  Summary: Upload an image
   *  Description: Upload an image to a recommendation based on unique name. This is separate due to the limitations of form data within  the browser.

   */
  public static recommendNameUniqueItemNameImagePost(
    formData: RecommendNameUniqueItemNameImagePostFormData,
    pathParams: RecommendNameUniqueItemNameImagePostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: RecommendService.basePath + 'recommend/name/:uniqueItemName/image',
      formData,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: recommendNameUniqueItemNameItemGet
   *  Summary: Fetch the uniqueItemName of an item added from a recommendation
   *  Description: For a recommendation that was accepted, return the uniqueItemName of the item that recommendation created
   */
  public static recommendNameUniqueItemNameItemGet(
    pathParams: RecommendNameUniqueItemNameItemGetPath
  ): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'GET',
      path: RecommendService.basePath + 'recommend/name/:uniqueItemName/item',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: recommendNameUniqueItemNameReportPost
   *  Summary: Report an item recommendation
   *  Description: Reports an item recommendation to the admin for moderation
   */
  public static recommendNameUniqueItemNameReportPost(
    body: RecommendNameUniqueItemNameReportPost,
    pathParams: RecommendNameUniqueItemNameReportPostPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: RecommendService.basePath + 'recommend/name/:uniqueItemName/report',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: recommendPost
   *  Summary: Create or email a recommendation
   *  Description: Create or email a recommendation
   */
  public static recommendPost(body: RecommendPost): Promise<Recommendation> {
    return HttpService.sendRequest({
      method: 'POST',
      path: RecommendService.basePath + 'recommend',
      body,
    });
  }
}
