/**
 * Returns a base 64 string from a URL
 * @param imageUrl
 */
import calculateFullImageHref from '@/storybook-components/src/utils/calculateFullImageHref';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import config from '@/config';
import fileReaderToBase64Preview from '@/utils/fileReaderToBase64Preview';

export default async (imageFileOrURL: string, external = false): Promise<any> => {
  const res = await fetch(external ? imageFileOrURL : calculateFullImageHref(
    config.api.baseUrl + config.api.basePaths.imageRead,
    Size.The400X,
    imageFileOrURL
  ));

  let blob = await res.blob();
  const type = imageFileOrURL.split('.').pop();
  blob = blob.slice(0, blob.size, 'image/' + type);

  return fileReaderToBase64Preview(blob);
};
