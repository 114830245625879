import { Item } from '@/api/ms-item/services/interfaces/Item';
import getMapsLink from '@/utils/getMapsLink';

//documentation to using google maps urls: https://developers.google.com/maps/documentation/urls/get-started
export default (item: Item) => {
  const link = getMapsLink(item);
  if (link) {
    window.open(link.link, link.target);
  } else {
    console.error('Undefined geolocation object sent for clickout');
  }
};
