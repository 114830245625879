export interface ChannelsGetQuery {
  /**
   * Boolean to determine whether returning archived or active channels (default is
   * false/active)
   */
  archived?: boolean;
  /**
   * When true should return all and will not paginate
   */
  getAll?: boolean;
  /**
   * If true the private channels will be omitted form the search results
   */
  hidePrivate?: boolean;
  /**
   * If true the public channels will be omitted form the search results
   */
  hidePublic?: boolean;
  /**
   * If true will return if the current user can or cannot add to this channel
   */
  includeCanAdd?: boolean;
  /**
   * If true is means the user should be a member of, if false not a member of. Defaults to
   * true
   */
  memberOf?: boolean;
  /**
   * The number of items to skip before starting to collect the result set.
   */
  offset?: number;
  /**
   * When true will only return channels the current user owns
   */
  onlyChannelsIOwn?: boolean;
  /**
   * The attribute / field to sort by
   */
  sortBy?: SortBy;
  /**
   * The direction to sort by
   */
  sortDir?: SortDir;
  /**
   * The text string to search for.
   */
  text?: string;
}

/**
 * The attribute / field to sort by
 */
export enum SortBy {
  CreatedAt = 'createdAt',
  ItemLastAdded = 'itemLastAdded',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Username = 'username',
}

/**
 * The direction to sort by
 */
export enum SortDir {
  Asc = 'asc',
  Desc = 'desc',
}
