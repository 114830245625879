import { Route } from 'vue-router/types/router';
import { RouteNames } from '@/router/RouteNames';
import clone from '@/utils/clone';
import { i18n } from '@/plugins/i18n';
import striptags from 'striptags';
import { decode } from 'html-entities';
import isBrowserExt from '@/utils/isBrowserExt';
import config from '@/config';

interface Options {
  title?: string,
  description?: string,
  og?: {
    image?: string
  }
}

class SeoInjector {
  careerBase = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    'title': '',
    'description': '',
    'responsibilities': '',
    'identifier': {
      '@type': 'PropertyValue',
      'name': 'Liffery',
      'value': 'sales'
    },
    'hiringOrganization': {
      '@type': 'Organization',
      'name': 'Liffery',
      'sameAs': config.api.baseUrl
    },
    'industry': 'Digital',
    'employmentType': 'FULL_TIME',
    'workHours': 'Flexible',
    'datePosted': '2021-10-16',
    'validThrough': '2022-10-01',
    'applicantLocationRequirements': {
      '@type': 'Country',
      'name': '150'
    },
    'jobLocationType': 'TELECOMMUTE',
    'experienceRequirements': '3-5 years or more'
  };

  schemaChunks = {
    Organization: {
      '@type': 'Organization',
      '@id': config.api.baseUrl + '/#organization',
      name: 'Liffery Ltd',
      url: config.api.baseUrl + '/liffery/for-business',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'London, England',
        postalCode: 'WC2H 9JQ',
        streetAddress: '71-75 Shelton Street'
      },
      sameAs: [
        'https://www.facebook.com/Liffery',
        'https://www.instagram.com/liffery_',
        'https://www.linkedin.com/company/liffery/'
      ],
      logo: {
        '@type': 'ImageObject',
        '@id': config.api.baseUrl + '#logo',
        inLanguage: 'en-GB',
        url: config.api.baseUrl + '/img/icons/Liffery-Logo.png',
        contentUrl: config.api.baseUrl + '/img/icons/Liffery-Logo.png',
        width: 512,
        height: 512,
        caption: 'Liffery is your personal research assistant!'
      },
      image: { '@id': config.api.baseUrl + '/#logo' }
    },
    WebSite: {
      '@type': 'WebSite',
      '@id': config.api.baseUrl + '/#website',
      url: config.api.baseUrl,
      name: 'Liffery',
      description: 'Liffery is your personal research assistant. The first tool focussing on letting people capture, consider and collaborate on product discoveries and more without any intrusive advertising. We are advocates for the natural flow of purchase-decision making and are en route to changing the noisy and inefficient dynamics of our e-commerce spaces. ',
      publisher: { '@id': config.api.baseUrl + '#organization' },
      inLanguage: 'en-GB'
    },
    WebPage: {
      '@type': 'WebPage',
      '@id': config.api.baseUrl + '/#webpage',
      url: config.api.baseUrl,
      name: 'Liffery is your personal research assistant!',
      isPartOf: { '@id': config.api.baseUrl + '/#website' },
      about: { '@id': config.api.baseUrl + '/#organization' },
      datePublished: '2021-10-14T15:16:54+00:00',
      dateModified: '2021-10-15T10:11:17+00:00',
      description: '',
      breadcrumb: { '@id': config.api.baseUrl + '/#breadcrumb' },
      inLanguage: 'en-US',
      potentialAction: [{ '@type': 'ReadAction', 'target': [config.api.baseUrl] }]
    },
    ContactPoint: {
      '@type': 'ContactPoint',
      '@id': config.api.baseUrl + '/liffery/contact',
      email: config.emails.support,
      telephone: '+44 20 3985 0916',
      contactType: 'Sales & Support',
      availableLanguage: ['English', 'German'],
      sameAs: [
        config.api.baseUrl + '/liffery/contact'
      ],
    }
  };

  // title() will use the string in title, or if empty then will use the default title
  pageTitle: {
    title: string,
    default: string
  } = {
    title: '',
    default: 'Liffery - Your quiet online space',
  };

  routesDefaultSEODescription = i18n.t('page.whatIsLiffery.opener.1') + ' ' + i18n.t('page.whatIsLiffery.opener.1');

  init (route: Route, options?: { title?: string, description?: string, og?: { image?: string } }): void {

    if (!this.shouldRunSeoInjector()) {
      return;
    }

    const seo: any = route.matched.filter((record) => record.meta.seo);
    // route level seo content trumps options
    if (seo.length > 0) {
      const seoMeta = seo.pop().meta.seo;
      this.addLifferyToTitle(seoMeta.title);
      if (seoMeta.description) {
        this.setDescription(seoMeta.description);
      }
    } else if (options) {
      if (options.title) {
        this.addLifferyToTitle(options.title);
      }
      if (options.description) {
        this.setDescription(options.description);
      }
    }

    this.title();
    this.ogImg(route, options);
    this.schemaOrg(route);
  }

  shouldRunSeoInjector () {
    return (!isBrowserExt());
  }

  title () {
    if (!this.shouldRunSeoInjector()) {
      return;
    }
    const title = this.getPageTitle();
    document.title = title;
    // @ts-ignore
    document.querySelector('meta[property="og:title"]').setAttribute('content', title);
  }

  getPageTitle () {
    return this.pageTitle.title ? this.pageTitle.title : this.pageTitle.default;
  }

  /**
   * Wrapper adds " | Liffery" to the page title. E.g. "Forename Surname | Liffery"
   */
  setPageTitle (title: string): void {
    if (!this.shouldRunSeoInjector()) {
      return;
    }
    if (title) {
      // Sanitise everything back to basic text that's sent here. Strip tags and decode html. Title tag must be text only.
      this.addLifferyToTitle(
        decode(striptags(title))
      );
      this.title();
    }
  }

  addLifferyToTitle (title) {
    this.pageTitle.title = title + ' | Liffery';
  }

  // Clears the page title to ensure default is used, and then sets it by running title()
  resetPageTitle () {
    this.pageTitle.title = '';
    this.title();
  }

  // Clears the page title variable without changing the current page title, e.g. to use in beforeDestroy
  clearPageTitle () {
    this.pageTitle.title = '';
  }

  setDescription (desc: string = ''): void {
    if (!this.shouldRunSeoInjector()) {
      return;
    }
    desc = decode(striptags(desc));
    if (!desc) {
      desc = this.routesDefaultSEODescription;
    }
    // @ts-ignore
    document.querySelector('meta[name="description"]').setAttribute('content', desc);
    // @ts-ignore
    document.querySelector('meta[property="og:description"]').setAttribute('content', desc);
  }

  getImageSource (cssSelector: string): string {
    try {
      // @ts-ignore
      return document.querySelector(cssSelector).src;
    } catch (e) {
      // just return the default image
      return this.getOgImg(RouteNames.ROUTE_INFO_WHAT_IS);
    }
  }

  getOgImg (name: RouteNames): string {
    switch (name) {
      case RouteNames.ROUTE_CHANNEL_VIEW:
        return this.getImageSource('.channel-image-hero');
      case RouteNames.ROUTE_ITEM_DETAIL_VIEW:
        return this.getImageSource('.item-image');
      case RouteNames.ROUTE_INFO_BUSINESS:
        return 'https://www.liffery.com/img/icons/Liffery-for-Business.png';
      default:
        return 'https://www.liffery.com/img/icons/Liffery-for-People.png';
    }
  }

  ogImg (r: Route, options?: Options) {
    // @ts-ignore
    document.querySelector('meta[property="og:image"]').setAttribute(
      'content',
      (options && options.og && options.og.image) ? options.og.image : this.getOgImg(r.name as RouteNames)
    );
  }

  getSchemaOrgElements (route: Route): any[] {
    const base = clone(this.careerBase);
    const adIntro: string[] = Object.values(i18n.t('page.career.adIntro.points'));
    base.description = '<p>' + adIntro.join('.</p><p>') + '</p>';

    switch (route.name) {
      case RouteNames.ROUTE_INFO_WHAT_IS:
        return [
          this.schemaChunks.WebSite,
          this.schemaChunks.WebPage,
          this.schemaChunks.ContactPoint
        ];
      case RouteNames.ROUTE_INFO_BUSINESS:
        return [
          this.schemaChunks.Organization,
          this.schemaChunks.ContactPoint
        ];

      case RouteNames.ROUTE_INFO_CAREERS_DEV_FS:
        base.title = 'Fullstack Typescript web developer';
        base.identifier.value = base.title;
        base.responsibilities = 'Typescript, NodeJS, MongoDB, Redis, OpenAPI, Docker, VueJS';
        return base;

      case RouteNames.ROUTE_INFO_CAREERS_DEV_FE:
        base.title = 'Frontend Web Developer';
        base.identifier.value = base.title;
        base.responsibilities = 'VueJS, Buefy, Bulma, Svelte, CapacitorJS';
        return base;

      case RouteNames.ROUTE_INFO_CAREERS_SALES:
        base.title = 'Sales';
        base.identifier.value = base.title;
        base.responsibilities = Object.values(i18n.t('page.career.jobs.sales.responsibilities')).join('. ');
        return base;

      default:
        return [this.schemaChunks.ContactPoint];
    }
  }

  schemaOrg (route: Route): void {
    const defaultSchema: any = {
      '@context': 'https://schema.org',
      '@graph': this.getSchemaOrgElements(route)
    };

    // @ts-ignore
    document.querySelector('head .liffery-schema').innerHTML = JSON.stringify(defaultSchema);
  }
}

export default new SeoInjector();
