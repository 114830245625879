/**
 * A custom function to return the vScroll box height and width
 * used in the items store but also in the pages to check previously
 * configured outer sizes to decide to recalculate or not
 */
export default (fullHeight?: boolean): {
  height: number,
  width: number,
  minDivisionValue: number
} => {
  const width = (document.body.clientWidth || document.documentElement.clientWidth);
  let height;
  if (fullHeight) {
    height = window.innerHeight;
  } else {
    const closestContainer = document.querySelector('.MHeader') as Element;
    const closestContainerDims = closestContainer.getBoundingClientRect();
    height = window.innerHeight - closestContainerDims.bottom;
  }

  return {
    height,
    width,
    minDivisionValue: 450
  };
};
