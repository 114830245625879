
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import AAddItemIcon from '@/components/atoms/icon/AAddItemIcon.vue';

@Component({
  components: { AAddItemIcon }
})
export default class AAddItemButtonLink extends Vue {
  @Prop({ required: false, default: '1' })
  iconSize!: string | number;
  @Prop({ required: false, default: 'font-body-s' })
  textClass!: string;

  click () {
    this.$emit(CHILD_EMIT);
  }
}
