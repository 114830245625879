import { Connections, UserSearchs } from '@/api/ms-authentication/services/interfaces';
import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { initialDataObjectNonItem } from '@/store/modules/constants/initialDataObject';
import { Datum as ConnectionsDatum } from '@/api/ms-authentication/services/interfaces/Connections';

export interface People {
  channel: {
    connected: Connections,
    searchResults: UserSearchs,
    searchTerm: string
  },
  dashboard: {
    connected: Connections,
    searchResults: UserSearchs,
    searchTerm: string
  },
  profile: {
    connected: Connections,
    searchResults: UserSearchs,
    searchTerm: string
  }
}

export interface IPeopleModule extends IStoreModule {
  // General people object for dash/channel/profile pages
  people: People;

  // People you have requested to be connected to
  // or are invited to be connected to
  connectionRequests: ConnectionsDatum[];

  // People you are already connected to
  connectedPeople: ConnectionsDatum[];
}

export const initialPeopleState: People = {
  dashboard: {
    connected: initialDataObjectNonItem,
    searchResults: initialDataObjectNonItem,
    searchTerm: ''
  },
  channel: {
    connected: initialDataObjectNonItem,
    searchResults: initialDataObjectNonItem,
    searchTerm: ''
  },
  profile: {
    connected: initialDataObjectNonItem,
    searchResults: initialDataObjectNonItem,
    searchTerm: ''
  }
};

export interface INoMoreResults {
  connected: boolean,
  notConnected: boolean,
  matching: boolean,
  notMatching: boolean,
  myConnections: boolean
}

export const initialNoMoreResults: INoMoreResults = {
  connected: true,
  notConnected: true,
  matching: true,
  notMatching: true,
  myConnections: true
};
