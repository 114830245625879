// This is a mapping to:
// @/components/atoms/icon/AVueFeatherIconPack.vue
export default {
  vfi: {
    sizes: {
      'default': '1.5x',
      'is-small': '1x',
      'is-medium': '2x',
      'is-large': '3x'
    },
    iconPrefix: '',
    internalIcons: {
      'check': 'check',
      'information': 'info',
      'check-circle': 'check-circle',
      'alert': 'alert-triangle',
      'alert-circle': 'alert-circle',
      'arrow-up': 'arrow-up',
      'minus': 'minus-icon',
      'plus': 'plus-icon',
      'chevron-right': 'chevron-right',
      'chevron-left': 'chevron-left',
      'chevron-down': 'chevron-down',
      'eye': 'eye',
      'eye-off': 'eye-off',
      'menu-down': 'arrow-down',
      'menu-up': 'arrow-up',
      'star': 'star'
    }
  }
};
