
import { Component, Vue } from 'vue-property-decorator';
import MFacebookButton from '@/components/molecules/buttons/MFacebookButton.vue';
import MGoogleButton from '@/components/molecules/buttons/MGoogleButton.vue';

@Component({
  components: {
    MFacebookButton,
    MGoogleButton
  },
})
export default class MSSOButtons extends Vue {

}
