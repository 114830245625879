import { ChannelsGetQuery, SortBy, SortDir } from '@/api/ms-channel/services/interfaces/ChannelsGetQuery';

/**
 * @param $route ==> this.$route from Vue
 */
export default ($route: any): ChannelsGetQuery => {
  const { hidePublic, hidePrivate, onlyChannelsIOwn, sortBy, sortDir } = $route.query;
  return {
    hidePrivate: hidePrivate === 'true',
    hidePublic: hidePublic === 'true',
    onlyChannelsIOwn: onlyChannelsIOwn === 'true',
    sortDir: sortDir as unknown as SortDir,
    sortBy: sortBy as unknown as SortBy
  };
};
