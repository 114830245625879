import { Key, Payload, Variable } from '@/api/ms-notification/services/interfaces/Notification';

export default (payload?: Payload): Record<Key, any> => {
  const obj: any = {};
  if (payload && Array.isArray(payload.variables)) {
    payload.variables.forEach((value: Variable) => {
      obj[value.key] = value.value;
    });
  }
  return obj;
};
