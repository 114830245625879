
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { UserIcon } from 'vue-feather-icons';
import { User } from '@/api/ms-authentication/services/interfaces/Login';
import { RouteNames } from '@/router/RouteNames';
import { Item } from '@/api/ms-item/services/interfaces';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    UserIcon
  }
})
export default class OItemLists extends Vue {
  @Prop()
  isNotOnShoppingList!: boolean;

  @Prop({ required: true })
  item!: Item;

  name = RouteNames.ROUTE_GETGOT;
  userImageSize = Size.The60X60;

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }
}
