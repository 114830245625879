export interface ChannelMemberSlugGetQuery {
  /**
   * If true will return users as the owner of the channel should see
   */
  asOwner?: boolean;
  limit?: number;
  memberStatus?: MemberStatus;
  /**
   * The number of items to skip before starting to collect the result set.
   */
  offset?: number;
  requestCount?: boolean;
  /**
   * The attribute / field to sort by
   */
  sortBy?: SortBy;
  /**
   * The direction to sort by
   */
  sortDir?: SortDir;
  /**
   * The text string to search for.
   */
  text?: string;
}

export enum MemberStatus {
  ConciergeJobAccepted = 'ConciergeJobAccepted',
  ConciergeJobCancelled = 'ConciergeJobCancelled',
  ConciergeJobCompleted = 'ConciergeJobCompleted',
  ConciergeJobRemoved = 'ConciergeJobRemoved',
  InviteAccepted = 'InviteAccepted',
  InviteCancelled = 'InviteCancelled',
  InviteRejected = 'InviteRejected',
  Invited = 'Invited',
  JoinRequestAccepted = 'JoinRequestAccepted',
  JoinRequestCancelled = 'JoinRequestCancelled',
  JoinRequestRejected = 'JoinRequestRejected',
  JoinRequestSent = 'JoinRequestSent',
  RemovedByChannel = 'RemovedByChannel',
  SelfRemoval = 'SelfRemoval',
}

/**
 * The attribute / field to sort by
 */
export enum SortBy {
  CreatedAt = 'createdAt',
  ItemLastAdded = 'itemLastAdded',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Username = 'username',
}

/**
 * The direction to sort by
 */
export enum SortDir {
  Asc = 'asc',
  Desc = 'desc',
}
