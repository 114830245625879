
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { CaptchaPost } from '@/api/ms-authentication/services/interfaces';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import { AuthenticationStore } from '@/store';

@Component({
  components: {
    AFormServerError,
    MInputWithValidation,
    ValidationObserver,
  },
})
export default class OCaptchaForm extends Vue {
  private loading: boolean = false;
  private loadingSVG: boolean = false;
  private captchaSVG: string = '';
  private form: CaptchaPost = {
    email: this.lastEmailLoginAttempt,
    hash: '',
    answer: '',
  };

  get errors () {
    return AuthenticationStore.errorCaptchaContent;
  }

  get lastEmailLoginAttempt () {
    return AuthenticationStore.lastEmailLoginAttempt;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_CAPTCHA_UNSET();
  }

  async onSubmit () {
    this.loading = true;
    // NB: The svg and hash are not persisted to the store to reduce stale content on reload
    if (!(await AuthenticationStore.unlockCaptcha(this.form))) {
      await this.fetchCaptcha();
    }
    this.loading = false;
  }

  private async mounted () {
    await this.fetchCaptcha();
  }

  private async fetchCaptcha () {
    this.form.hash = '';
    this.form.answer = '';
    this.captchaSVG = '<i class="el-icon-loading"></i>';
    this.loadingSVG = true;
    const captcha = await AuthenticationStore.getCaptcha(
      this.lastEmailLoginAttempt
    );
    this.loadingSVG = false;
    this.form.hash = captcha.hash;
    this.captchaSVG = captcha.data.replace('\\', '');
  }
}
