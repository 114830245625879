import { Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import { IWatchersModule } from '@/store/modules/interfaces/WatchersModule';
import { WatchItems } from '@/api/ms-watch-urls/services/interfaces';
import WatchItemPriceService from '@/api/ms-watch-urls/services/WatchItemPriceService';
import { WatchersStore } from '@/store';

@Module({
  name: StoreNames.WATCHERS_STORE,
  namespaced: true
})
export default class SearchSettingsModule extends VuexModule implements IWatchersModule {

  watchers: WatchItems = [];
  fetching: boolean = false;

  @Mutation
  RESET () {
    this.watchers = [];
  }

  get getWatchers () {
    return this.watchers;
  }

  /**
   * MutationAction here will return and mutate the store in 1 go
   * See: https://www.npmjs.com/package/vuex-module-decorators
   */
  @MutationAction({ mutate: ['watchers'] })
  async getAllActive (input: { username: string }) {
    WatchersStore.FETCHING_STATUS(true);
    const watchers = await WatchItemPriceService.watchItemPriceUsernameGet({
      username: input.username
    });
    WatchersStore.FETCHING_STATUS(false);
    return { watchers };
  }

  @MutationAction({ mutate: ['watchers'] })
  async removeWatcherById (input: { id: string, username: string }) {
    WatchersStore.FETCHING_STATUS(true);
    await WatchItemPriceService.watchItemPriceUsernameIdDelete({
      id: input.id,
      username: input.username
    });
    return WatchersStore.getAllActive(input);
  }

  @Mutation
  FETCHING_STATUS (state: boolean) {
    this.fetching = state;
  }
}
