var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"OItemsContainer"},[(!_vm.pinned)?_c('VirtualCollection',{class:{'hide-scroll': _vm.disableScroll},attrs:{"cellSizeAndPositionGetter":item => { return { width: item.width, height: item.height, x: item.x, y: item.y }},"collection":_vm.items.items,"container-padding-bottom":25,"headerSlotHeight":_vm.headerSlotHeight,"height":_vm.getVScrollDims.boxHeight,"scrollToBottomRange":500,"width":_vm.getVScrollDims.boxWidth},on:{"scrolled-to-bottom-range":_vm.scrollBottom},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._t("search"),_c('div',{staticClass:"show-new-button"},[(_vm.newItemsCount)?_c('m-button-with-icon',{attrs:{"classes":"is-rounded"},on:{"click":_vm.injectLatest}},[_c('template',{slot:"icon"},[_c('refresh-cw-icon',{staticClass:"show-new-icon",attrs:{"size":"1x"}})],1),_c('template',{slot:"text"},[_vm._v(" "+_vm._s(_vm.$t('dict.showNewItems'))+" ")])],2):_vm._e()],1),(!_vm.items.busy && _vm.noItems)?_c('div',{staticClass:"not-found-wrapper"},[_c('div',[[_c('p',{staticClass:"title-7 not-found-message"},[(!_vm.hasSearchParams.content)?[(_vm.searchType === _vm.SearchType.dashboard || (_vm.searchType === _vm.SearchType.profile && _vm.isOwnProfile()))?[_c('m-no-items-empty-search')]:(_vm.searchType === _vm.SearchType.dashboardExplore)?[_c('m-no-items-empty-explore-search')]:(_vm.searchType === _vm.SearchType.channel)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.channel'))}})]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.otherPersonsProfile', {firstName: _vm.userOtherMeta.firstName}))}})]]:(_vm.hasSearchParams.qs !== '' && _vm.hasSearchParams.filtersCount === 0)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.fromSearch', {
                  qs: _vm.hasSearchParams.qs,
                  sortCount: _vm.hasSearchParams.filtersCount
                }))}})]:(_vm.hasSearchParams.qs !== '' && _vm.hasSearchParams.filtersCount !== 0)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.fromSearchAndSort', {
                qs: _vm.hasSearchParams.qs,
                sortCount: _vm.hasSearchParams.filtersCount
              }))}})]:(_vm.hasSearchParams.qs === '' && _vm.hasSearchParams.filtersCount !== 0)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('containers.items.nonFound.fromSort', {
              sortCount: _vm.hasSearchParams.filtersCount
            }))}})]:_vm._e()],2)]],2)]):_vm._e()]},proxy:true},{key:"header-inner-container",fn:function(){return [_vm._t("inner")]},proxy:true},{key:"cell",fn:function(props){return _c('div',{staticClass:"item-card"},[(props.data.skeleton)?[_c('m-item-card-skeleton')]:(props.data.phantom)?[_c('div',{staticClass:"phantom-card"})]:[_c('o-item-card',{key:props.data.item.uniqueItemName,attrs:{"currentUser":_vm.currentUser,"item":props.data.item,"search-type":_vm.searchType,"show-get-got-control":_vm.cardControls.showGetGotControl},on:{"item-request-update":(item) => _vm.$emit('item-request-update', item)}})]],2)}}],null,true)}):_c('o-pinned-items-container',{attrs:{"search-type":_vm.searchType}},[_vm._t("search"),_vm._t("inner")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }