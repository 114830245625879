
import { Component, Vue } from 'vue-property-decorator';
import { ItemDetailStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';

@Component({
  components: {
    ARouterLinkProfile,
    AUserProfilePic,
  }
})
export default class OItemPins extends Vue {
  userImageSize = Size.The60X60;
  loading: boolean = false;

  get pins () {
    const allPins = ItemDetailStore.getItemPins;

    return allPins.filter(pinObj => pinObj.pin);
  }
}
