
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { UserIcon } from 'vue-feather-icons';

@Component({
  components: {
    UserIcon
  }
})
export default class ARouterLinkYourProfile extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_YOUR_PROFILE;
}
