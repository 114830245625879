export interface ConnectionsUsernameMatchingGetQuery {
  /**
   * Includes the requested username
   */
  includeRequestedUsername?: boolean;
  /**
   * The number of items to skip before starting to collect the result set.
   */
  offset?: number;
  /**
   * Search string where a minimum length isn't required
   */
  qs?: string;
  /**
   * The attribute / field to sort by
   */
  sortBy?: SortBy;
  /**
   * The direction to sort by
   */
  sortDir?: SortDir;
  /**
   * Connection states, when this param is not present the default will be: [
   * State.RequestReceivedAccepted,
   * State.RequestSentAccepted,
   * State.RequestSent,
   * State.RequestReceived
   * ]
   */
  state?: State[];
}

/**
 * The attribute / field to sort by
 */
export enum SortBy {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  UpdatedAt = 'updatedAt',
}

/**
 * The direction to sort by
 */
export enum SortDir {
  Asc = 'asc',
  Desc = 'desc',
}

export enum State {
  RequestReceived = 'requestReceived',
  RequestReceivedAccepted = 'requestReceivedAccepted',
  RequestReceivedAcceptedDisconnectedByRequester = 'requestReceivedAcceptedDisconnectedByRequester',
  RequestReceivedCancelled = 'requestReceivedCancelled',
  RequestReceivedRejected = 'requestReceivedRejected',
  RequestSent = 'requestSent',
  RequestSentAccepted = 'requestSentAccepted',
  RequestSentAcceptedDisconnectByRequester = 'requestSentAcceptedDisconnectByRequester',
  RequestSentAcceptedDisconnectedByReceiver = 'requestSentAcceptedDisconnectedByReceiver',
  RequestSentCancelled = 'requestSentCancelled',
  RequestSentRejected = 'requestSentRejected',
}
