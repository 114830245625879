
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField, FieldType } from '@/api/ms-item/services/interfaces/Item';

@Component
export default class ACustomFieldDisplayRating extends Vue {
  @Prop({ required: true })
  field!: CustomField;
  @Prop({required: false, default: 10})
  maxDisplay!: number;
  @Prop({required:true, default: true})
  displayUnit!:boolean;

  created () {
    if( this.field.fieldType !== FieldType.Rating ){
      console.error('Wrong field type. Expects rating.');
    }
  }
}
