import { render, staticRenderFns } from "./OChannelHeader.vue?vue&type=template&id=007cc519&scoped=true&"
import script from "./OChannelHeader.vue?vue&type=script&lang=ts&"
export * from "./OChannelHeader.vue?vue&type=script&lang=ts&"
import style0 from "./OChannelHeader.vue?vue&type=style&index=0&id=007cc519&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "007cc519",
  null
  
)

export default component.exports