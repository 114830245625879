import isIosApp from '@/utils/isIosApp';
import isApp from '@/utils/isApp';
import { Item } from '@/api/ms-item/services/interfaces';

export default (item: Item): null | {
  link: string,
  target: '_blank' | '_system'
} => {
  const geoData = item.editable.geolocationData;
  if (geoData) {
    const coordinates = geoData.position.coordinates;

    const coordsString = coordinates[1] + ',' + coordinates[0];
    // todo: figure out if the user has apple maps installed or not
    const hasAppleMaps = false;
    if (isIosApp() && hasAppleMaps) {
      return {
        link: 'maps://?q=' + coordsString,
        target: '_system'
      };
    }

    let query = '?api=1&query=' + coordsString;
    if( geoData?.placeId ){
      // https://developers.google.com/maps/documentation/urls/get-started#search-action
      // if we have a place ID then including that in the search query will load with the sidebar information
      query += '&query_place_id=' + geoData.placeId;
    }
    return {
      link: 'https://www.google.com/maps/search/' + query,
      target: isApp() ? '_system' : '_blank'
    };
  }

  return null;
};
