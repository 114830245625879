
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
  import { HomeIcon } from 'vue-feather-icons';

  @Component({
    components: {
      HomeIcon
    }
  })
  export default class ARouterLinkDashboard extends Vue {
    @Prop()
    hasIcon!: boolean;
  
    name = RouteNames.ROUTE_DASHBOARD;
  }
