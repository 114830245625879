/**
 * Returns true if the item provided has a price
 */
import { Item } from '@/api/ms-item/services/interfaces/Item';

// eslint-disable-next-line max-lines-per-function
export default (item: Item): boolean => {
  return !!(
    item.urlCache &&
    item.urlCache.price &&
    typeof item.urlCache.price.price !== 'undefined'
  );
};
