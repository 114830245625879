
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { CustomField, FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import MChannelCustomField from '@/components/molecules/forms/MChannelCustomField.vue';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { clone } from 'common-utils/object';
import { moveElement } from 'common-utils/array';
import StaticApiDataService from '@/services/StaticApiDataService';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';

export interface CustomFieldEntry extends Partial<CustomField> {
  componentId: number;
  _id?: string;
  label: string;
  unit: string;
  fieldType?: FieldType;
  modified?: number;
}

@Component({
  components: { OModalWrapper, MChannelCustomField, MInputWithValidation }
})
export default class MChannelCustomFields extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: CustomFieldEntry[];
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({ required: false, default: false })
  recalculateContainer!: boolean;

  finishedLoadingData = false;
  containerWidth: number = 0;
  recalculatedContainer: boolean = false;

  value: CustomFieldEntry[] = [];
  blankField: CustomFieldEntry = {
    componentId: 0,
    label: '',
    unit: ''
  };

  dataTypes: CustomFieldsUnitGroupeds = [];

  $refs!: {
    CustomFieldContainer: HTMLElement
  };

  async created () {
    this.loadValues();
    await this.getDataTypes();
    this.finishedLoadingData = true;
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    window.addEventListener('resize', this.getContainerWidth);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.getContainerWidth);
  }

  getContainerWidth () {
    this.containerWidth = this.$refs.CustomFieldContainer.offsetWidth;
  }

  // loads values and applies an ID to each component for vue reactivity
  loadValues () {
    this.value = this.activeValue ? this.activeValue : [];
    for (let i = 0; i < this.value.length; i++) {
      this.value[i].componentId = this.createId();
    }
  }

  async getDataTypes () {
    this.dataTypes = await StaticApiDataService.channelsCustomFieldsAllGet();
  }

  // create a random id for each component as vue reactivity wasn't working property with array index position
  createId () {
    let id: number = Math.random();
    while (this.value.some((val) => val.componentId === id)) {
      id = Math.random();
    }

    return id;
  }

  addField () {
    const newField: CustomFieldEntry = clone(this.blankField);
    newField.componentId = this.createId();
    this.value.push(newField);
    this.getContainerWidth();
  }

  removeField (index: number) {
    this.value.splice(index, 1);
  }

  moveUp (index: number) {
    if (index > 0) {
      moveElement(this.value, index, index - 1);
    }
  }

  moveDown (index: number) {
    moveElement(this.value, index, index + 1);
  }

  showCustomFieldHelp () {
    this.$buefy.dialog.alert({
      title: this.$t('channel.customFields.helpOpener') as string,
      message: this.$t('channel.customFields.helpText') as string,
    });
  }

  // e.g. when used in tabs, recalculate when tab is active
  @Watch('recalculateContainer')
  recalculateContainerSize () {
    if (this.recalculateContainer && !this.recalculatedContainer) {
      this.recalculatedContainer = true;
      this.getContainerWidth();
    } else if (!this.recalculateContainer) {
      this.recalculatedContainer = false;
    }
  }

  @Watch('value', { deep: true })
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
