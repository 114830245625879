export interface MessageMessageIdStatePatchQuery {
  /**
   * Add state to message for user
   */
  state?: State;
}

/**
 * Add state to message for user
 */
export enum State {
  Clicked = 'clicked',
  Seen = 'seen',
  Unseen = 'unseen',
}
