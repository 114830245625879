
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { XIcon } from 'vue-feather-icons';

  @Component({
    components: {
      XIcon
    }
  })
  export default class AMessage extends Vue {
    @Prop({ required: true })
    type!: string;
    @Prop({ default: true })
    canClose!: boolean;

    closeMessageEvent () {
      this.$emit('child-output', {});
    }
  }
