
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { UsersIcon } from 'vue-feather-icons';

@Component({
  components: {
    UsersIcon
  }
})
export default class ARouterLinkConnections extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_PEOPLE_MANAGEMENT;
}
