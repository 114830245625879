export interface ItemsGetQuery {
  /**
   * When true we call the items search as if the user was not logged into Liffery
   */
  asAnon?: boolean;
  /**
   * The channel to search for items within
   */
  availableCount?: boolean;
  /**
   * The channel slug, ie. to search for items within
   */
  channel?: string;
  /**
   * When passed will narrow search results to only include items from this content category
   * group
   */
  contentCategory?: string;
  /**
   * The date created greater than provided
   */
  dateGT?: Date;
  /**
   * The date created less than provided
   */
  dateLT?: Date;
  /**
   * The array of channels to exclude
   */
  excludeChannels?: string[];
  /**
   * Where the user came from
   */
  from?: string;
  /**
   * The number of items to skip before starting to collect the result set.
   */
  offset?: number;
  /**
   * When true ONLY returns the current users getgot list, this has no affect for an unknown
   * user
   */
  onlyYourGotGet?: boolean;
  /**
   * When true also returns items others have in getGot state
   */
  othersGotGet?: boolean;
  /**
   * When sent will retrieve only items that have been pinned state as true by the given
   * username
   */
  pinnedBy?: string;
  /**
   * The attribute / field to sort by
   */
  sortBy?: SortBy;
  /**
   * The direction to sort by
   */
  sortDir?: SortDir;
  /**
   * Search by accompaniment, e.g. the ID of the custom field to sort by
   */
  sortId?: string;
  /**
   * The text string to search for.
   */
  text?: string;
  /**
   * The URL to get the preview for. When used in conjunction with item search will be used
   * against a URL field in a "starts with..." query.
   */
  urlSearch?: string;
  /**
   * The username to only search for. Reserved for loading a profile page only.
   */
  username?: string;
  /**
   * The username to only search for. Differs to username parameter in that can be used on non
   * profile pages.
   */
  usernameBy?: string;
  /**
   * The username to exclude from search. Can be used to load a channel or dashboard but
   * excluding 1 username
   */
  usernameNot?: string;
  /**
   * When true only items with comments
   */
  withComments?: With;
  /**
   * When true only items with images will be returned, user or url images
   */
  withImage?: With;
  /**
   * When true only items with likes
   */
  withLikes?: With;
  /**
   * When true only items with pins
   */
  withPins?: boolean;
  /**
   * When true only items with urls images will be returned
   */
  withUrlImage?: boolean;
  /**
   * When true also returns items in you have in getGot state
   */
  yourGotGet?: boolean;
}

/**
 * The attribute / field to sort by
 */
export enum SortBy {
  CommentCount = 'commentCount',
  CreatedAt = 'createdAt',
  CustomFields = 'customFields',
  LikeCount = 'likeCount',
  PinCount = 'pinCount',
  SwipeScore = 'swipeScore',
  UrlCachePricePrice = 'urlCache.price.price',
  UrlCacheurl = 'urlCache.url',
}

/**
 * The direction to sort by
 */
export enum SortDir {
  Asc = 'asc',
  Desc = 'desc',
}

/**
 * When true only items with comments
 *
 * When true only items with images will be returned, user or url images
 *
 * When true only items with likes
 */
export enum With {
  With = 'with',
  Without = 'without',
}
