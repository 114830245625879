
import { Component, Prop, Vue } from 'vue-property-decorator';
// Flicking docs: https://naver.github.io/egjs-flicking/Options
import { Flicking } from '@egjs/vue-flicking';
import { ChannelsStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AImage from '@/components/atoms/AImage.vue';
import { ChannelType } from '@/api/ms-item/services/interfaces/Item';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import { CalendarIcon } from 'vue-feather-icons';
import { decode } from 'html-entities';
import { getDifferenceBetweenTwoDates } from 'common-utils/time';
import AFoldersSvgIcon from '@/components/atoms/icon/svg/AFoldersSvgIcon.vue';

@Component({
  components: {
    AFoldersSvgIcon,
    ALightbulbOnOutlineIcon,
    AImage,
    CalendarIcon,
    Flicking,
  }
})
export default class MChannelsRecentlyAddedTo extends Vue {
  @Prop({ default: Size.The60X60 })
  imageSize?: Size;
  @Prop({ default: true })
  showLabel?: boolean;
  @Prop({ default: 'p-1' })
  labelClass?: string;

  channelTypes = ChannelType;

  get getChannelsLastAddedTo () {
    return ChannelsStore.getChannelsLastAddedTo;
  }

  get getChannelsLastAddedToFetched () {
    return ChannelsStore.getChannelsLastAddedToFetched;
  }

  async created () {
    await this.setChannelsLastAddedTo();
  }

  async setChannelsLastAddedTo () {
    const timeBetweenDates = getDifferenceBetweenTwoDates(new Date(), this.getChannelsLastAddedToFetched);
    // if older than 10 minutes, refresh
    if (isNaN(timeBetweenDates.milliseconds) || timeBetweenDates.milliseconds > 10 * 60 * 1000) {
      await ChannelsStore.fetchChannelsLastAddedTo();
    }
  }

  figureTitle (name: string): string {
    return decode(name);
  }

  selectChannel (channel) {
    this.$emit('channel-selected', channel);
  }

  skeletonSelected () {
    this.$emit('skeleton-selected');
  }
}
