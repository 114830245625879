import regexGetMatches from '@/utils/regexGetMatches';

export default (url?: string): string | false => {
  if (!url) {
    return false;
  }
  // embed regex
  const embedPattern = /^(https:\/\/open.spotify.com\/)(embed\/playlist\/|embed\/track\/)([a-zA-Z\d]*)([?])(.*)/gm;
  const shareLink = /^(https:\/\/open.spotify.com\/)(playlist\/|track\/)([a-zA-Z\d]*)([?])(.*)/gm;

  let matches = regexGetMatches(embedPattern, url);
  if (matches.length === 0) {
    matches = regexGetMatches(shareLink, url);
  }
  if (matches.length === 0) {
    return false;
  }

  let returnUrl = 'https://open.spotify.com/';

  // else compile the embed iframe URL
  if (matches[2].match.split('/').length === 3) {
    // Embed match hit eg 'embed/playlist/'
    returnUrl += matches[2].match;
  } else {
    // Link share hit eg 'playlist/
    returnUrl += 'embed/' + matches[2].match;
  }

  returnUrl += matches[3].match + '?theme=0';

  return returnUrl;
};
