
import { Component, Prop, Vue } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import UserService from '@/api/ms-authentication/services/UserService';
import { Datum as ChannelMemberDatum } from '@/api/ms-channel/services/interfaces/ChannelMembers';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import {
  ItemPost,
  RecommendNameUniqueItemNameImagePostFormData,
  RecommendPost
} from '@/api/ms-item/services/interfaces';
import { Datum } from '@/api/ms-authentication/services/interfaces/UserSearchConnectionss';
import { MailIcon, XIcon } from 'vue-feather-icons';
import { RouteNames } from '@/router/RouteNames';
import EventBus, { EventBusEvents } from '@/EventBus';
import { clone } from 'common-utils/object';
import { RecommendType } from '@/api/ms-item/services/interfaces/RecommendPost';
import RecommendService from '@/api/ms-item/services/RecommendService';
import genericFormError from '@/utils/genericFormError';
import { ImageUploadPreviews } from '@/components/organisms/forms/OItemEntryForm.vue';
import convertBase64ToBlob from '@/utils/convertBase64ToBlob';

@Component({
  components: {
    AImage,
    MInputWithValidation,
    MailIcon,
    XIcon
  }
})
export default class MItemEntryRecommendTo extends Vue {
  @Prop()
  form!: ItemPost;
  @Prop()
  imagesToUploadPreviews!: ImageUploadPreviews;

  loading: boolean = false;
  searchString: string = '';
  invites: ChannelMemberDatum[] = [];
  connections: Datum[] = [];
  sendTo: Datum | false = false;
  userImageSize = Size.The32X32;
  searchLoading: boolean = false;
  searchByEmail: boolean = false;
  showEmailInvite: boolean = false;
  recommendByEmail: boolean = false;
  recommendMessage: string = '';
  recommendMessagePlaceholder: string = this.$t('item.entry.recommendTo.addMessagePlaceholder') as string;

  get formDisabled (): boolean {
    return !!(!this.sendTo || this.sendTo.emailMatchNotConnected);
  }

  async searchInvites () {
    if (this.searchString.length >= 2) {
      this.searchLoading = true;
      this.searchByEmail = this.isSearchStringEmail(this.searchString);

      const { data } = await UserService.userSearchConnectionsGet({ qs: this.searchString, any: true });
      this.showEmailInvite = (this.searchByEmail && !data.length);
      //filter out any connections already in the channel, then store what's left
      this.connections = data.filter((payload) => {
        for (let i = 0; i < this.invites.length; i++) {
          if (this.invites[i].username === payload.username) {
            return false;
          }
        }
        return true;
      }).map((payload) => {
        return payload;
      });

      this.searchLoading = false;
    } else {
      this.searchByEmail = false;
    }
  }

  // very loose check on email, but we don't need anything more complicated
  isSearchStringEmail (str: string): boolean {
    return !!str.match(/^\S+@\S+\.\S+$/);
  }

  handleSelection (selection) {
    this.sendTo = selection;
  }

  handleRecommendToByEmail () {
    this.recommendByEmail = true;
    this.sendTo = { firstName: 'email', lastName: 'email', username: 'email' };
  }

  clearSelected () {
    this.sendTo = false;
    this.recommendByEmail = false;
  }

  goToProfile () {
    if (this.sendTo) {
      const username = this.sendTo.username;
      this.$buefy.dialog.confirm({
        title: this.$t('form.leaveConfirm.title') as string,
        message: this.$t('form.leaveConfirm.message') as string,
        onConfirm: () => {
          EventBus.$emit(EventBusEvents.ITEM_ENTRY_CLOSE);
          this.$router.push({
            name: RouteNames.ROUTE_PROFILE, params: { username }
          });
        }
      });
    }
  }

  canSubmit (): boolean {
    return !!this.sendTo;
  }

  cannotSubmitForm () {
    this.$buefy.dialog.alert({
      title: this.$t('item.entry.recommendTo.cannotSubmit.title') as string,
      message: this.$t('item.entry.recommendTo.cannotSubmit.message') as string,
    });
  }

  sendRecommendation () {
    if (!this.canSubmit()) {
      this.cannotSubmitForm();
      return false;
    }

    this.handlePost()
        .then(() => {
          this.$buefy.dialog.alert({
            title: this.$t('item.recommendation.sent.title') as string,
            message: this.$t('item.recommendation.sent.message') as string,
            onConfirm: () => {
              this.$emit('recommendationSent');
            }
          });
        })
        .catch(genericFormError);
  }

  async handlePost () {
    this.loading = true;

    if( this.imagesToUploadPreviews.previews.length > 0 ){
      console.log(this.imagesToUploadPreviews.previews);
    }

    const formClone: RecommendPost = clone(this.form);

    // add the recommended to details
    formClone.recommendType = this.recommendByEmail ? RecommendType.Email : RecommendType.Connection;
    if (this.sendTo && formClone.recommendType === RecommendType.Connection) {
      formClone.connection = {
        firstName: this.sendTo.firstName,
        lastName: this.sendTo.lastName,
        username: this.sendTo.username
      };
    } else {
      formClone.email = this.searchString;
    }
    // use placeholder as default message if one not added
    formClone.message = this.recommendMessage.length > 0 ? this.recommendMessage : this.recommendMessagePlaceholder;

    // post
    const recommendation = await RecommendService.recommendPost(formClone);

    // handle posting any user photos
    await this.handleUserPhotos(recommendation.uniqueItemName);

    this.loading = false;
  }

  /**
   * If this contains a user image, post it to the server to be processed
   */
  async handleUserPhotos (uniqueItemName: string) {
    if (this.imagesToUploadPreviews.previews.length > 0) {
      const image = this.imagesToUploadPreviews.previews[0];
      const block = image.preview.split(';');
      const contentType = block[0].split(':')[1];
      const formData: RecommendNameUniqueItemNameImagePostFormData = {
        image: convertBase64ToBlob(image.preview, contentType)
      };
      if (image.exifJson) {
        formData.exifJson = image.exifJson;
      }
      await RecommendService.recommendNameUniqueItemNameImagePost(formData, { uniqueItemName });
    }
  }

  close () {
    this.$emit('close');
  }
}
