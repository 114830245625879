
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Channel } from '@/api/ms-channel/services/interfaces';
import { SendIcon, XIcon } from 'vue-feather-icons';
import AInfoNoCircleIcon from '@/components/atoms/icon/svg/AInfoNoCircleIcon.vue';

enum HeaderActionIcons {
  Close = 'close',
  Info = 'info',
  Send = 'send'
}

@Component({
  components: {
    AInfoNoCircleIcon,
    SendIcon,
    XIcon
  }
})
export default class MChannelSwipeHeaderActions extends Vue {
  @Prop()
  channel!: Channel;
  @Prop({ default: 0 })
  swipePosition!: number;
  @Prop({ default: 0 })
  totalItems!: number;

  iconSize: string = '1.5x';
  iconSizeB: number = 1.5;

  ActionIcons = HeaderActionIcons;

  closeModal () {
    this.$emit('close-modal');
  }

  sendToOthers () {
    this.$emit('send-to-others');
  }

  showInfo () {
    this.$emit('show-info');
  }
}
