export interface UserInvitationsAssignPost {
  /**
   * If no code is passed, use the earliest available code that is not in use, or create a new
   * one
   */
  code?: string;
  email: string;
  firstName: string;
  /**
   * An array of post-invitation hooks to implement after a user signs up, e.g. automatically
   * signup to a channel
   */
  invitationHooks?: InvitationHook[];
  lastName: string;
  message?: string;
}

export interface InvitationHook {
  /**
   * If more than a unique reference is required to execute a hook, or more data is needed to
   * display a human understandable message, build up an array of key:value pairs to hold the
   * rest of that data.
   */
  payload?: Payload[];
  type: Type;
  /**
   * Relevant to the type of hook this is, e.g. channel slug for a channelAccept or whatever
   */
  uniqueRef: string;
}

export interface Payload {
  key: string;
  value: string;
}

export enum Type {
  ChannelAccept = 'channelAccept',
}
