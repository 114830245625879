
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';

  @Component({
    components: {
      AButtonSubmit
    }
  })
  export default class AFetchFromLocationButton extends Vue {
    @Prop({ default: true })
    disabled!: boolean;

    @Prop({ default: '' })
    size!: string;

    @Prop({ default: false })
    loading!: boolean;

    @Prop({ default: 'Fetch' })
    buttonText!: string;

    @Prop()
    buttonClasses!: string;

    @Prop()
    moreResultsToLoad!: boolean;

    @Prop()
    tooltipLabel!: string;
  }
