
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import WatchItemPriceService from '@/api/ms-watch-urls/services/WatchItemPriceService';
import { AuthenticationStore, WatchersStore } from '@/store';
import ARouterLinkSettingWatchers from '@/components/atoms/link/ARouterLinkSettingWatchers.vue';
import { RouteNames } from '@/router/RouteNames';

@Component({
  components: { ARouterLinkSettingWatchers }
})
export default class OPriceWatchSetForm extends Vue {
  @Prop({ required: true })
  item!: Item;

  loading = false;
  successfullyAdded: boolean = false;
  errorAdding: boolean = false;
  canAddAnotherWatcher: boolean = true;

  get user () {
    return AuthenticationStore.currentUser;
  }

  async created () {
    await this.canAddWatcher();
  }

  async canAddWatcher () {
    const { canAddMore } = await WatchItemPriceService.watchItemPriceUsernameCanAddNewWatcherGet({
      username: this.user.username
    });
    this.canAddAnotherWatcher = canAddMore;
  }

  async addWatcher () {
    this.loading = true;
    try {
      await WatchItemPriceService.watchItemPriceUsernamePost({
        uniqueItemName: this.item.uniqueItemName
      }, {
        username: this.user.username
      });
      await WatchersStore.getAllActive({ username: this.user.username });
      this.loading = false;
      this.successfullyAdded = true;
    } catch (e) {
      this.loading = false;
      this.errorAdding = true;
    }
  }

  goToMgmtPage () {
    this.$router.push({ name: RouteNames.ROUTE_SETTINGS_WATCHERS });
    this.$emit('close');
  }
}
