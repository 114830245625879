export enum BuildType {
  WEB = 'web',
  CAPACITOR = 'capacitor',
  BROWSER_EXT = 'browser_ext'
}

export enum BuildTypeFor {
  ios = 'ios',
  android = 'android',
  browser = 'browser',
  chrome = 'chrome'
}

export enum Env {
  develop = 'develop',
  stage = 'staging',
  production = 'production'
}

export interface IConfig {
  accountsHref: string,
  api: {
    baseUrl: string,
    baseUrlClickout: string,
    baseWss: string,
    basePaths: {
      imageRead: string,
      clickout: string,
    }
  },
  emails: {
    support: string,
    careers: string,
  },
  gui: {
    channel: {
      showToggleMaxWidth: number,
    }
  },
  sso: {
    facebookAppId: string,
    googleClientId: string,
  },
  keys: {
    gmaps: string
  },
  cookie: {
    access: string,
    renew: string,
  },
  buildType: BuildType,
  buildTypeFor: BuildTypeFor,
  env: Env,
  mode: Env,
  appLinks: {
    android: string,
    apple: string
  },
  legal: {
    privacyLink: string,
    termsLink: string
  },
  settings: {
    researchConcierge: string
  }
}

const config: IConfig = {
  accountsHref: 'https://accounts.liffery.com',
  api: {
    baseUrl: process.env.VUE_APP_BASE_URL as string,
    baseUrlClickout: (process.env.VUE_APP_BASE_URL as string).replace(/https:\/\/(dev[\d]+-)?www\./, 'https://$1ww.'),
    baseWss: process.env.VUE_APP_BASE_WSS as string,
    basePaths: {
      imageRead: '/__/ms-image-server-cache/image',
      clickout: '/__/ms-clickout/',
    }
  },
  emails: {
    support: 'support@liffery.com',
    careers: 'careers@liffery.com'
  },
  gui: {
    channel: {
      showToggleMaxWidth: 768,
    }
  },
  sso: {
    facebookAppId: '904119163395754',
    googleClientId: '804679820469-u4hoq9lqq1if02v9cofn75q5vj8tls01.apps.googleusercontent.com',
  },
  keys: {
    gmaps: process.env.VUE_APP_GOOGLE_MAPS_KEY as string
  },
  cookie: {
    access: 'at',
    renew: 'rt',
  },
  buildType: process.env.VUE_APP_BUILD_TYPE as BuildType,
  buildTypeFor: process.env.VUE_APP_BUILD_TYPE_FOR as BuildTypeFor,
  env: process.env.NODE_ENV as Env,
  mode: process.env.VUE_APP_MODE as Env,
  appLinks: {
    android: 'https://play.google.com/store/apps/details?id=com.liffery.www',
    apple: 'https://apps.apple.com/us/app/liffery/id1577076705'
  },
  legal: {
    privacyLink: 'https://help.liffery.com/docs/liffery-privacy-policy/',
    termsLink: 'https://help.liffery.com/docs/liffery-terms-and-conditions-of-use/'
  },
  settings: {
    researchConcierge: 'RESEARCH_CONCIERGE'
  }
};

export default config;
