
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AvailableTours } from '@/components/organisms/OVueTour.types';
import camelCaseToSentenceCase from '@/storybook-components/src/utils/camelCaseToSentenceCase';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: {},
  filters: {
    camelToSentence: camelCaseToSentenceCase
  }
})
export default class MVueTourSelector extends Vue {
  @Prop()
  tourSelected!: boolean;

  @Prop()
  endOfWelcomeTour!: boolean;

  tourChoice: AvailableTours = AvailableTours.DashboardTour;

  availableTours = Object.values(AvailableTours);

  dontList: AvailableTours[] = [
      AvailableTours.SelectATour,
      AvailableTours.WelcomeTour
  ];

  get listedTours () {
    return this.availableTours.filter((tour) => !this.dontList.includes(tour));
  }

  @Watch('tourSelected', {immediate: true})
  triggerTour () {
    if( this.tourSelected ){
      EventBus.$emit(EventBusEvents.TOUR_START, this.tourChoice);
    }
  }

  created () {
    if( this.endOfWelcomeTour ){
      this.tourChoice = AvailableTours.ProfileTour;
    }
  }
}
