import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { AuthenticationStore, NavigationStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';
import SeoInjector from '@/services/SeoInjector';
import htmlBodyClassToggle from '@/utils/htmlBodyClassToggle';

// eslint-disable-next-line max-lines-per-function
export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  // clean the body/html no-scroll classes
  htmlBodyClassToggle('hide-scroll', true);

  // Persists the navigation history for the breadcrumbs and stats
  NavigationStore.ADD_NEW(from);

  // Required before variables
  let nextArg: any = undefined;
  let promptLogin = false;
  const mustBeAuthenticated = to.matched.some((record) => record.meta.auth);
  const canOnlySeeOwn = to.matched.some((record) => record.meta.ownOnly);
  const authenticated = AuthenticationStore.getAuthenticated;
  const ownSEOInject = to.matched.some((record) => record.meta.ownSEOInj);

  // If the target does not call the seo injector on its own call it here as a catcha all
  if (!ownSEOInject) {
    SeoInjector.init(to);
  }

  // Auth toggle prompter check
  if (mustBeAuthenticated) {
    if (!authenticated) {
      nextArg = '/';
      promptLogin = true;
    }
  }

  // Disallow loading someone else page if protected by ownOnly, redirect back to the dashboard
  else if (canOnlySeeOwn) {
    if (!authenticated || to.params.username !== AuthenticationStore.user.username) {
      nextArg = '/';
    }
  } else if (to.path === '/' && authenticated) {
    nextArg = {
      name: RouteNames.ROUTE_DASHBOARD
    };
  }

  // if (config.buildType === BuildType.WEB && MemoryStore.values.newPWA) {
  //   EventBus.$emit(EventBusEvents.NEW_APP_DOWNLOADED_RELOAD_HIDDEN);
  //   // Give a few ms grace time for the event to be emitted to the other tabs
  //   setTimeout(() => window.location.href = (nextArg) ? '/' : to.fullPath, 10);
  // } else {
  next(nextArg);
  if (promptLogin) {
    // allow the new route to render before prompting login
    setTimeout(() => {
      AuthenticationStore.TOGGLE_PROMPT_LOGIN(true);
    }, 10);
  }
  // }
};
