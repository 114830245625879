export default (attribute: string, direction: 'asc' | 'desc' = 'asc') => {
  return (a, b) => {
    if (direction === 'asc') {
      if (a[attribute] < b[attribute]) {
        return -1;
      }
      if (a[attribute] > b[attribute]) {
        return 1;
      }
    } else {
      if (a[attribute] > b[attribute]) {
        return -1;
      }
      if (a[attribute] < b[attribute]) {
        return 1;
      }
    }
    return 0;
  };
};
