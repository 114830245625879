
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalendarIcon } from 'vue-feather-icons';
import getChannelRouterObject from '@/utils/getChannelRouterObject';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';

@Component({
  components: { ALightbulbOnOutlineIcon, CalendarIcon }
})
export default class ARouterLinkChannelView extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  @Prop()
  customText!: string;

  @Prop()
  customClass?: string;

  @Prop({ required: false, default: false })
  isChannelEvent!: boolean;

  @Prop({ required: false, default: false })
  isResearchConcierge!: boolean;

  get url () {
    return getChannelRouterObject(this.channelSlug);
  }
}
