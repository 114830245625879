import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { Item } from '@/api/ms-item/services/interfaces';
import { ChannelType } from '@/api/ms-item/services/interfaces/Item';

export interface IItemDetailModule extends IStoreModule {
  itemDetail: Item
}

export const initialItemState: Item = {
  likes: [],
  pins: [],
  uniqueItemName: '',
  comments: [],
  actor: {
    username: '',
    firstName: '',
    lastName: '',
  },
  getgot: [],
  createdAt: new Date(),
  editable: {
    channel: {
      name: '',
      slug: '',
      channelType: ChannelType.General
    },
    text: '',
  },
  performanceValues: { popularityOverXTime: 1, likes: 0, pins: 0, commentsAndReplies: 0, commentsAndRepliesLikes: 0, swipeScore: 0 },
  updatedAt: new Date(),
};
