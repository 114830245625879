/**
 * This is a very simple static object
 * It bypasses any requirement on the browser sessions
 * When the page reloads the values are reset to the defauls
 * The defaults are those set on class initialisation
 */
import EventBus, { EventBusEvents } from '@/EventBus';

export default class MemoryStore {
  static values = {
    newPWA: false,
    eventsBound: false
  };

  static eventsBind () {
    if (!MemoryStore.values.eventsBound) {
      EventBus.$on(EventBusEvents.NEW_APP_DOWNLOADED, '_MemoryStore', () => {
        MemoryStore.values.newPWA = true;
      });
      MemoryStore.values.eventsBound = true;
    }
  }
}
