
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Datum as ChatsDatum } from '@/api/ms-chat/services/interfaces/Chats';
import { format } from 'timeago.js';

import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { AuthenticationStore } from '@/store';
import linkedText from '@/utils/linkedText';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import { formatMentionUserContent } from '@/utils/formatMentionUsersData';
import nl2br from '@/utils/nl2br';
import ellipsisString from '@/utils/ellipsisString';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';

enum ReservedUsernames {
  ResearchConcierge = '##Research.concierge##'
}

@Component({
  components: {
    ALightbulbOnOutlineIcon,
    ARouterLinkProfile,
    AUserProfilePic
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    ellipsisString
  }
})
export default class MChatMessage extends Vue {
  @Prop()
  message!: ChatsDatum;

  imageSize: Size = Size.The32X32;

  reservedUsernames = ReservedUsernames;
  reservedUsernamesArray = Object.values(ReservedUsernames);

  nl2br = nl2br;

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  formatMessageContent (input: string): string {
    let comment = linkedText(input);

    return formatMentionUserContent(comment);
  }

}
