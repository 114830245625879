
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import calculateFullImageHref from '@/storybook-components/src/utils/calculateFullImageHref';
import config from '@/config';

@Component
export default class AImage extends Vue {
  @Prop(String)
  altText;

  @Prop(String)
  imagePath;

  @Prop(String)
  imageBase64;

  @Prop({
    type: String,
    validator (value: any): boolean {
      return (Object.values(Size).includes(value));
    }
  })
  size;

  @Prop({ default: false })
  rounded!: boolean;

  @Prop(String)
  backup;

  get fullHref (): string {
    if (this.imagePath) {
      return calculateFullImageHref(
        config.api.baseUrl + config.api.basePaths.imageRead,
        this.size,
        this.imagePath
      );
    } else if (this.imageBase64) {
      return this.imageBase64;
    } else {
      return '';
    }
  }

  loaded () {
    this.$emit('loaded');
  }
}
