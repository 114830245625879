
import { Component, Vue } from 'vue-property-decorator';
import { PasswordResetRequestPost } from '@/api/ms-authentication/services/interfaces';
import PasswordService from '@/api/ms-authentication/services/PasswordService';
import { ValidationObserver } from 'vee-validate';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AMessage from '@/components/atoms/AMessage.vue';
import { FromOrigin } from '@/api/ms-authentication/services/interfaces/PasswordResetRequestPost';

@Component({
  components: {
    MInputWithValidation,
    ValidationObserver,
    AMessage
  },
})
export default class OPasswordForgotRequestForm extends Vue {
  private loading: boolean = false;
  private error: string = '';
  private success: string = '';
  private form: PasswordResetRequestPost = {
    email: '',
    fromOrigin: FromOrigin.UserApp
  };

  handleCloseMessage (type: string) {
    this[type] = '';
  }

  setShowLogin () {
    this.$emit('child-output', false);
  }

  async onSubmit () {
    this.loading = true;
    try {
      await PasswordService.passwordResetRequestPost(this.form);
      this.success = String(this.$i18n.tc('success.resetLinkSent'));
    } catch (e) {
      this.error = String(this.$i18n.tc('errors.somethingWentWrong'));
    }
    this.loading = false;
  }
}
