import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  IPinnedItemsModule,
  PinnedItemDisplay,
  PinnedItemsStoreItemsComponent
} from '@/store/modules/interfaces/PinnedItemsModule';
import clone from '@/utils/clone';
import { initialDataObject } from '@/store/modules/constants/initialDataObject';
import { Item, ItemsGetQuery } from '@/api/ms-item/services/interfaces';
import { SearchType } from '@/store/modules/enums/SearchType';
import waitTillHeaderIsVisible from '@/utils/waitTillHeaderIsVisible';
import itemsFetchQueryBuilder from '@/store/modules/utils/itemsFetchQueryBuilder';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import vScrollHeightWidth from '@/utils/vScrollHeightWidth';
import { pause } from 'common-utils/time';

@Module({
  name: StoreNames.PINNED_ITEMS_STORE,
  namespaced: true,
})
export default class PinnedItemsModule extends VuexModule implements IPinnedItemsModule {
  items: PinnedItemsStoreItemsComponent = clone(initialDataObject);
  pinnedSearch: ItemsGetQuery = {};
  lastPinnedSearchType: SearchType = SearchType.dashboard;
  newItemsFetched: Item[] = [];
  columnCount: number = 4;

  /**
   * GETTERS
   */
  get getPinnedItems (): PinnedItemsStoreItemsComponent {
    return this.items;
  }

  get getNewItemsFetchedCount (): number {
    return this.newItemsFetched.length;
  }

  get getSearchQuery () {
    return this.pinnedSearch;
  }

  get getLastSearchType (): SearchType {
    return this.lastPinnedSearchType;
  }

  get getColumnCount (): number {
    return this.columnCount;
  }

  /**
   * RESET AND CLEAR MUTATIONS
   */
  @Mutation
  RESET () {
    this.items = clone(initialDataObject);
    this.pinnedSearch = {};
    this.lastPinnedSearchType = SearchType.dashboard;
    this.newItemsFetched = [];
  }

  @Mutation
  CLEAR_PINNED_ITEMS () {
    this.items = clone(initialDataObject);
    this.newItemsFetched = [];
    this.pinnedSearch = {};
  }

  /**
   * ACTIONS
   */
  //formats pinned items to same shape as items tab so we can reuse same logic
  @Action
  async prepareItemsForDisplay (input: { items: any[] }): Promise<PinnedItemDisplay[]> {
    const { items } = input;
    const itemsReadyForDisplay: PinnedItemDisplay[] = [];
    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      itemsReadyForDisplay.push({
        data: {
          item,
          phantom: !!item?.phantom,
          skeleton: !!item?.skeleton
        }
      });
    }

    return itemsReadyForDisplay;
  }

  // eslint-disable-next-line max-lines-per-function
  @Action({ rawError: true })
  async pinnedItemSearch (input: {
    searchType: SearchType,
    search: ItemsGetQuery,
    mutationKey?: string,
  }): Promise<void> {
    const { search, searchType } = input;
    this.SET_API_BUSY({ busy: true });
    this.SET_LAST_PINNED_SEARCH_TYPE({ searchType: searchType });
    // WARNING! This action is used by pagination too, resetting the store only for a new search
    const mutationKey = input.mutationKey || 'ITEMS_FETCHED_NEW';

    if (mutationKey === 'ITEMS_FETCHED_NEW') {
      this.CLEAR_PINNED_ITEMS();
      await waitTillHeaderIsVisible();
    }

    // Remove the any potential phantom cards
    this.REMOVE_PHANTOMS();

    // We inject the skeletons now before anything else
    await this.injectSkeletons();

    // Call the api
    const start = new Date();
    const { data } = await itemsFetchQueryBuilder({ search, searchType, pinned: true });
    const end = new Date();
    if (end.getTime() - start.getTime() < 750) {
      await pause(750 - (end.getTime() - start.getTime()));
    }
    // wipe the store and start again for new search
    if (mutationKey === 'ITEMS_FETCHED_NEW') {
      this.CLEAR_PINNED_ITEMS();
      await waitTillHeaderIsVisible();
    } else {
      // remove the skeletons and recalculate stuffs
      this.REMOVE_SKELETONS();
    }

    // this means we have a qty count less than the qty requested, which means we have reached the end of available results
    this.SET_NO_MORE_RESULTS({ on: (data.length < 20) });

    const items = await this.prepareItemsForDisplay({ items: data });

    // lastly apply the mutations, inject the items, save the search params and mark this items type as not busy
    this[mutationKey]({
      items,
      search
    });

    // Inject phantoms to ensure horizontal fill of the v-container
    await this.injectPhantomCards();

    // Mark the last card in the 1st row (phantom or not)
    this.SET_LAST_COL_1ST_ROW();

    this.SET_PINNED_SEARCH({ search });
    this.SET_API_BUSY({ busy: false });
  }

  @Action({ rawError: true })
  async paginate (input: { searchType: SearchType }): Promise<void> {
    //if items is empty or there are any phantoms or skeletons on the page the pagination should not run
    if( !this.items.items.length ){
      return;
    }
    for (let i = 0; i < this.items.items.length; i++) {
      if( this.items.items[i].data.phantom || this.items.items[i].data.skeleton ){
        return;
      }
    }
    if (this.items.noMoreResults || this.items.busy) {
      return;
    }
    this.SET_API_BUSY({ busy: true });
    // we run everything through the main search to ensure there are no params dropped from the original search and reduction of duped code
    const search = this.pinnedSearch;
    // Start the offset at the current count we have in the store
    search.offset = this.items.items.length;
    await this.pinnedItemSearch({
      searchType: input.searchType,
      search,
      mutationKey: 'ITEMS_FETCHED_PAGINATION'
    });
  }

  @Action({ rawError: true })
  async injectSkeletons (input?: { qty?: number }) {
    const qty = input?.qty || 8;
    const items = await this.prepareItemsForDisplay({
      items: new Array(qty).fill({
        skeleton: true
      })
    });
    this.ITEMS_FETCHED_PAGINATION({ items });
  }

  //The phantom card only lives when the qty of cards is less that the number of columns that could fit the width of the screen
  @Action
  async injectPhantomCards () {
    //calculate how many columns the user's screen can view
    const { width } = vScrollHeightWidth();
    const divisionValue = 450;
    this.SET_COLUMN_COUNT(width > divisionValue ? Math.floor(width / divisionValue) : 1);
    const count = this.items.items.filter(item => !item.data.phantom && !item.data.skeleton).length;
    if (count >= this.columnCount) {
      return;
    }
    const items = await this.prepareItemsForDisplay({
      items: new Array(this.columnCount - count).fill({
        phantom: true
      })
    });
    this.ITEMS_FETCHED_PAGINATION({ items });
  }

  /**
   * OTHER MUTATIONS
   */
  @Mutation
  SET_ITEM_DETAIL (newItem: Item) {
    const index = this.items.items.findIndex((item: PinnedItemDisplay) => item.data.item?.uniqueItemName === newItem.uniqueItemName);
    if (index !== -1) {
      this.items.items[index].data.item = {
        ...this.items.items[index].data.item,
        ...clone(newItem)
      };
    }
  }

  @Mutation
  SET_COLUMN_COUNT (count: number) {
    this.columnCount = count;
  }

  @Mutation
  SET_LAST_COL_1ST_ROW () {
    const columnCountIndex = this.columnCount - 1;
    if (this.items.items[columnCountIndex]) {
      this.items.items[columnCountIndex].data.lastIn1stRow = true;
    }
  }

  @Mutation
  SET_API_BUSY (input: { busy: boolean }) {
    this.items.busy = input.busy;
  }

  @Mutation
  SET_LAST_PINNED_SEARCH_TYPE (input: { searchType: SearchType }) {
    this.lastPinnedSearchType = input.searchType;
  }

  @Mutation
  SET_PINNED_SEARCH (input: { search: ItemsGetQuery }) {
    this.pinnedSearch = input.search;
  }

  @Mutation
  SET_NO_MORE_RESULTS (input: { on: boolean }) {
    this.items.noMoreResults = input.on;
  }

  @Mutation
  ITEMS_FETCHED_NEW (input: { items: PinnedItemDisplay[] }): void {
    this.items.items = input.items;
  }

  @Mutation
  ITEMS_FETCHED_LATEST (input: { items: any[] }): void {
    // add the items to the very top of the newly fetched, could be more than 1 batch waiting to be injected
    this.newItemsFetched = input.items.concat(this.newItemsFetched);
  }

  @Mutation
  ITEMS_FETCHED_PAGINATION (input: { items: PinnedItemDisplay[] }): void {
    const { items } = input;
    this.items.items = this.items.items.concat(items);
  }

  @Mutation
  REMOVE_SKELETONS (input?: { qty: number }) {
    const qty = input?.qty || 8;
    this.items.items.splice(
      this.items.items.length - qty,
      qty
    );
  }

  @Mutation
  REMOVE_PHANTOMS () {
    // get the starting index of the 1st phantom to start the splice from
    const index = this.items.items.findIndex(item => item.data.phantom);
    if (index !== -1) {
      this.items.items.splice(
        index,
        this.items.items.length // splice will gracefully omit splicing after the end
      );
    }
  }
}
