
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import { MessageSquareIcon } from 'vue-feather-icons';

@Component({
  components: {
    ARouterLinkIssueDetail,
    MessageSquareIcon
  }
})
export default class ACommentIcon extends Vue {
  @Prop({ default: 0, required: true })
  commentCount!: number;

  @Prop({ required: true })
  uniqueItemName!: string;

  commentsRoute = RouteNames.ROUTE_ITEM_DETAIL_VIEW_COMMENTS;
}
