
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AChannelSwipeItemUp extends Vue {
  @Prop({default: '1x'})
  size!: string;
  @Prop({required: false, default: false})
  fill!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  mouseover = false;

  colours: {
    disabled: string,
    swipeUp: string,
    white: string
  } = {
    disabled: 'var(--grey-mid-color)',
    swipeUp: 'rgb(var(--channel-swipe-up))',
    white: 'var(--white-color)'
  };

  get style () {
    const size = parseFloat(this.size);
    return `width: ${size}rem; height: ${size}rem;`;
  }

  get fillColour () {
    if (this.disabled) {
      return this.colours.white;
    }

    return (this.mouseover || this.fill) ? this.colours.swipeUp : this.colours.white;
  }

  get strokeColour () {
    if (this.disabled) {
      return this.colours.disabled;
    }

    return (!this.mouseover && !this.fill) ? this.colours.swipeUp : this.colours.white;
  }

  get circleStrokeColour () {
    return this.disabled ? this.colours.disabled : this.colours.swipeUp;
  }
}
