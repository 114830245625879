
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField, FieldType } from '@/api/ms-item/services/interfaces/Item';
import StaticApiDataService, { CompassDatum } from '@/services/StaticApiDataService';
import { ArrowUpIcon } from 'vue-feather-icons';

@Component({
  components: {
    ArrowUpIcon
  }
})
export default class ACustomFieldDisplayCompass extends Vue {
  @Prop({ required: true })
  field!: CustomField;

  get getCompassArrowRotate () {
    return {
      transform: `rotate(${Number(this.field.value) + 180}deg)`
    };
  }

  get compass (): CompassDatum {
    // get the closes degrees match based on the group
    const degrees = Number(this.field.value);
    const options = this.field.unit === 'compassSimple' ?
        StaticApiDataService.compassData.compassSimple :
        StaticApiDataService.compassData.compassDetail;

    let opt: CompassDatum = { label: 'North', value: 0 };
    for (let i = 0; i < options.length; i++) {
      const o = options[i];
      if (
          degrees === o.value ||
          (degrees > o.value && (options[i + 1] && degrees < options[i + 1].value))
      ) {
        opt = o;
        return opt;
      }
    }
    return opt;
  }

  created () {
    if( this.field.fieldType !== FieldType.Compass ){
      console.error('Wrong field type. Expects compass.');
    }
  }
}
