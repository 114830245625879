
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon, ChevronUpIcon, SendIcon } from 'vue-feather-icons';
import { NativeButtonTypes } from './MDropdownButtonInterfaces';
import { DropdownOptions } from './MDropdownButtonInterfaces';



@Component({
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    SendIcon
  }
})
export default class MDropdownButton extends Vue {
  @Prop()
  mainActionLabel!: string;
  @Prop({ default: NativeButtonTypes.Submit })
  mainActionType?: NativeButtonTypes;
  @Prop()
  dropdownOptions!: DropdownOptions[];

  emitOption (value: string) {
    this.$emit('option-selected', value);
  }

  created () {
    if (this.dropdownOptions.length === 0) {
      console.error('Error: No options provided to component');
    }
  }
}
