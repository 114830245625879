
import { Component, Prop, Vue } from 'vue-property-decorator';
import { XIcon } from 'vue-feather-icons';
import { ImageUpload } from '@/components/organisms/forms/OItemEntryForm.vue';
import { Provider } from '@/api/ms-channel/services/interfaces/ChannelGenerateImage';

export interface MUploadImagePreviewChildOutput {
  imageIndexRemoved: number;
}

export interface MUploadImagePreviewImageItem {
  /**
   * Base64 encoded string of the image
   */
  preview: string,
  /**
   * Friendly filename to display on screen if hiding image in tooltip
   */
  userFriendlyFilename?: string,
  /**
   * If image is generated include the original url and credits of the photographer
   */
  generationUrl?: string,
  credits?: string,
  /**
   * If the image was not uploaded by the user, i.e. generated, then record who provided the
   * image
   */
  provider?: Provider;
}

@Component({
  components: {
    XIcon
  }
})
export default class MUploadImagePreview extends Vue {
  @Prop({ default: () => [] })
  base64Strings!: ImageUpload[];
  @Prop({ default: true })
  showRemove!: boolean;
  @Prop()
  classImage!: string;
  @Prop()
  classDeleteButton!: string;
  @Prop({ default: false })
  tooltip!: boolean;
  @Prop({ required: false })
  maxHeight!: number;
  @Prop({required: false, default: false})
  borderlessCloseIcon!: boolean;

  handleUploadRemove (index) {
    this.$emit('child-output', { imageIndexRemoved: index } as MUploadImagePreviewChildOutput);
  }
}
