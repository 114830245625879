import HttpService from '@/services/HttpService';

import { CustomFieldsUnitGroupeds } from './interfaces/CustomFieldsUnitGroupeds';

export default class ChannelsService {
  public static basePath = '/__/ms-static-api-data/';

  /**
   *  Operation ID: channelsCustomFieldsAllGet
   *  Summary: Get all, from custom-fields, from channels
   *  Description:
   */
  public static channelsCustomFieldsAllGet(): Promise<CustomFieldsUnitGroupeds> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ChannelsService.basePath + 'channels/custom-fields/all',
    });
  }
}
