
import { Component, Prop, Vue } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import OItemCard, { ItemPreview } from '@/components/organisms/OItemCard.vue';
import { User } from '@/api/ms-authentication/services/interfaces';

@Component({
  components: { OItemCard, AImage },
})
export default class MUrlPreview extends Vue {
  @Prop()
  urlPreview!: any;

  @Prop(Object)
  currentUser!: User;

  @Prop()
  classes?: string;

  @Prop()
  previewSettings?: ItemPreview;

  @Prop()
  showSaveButton!: boolean;
}
