
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { Datum as ChannelMemberDatum, SubscriptionStatus } from '@/api/ms-channel/services/interfaces/ChannelMembers';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { CheckIcon, XCircleIcon } from 'vue-feather-icons';
import { ChannelsStore } from '@/store';
import MPersonChannelJoinRequestCard from '@/components/molecules/MPersonChannelJoinRequestCard.vue';
import {
  ChannelMemberSlugGetQuery,
  MemberStatus,
  SortBy,
  SortDir
} from '@/api/ms-channel/services/interfaces/ChannelMemberSlugGetQuery';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import UserService from '@/api/ms-authentication/services/UserService';
import { Datum } from '@/api/ms-authentication/services/interfaces/UserSearchs';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import { ChannelMember } from '@/api/ms-channel/services/interfaces';
import EventBus, { EventBusEvents } from '@/EventBus';
import { InvitationHook, Type } from '@/api/ms-authentication/services/interfaces/UserInvitationsAssignPost';
import { OModalsContainerInviteByEmail } from '@/components/organisms/OModalsContainer.vue';
import { Invitation } from '@/api/ms-authentication/services/interfaces';

@Component({
  components: {
    MButtonWithIcon,
    ARouterLinkProfile,
    MPersonChannelJoinRequestCard,
    AImage,
    AUserProfilePic,
    CheckIcon,
    XCircleIcon
  },
})
export default class MChannelInvitations extends Vue {
  @Prop()
  channelSlug!: string;

  @Prop({ default: false })
  isResearchConcierge!: boolean;

  callerId = 'MChannelInvitations';
  loadingInvites = false;
  loadingJoinRequests = false;

  subscriptionStatus = SubscriptionStatus;
  invites: ChannelMemberDatum[] = [];
  connections: Datum[] = [];
  userImageSize = Size.The32X32;

  joinRequests: ChannelMemberDatum[] = [];
  isDisabledForm: boolean = !this.channelSlug;

  channelMembersQuery: ChannelMemberSlugGetQuery = {};

  // Tabular invite data
  currentPage: number = 1;
  perPage: number = 10;
  totalChannelMembers: number = 0;
  searchLoading: boolean = false;
  searchByEmail: boolean = false;
  searchString: string = '';
  showEmailInvite: boolean = false;
  sortBy: SortBy = SortBy.Username;
  sortingDirection: SortDir = SortDir.Asc;

  get channel () {
    return ChannelsStore.getChannelEditing;
  }

  async created () {
    if (this.channelSlug) {
      this.channelMembersQuery.requestCount = true;
      await this.fetchExistingMembersAndInvites();
    }
    this.bindEvents();
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  bindEvents () {
    EventBus.$on(EventBusEvents.INVITE_BY_EMAIL_SENT, this.callerId, this.inviteSent);
  }

  unbindEvents () {
    EventBus.$remove(EventBusEvents.INVITE_BY_EMAIL_SENT, this.callerId);
  }

  async fetchExistingMembersAndInvites () {
    this.loadingInvites = true;
    this.channelMembersQuery.asOwner = true;
    this.channelMembersQuery.offset = (this.currentPage * this.perPage) - this.perPage;
    const { data, meta } = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.channelSlug, },
        this.channelMembersQuery
    );
    if (meta.total) {
      this.totalChannelMembers = meta.total;
    }
    this.invites = data;
    this.loadingInvites = false;
    if (this.channelMembersQuery.requestCount) {
      //only need the count the first time
      delete this.channelMembersQuery.requestCount;
    }
  }

  onPageChange (page) {
    this.currentPage = page;
    this.fetchExistingMembersAndInvites();
  }

  onSort (field, order) {
    this.channelMembersQuery.sortBy = field;
    this.channelMembersQuery.sortDir = order;
    this.fetchExistingMembersAndInvites();
  }

  async fetchJoinRequests () {
    this.loadingJoinRequests = true;
    // grab the current incoming requests to join
    const { data } = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.channelSlug, },
        { memberStatus: MemberStatus.JoinRequestSent }
    );
    this.joinRequests = data;
    this.loadingJoinRequests = false;
  }

  async searchInvites () {
    if (this.searchString.length >= 2) {
      this.searchLoading = true;
      this.searchByEmail = this.isSearchStringEmail(this.searchString);

      const { data } = await UserService.userSearchGet({ qs: this.searchString, any: true });
      this.showEmailInvite = (this.searchByEmail && !data.length);
      //filter out any connections already in the channel, then store what's left
      this.connections = data.filter((payload) => {
        for (let i = 0; i < this.invites.length; i++) {
          if (this.invites[i].username === payload.username) {
            return false;
          }
        }
        return true;
      }).map((payload) => {
        return payload;
      });

      this.searchLoading = false;
    } else {
      this.searchByEmail = false;
    }
  }

  // very loose check on email, but we don't need anything more complicated
  isSearchStringEmail (str: string): boolean {
    return !!str.match(/^\S+@\S+\.\S+$/);
  }

  async handleSelection (selection) {
    this.loadingInvites = true;

    const newInvite = await ChannelMemberService.channelMemberSlugInvitePost(
        {
          username: selection.username,
          canAdd: false,
        },
        {
          slug: this.channelSlug,
        }
    );
    this.invites.unshift(newInvite);
    this.connections = [];
    this.loadingInvites = false;
  }

  async handleCanAddToggle (channelMember: ChannelMemberDatum) {
    const newDatum: ChannelMember = await ChannelMemberService.channelMemberSlugInviteUpdatePatch(
        {
          canAdd: channelMember.canAdd,
          username: channelMember.username,
        },
        { slug: this.channelSlug, }
    );
    this.canAddOrManageCb(newDatum);
  }

  async handleCanManageToggle (channelMember: ChannelMemberDatum) {
    const newDatum: ChannelMember = await ChannelMemberService.channelMemberSlugInviteUpdatePatch(
        {
          canManage: channelMember.canManage,
          username: channelMember.username,
        },
        { slug: this.channelSlug, }
    );
    this.canAddOrManageCb(newDatum);
  }

  canAddOrManageCb (newDatum) {
    for (let i = 0; i < this.invites.length; ++i) {
      if (this.invites[i].username === newDatum.username) {
        this.invites[i] = newDatum;
        break;
      }
    }
  }

  isChannelOwner (username: string) {
    return this.channel.owner.username === username;
  }

  async removeInvite (channelMember: ChannelMemberDatum) {
    await ChannelMemberService.channelMemberSlugInviteRemoveUsernameDelete({
      slug: this.channelSlug,
      username: channelMember.username,
    });
    await this.fetchExistingMembersAndInvites();
  }

  async acceptJoinRequest (person: ChannelMemberDatum) {
    await ChannelMemberService.channelMemberSlugJoinRequestAcceptPatch({
      username: person.username,
      canAdd: false
    }, {
      slug: this.channelSlug
    });
    await this.fetchExistingMembersAndInvites();
  }

  async rejectJoinRequest (person: ChannelMemberDatum) {
    await ChannelMemberService.channelMemberSlugJoinRequestRejectPatch({
      username: person.username,
      canAdd: false
    }, {
      slug: this.channelSlug
    });
    await this.fetchExistingMembersAndInvites();
  }

  inviteToChannelByEmail () {
    const hook: InvitationHook = {
      type: Type.ChannelAccept,
      uniqueRef: this.channelSlug,
      payload: [{
        key: 'channelName',
        value: this.channel.name
      }]
    };
    const payload: OModalsContainerInviteByEmail = {
      email: this.searchString,
      emailDisabled: true,
      message: this.$t('page.channelUpsert.form.inviteToChannel.message', { channelName: this.channel.name }) as string,
      invitationHooks: [hook],
      includeCodeToCopy: false
    };
    EventBus.$emit(EventBusEvents.INVITE_BY_EMAIL, payload);
  }

  inviteSent (sent: Invitation) {
    if( sent.invitedTo && sent.invitedTo.length > 0 ){
      this.$buefy.dialog.alert({
        title: this.$t('page.invitations.invitationSent.title') as string,
        message: this.$t('page.invitations.invitationSent.message', {
          firstName: sent.invitedTo[0].firstName,
          lastName: sent.invitedTo[0].lastName,
          email: sent.invitedTo[0].email,
        }) as string
      });
    }
  }

  @Watch('channelSlug')
  async loadChannelInvitations () {
    if (this.channelSlug) {
      this.channelMembersQuery.requestCount = true;
      this.isDisabledForm = false;
      await this.fetchExistingMembersAndInvites();
    }
  }
}
