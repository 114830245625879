import ChannelsService from '@/api/ms-static-api-data/services/ChannelsService';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import CurrenciesService from '@/api/ms-static-api-data/services/CurrenciesService';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import ContentCategoriesService from '@/api/ms-static-api-data/services/ContentCategoriesService';
import { Datum as ContentCategoryGroup } from '@/api/ms-static-api-data/services/interfaces/ContentCategoriesGroups';
import { pause } from 'common-utils/time';

export interface CompassDatum {
  label: string,
  value: number
}

interface CompassData {
  [key: string]: CompassDatum[];
}

export default class StaticApiDataService {
  private static channelsCustomFields: CustomFieldsUnitGroupeds = [];
  private static uniqueCurrencies: Currency[] = [];
  private static contentCategories: ContentCategoryGroup[] = [];

  private static progressMap: any = {};

  private static async genericFetchOrWait (classKey: string, apiToCall: () => any, respKey?: string) {
    if (!StaticApiDataService[classKey].length) {
      if (StaticApiDataService.progressMap[classKey]) {
        // wait 200 then just return this function again which will in turn wait or return the actual desired result
        await pause(200);
        return StaticApiDataService.genericFetchOrWait(classKey, apiToCall, respKey);
      } else {
        StaticApiDataService.progressMap[classKey] = true;
        const resp = await apiToCall();
        StaticApiDataService[classKey] = (respKey) ? resp[respKey] : resp;
        StaticApiDataService.progressMap[classKey] = false;
        return StaticApiDataService[classKey];
      }
    } else {
      return StaticApiDataService[classKey];
    }
  }

  public static compassData: CompassData = {
    compassSimple: [
      { value: 0, label: 'North' },
      { value: 45, label: 'North east' },
      { value: 90, label: 'East' },
      { value: 135, label: 'South east' },
      { value: 180, label: 'South' },
      { value: 225, label: 'South west' },
      { value: 270, label: 'West' },
      { value: 315, label: 'North west' }
    ],
    compassDetail: [
      { value: 0, label: 'North' },
      { value: 22.5, label: 'North north east' },
      { value: 45, label: 'North east' },
      { value: 67.5, label: 'North east east' },
      { value: 90, label: 'East' },
      { value: 112.5, label: 'East east south' },
      { value: 135, label: 'South east' },
      { value: 157.5, label: 'South south east' },
      { value: 180, label: 'South' },
      { value: 202.5, label: 'South south west' },
      { value: 225, label: 'South west' },
      { value: 247.5, label: 'South west west' },
      { value: 270, label: 'West' },
      { value: 292.5, label: 'West west north' },
      { value: 315, label: 'North west' },
      { value: 337.5, label: 'North north west' }
    ]
  };

  static channelsCustomFieldsAllGet (): Promise<CustomFieldsUnitGroupeds> {
    return StaticApiDataService.genericFetchOrWait(
      'channelsCustomFields',
      ChannelsService.channelsCustomFieldsAllGet,
    );
  }

  static currenciesUniqueGet (): Promise<Currency[]> {
    return this.genericFetchOrWait(
      'uniqueCurrencies',
      CurrenciesService.currenciesUniqueGet,
      'data'
    );
  }

  static async contentCategoriesGet (): Promise<ContentCategoryGroup[]> {
    return this.genericFetchOrWait(
      'contentCategories',
      ContentCategoriesService.contentCategoriesGroupsGet,
      'data'
    );
  }
}
