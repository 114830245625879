import { io, Socket } from 'socket.io-client';
import { AuthenticationStore, NotificationsStore } from '@/store';
import serviceWorkerUnregisterAll from '@/utils/serviceWorkerUnregisterAll';
import EventBus, { EventBusEvents } from '@/EventBus';
import config from '@/config';
import AuthenticationService from '@/services/AuthenticationService';
import UserAppSettingsHydrate from '@/services/UserAppSettingsHydrate';
import { generateFibonacciArrayToX } from 'common-utils/number';
import updateItemInStores from '@/utils/updateItemInStores';

export enum RoomType {
  ChannelChat = 'ChannelChat'
}

class WebSocket {
  static connecting: boolean = false;
  static connectCalled: boolean = false;
  static disconnected: boolean = false;
  static reconnectAttempts: number = 0;
  static socket: Socket;
  static inRooms: string[] = [];

  // eslint-disable-next-line max-lines-per-function
  static connect (connectionUrl: string, jwt: string) {
    if (WebSocket.socket && WebSocket.socket.connected) {
      return;
    }
    WebSocket.disconnected = false;
    WebSocket.connecting = true;
    WebSocket.connectCalled = true;

    const socket = io(connectionUrl, { transports: ['websocket'] });
    socket.on('connect', () => {
      socket
        .emit('authenticate', { token: jwt })
        .once('authenticated', () => {
          WebSocket.connecting = false;
          console.log('Authenticated socket connection to Liffery (re)established');
          WebSocket.socket = socket;
          WebSocket.reJoinRooms();
        })
        .on('unauthorized', (msg) => {
          WebSocket.connecting = false;
          throw new Error(msg.data.type);
        });
    });
    // note: disconnect currently only happens when authentication fails
    socket.on('disconnect', () => {
      WebSocket.disconnected = true;
      // An app wide event to listen out for when the socket connection drops
      EventBus.$emit(EventBusEvents.SOCKET_CONNECTION_DROPPED);
      // attempt to reconnect
      WebSocket.waitTill().catch(console.error);
    });
    socket.on('direct', (data) => {
      WebSocket.eventHandler(data);
    });
    socket.on('room-joined', (data) => {
      WebSocket.inRooms.push(data.name);
    });
    socket.on('room-left', (data) => {
      const roomIndex = WebSocket.inRooms.findIndex((room) => data.name === room);
      if (roomIndex !== -1) {
        WebSocket.inRooms.splice(roomIndex, 1);
      }
    });
  }

  static async reconnect () {
    ++WebSocket.reconnectAttempts;
    if (WebSocket.socket) {
      WebSocket.socket.disconnect();
    }
    if (AuthenticationStore.getAuthenticated) {
      await AuthenticationStore.reFetchCurrentUser();
      const accessToken = await AuthenticationService.getAccessJWT();
      if (accessToken) {
        WebSocket.connect(config.api.baseWss, accessToken);
      }
    }
  }

  static reJoinRooms () {
    WebSocket.inRooms.forEach((room: string) => {
      const parts = room.split(':');
      if (parts.length === 2) {
        WebSocket
          .joinRoom(parts[0], parts[1])
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }

  static fibonacci: number[] = [];

  static waitTill (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // web socket was somehow never initiated, run reconnect
      if (!WebSocket.connectCalled) {
        WebSocket.reconnect().catch(console.error);
      }
      // the successful response we are looking for
      if (!WebSocket.connecting && WebSocket.connectCalled) {
        resolve();
      } else {
        // we are here because either a. we're awaiting the websocket to connect, or b. the websocket failed to connect
        const fibonacci = generateFibonacciArrayToX(10);
        WebSocket.fibonnaciReconnect(fibonacci, (err?: Error) => {
          err ? reject(err) : resolve();
        }).catch((e) => {
          console.error('Could not reconnect web sockets');
          throw new Error(e);
        });
      }
    });
  }

  static async fibonnaciReconnect (fibonacci: number[], cb: (err?: Error) => any) {
    // if we have hit fibonacci ceiling, this is fail
    if (fibonacci.length === 0) {
      return cb(new Error('Maximum reconnect attempts'));
    }

    const time = fibonacci.shift() as number;
    setTimeout(() => {
      // the websocket authentication failed, this may be due to expired JWT, attempt to reconnect
      if (WebSocket.disconnected) {
        WebSocket.reconnect().catch(console.error);
      }
      // else if not disconnected, we just need to sit tight and wait for the hopefully positive authentication
      else if (!WebSocket.connecting && WebSocket.connectCalled) {
        // this is success
        cb();
      }

      // this is wait
      WebSocket.fibonnaciReconnect(fibonacci, cb);
    }, time * 1000);
  }

  static async joinRoom (type: string, typeIdentifier: string) {
    await WebSocket.waitTill();
    WebSocket.socket.emit('join-room', { type, typeIdentifier });
  }

  static leaveRoom (type: string, typeIdentifier: string) {
    if (WebSocket.socket) {
      WebSocket.socket.emit('leave-room', { type, typeIdentifier });
    }
  }

  static disconnect () {
    if (WebSocket.socket) {
      WebSocket.socket.disconnect();
    }
  }

  static eventHandler (data: {
    time: string,
    routingKey: string,
    payload: any
  }) {
    switch (data.routingKey) {
      case 'msAuthenticationUserSettingsUpdatedPublish': {
        UserAppSettingsHydrate.hydrate(data.payload);
        break;
      }
      case 'msNotificationNotificationCreatedPublish': {
        NotificationsStore.INJECT_NEW_NOTIFICATION(data.payload);
        EventBus.$emit(EventBusEvents.NOTIFICATION_RECEIVED, data.payload);
        break;
      }
      case 'msChatChannelNewMessagePublish': {
        EventBus.$emit(EventBusEvents.CHAT_NEW_MSG, data.payload);
        break;
      }
      case 'msItemItemEmitChangeToFrontendPublish': {
        updateItemInStores(data.payload);
        break;
      }
      case 'sosDestroyCache': {
        serviceWorkerUnregisterAll();
        break;
      }
    }
  }
}

export default WebSocket;
