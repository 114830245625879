
import { Component, Vue } from 'vue-property-decorator';
import { ItemDetailStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

import OItemCommentForm from '@/components/organisms/forms/OItemCommentForm.vue';
import MItemCommentList from '@/components/molecules/comment/MItemCommentList.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import { ThumbsUpIcon } from 'vue-feather-icons';

@Component({
  components: {
    ARouterLinkProfile,
    AUserProfilePic,
    MItemCommentList,
    OItemCommentForm,
    ThumbsUpIcon
  }
})
export default class OItemLikes extends Vue {
  userImageSize = Size.The60X60;
  loading: boolean = false;

  get likes () {
    return ItemDetailStore.getItemLikes;
  }
}
