
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { Urgency } from '@/api/ms-channel/services/interfaces/Channel';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import { camelCaseToSentenceCase } from 'common-utils/string';

@Component({
  components: { MRadioCards },
  methods: { camelCaseToSentenceCase }
})
export default class MChannelConciergeSettingsUrgency extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: Urgency;
  @Prop({required: false, default: false})
  recalculateAccordion!: boolean;
  @Prop({required: false, default: false})
  disabled!: boolean;

  value: Urgency = Urgency.OneWeek;
  urgencyTypes = Urgency;

  created () {
    this.value = this.activeValue;
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
