export interface Currenciess {
  data: Datum[];
  meta: Meta;
}

export interface Datum {
  /**
   * The currency code, e.g. GBP
   */
  currency: string;
  /**
   * Other ways the currency is represented, e.g. Dirham main symbol is "د.إ" but can also be
   * 'Dh' or 'Dhs'
   */
  otherSymbols: string[];
  /**
   * The primary way the currency is represented, usually by a symbol. E.g. £
   */
  symbol: string;
  /**
   * Whether a gap should be present between symbol and number. E.g. No for £50 or 50€, but
   * yes for 50 lei and 50 лв.
   */
  symbolGap: boolean;
  symbolPlacement: SymbolPlacement;
}

export enum SymbolPlacement {
  Both = 'both',
  Left = 'left',
  Right = 'right',
}

export interface Meta {
  limit?: number;
  offset?: number;
  totalResultCount?: number;
}
