
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ThumbsUpIcon } from 'vue-feather-icons';

export interface ALikeChildOutput {
  like: boolean,
}

@Component({
  components: {
    ThumbsUpIcon
  }
})
export default class ALike extends Vue {
  @Prop({ default: false })
  loading!: boolean | undefined;

  @Prop({ default: false })
  like!: boolean;

  @Prop({ required: true })
  likeCount!: number;

  @Prop()
  iconSize!: string;

  @Prop({ default: false })
  numericOnly!: boolean;

  getLikeInfo (): string {
    if (!this.likeCount) {
      return '';
    }
    return `x${this.likeCount}`;
  }

  emit () {
    const newState = this.like === undefined ? true : !this.like;
    this.$emit('child-output', {
      // emit the opposite of the current like value
      like: newState,
    } as ALikeChildOutput);
  }
}
