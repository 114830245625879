
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ABadge extends Vue {
  @Prop({ default: 0 })
  count!: number;

  @Prop({default: 'top-left'})
  position!: 'top-left' | 'bottom-right';

  @Prop({ default: 'primary' })
  type!: 'primary' | 'secondary';
}
