import config from '@/config';

export const defaultMapPosition = {
  //default position is roughly the centre of the uk
  position: {
    type: 'Point',
    coordinates: [-4.087832161591848, 54.66146490422102]
  }
};

export default (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (window.google && window.google.maps) {
      return resolve();
    }
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.onload = () => {
      return resolve();
    };
    s.onerror = () => {
      return reject();
    };
    s.src = 'https://maps.googleapis.com/maps/api/js?key=' + config.keys.gmaps + '&v=weekly&channel=2&libraries=places';
    document.head.appendChild(s);
  });
};
