
import { Component, Prop, Vue } from 'vue-property-decorator';
import injectGoogleMapScript from '@/utils/injectGoogleMapScript';
import clickOutToMap from '@/utils/clickOutToMap';
import { Item } from '@/api/ms-item/services/interfaces';

@Component
export default class MItemDetailLocation extends Vue {
  @Prop()
  item!: Item;

  map: any;
  markers: any[] = [];
  icon: {
    url: string
  } = {
    url: '/img/icons/MapPinBlackGreenDotWhiteBorderThin30x34.png'
  };

  $refs!: {
    mapContainer: HTMLElement
  };

  async mounted () {
    if (typeof this.item.editable.geolocationData !== 'undefined' && typeof this.map === 'undefined') {
      await injectGoogleMapScript();
      this.loadMap();
    }
  }

  loadMap () {
    const lat = this.item.editable.geolocationData?.position.coordinates[1];
    const lng = this.item.editable.geolocationData?.position.coordinates[0];
    this.map = new window.google.maps.Map(this.$refs.mapContainer, {
      disableDefaultUI: true,
      mapTypeId: 'terrain',
      center: new window.google.maps.LatLng(lat, lng),
      streetViewControl: false,
      zoomControl: true,
      zoom: 16,
      mapTypeControl: true,
      fullscreenControl: true,
      gestureHandling: 'greedy'
    });

    const marker = new window.google.maps.Marker({
      position: {
        lat: lat,
        lng: lng
      },
      map: this.map,
      icon: this.icon
    });
    this.markers.push(marker);

    marker.addListener('click', async () => {
      clickOutToMap(this.item);
    });

    new window.google.maps.event.addListener(this.map, 'click', (event) => {
      this.addListenerClick(event);
    });
  }

  addListenerClick (event) {
    if (event.placeId) {
      event.stop();
    }
  }
}
