export enum RouteNames {
  ROUTE_ACTIVATE_ACCOUNT = 'activateAccount',
  ROUTE_UNSUB_WELCOME = 'unsubscribeWelcome',
  ROUTE_PEOPLE_MANAGEMENT = 'peopleManagement',
  ROUTE_CHANNEL_MANAGEMENT = 'channelManagement',
  ROUTE_CHANNEL_CREATE = 'channelCreate',
  ROUTE_CHANNEL_EDIT = 'channelEdit',
  ROUTE_CHANNEL_BUSINESS_VIEW = 'channelBusinessView',
  ROUTE_CHANNEL_VIEW = 'channelView',
  ROUTE_ITEM_DETAIL_VIEW = 'itemDetailView',
  ROUTE_ITEM_DETAIL_VIEW_COMMENTS = 'itemDetailViewComments',
  ROUTE_ITEM_DETAIL_VIEW_LIKES = 'itemDetailViewLikes',
  ROUTE_ITEM_RECOMMENDATION_DETAIL_VIEW = 'itemRecommendationDetailView',
  ROUTE_INFO_WHAT_IS = 'infoWhatIs',
  ROUTE_INFO_OUR_STORY = 'infoOurStory',
  ROUTE_INFO_ABOUT_US = 'infoAboutUs',
  ROUTE_INFO_WHAT_IS_AUTHED = 'infoWhatIsAuthed',
  ROUTE_INFO_BUSINESS = 'infoBusiness',
  ROUTE_INFO_CONTACT = 'infoContact',
  ROUTE_INFO_CAREERS = 'infoCareers',
  ROUTE_INFO_CAREERS_DEV_FS = 'infoCareersDevFs',
  ROUTE_INFO_CAREERS_DEV_FE = 'infoCareersDevFe',
  ROUTE_INFO_CAREERS_SALES = 'infoCareersSales',
  ROUTE_INFO_HELP = 'infoHelp',
  ROUTE_DASHBOARD = 'dashboard',
  ROUTE_GETGOT = 'getgot',
  ROUTE_LOST = 'lost',
  ROUTE_NOTIFICATIONS = 'notifications',
  ROUTE_NOTIFICATIONS_ID = 'notifications_id',
  ROUTE_PROFILE = 'profile',
  ROUTE_PASSWORD_RESET = 'passwordReset',
  ROUTE_SETTINGS = 'settings',
  ROUTE_SETTINGS_GENERAL = 'settingsGeneral',
  ROUTE_SETTINGS_SECURITY = 'settingsSecurity',
  ROUTE_SETTINGS_NOTIFICATIONS = 'settingsNotifications',
  ROUTE_SETTINGS_INVITATIONS = 'settingsInvitations',
  ROUTE_SETTINGS_WATCHERS = 'settingsWatchers',
  ROUTE_YOUR_PROFILE = 'yourProfile',
}
