export default (arr: any[], identifier?: string): any[] => {
  if (identifier) {
    if (identifier.includes('.')) {
      throw Error('Identifier cannot be nested.');
    }

    const uniqueArray: any[] = [];
    const map = new Map();

    for (const item of arr) {
      if (!map.has(item[identifier])){
        map.set(item[identifier], true);
        uniqueArray.push({ ...item });
      }
    }

    return uniqueArray;
  }
  else {
    return Array.from(new Set(arr));
  }
};
