export interface NotificationBaseAttributesArray {
  notificationSettings: NotificationSetting[];
}

export interface NotificationSetting {
  enabled: boolean;
  /**
   * Type of channel notification. To start with "item added". Future may include "new
   * member", "channel edited" or whatever
   */
  notificationType: NotificationType;
}

/**
 * Type of channel notification. To start with "item added". Future may include "new
 * member", "channel edited" or whatever
 */
export enum NotificationType {
  ItemAdded = 'itemAdded',
}
