import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import config from '@/config';
import AuthenticationService from '@/services/AuthenticationService';

export interface RequestObject {
  method: string;
  path: string;
  formData?: object;
  params?: object;
  qs?: object;
  body?: object;
  headers?: object;
  baseUrl?: string;
}

export default class HttpService {
  static headers: any = {};
  static abortControllers: Record<string, AbortController> = {};
  static abortable = ['ms-item', 'ms-channel'];

  static abortPreviousRequest (URL: string) {
    if (HttpService.abortControllers[URL] && this.abortable.find(str => URL.includes(str))) {
      try {
        HttpService.abortControllers[URL].abort();
      } catch (e) {
        // do nothing
      }
    }
  }

  static isTokenRoute (url: string): boolean {
    return url.includes('token/renew/access') || url.includes('token/renew/renewal');
  }

  /**
   * https://axios-http.com/docs/cancellation
   * Cancellation logic
   */
  static newAbortController (URL: string) {
    HttpService.abortControllers[URL] = new AbortController();
  }

  static removeAbortController (URL: string) {
    delete HttpService.abortControllers[URL];
  }

  /**
   * Make the http request via axios
   */
  // eslint-disable-next-line max-lines-per-function
  static async sendRequest (requestObject: RequestObject): Promise<any> {
    const baseUrl = requestObject.baseUrl || config.api.baseUrl;
    const URL = baseUrl + HttpService.injectParamsToPath(requestObject.params, requestObject.path);
    HttpService.abortPreviousRequest(URL);
    HttpService.newAbortController(URL);
    const headers: any = {};
    // We already have a auth header
    if (this.headers.Authorization && typeof this.headers.Authorization === 'string') {
      headers.Authorization = this.headers.Authorization;
    }
    // else, if this is not a token route being called, try and inject it
    else if (!HttpService.isTokenRoute(URL)) {
      // We do not already have the header for auth, so lets inject now
      // before we continue, if the user is authenticated
      const accessJWT = await AuthenticationService.getAccessJWT();
      if (accessJWT) {
        headers.Authorization = 'Bearer ' + accessJWT;
        HttpService.headers.Authorization = headers.Authorization;
      }
    }

    const axiosReq: AxiosRequestConfig = {
      headers: {
        ...requestObject.headers || {},
        ...headers
      },
      method: requestObject.method as unknown as Method,
      data: requestObject.body || {},
      params: requestObject.qs || {},
      url: URL,
      signal: HttpService.abortControllers[URL].signal
    };
    if (requestObject.formData) {
      axiosReq.headers = {
        ...axiosReq.headers,
        ...{ 'Content-Type': 'multipart/form-data' }
      };
      const formData = new FormData();
      for (const key in requestObject.formData) {
        formData.append(key, requestObject.formData[key]);
      }
      axiosReq.data = formData;
    }
    return new Promise((resolve, reject) => {
      axios(axiosReq).then((response: AxiosResponse) => {
        HttpService.removeAbortController(URL);
        if (response && response.data) {
          resolve(response.data);
        }
      }).catch((err: AxiosError) => {
        HttpService.removeAbortController(URL);
        reject(err);
      });
    });
  }

  /**
   * Injects the values into a path
   */
  static injectParamsToPath (params: object = {}, path: string) {
    Object.keys(params).forEach((param) => {
      path = path.replace(':' + param, params[param]);
    });
    return path;
  }
}
