
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class APinSvgIcon extends Vue {
  @Prop()
  pinned!: boolean;

  @Prop({ default: 1 })
  size!: number;

  width = 0;
  height = 0;

  viewBox = '';
  viewMinX = 0;
  viewMinY = -2;
  viewWidth = 11;
  viewHeight = 15;

  created () {
    this.width = 14 * this.size;
    this.height = 19 * this.size;
    const minYK = Math.ceil((1 - this.size) * 10);
    if (this.size > 1) {
      this.viewMinY -= minYK;
    } else if (this.size < 1) {
      this.viewMinY += minYK;
    }
    this.viewBox = `${this.viewMinX} ${this.viewMinY} ${this.viewWidth} ${this.viewHeight}`;
  }
}
