
import { Component, Prop, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import ALink from '@/components/atoms/link/ALink.vue';

@Component({
  components: { ALink, MLanguageSwitcher }
})
export default class MFooterGeneralSidebar extends Vue {
  @Prop({ required: true })
  footerLinks!: any[];
}
