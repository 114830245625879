
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { DatumLike, Pin } from '@/api/ms-item/services/interfaces/Items';
import { User } from '@/api/ms-authentication/services/interfaces';
import { translation } from '@/plugins/i18n/Translation';
import { AuthenticationStore, ItemsStore, WatchersStore } from '@/store';
import { format } from 'timeago.js';
import { ChannelType, FetchStatus, Getgot } from '@/api/ms-item/services/interfaces/Item';
import usersGetGotFromItem from '@/utils/usersGetGotFromItem';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';

import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkChannelView from '@/components/atoms/link/ARouterLinkChannelView.vue';
import ARouterLinkItemUrl from '@/components/atoms/link/ARouterLinkItemUrl.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ALikeIcon, { ALikeChildOutput } from '@/components/atoms/icon/ALikeIcon.vue';
import AShoppingListButton from '@/components/atoms/buttons/AShoppingListButton.vue';
import {
  AlertCircleIcon,
  CopyIcon,
  Edit2Icon,
  ExternalLinkIcon,
  MessageSquareIcon,
  MoreVerticalIcon,
  MoveIcon,
  RepeatIcon,
  Share2Icon,
  ShareIcon,
  ThumbsUpIcon,
  XIcon
} from 'vue-feather-icons';
import MYouTube from '@/components/molecules/video/MYouTube.vue';
import getYoutubeId from '@/utils/getYoutubeId';
import AYoutubePlay from '@/storybook-components/src/stories/atoms/AYoutubePlay.vue';
import ellipsisString from '@/utils/ellipsisString';
import isItemUrlCacheEmpty from '@/utils/isItemUrlCacheEmpty';
import getSpotifyEmbed from '@/utils/getSpotifyEmbed';
import MSpotifyPlayer from '@/components/molecules/video/MSpotifyPlayer.vue';
import ASpotifyPlay from '@/storybook-components/src/stories/atoms/ASpotifyPlay.vue';
import updateItemInStores from '@/utils/updateItemInStores';
import AMapOutLink from '@/components/atoms/link/AMapOutLink.vue';
import APinItemIcon, { APinItemChildOutput } from '@/components/atoms/icon/APinItemIcon.vue';
import AListenMenu from '@/components/atoms/AListenMenu.vue';
import ACommentIcon from '@/components/atoms/icon/ACommentIcon.vue';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import isTouchScreen from '@/utils/isTouchScreen';
import currentUserPinnedThisItem from '@/utils/currentUserPinnedThisItem';
import currentUserLikesThisItem from '@/utils/currentUserLikesThisItem';
import itemTotalCommentCount from '@/utils/itemTotalCommentCount';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import clickOutToMap from '@/utils/clickOutToMap';
import isApp from '@/utils/isApp';
import { extractHashtags } from 'common-utils/regex';
import MItemCardText from '@/components/molecules/MItemCardText.vue';
import MItemActionsDropdown from '@/components/molecules/MItemActionsDropdown.vue';
import AItemReminder from '@/components/atoms/AItemReminder.vue';
import AItemPrice from '@/components/atoms/AItemPrice.vue';
import ARouterLinkSettingWatchers from '@/components/atoms/link/ARouterLinkSettingWatchers.vue';
import ADecisionMakerIcon from '@/components/atoms/icon/ADecisionMakerIcon.vue';
import AAddItemIcon from '@/components/atoms/icon/AAddItemIcon.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import AAddItemButtonLink from '@/components/atoms/buttons/AAddItemButtonLink.vue';
import ALoadingIcon from '@/storybook-components/src/stories/atoms/icons/svg/ALoadingIcon.vue';

export interface ItemPreview {
  isPreview: boolean;
  loading?: boolean;
  message?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  customHeader?: string;
  backgroundRescrapeInProgress?: boolean;
}

@Component({
  components: {
    ALoadingIcon,
    AAddItemButtonLink,
    AAddItemIcon,
    ADecisionMakerIcon,
    ARouterLinkSettingWatchers,
    AItemPrice,
    AItemReminder,
    MItemActionsDropdown,
    MItemCardText,
    MButtonWithIcon,
    ATooltip,
    ACommentIcon,
    APinItemIcon,
    AListenMenu,
    AMapOutLink,
    ASpotifyPlay,
    MSpotifyPlayer,
    AYoutubePlay,
    MYouTube,
    ARouterLinkChannelView,
    AUserProfilePic,
    AImage,
    ALikeIcon,
    ARouterLinkIssueDetail,
    ARouterLinkItemUrl,
    ARouterLinkProfile,
    AShoppingListButton,
    AlertCircleIcon,
    CopyIcon,
    Edit2Icon,
    ExternalLinkIcon,
    RepeatIcon,
    MessageSquareIcon,
    MoveIcon,
    MoreVerticalIcon,
    ShareIcon,
    Share2Icon,
    ThumbsUpIcon,
    XIcon
  },
  filters: {
    formatHashTag: (inputString: string): string => {
      const tags = extractHashtags(inputString, true);
      tags.forEach((tag) => {
        inputString = inputString.replace(tag, '<a>' + tag + '</a>');
      });
      return inputString;
    },
    removeUrl: remove1stUrlFromString,
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    ellipsisString
  },
})
export default class OItemCard extends Vue {
  @Prop({ default: false })
  showGetGotControl!: boolean;

  @Prop(Object)
  item!: Item & {
    anonymous?: boolean,
    count?: number
  };

  @Prop(Object)
  currentUser!: User;

  @Prop({ default: false })
  isMapView!: boolean;

  @Prop({ default: false })
  smallWindow!: boolean;

  @Prop({required: false})
  imageMaxHeight?: number;

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      isPreview: false,
      loading: false,
      message: 'Fetching preview...',
      hideFooter: false,
      hideHeader: false,
      customHeader: ''
    })
  })
  preview!: ItemPreview;

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      isShown: false,
      title: 'Title'
    })
  })
  itemCardTitle!: any;

  $refs!: {
    itemCard: HTMLElement
  };

  cardHeight: number = 200;
  checkingForUrlCacheUpdate = false;
  likeLoading = false;
  pinLoading = false;
  isTouchScreen = isTouchScreen();
  showSpotify = false;
  showYouTube = false;
  ellipsisString = ellipsisString;

  get isAlreadyPriceWatched () {
    return WatchersStore.getWatchers.find((watcher) => watcher.itemUniqueName === this.item.uniqueItemName);
  }

  get itemWidth () {
    return ItemsStore.vScrollDims.cardWidth;
  }

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get itemTotalCommentCount () {
    return itemTotalCommentCount(this.item, this.currentUser);
  }

  get theCurrentUserLikesThisItem () {
    return currentUserLikesThisItem(this.item.likes, this.currentUser);
  }

  get itemTotalLikeCount () {
    const likes = this.item.likes?.filter((like: DatumLike) => {
      return like.like;
    });
    return likes ? likes.length : 0;
  }

  get theCurrentUserPinnedThisItem () {
    return currentUserPinnedThisItem(this.item.pins, this.currentUser);
  }

  get itemTotalPinCount () {
    const pins = this.item.pins?.filter((pin: Pin) => {
      return pin.pin;
    });
    return pins ? pins.length : 0;
  }

  get getYouTubeId (): string | null {
    return getYoutubeId(this.item.urlCache?.url || '');
  }

  get getSpotifyEmbed () {
    return getSpotifyEmbed(this.item.urlCache?.url);
  }

  get itemIsInChannel () {
    return !!(this.item.editable && this.item.editable.channel && this.item.editable.channel.slug);
  }

  get isChannelEvent () {
    return this.item.editable.channel?.channelType === ChannelType.Event;
  }

  get isResearchConcierge () {
    return this.item.editable.channel?.channelType === ChannelType.ResearchConcierge;
  }

  get isApp () {
    return isApp();
  }

  created () {}

  loadedImage () {
    this.cardHeight = this.$refs.itemCard.offsetHeight;
  }

  /**
   * Returns the getgot item for the current username, or undefined
   */
  currentUserGetGot (): Getgot | undefined {
    return usersGetGotFromItem(this.item, this.currentUser.username);
  }

  async handleCheckUpdateClick (e: Event) {
    e.preventDefault();
    this.checkingForUrlCacheUpdate = true;
    this.$emit('item-request-update', this.item);
    setTimeout(() => this.checkingForUrlCacheUpdate = false, 1000);
  }

  toggleShowYouTube () {
    this.showYouTube = !this.showYouTube;
  }

  async likeItem (input: ALikeChildOutput) {
    this.likeLoading = true;
    const response = await ItemsStore.itemLikeToggle({
      item: this.item,
      newLikeState: input.like,
      uniqueItemName: this.item.uniqueItemName,
    });
    this.likeLoading = false;

    updateItemInStores(response);
  }

  async pinItem (input: APinItemChildOutput) {
    this.pinLoading = true;
    const response = await ItemsStore.itemPinToggle({
      item: this.item,
      newPinState: input.pin,
      uniqueItemName: this.item.uniqueItemName,
    });
    this.pinLoading = false;

    updateItemInStores(response);
  }

  isItemAnon () {
    return !!this.item.anonymous;
  }

  hideFooter () {
    return this.isItemAnon() || this.preview.hideFooter;
  }

  hideHeader () {
    return this.preview.hideHeader;
  }

  clickOutToMap () {
    clickOutToMap(this.item);
  }

  getUserDescLineClamp (): number {
    return this.hasItemImage() || this.hasUserImage() || this.preview.loading ? 1 : 3;
  }

  hasUserDescription (): boolean {
    if (!this.item.urlCache?.url && this.item.editable.text.length > 0) {
      return true;
    }
    return (
        this.item.editable &&
        this.$options.filters?.removeUrl(this.item.editable.text).length > 0
    );
  }

  hasItemImage (): boolean {
    return !!this.item?.urlCache?.image?.href;
  }

  hasUserImage (): boolean | undefined {
    return this.item?.userPhotos && this.item.userPhotos.length > 0;
  }

  hasItemUrl (): boolean {
    return !isItemUrlCacheEmpty(this.item) || !!this.item.urlCache?.url;
  }

  hasItemUrlMeta (): boolean {
    return !!(this.item.urlCache && this.item.urlCache.meta && (this.item.urlCache.meta.title || this.item.urlCache.meta.description));
  }

  hasItemDescription (): boolean {
    return !!this.item?.urlCache?.meta?.description;
  }

  isInProgress (): boolean {
    return this.item?.urlCache?.fetchStatus === FetchStatus.InProgress;
  }

  showCopyItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_COPY, {
      uniqueItemName: this.item.uniqueItemName
    });
  }

  showLogin () {
    AuthenticationStore.TOGGLE_PROMPT_LOGIN(true);
  }
}
