
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Invitation, UserInvitationsAssignPost } from '@/api/ms-authentication/services/interfaces';
import UserService from '@/api/ms-authentication/services/UserService';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { ValidationObserver } from 'vee-validate';
import { InvitationHook } from '@/api/ms-authentication/services/interfaces/UserInvitationsAssignPost';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Type as HookType } from '@/api/ms-authentication/services/interfaces/Invitation';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver
  }
})
export default class MEmailInvitationForm extends Vue {
  @Prop({ required: false, default: '' })
  code!: string;
  @Prop({ required: false, default: '' })
  email!: string;
  @Prop({ required: false, default: false })
  emailDisabled!: boolean;
  @Prop({ required: false, default: '' })
  firstName!: string;
  @Prop({ required: false, default: '' })
  lastName!: string;
  @Prop({ required: false, default: '' })
  message!: string;
  @Prop({ required: false, default: false })
  messageDisabled!: boolean;
  @Prop({ required: false, default: () => [] })
  invitationHooks!: InvitationHook[];
  @Prop({ required: false, default: true })
  includeCodeToCopy!: boolean;

  loading: boolean = false;

  inviteForm: UserInvitationsAssignPost = {
    lastName: '',
    firstName: '',
    email: '',
    code: '',
    message: '',
    invitationHooks: []
  };

  get invitationOpener (): string {
    let opener = this.$t('page.invitations.sendingAnInviteOpener') as string;
    if (this.invitationHooks && this.invitationHooks.length > 0) {
      switch (this.invitationHooks[0].type) {
        case HookType.ChannelAccept:
          if (this.invitationHooks[0].payload) {
            const channelNameIndex = this.invitationHooks[0].payload.findIndex((el) => el.key === 'channelName');
            const channelName = this.invitationHooks[0].payload[channelNameIndex].value;
            opener = this.$t('page.invitations.sendingAnInviteToChannelOpener', { channelName }) as string;
          }
          break;
      }
    }
    return opener;
  }

  mounted () {
    this.inviteForm = {
      lastName: this.lastName,
      firstName: this.firstName,
      email: this.email,
      code: this.code,
      message: this.message, //this.$t('page.invitations.formMessage').toString()
      invitationHooks: this.invitationHooks
    };
  }

  async submitInviteForm () {
    this.loading = true;
    // send the form and then emit the result via the event bus to be picked up by whatever triggered this form
    const sent: Invitation = await UserService.userInvitationsAssignPost(this.inviteForm);
    EventBus.$emit(EventBusEvents.INVITE_BY_EMAIL_SENT, sent);
    this.$emit('close');
    this.loading = false;
  }
}
