
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ATooltip extends Vue {
  @Prop({
    required: true,
    type: String
  })
  tip!: string;

  @Prop({ default: 'is-bottom' })
  position!: string;

  @Prop({ default: false })
  dotted!: boolean;

  // if true will hide the question mark - i.e. if you want to use this instead of the title attribute
  @Prop({default: false})
  discreet!: boolean;

  @Prop()
  size?: string

  @Prop({default: true})
  multilined!: boolean;
}
