import HttpService from '@/services/HttpService';

import { ContentCategoriesGroups } from './interfaces/ContentCategoriesGroups';
import { ContentCategoriesLabelGet } from './interfaces/ContentCategoriesLabelGet';

export default class ContentCategoriesService {
  public static basePath = '/__/ms-static-api-data/';

  /**
   *  Operation ID: contentCategoriesGroupsGet
   *  Summary: Get groups, from content-categories
   *  Description: Returns an array of group names used for content categories
   */
  public static contentCategoriesGroupsGet(): Promise<ContentCategoriesGroups> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ContentCategoriesService.basePath + 'content-categories/groups',
    });
  }

  /**
   *  Operation ID: contentCategoriesLabelsGet
   *  Summary: Get labels, from content-categories
   *  Description: Returns an array of strings which are the labels used in content categorisation
   */
  public static contentCategoriesLabelsGet(): Promise<ContentCategoriesLabelGet> {
    return HttpService.sendRequest({
      method: 'GET',
      path: ContentCategoriesService.basePath + 'content-categories/labels',
    });
  }
}
