import { NavigationStore } from '@/store';
import ItemsService from '@/api/ms-item/services/ItemsService';
import { ItemsGetQuery } from '@/api/ms-item/services/interfaces';
import { SearchType } from '@/store/modules/enums/SearchType';
import { ItemViewType } from '@/store/modules/enums/ItemViewType';

interface Input {
  search: ItemsGetQuery;
  searchType: SearchType;
  itemViewType?: ItemViewType;
  pinned?: boolean;
}

// search: ItemsGetQuery, pinned?: boolean
export default (input: Input) => {
  // Decide which api to use based on auth state todo - route for pinned
  const fetchApi = input.pinned ? ItemsService.itemsPinnedGet : ItemsService.itemsGet;

  // Create the search object
  const searchQuery: ItemsGetQuery = Object.assign(
    {},
    input.search,
    { from: NavigationStore.getLastRouterHistoryFullpath }
  );

  // execute and return the promise
  return fetchApi(searchQuery);
};
