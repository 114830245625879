
import { Component, Prop, Vue } from 'vue-property-decorator';
import ACloseModalButton from '../atoms/buttons/ACloseModalButton.vue';

@Component({
  components: { ACloseModalButton }
})
export default class OModalWrapper extends Vue {
  @Prop({ default: false })
  showModal!: boolean;

  @Prop({ default: false })
  headAlignCenter!: boolean;

  @Prop({ default: false })
  hideClose!: boolean;

  @Prop({ default: false })
  overflowY!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({default: false})
  fullScreen!: boolean

  @Prop({default: false})
  canCancel!: boolean;

  get hasTitleSlot () {
    return this.$slots.title !== undefined;
  }

  get hasBodySlot () {
    return this.$slots.body !== undefined;
  }

  get hasFooterSlot () {
    return this.$slots.footer !== undefined;
  }
}
