
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';

import { LogOutIcon } from 'vue-feather-icons';

@Component({
  components: {
    LogOutIcon
  },
})
export default class ARouterLinkLogout extends Vue {
  logout () {
    AuthenticationStore.logout();
  }
}
