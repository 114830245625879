
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LinkIcon } from 'vue-feather-icons';
import formatUrl from '@/utils/formatUrl';
import clickoutUrl from '@/utils/clickoutUrl';
import AItemPrice from '@/components/atoms/AItemPrice.vue';

@Component({
  components: {
    AItemPrice,
    LinkIcon
  },
  filters: {
    formatUrl: formatUrl
  }
})
export default class ARouterLinkItemUrl extends Vue {
  @Prop({ required: true })
  url!: string;

  @Prop()
  classes!: string;

  @Prop()
  isBig!: boolean;

  @Prop()
  hasIcon!: boolean;

  sizeIcon = this.isBig ? '2x' : '14';

  getHref (url: string) {
    return clickoutUrl(url);
  }
}
