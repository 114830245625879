
import { Component, Prop, Vue } from 'vue-property-decorator';
import getYoutubeId from '@/utils/getYoutubeId';

@Component
export default class MYouTube extends Vue {
  @Prop({ required: false })
  url!: string;

  @Prop({ required: false })
  vidId!: string;

  @Prop({ default: 0 })
  autoPlay!: number;

  @Prop({ default: 0 })
  captions!: number;

  @Prop({ default: 0 })
  mute!: number;

  get isValid () {
    return (this.vidId || getYoutubeId(this.url));
  }

  get getIframeSrc (): string | undefined {
    return `https://www.youtube.com/embed/${(this.vidId || getYoutubeId(this.url))}?autoplay=${this.autoPlay}`;
  }
}
