
import { ArrowDownIcon, ArrowUpIcon, Trash2Icon } from 'vue-feather-icons';
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { CustomFieldEntry } from '@/components/molecules/forms/MChannelCustomFields.vue';
import { MeasurementSystem, Value } from '@/api/ms-static-api-data/services/interfaces/CustomFieldsUnitGroupeds';
import MChannelCustomFieldCustomSelect from '@/components/molecules/forms/MChannelCustomFieldCustomSelect.vue';

interface SelectUnit {
  fieldType?: FieldType,
  value?: string
}

@Component({
  components: {
    MChannelCustomFieldCustomSelect,
    MInputWithValidation,
    ArrowUpIcon,
    ArrowDownIcon,
    Trash2Icon,
  },
  filters: {
    strToLower: (str) => {
      return str.toLowerCase();
    }
  }
})
export default class MChannelCustomField extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: CustomFieldEntry;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({ required: true })
  dataTypes!: CustomFieldsUnitGroupeds;
  @Prop({ required: true })
  containerWidth!: number;
  @Prop({ required: true })
  showModeControls!: boolean;

  loading = true;
  isGrouped = true;
  groupedWidthBreakPoint = 500;
  measurementSystem: MeasurementSystem = MeasurementSystem.Metric;
  measurementSystems = MeasurementSystem;
  fieldTypes = FieldType;
  additionalDataNeeded = false;
  defaultCurrency: string = 'GBP';
  currencies: Value[] = [];

  selectModel: SelectUnit = {};

  value: CustomFieldEntry = {
    componentId: 0,
    label: '',
    fieldType: FieldType.Area,
    unit: '',
    options: []
  };

  created () {
    this.value = this.activeValue;
    if (this.value.fieldType && this.value.unit) {
      this.selectModel.fieldType = this.value.fieldType;
      this.selectModel.value = this.value.unit;
      this.determineMeasurementSystem();
    }
    this.setAdditionalDataNeeded();
    this.loading = false;
  }

  mounted () {
    this.storeCurrencies();
  }

  // when loading component with data already entered, figure out which measurement system the value is from
  determineMeasurementSystem () {
    const dataType = this.dataTypes.filter((el) => el.fieldType === this.value.fieldType)[0];
    const dataField = dataType.values.filter((el) => el.value === this.value.unit)[0];
    this.measurementSystem = dataField.measurementSystem === MeasurementSystem.Imperial ? MeasurementSystem.Imperial : MeasurementSystem.Metric;
    // if price set the default currency to what currency was selected
    if (this.value.fieldType === FieldType.Price) {
      this.defaultCurrency = this.value.unit;
    }
  }

  // prices are returned with data types, extract them into a variable for creating the drop down
  storeCurrencies () {
    this.currencies = this.dataTypes.filter((el) => el.fieldType === FieldType.Price)[0].values;
  }

  unitSelected (th: SelectUnit) {
    if (th.fieldType && th.value) {
      this.value.fieldType = th.fieldType;
      this.value.unit = th.value;
    }
  }

  setAdditionalDataNeeded () {
    this.additionalDataNeeded = this.selectModel.fieldType ?
      [FieldType.Price, FieldType.Rating, FieldType.CustomSelect].includes(this.selectModel.fieldType) :
      false;
  }

  // determines whether to display the symbol for the unit next to the name, type NA do not display unless Electrical or Sound
  unitOptionText (fieldType: FieldType, value: Value): string {
    const label = this.$t(`channel.customFields.units.${value.measurementSystem}.${value.key}`) as string;
    return value.measurementSystem === MeasurementSystem.Na && ![FieldType.Electrical, FieldType.Sound].includes(fieldType) ? label : `${label} (${value.value})`;
  }

  removeCustomField () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: this.$t('dict.areYouSure') as string,
      onConfirm: () => {
        this.$emit('removeCustomField');
      }
    });
  }

  @Watch('selectModel', { immediate: true })
  handleDataTypeSelection () {
    this.setAdditionalDataNeeded();
  }

  @Watch('value', { deep: true })
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }

  @Watch('containerWidth', { immediate: true })
  setGrouped () {
    this.isGrouped = this.containerWidth > this.groupedWidthBreakPoint;
  }
}
