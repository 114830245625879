import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import clone from '@/utils/clone';
import { ILifferyTourModule } from '@/store/modules/interfaces/LifferyTourModule';
import { ToursSeen } from '@/api/ms-authentication/services/interfaces/UserSettingsPut';

const initialState: ToursSeen = {
  channel: {
    chatOpened: 0
  },
  welcome: {
    takeTheTour: 0
  }
};

@Module({
  name: StoreNames.LIFFERY_TOUR_STORE,
  namespaced: true
})
export default class LifferyTourModule extends VuexModule implements ILifferyTourModule {
  @Mutation
  public RESET () {
    this.toursSeen = clone(initialState);
  }

  toursSeen: ToursSeen = clone(initialState);

  get getToursSeen () {
    return this.toursSeen;
  }

  @Action
  increaseWelcomeTourCount () {
    const currentCount = typeof this.toursSeen.welcome?.takeTheTour === 'undefined' ? 0 : this.toursSeen.welcome?.takeTheTour;
    this.SET_WELCOME_TOUR_COUNT(currentCount + 1);
  }

  @Action
  increaseDashboardTourCount () {
    const currentCount = typeof this.toursSeen.dashboard?.overview === 'undefined' ? 0 : this.toursSeen.dashboard?.overview;
    this.SET_DASHBOARD_OVERVIEW_TOUR_COUNT(currentCount + 1);
  }

  @Action
  increaseProfileTourCount () {
    const currentCount = typeof this.toursSeen.profile?.overview === 'undefined' ? 0 : this.toursSeen.profile?.overview;
    this.SET_PROFILE_OVERVIEW_TOUR_COUNT(currentCount + 1);
  }

  @Mutation
  REHYDRATE (input: ToursSeen) {
    if (input) {
      this.toursSeen = input;
    }
  }

  @Mutation
  CHANNEL_CHAT_OPENED (amount: number = 1) {
    if (this.toursSeen.channel && this.toursSeen.channel.chatOpened <= 2) {
      this.toursSeen.channel.chatOpened = amount;
    }
  }

  @Mutation
  SET_WELCOME_TOUR_COUNT (amount: number) {
    this.toursSeen.welcome = this.toursSeen.welcome || {
      takeTheTour: 0
    };
    this.toursSeen.welcome.takeTheTour = amount;
  }

  @Mutation
  SET_DASHBOARD_OVERVIEW_TOUR_COUNT (amount: number) {
    this.toursSeen.dashboard = this.toursSeen.dashboard || {
      overview: 0
    };
    this.toursSeen.dashboard.overview = amount;
  }

  @Mutation
  SET_PROFILE_OVERVIEW_TOUR_COUNT (amount: number) {
    this.toursSeen.profile = this.toursSeen.profile || {
      overview: 0
    };
    this.toursSeen.profile.overview = amount;
  }
}
