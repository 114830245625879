
import { Component, Prop, Vue } from 'vue-property-decorator';
import AChannelSwipeItemUp from '@/components/atoms/channel-swipe/AChannelSwipeItemUp.vue';
import AChannelSwipeItemDown from '@/components/atoms/channel-swipe/AChannelSwipeItemDown.vue';
import AChannelSwipeItemRight from '@/components/atoms/channel-swipe/AChannelSwipeItemRight.vue';
import AChannelSwipeItemLeft from '@/components/atoms/channel-swipe/AChannelSwipeItemLeft.vue';
import { Action } from '@/api/ms-item/services/interfaces/SwipeScores';

@Component({
  components: { AChannelSwipeItemLeft, AChannelSwipeItemRight, AChannelSwipeItemDown, AChannelSwipeItemUp }
})
export default class MChannelSwipeActionIcons extends Vue {
  @Prop({ required: true })
  direction!: Action;
  /**
   * Default use is on the swipe cards with position:absolute;. Set this to true to unset the absolute
   * position to use elsewhere, then wrap this component in a div with display:inline-block; to have
   * greater control over its placement
   */
  @Prop({ required: false, default: false })
  unsetPosition!: boolean;
  @Prop({ required: false, default: '5x' })
  iconSize!: string;
  @Prop({required: false, default: true})
  fillIcons!: boolean;

  SwipeDirections = Action;

  get wrapperClasses (): string {
    return [
      this.direction,
      this.direction === Action.SwipeLeft ? 'topRight' : 'topLeft',
      this.unsetPosition ? 'unsetPosition' : ''
    ].join(' ');
  }

  get iconPadding (): string {
    const iconSize = parseFloat(this.iconSize);
    const baseIconSize = 2.5;
    const basePadding = 1.5;
    if (iconSize === baseIconSize) {
      return basePadding + 'rem';
    } else if (iconSize < baseIconSize) {
      // looks bad once you start providing very small icon sizes, but works well otherwise
      const diff = basePadding - Math.abs((iconSize - baseIconSize) / 2);
      return diff > 0.5 ? diff + 'rem' : '.5rem';
    } else {
      // max padding of 3rem looks fine even at ultra large sizes
      const diff = ((iconSize - baseIconSize) / 2) + basePadding;
      return diff < 3 ? diff + 'rem' : '3rem';
    }
  }
}
