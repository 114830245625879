
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class APexelsSvgIcon extends Vue {
  @Prop({ default: 1 })
  size!: number;

  @Prop({default: 'currentColor'})
  fillColour!: string;

  width = 0;
  height = 0;

  viewBox = '';
  viewMinX = 0;
  viewMinY = 0;
  viewWidth = 32;
  viewHeight = 32;

  created () {
    this.width = this.viewWidth * this.size;
    this.height = this.viewHeight * this.size;
    const minYK = Math.ceil((1 - this.size) * 10);
    if (this.size > 1) {
      this.viewMinY -= minYK;
    } else if (this.size < 1) {
      this.viewMinY += minYK;
    }
    this.viewBox = `${this.viewMinX} ${this.viewMinY} ${this.viewWidth} ${this.viewHeight}`;
  }
}
