import HttpService from '@/services/HttpService';

import { HashtagSearchGetQuery } from './interfaces/HashtagSearchGetQuery';
import { Tags } from './interfaces/Tags';

export default class HashtagService {
  public static basePath = '/__/ms-hashtags/';

  /**
   *  Operation ID: hashtagSearchGet
   *  Summary: Get search, from hashtag
   *  Description:
   */
  public static hashtagSearchGet(query: HashtagSearchGetQuery): Promise<Tags> {
    return HttpService.sendRequest({
      method: 'GET',
      path: HashtagService.basePath + 'hashtag/search',
      qs: query,
    });
  }
}
