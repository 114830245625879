import { InjectType, ssoScriptInjector } from '@/utils/ssoScriptInjector';
import config from '@/config';
import { LoginGooglePost } from '@/api/ms-authentication/services/interfaces';

export const SSOGoogle = (): Promise<LoginGooglePost> => {
  return new Promise((resolve, reject) => {
    const injectedId = 'liffery-google-single-sign-on-script';
    const errorHandler = (e) => {
      reject('Google login error');
      console.error(e);
    };
    const auth = () => {
      const gapi = window.gapi;
      gapi.load('auth2', () => {
        const goog = gapi.auth2.init({
          client_id: config.sso.googleClientId
        });
        goog.signIn({})
          .then((resp) => {
            for (const key in resp) {
              if (resp[key].access_token) {
                resolve({ accessToken: resp[key].access_token });
              }
            }
          }).catch(errorHandler);
      });
    };
    ssoScriptInjector(injectedId, InjectType.google).then(auth).catch(errorHandler);
  });
};
