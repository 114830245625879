
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LoaderIcon } from 'vue-feather-icons';

@Component({
  components: {
    LoaderIcon
  }
})
export default class ALoadingIcon extends Vue {
  @Prop({ default: '1x' })
  size?: string;
}
