import { render, staticRenderFns } from "./MAuthenticationTabs.vue?vue&type=template&id=93ef48bc&scoped=true&"
import script from "./MAuthenticationTabs.vue?vue&type=script&lang=ts&"
export * from "./MAuthenticationTabs.vue?vue&type=script&lang=ts&"
import style0 from "./MAuthenticationTabs.vue?vue&type=style&index=0&id=93ef48bc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93ef48bc",
  null
  
)

export default component.exports