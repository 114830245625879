import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { Recommendation } from '@/api/ms-item/services/interfaces';
import { initialItemState as itemDetailInitialItemState } from '@/store/modules/interfaces/ItemDetailModule';
import { RecommendState, RecommendType } from '@/api/ms-item/services/interfaces/Recommendation';

export interface IRecommendationDetailModule extends IStoreModule {
  recommendationDetail: Recommendation
}

export const initialItemState: Recommendation = {
  ...itemDetailInitialItemState,
  ...{
    recommendedTo: {
      connection: {
        firstName: '',
        lastName: '',
        username: ''
      },
      message: '',
      recommendState: RecommendState.Pending,
      recommendType: RecommendType.Connection
    }
  }
};
