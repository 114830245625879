import HttpService from '@/services/HttpService';

import { Setting } from './interfaces/Setting';
import { SettingGetQuery } from './interfaces/SettingGetQuery';

export default class SettingService {
  public static basePath = '/__/ms-app-settings/';

  /**
   *  Operation ID: settingGet
   *  Summary: Get setting
   *  Description:
   */
  public static settingGet(query: SettingGetQuery): Promise<Setting> {
    return HttpService.sendRequest({
      method: 'GET',
      path: SettingService.basePath + 'setting',
      qs: query,
    });
  }
}
