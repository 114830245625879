
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { Channel, ChannelSlugSlugReportPost } from '@/api/ms-channel/services/interfaces';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver
  }
})
export default class OChannelReportForm extends Vue {
  @Prop({ required: true })
  channel!: Channel;

  loading = false;

  form!: ChannelSlugSlugReportPost;

  created () {
    this.form = {
      reportBody: '',
      ...this.channel
    };
  }

  onSubmit () {
    this.loading = true;
    ChannelService.channelSlugSlugReportPost(
      this.form,
      { slug: this.channel.slug }
    );
    this.loading = false;
    this.$emit('close');
  }
}
