
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Cookie from '@/services/Cookies';
import { CookieNames } from '@/constants/CookieNames';
import { getDateInXMonths } from 'common-utils/time';
import config from '@/config';

@Component
export default class MAgreeToTcsCs extends Vue {
  @Prop({ default: 'mt-4' })
  marginTop!: string;

  form: { agreed: boolean } = {
    agreed: false
  };

  get links () {
    return config.legal;
  }

  @Watch('form.agreed', { immediate: true })
  agreedHandle () {
    if (this.form.agreed) {
      Cookie.set(
        CookieNames.PRIVACY_POLICY_AGREED,
        new Date().toUTCString(),
        { expires: getDateInXMonths(18), }
      );
      Cookie.set(
        CookieNames.TERMS_OF_USE_AGREED,
        new Date().toUTCString(),
        { expires: getDateInXMonths(18), }
      );
      this.$emit('agreed');
    } else {
      Cookie.remove(CookieNames.PRIVACY_POLICY_AGREED);
      Cookie.remove(CookieNames.TERMS_OF_USE_AGREED);
      this.$emit('disagreed');
    }
  }
}
