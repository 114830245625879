
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import { CustomField as ItemCustomField } from '@/api/ms-item/services/interfaces/ItemPost';
import { CustomField as ChannelCustomField, FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { CustomFieldsUnitGrouped } from '@/api/ms-static-api-data/services/interfaces/CustomFieldsUnitGroupeds';
import MItemEntryCustomFieldLabel from '@/components/molecules/itemEntry/MItemEntryCustomFieldLabel.vue';
import { StarIcon } from 'vue-feather-icons';
import StaticApiDataService from '@/services/StaticApiDataService';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import CustomFields from '@/services/CustomFields';

@Component({
  components: {
    MItemEntryCustomFieldLabel,
    MInputWithValidation,
    DatePicker,
    StarIcon
  }
})
export default class MItemEntryCustomFields extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ItemCustomField;

  @Prop({ required: true })
  channelCustomFieldData!: ChannelCustomField;

  @Prop({ required: true })
  currenciesUnique!: Currency[];

  @Prop({ required: true })
  fieldNumber!: number;

  @Prop({ required: true })
  allChannelUnitData!: CustomFieldsUnitGroupeds;

  fieldTypes = FieldType;
  maxRatingDisplay = 10;
  value: ItemCustomField = {
    _id: '',
    fieldType: FieldType.Area,
    unit: '',
    value: ''
  };

  get channelUnitDisplay (): string | undefined {
    return CustomFields.channelUnitDisplay(this.channelCustomFieldData);
  }

  get channelUnitData () {
    let unitData;
    this.allChannelUnitData.forEach((allChannelUnitData: CustomFieldsUnitGrouped) => {
      if (allChannelUnitData.fieldType === this.value.fieldType) {
        unitData = allChannelUnitData.values.find((value) => {
          return value.value === this.value.unit;
        });
      }
    });
    return unitData;
  }

  get getInputType (): string {
    if (this.channelUnitData.dataType === 'string') {
      return 'text';
    } else if (this.channelUnitData.dataType === 'date' && this.channelUnitData.value === 'year') {
      return 'number';
    } else {
      return this.channelUnitData.dataType;
    }
  }

  get getInputDecimal (): boolean {
    return !(this.channelUnitData.dataType === 'date' && this.channelUnitData.value === 'year');
  }

  get getMinValue () {
    return this.channelCustomFieldData.unit === 'compassDegrees' ? 0 : '';
  }

  get getMaxValue () {
    return this.channelCustomFieldData.unit === 'compassDegrees' ? 360 : '';
  }

  get getCompassDirections (): { label: string, value: number }[] {
    return StaticApiDataService.compassData[this.channelCustomFieldData.unit];
  }

  created () {
    this.setValue();
  }

  /**
   * The values need to be cast for different input types
   * Specifically date inputs.
   */
  setValue () {
    this.value = this.activeValue;
    if (!this.value.value || this.value.value === '') {
      if (this.channelCustomFieldData.fieldType !== 'rating') {
        return;
      } else {
        // @ts-ignore
        this.value.value = 0;
      }
    } else if (this.channelUnitData.dataType === 'date' && this.channelUnitData.value === 'year') {
      // @ts-ignore
      this.value.value = new Date(this.value.value).getFullYear();
    } else if (this.channelUnitData.dataType === 'date') {
      this.value.value = new Date(this.value.value).toISOString().substring(0, 10);
    } else if (this.channelUnitData.dataType === 'number') {
      // @ts-ignore
      this.value.value = Number(this.value.value);
    }
  }

  @Watch('value', { deep: true })
  updateValue () {
    const valueToEmit = JSON.parse(JSON.stringify(this.value)) as ItemCustomField;
    valueToEmit.value = String(valueToEmit.value);
    this.$emit('updateModelValue', valueToEmit);
  }
}
