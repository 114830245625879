import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  Item,
  ItemNameUniqueItemNameCommentCommentIdReplyPost,
  ItemNameUniqueItemNameCommentPost
} from '@/api/ms-item/services/interfaces';
import ItemService from '@/api/ms-item/services/ItemService';
import { StateName } from '@/api/ms-item/services/interfaces/ItemNameUniqueItemNameGetgotIdIdPatch';
import { ItemsStore, NavigationStore } from '@/store';
import { IItemDetailModule, initialItemState } from '@/store/modules/interfaces/ItemDetailModule';
import { ForActor } from '@/api/ms-item/services/interfaces/ItemGetgot';

@Module({
  name: StoreNames.ITEM_DETAIL_STORE,
  namespaced: true,
})
export default class ItemDetailModule extends VuexModule implements IItemDetailModule {
  @Mutation
  public RESET () {
    this.itemDetail = initialItemState;
  }

  itemDetail: Item = initialItemState;

  get getItemDetail () {
    return this.itemDetail;
  }

  get getItemComments () {
    return this.itemDetail.comments || [];
  }

  get getItemLikes () {
    return this.itemDetail.likes || [];
  }

  get getItemPins () {
    return this.itemDetail.pins || [];
  }

  get getItemLists () {
    return this.itemDetail.getgot || [];
  }

  get getItemUniqueItemName () {
    return this.itemDetail.uniqueItemName;
  }

  @Action({ rawError: true })
  public async fetch (uniqueItemName: string) {
    this.ITEM_CLEAR_SET(
      await ItemService.itemNameUniqueItemNameGet({
        uniqueItemName: uniqueItemName
      }, {
        from: NavigationStore.getLastRouterHistoryFullpath
      })
    );
  }

  @Action
  public async itemLikeToggle (input: { newLikeState: boolean, uniqueItemName: string }): Promise<Item> {
    const { newLikeState, uniqueItemName } = input;
    const item = await ItemService.itemNameUniqueItemNameLikePatch({
      like: newLikeState
    }, {
      uniqueItemName
    });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  public async itemPinToggle (input: { newPinState: boolean, uniqueItemName: string }): Promise<Item> {
    const { newPinState, uniqueItemName } = input;
    const item = await ItemService.itemNameUniqueItemNamePinPatch({
      pin: newPinState
    }, {
      uniqueItemName
    });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentSubmitNew (body: ItemNameUniqueItemNameCommentPost): Promise<Item> {
    const { uniqueItemName } = this.itemDetail;
    const item = await ItemService.itemNameUniqueItemNameCommentPost(body, { uniqueItemName });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentSubmitNewReply (input: { body: ItemNameUniqueItemNameCommentCommentIdReplyPost, commentId: string }): Promise<Item> {
    const { uniqueItemName } = this.itemDetail;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdReplyPost(
      input.body,
      {
        uniqueItemName,
        commentId: input.commentId
      }
    );
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentReplyLikePatch (input: { commentId: string, replyCommentId: string, like: boolean }): Promise<Item> {
    const { like, commentId, replyCommentId } = input;
    const { uniqueItemName } = this.itemDetail;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdLikePatch({ like }, {
      uniqueItemName,
      commentId,
      replyCommentId
    });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentSubmitPatch (input: { comment: string, commentId: string }): Promise<Item> {
    const { uniqueItemName } = this.itemDetail;
    const { commentId, comment } = input;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdPatch({ comment }, {
      uniqueItemName,
      commentId
    });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentReplyDelete (input: { commentId: string, replyCommentId: string }): Promise<Item> {
    const { uniqueItemName } = this.itemDetail;
    const { commentId, replyCommentId } = input;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdDelete({
      commentId,
      replyCommentId,
      uniqueItemName
    });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentDelete (input: { commentId: string }): Promise<Item> {
    const { uniqueItemName } = this.itemDetail;
    const { commentId } = input;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdDelete({ commentId, uniqueItemName });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentLikePatch (input: { commentId: string, like: boolean }): Promise<Item> {
    const { like, commentId } = input;
    const { uniqueItemName } = this.itemDetail;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdLikePatch({ like }, {
      uniqueItemName,
      commentId
    });
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async itemCommentReplySubmitPatch (input: { comment: string, commentId: string, replyCommentId: string }): Promise<Item> {
    const { uniqueItemName } = this.itemDetail;
    const { commentId, comment, replyCommentId } = input;
    const item = await ItemService.itemNameUniqueItemNameCommentCommentIdReplyReplyCommentIdPatch(
      { comment },
      {
        commentId,
        uniqueItemName,
        replyCommentId
      }
    );
    this.ITEM_SET(item);
    return item;
  }

  @Action
  async getgotStateUpdate (input: { itemName: string, username: string, privateState: boolean, getGotId: string, stateName: StateName, forActor: ForActor }): Promise<Item> {
    const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdPatch({
      private: input.privateState,
      stateName: input.stateName,
      forActor: input.forActor,
    }, {
      id: input.getGotId,
      uniqueItemName: input.itemName || this.itemDetail.uniqueItemName
    });
    this.ITEM_SET(newItem);
    return newItem;
  }

  @Action
  async getgotStateDelete (input: { itemName: string, getGotId: string }): Promise<Item> {
    const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdDelete({
      id: input.getGotId,
      uniqueItemName: input.itemName || this.itemDetail.uniqueItemName
    });
    this.ITEM_SET(newItem);
    return newItem;
  }

  @Action
  async deleteItem (input: { uniqueItemName: string }) {
    const { uniqueItemName } = input;
    // now send the deletion to the api
    await ItemService.itemNameUniqueItemNameDelete({ uniqueItemName });

    // remove the item from the items store
    ItemsStore.CLEAR_ITEMS();
  }

  @Mutation
  ITEM_SET (item: Item) {
    this.itemDetail = Object.assign(this.itemDetail, item);
  }

  @Mutation
  ITEM_CLEAR_SET (item: Item) {
    this.itemDetail = item;
  }
}
