export interface Invitation {
  allowMulti?: boolean;
  code: string;
  createdAt: Date;
  /**
   * Array of recipients of this particular invitation code
   */
  invitedTo?: InvitedTo[];
  updatedAt: Date;
  usedBy?: UsedBy;
  usedByMany?: UsedByMany[];
}

/**
 * Who is receiving the invitation, an optional message with it, and any post-invitation
 * hooks to be executed after signup
 */
export interface InvitedTo {
  createdAt?: Date;
  email: string;
  firstName: string;
  /**
   * An array of post-invitation hooks to implement after a user signs up, e.g. automatically
   * signup to a channel
   */
  invitationHooks?: InvitationHook[];
  lastName: string;
  message?: string;
  updatedAt?: Date;
}

export interface InvitationHook {
  /**
   * If more than a unique reference is required to execute a hook, or more data is needed to
   * display a human understandable message, build up an array of key:value pairs to hold the
   * rest of that data.
   */
  payload?: Payload[];
  type: Type;
  /**
   * Relevant to the type of hook this is, e.g. channel slug for a channelAccept or whatever
   */
  uniqueRef: string;
}

export interface Payload {
  key: string;
  value: string;
}

export enum Type {
  ChannelAccept = 'channelAccept',
}

export interface UsedBy {
  actor: UsedByActor;
  createdAt: Date;
  updatedAt: Date;
}

export interface UsedByActor {
  firstName: string;
  lastName: string;
  username: string;
}

export interface UsedByMany {
  actor: UsedByManyActor;
  createdAt: Date;
  updatedAt: Date;
}

export interface UsedByManyActor {
  firstName: string;
  lastName: string;
  username: string;
}
