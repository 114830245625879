
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { SettingsIcon } from 'vue-feather-icons';

@Component({
  components: {
    SettingsIcon
  }
})
export default class ARouterLinkSettings extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_SETTINGS_GENERAL;
}
