
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';

@Component({
  components: { MRadioCards, ATooltip }
})
export default class MChannelPrivacySettingsWhoCanAdd extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: boolean;
  @Prop({ required: false, default: false })
  recalculateAccordion!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  value: boolean = true;

  created () {
    this.value = this.activeValue;
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
